import React from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import acc from 'accounting';
import './StoreTrackerPageTabs.less';

const tabs = [
  {
    title: 'Overview',
    key: 'overview',
    count: false
  },
  {
    title: 'Sales & Revenue',
    key: 'sales_&_revenue',
    count: false
  },
  {
    title: 'Competitors',
    key: 'competitors',
    count: 10
  },
  {
    title: 'Facebook ads',
    key: 'facebook_ads',
    count: 1234
  },
  {
    title: 'Products',
    key: 'products',
    count: 24
  },
  {
    title: 'Apps',
    key: 'apps',
    count: 2
  },
]

const StoreTrackerPageTabs = (
  {
    active,
    setActive
  }) => {

  const {t} = useTranslation();

  return (
    <div className={'store-tracker-page-tabs'}>
      {
        tabs.map(el => (
          <span key={el.key}
                className={cls('store-tracker-page-tab', {
                  active: active === el?.key,
                  disabled: false,
                })}
                onClick={() => setActive(el?.key)}
          >
            {t(el?.title)}
            {
              el?.count || el?.count === 0 ?
                <span className="count">
                  {acc.formatNumber(el?.count, 0, ',')}
                </span>
                :
                null
            }
          </span>
        ))
      }
    </div>
  );
};

export default StoreTrackerPageTabs;
