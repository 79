import React from 'react';
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import ButtonComponent from "../../../Components/Button";
import { Divider } from 'antd';
import Modal from '../../../Components/Modal';

export const CanNotRemoveTrackingContent = ({ setModal, isMobile }) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {
              t('Free trial users can not remove a store/product from tracking. To access this feature, you need to activate your membership.')
            }
          </p>
        </div>
        <Divider type={'horizontal'}
                 style={{ margin: '24px 0' }}
        />
        <div className={'collection-modal-btn-wrapper'}>
          <ButtonComponent className="collection-modal-btn collection-modal-btn-save wide"
                           disabled={false}
                           onClick={() => {
                             setModal(null);
                             navigate('/setting/plan');
                           }}
                           text={t('Go to billing')}
          />
        </div>
        {
          isMobile ?
            null
            :
            <div style={{ height: 16 }} />
        }
    </>
  )
}

const CanNotRemoveTracking = (
  {
    isMobile,
    setModal,
    visible
  }
) => {
  const {t} = useTranslation();

  return (
    <Modal handleOk={null}
           handleClose={() => {
             setModal(null);
           }}
           title={t('Can not remove tracking')}
           isMobile={isMobile}
           width={450}
           className="collection-list-modal collection-modal"
           open={visible}
           destroyOnClose
    >
        <CanNotRemoveTrackingContent setModal={setModal} isMobile={isMobile}/>
    </Modal>
  );
};

export default CanNotRemoveTracking;
