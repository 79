import React from 'react';
import {OverlayScrollbar} from "../../../Components/ScrollBar";

const AdItemPageSkeleton = (
  {
    isMobile
  }
) => {
  return (
    <OverlayScrollbar visibility="hidden">
      <div className={"fadspot-page-inner-content"} style={{ display: "flex", flexDirection: "column" }}>
        {isMobile ? (
           <span className="link skeleton" style={{height: 55, width: "100%"}}/>
        ) : (
          <div className="overview-advertiser-slider">
            <span className={'link skeleton'}
                  style={{ width: 160, height: 20 }}
            />
            <div className="overview-advertiser-slider-items"
                style={{marginLeft: 12}}
            >
              {
                [1, 2, 3].map(el => (
                  <div className="overview-advertiser-slider-item"
                      key={el}
                      style={{width: 220, height: 40}}
                  >
                    <span className={'link skeleton'}
                          style={{ width: 24, height: 24, borderRadius: '25%' }}
                    />
                    <span className={'link skeleton'}
                          style={{ width: 100, height: 20 }}
                    />
                    <span className={'link skeleton'}
                          style={{ width: 50, height: 20 }}
                    />
                  </div>
                ))
              }
            </div>
            <div className="overview-advertiser-slider-arrows">
              <span className={'link skeleton'}
                    style={{ width: 20, height: 20 }}
              />
              <span className={'link skeleton'}
                    style={{ width: 20, height: 20 }}
              />
            </div>
          </div>
        )}
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", flexDirection: isMobile ? "column" : "row" }}>
          <span className="link skeleton" style={{height: isMobile ? 40 : 40, width: 69, margin: isMobile ? "24px 0px 16px 16px" : "24px 32px"}}/>
          <span className="link skeleton" style={{height: isMobile ? 40 : 40, width: isMobile ? "90%" : 196, margin: isMobile ? "0px 16px 16px" : "24px 32px"}}/>
        </div>
        <div className={'fadspot-page-items-wrapper'}>
          <span className="fadspot-page-item-wrapper link skeleton"/>
          {
            isMobile ?
              null
              :
              <span className="fadspot-page-item-wrapper link skeleton"/>
          }
          {
            isMobile ?
              null
              :
              <span className="fadspot-page-item-wrapper link skeleton"/>
          }
          {
            isMobile ?
              null
              :
              <span className="fadspot-page-item-wrapper link skeleton"/>
          }
          {
            isMobile ?
              null
              :
              <span className="fadspot-page-item-wrapper link skeleton"/>
          }
          {
            isMobile ?
              null
              :
              <span className="fadspot-page-item-wrapper link skeleton"/>
          }
        </div>
      </div>
      
    </OverlayScrollbar>
  );
};

export default AdItemPageSkeleton;
