import React from 'react'
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import  './CompareHeaders.less';
import SwitchBlockSettings from './SwitchBlockSettings';
import AvailablePlans from './AvailablePlans';

const CompareHeaders = (
  {
    onBoarding,
    onChangeSwitch,
    handleSelectPlan,
    setModal,
    setCancelTrial,
    setUnpauseSub
  }) => {
    const {
      isYearlySubscription,
    } = onBoarding;

  const { t } = useTranslation();

  return (
    <div className='compare-headers-wrapper'>
      <div className='compare-headers-first-section'>
        <div className='switch-block-settings-header'>{t('Save up to 40% with yearly billing')}</div>
        <SwitchBlockSettings
          isYearlySubscription={isYearlySubscription}
          onChangeSwitch={onChangeSwitch}
          t={t}
        />
    </div>
        <AvailablePlans onChangeSwitch={onChangeSwitch}
                        handleSelectPlan={handleSelectPlan}
                        setModal={setModal}
                        setCancelTrial={setCancelTrial}
                        setUnpauseSub={setUnpauseSub}
        />
      </div>

  )
}
const mapStateToProps = (state) => ({
  onBoarding: state.onBoarding,
});


const mapDispatchToProps = (dispatch) => ({
  setYearlySubscription: (data) => dispatch(OnboardingCreators.setYearlySubscription(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompareHeaders);
