import React, { useEffect, useRef } from 'react';
import acc from 'accounting';
import { Spin, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SaveToCollection from '../../../../Components/SaveToCollections/save-to-collections';
import EmptyState from '../../../../Components/Products/empty-state';
import utils from '../../../../Utils/utils';

const ProductsComponent = (
  {
    products = [],
    loading = false,
    searchText,
    getNextList,
    next,
    addToCollectionFunc,
    isMobile = false,
    setInputText,
    setView
  }) => {

  const productsRef = useRef();
  const { t } = useTranslation();

  // GET NEXT PRODUCT HANDLER (INFINITY SCROLL)
  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;

    if (currentTopScroll >= maxTopScroll && !loading && !!next) {
      getNextList();

      // remove event listener after call
      event.target.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  // ADD EVENT LISTENER
  useEffect(() => {
    const productsContent = productsRef.current;
    if (productsContent) productsContent.addEventListener('scroll', onScrollHandlerFunc);

    return () => {
      if (productsContent) productsContent.removeEventListener('scroll', onScrollHandlerFunc);
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [next]);

  // SCROLL TO TOP WHEN INPUT TEXT
  useEffect(() => {
    scrollToTop();
  }, [searchText]);

  // SCROLL TO TOP
  const scrollToTop = () => {
    const productsContent = productsRef.current;

    if (productsContent) {
      productsContent.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className={'products-overview'}>
      {
        products?.length > 0 ?
          <Spin spinning={loading}>
            <div className="products-overview_wrapper"
                 ref={productsRef}
            >
              {
                products.map((value) => (
                  <div className="product"
                       key={`product-${value.product_id}`}
                  >
                    <Link to={`collections/product/${value.product_id}`}
                          className="product_image"
                          state={value}
                    >
                      {value.image && <img src={value?.image?.url} alt="images" onError={utils.addDefaultSrc} />}
                    </Link>
                    <div className="product_wrap"
                         id="product_wrap"
                    >
                      <Tooltip overlayClassName="product_name-tooltip details-tooltip"
                               title={value.title}
                               trigger={isMobile ? 'click' : 'hover'}
                               destroyTooltipOnHide={true}
                               getPopupContainer={() =>
                                 document.getElementById('product_wrap')
                      }
                      >
                        <Link to={`collections/product/${value.product_id}`}
                              className="product_name"
                        >
                          {value.title}
                        </Link>
                      </Tooltip>
                    </div>
                    <div className="product-detail_price">
                      ${acc.formatNumber(value.price, 2, ' ', ',')}
                    </div>
                    <div className="product_details">
                      <div className="product-detail">
                        <div className="product-detail_title">
                          {t('Cost')}
                        </div>
                        <div className="product-detail_value">
                          {value.cost ? value?.cost?.replaceAll('.', ',') : 0}
                        </div>
                      </div>
                      <div className="product-detail">
                        <div className="product-detail_title">
                          {t('P/C Ratio')}
                        </div>
                        <div className="product-detail_value">
                          {acc.formatNumber(value.p_c_ratio, 2, ' ', '.')}X
                        </div>
                      </div>
                      <div className="product-detail">
                        <div className="product-detail_title">
                          {t('BECPA')}
                        </div>
                        <div className="product-detail_value">
                          ${acc.formatNumber(value.BECPA, 2, ' ', ',')}
                        </div>
                      </div>
                      <div className="product-detail">
                        <div className="product-detail_title">
                          {t('POT Profit')}
                        </div>
                        <div className="product-detail_value">
                          ≈${acc.formatNumber(value.pot_profit, 0, ',', ',')}
                        </div>
                      </div>
                    </div>
                    <div className={'product-btn-wrapper'}>
                      <div className={'product_view-details'}>
                        <SaveToCollection isDisabled={false}
                                          saveFunc={(isSaved, defaultCollectionName) =>
                                            addToCollectionFunc(
                                              value.product_id,
                                              isSaved,
                                              value.title,
                                              defaultCollectionName
                                            )
                        }
                                          isSave={value.saved}
                        />
                      </div>
                      <Link to={`collections/product/${value.product_id}`}
                            state={{ ...value }}
                            className="product_view-details wide"
                      >
                        {t('Product insights')}
                      </Link>
                    </div>
                  </div>
                ))}
            </div>
          </Spin>
          :
          <EmptyState setInputText={(value) => {
              setInputText(value);
              setView('collections');
          }}
                      btnText={searchText?.length ? 'Clear search' : 'Go back'}
          />
      }
    </div>
  );
};

export default ProductsComponent;
