import React from 'react';

const NotificationTrackingError = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M9.99962 7.29099V10.6243M9.99962 12.916V12.9077M8.56706 3.03363L2.33041 13.5225C1.66983 14.6335 2.47044 16.041 3.76297 16.041H16.2363C17.5288 16.041 18.3294 14.6335 17.6688 13.5225L11.4322 3.03362C10.7861 1.94712 9.21308 1.94713 8.56706 3.03363ZM10.2079 12.916C10.2079 13.031 10.1147 13.1243 9.99962 13.1243C9.88456 13.1243 9.79128 13.031 9.79128 12.916C9.79128 12.8009 9.88456 12.7077 9.99962 12.7077C10.1147 12.7077 10.2079 12.8009 10.2079 12.916Z"
        stroke="#D71313" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};

export default NotificationTrackingError;
