import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import cls from 'classname';
import API from '../../Services/Api';
import InputComponent from '../Input';
import { CardComponent, CardCVV, CardExpiry, CardNumber } from '@chargebee/chargebee-js-react-wrapper';
import ArrowSelectDown from '../../Icon/img/ArrowSelectDown';
import Modal from '../../Components/Modal';
import { Divider, Dropdown, Spin } from 'antd';
import ButtonComponent from '../Button';
import Icon from '../../Icon';


const style = {
  light: {
    base: {
      color: '#151E3A',
      fontFamily: 'Inter, sans-serif',
      fontWeight: '500',
      fontSize: '14px',
      letterSpacing: '-0.3px',

      ':focus': {
        color: '#151E3A',
      },

      '::placeholder': {
        color: '#A1AAC8',
      },

      ':focus::placeholder': {
        color: '#A1AAC8',
      },
    },

    // Styles for invalid field state
    invalid: {
      color: '#D71313',

      ':focus': {
        color: '#D71313',
      },
      '::placeholder': {
        color: '#D71313',
      },
    },
  },
  dark: {
    base: {
      color: '#f2f3f8',
      fontFamily: 'Inter, sans-serif',
      fontWeight: '500',
      fontSize: '14px',
      letterSpacing: '-0.3px',

      ':focus': {
        color: '#f2f3f8',
      },

      '::placeholder': {
        color: 'rgba(255, 255, 255, 0.26)',
      },

      ':focus::placeholder': {
        color: 'rgba(255, 255, 255, 0.26)',
      },
    },

    // Styles for invalid field state
    invalid: {
      color: '#D71313',

      ':focus': {
        color: '#D71313',
      },
      '::placeholder': {
        color: '#D71313',
      },
    },
  },
};

const options = {
  // Custom classes - applied on container elements based on field's state
  classes: {
    focus: 'focus',
    invalid: 'invalid',
    empty: 'empty',
    complete: 'complete',
  },
  // locale
  locale: 'en',
  // Custom placeholders
  placeholder: {
    number: 'Enter card holder name...',
    expiry: 'MM/YY',
    cvv: 'CVC',
  },
  // Custom fonts
  fonts: [
    'https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap',
  ],
};

const EditCardModal = (
  {
    visible,
    isMobile,
    setModal,
    loading,
    isAdd,
    theme,
    onBoarding,
    intent,
    billingInfo,
    getCountries,
    changePayments,
    setSpin,
  }
) => {

  const {classes, locale, placeholder, fonts} = options;
  const optionsCountry = onBoarding?.countries?.length ?
    [...onBoarding?.countries].sort((a, b) => (a['name'] > b['name'] ? 1 : -1)).map((el) => ({ label: el.name, value: el.name, id: el.id, }))
    :
    [];
  const optionsState = onBoarding?.countries?.length ?
    [...[...onBoarding?.countries].filter((country) => country.name === 'United States')?.[0]?.['state']]?.sort((a, b)=> a.localeCompare(b)).map((el) => ({ label: el, value: el, }))
    :
    [];
  const isPreset = !!billingInfo;

  const {t} = useTranslation();
  const { pathname } = useLocation();
  const cardRef = useRef();

  const[dropdown, setDropdown] = useState({
    country: {
      visible: false,
      search: ''
    },
    state: {
      visible: false,
      search: ''
    },
  });
  const [step, setStep] = useState(1);
  const [data, setData] = useState({
    1: {
      name: '',
      number: null,
      expiry: null,
      cvv: null
    },
    2: {
      street: '',
      city: '',
      code: '',
      country: null,
      state: null
    },
  });

  const [focused, setFocused] = useState(false);
  const [error, setError] = useState('');
  const [cardIsValid, setCardIsValid] = useState({
    1: false,
    2: false
  });
  const [reload, setReload] = useState(false);
  const [instance, setInstance] = useState(null);
  const [innerVisible, setInnerVisible] = useState(null);

  useEffect(() => {
    let inst = window.Chargebee?.init({
      site: process.env.REACT_APP_CHARGEBEE_SITE,
      publishableKey: process.env.REACT_APP_APPLE_CHARGEBEE_PUBLISHABLE_KEY,
      enableRefersionTracking: true,
    });
    if(!onBoarding?.countries?.length) getCountries();
    setInstance(inst);
  }, []);

  useEffect(() => {
    if (!visible) {
      setCardIsValid({
        1: false,
        2: false
      });
      setInnerVisible(null);
      setStep(1);
      setData({
        1: {
          name: '',
          number: null,
          expiry: null,
          cvv: null
        },
        2: {
          street: '',
          city: '',
          code: '',
          country: null,
          state: null,
        },
      });
      setDropdown({
        country: {
          visible: false,
          search: ''
        },
        state: {
          visible: false,
          search: ''
        },
      });
    } else {
      setData(prev => ({
        ...prev,
        2: {
          ...prev?.[2],
          ...billingInfo
        }
      }));
    }
  }, [visible, isPreset]);

  useEffect(() => {
    if (step === 1) {
      if (error || !data?.[1]?.name || !data?.[1]?.number?.complete || !data?.[1]?.expiry?.complete || !data?.[1]?.cvv?.complete) {
        setCardIsValid(prev => ({
          ...prev,
          1: false
        }));
      } else setCardIsValid(prev => ({
        ...prev,
        1: true
      }));
    } else {
      if (data?.[2]?.street && data?.[2]?.city && data?.[2]?.code && Boolean(data?.[2]?.country)) {
        setCardIsValid(prev => ({
          ...prev,
          2: true
        }));
      } else setCardIsValid(prev => ({
        ...prev,
        2: false
      }));
    }
  }, [error, JSON.stringify(data)]);

  const onReady = form => {
    for (let field of form?.fields) {
      if (field?.mountStatus !== 3) return setReload(true);
    }
  };

  const onCancel = () => {
    if (onBoarding?.intentResult) intent?.clearIntent();
    setModal(null);
  };

  const onContinue = (data) => {
    changePayments(data);
  };

  const handleChange = (status) => {
    if (status?.complete && status?.error?.message) {
      setError(status?.error?.message);
    } else {
      setError('');
      setData((prev) => ({
        ...prev,
        1: {
          ...prev?.[1],
          [status?.field]: {...status}
        }
      }));
    }
  }

  useEffect(() => {
    if (cardRef?.current && intent?.result) {

      const additionalData = {
        billingAddress: {
          firstName: data?.[1]?.name?.split(' ')[0] || '',
          lastName: data?.[1]?.name?.split(' ')[1] || '',
          addressLine1: data?.[2]?.street || '',
          city: data?.[2]?.city || '',
          state: data?.[2]?.country?.id === 1450 ? data?.[2]?.state : null,
          country: data?.[2]?.country?.label || data?.[2]?.country?.name || '',
          zip: data?.[2]?.code || '',
        }
      }

      cardRef.current.authorizeWith3ds(intent?.result, additionalData)
        .then(authorizedPaymentIntent => {
          onContinue({
            intent_id: authorizedPaymentIntent.id
          });
        }).catch(error => {
        API.saveFrontLogs({ pathname, action: 'authorizeWith3ds',  error });
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [intent?.result]);

  function tokenize() {
    setSpin(true);
    cardRef.current.tokenize({
      firstName: data?.[1]?.name?.split(' ')[0] || '',
      lastName: data?.[1]?.name?.split(' ')[1] || '',
      addressLine1: data?.[2]?.street || '',
      city: data?.[2]?.city || '',
      state: data?.[2]?.country?.id === 1450 ? data?.[2]?.state : null,
      country: data?.[2]?.country?.label || data?.[2]?.country?.name || '',
      zip: data?.[2]?.code || '',
    }).then((data) => {
      intent.createIntent({
        'amount': intent?.amount,
        'gateway_account_id': 'gw_BTcXhMTspTHPA8UH',
        'payment_method_type': 'card',
        'card_token': data['vaultToken'],
      });
    }).catch((error) => {
      API.saveFrontLogs({ pathname, action: 'tokenize', error })
    });
  }

  return (
    <Modal handleOk={null}
           handleClose={() => {
             if (Boolean(innerVisible)) {
               setInnerVisible(null);
             } else onCancel()
           }}
           title={t(
             Boolean(innerVisible) ?
               innerVisible === 'state' ?
                 'State'
                 :
                 'Country'
               :
               isAdd ?
                 'Add payment method'
                 :
                 'Replace payment method')}
           isMobile={isMobile}
           width={450}
           className="collection-list-modal collection-modal account-modal edit-card-modal"
           open={visible}
           destroyOnClose
    >
      <Spin spinning={loading}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {
              t(
                Boolean(innerVisible) ?
                  `Change ${innerVisible === 'state' ? 'state' : 'country'} depending from your needs.`
                  :
                  isAdd ?
                  'Add a credit or debit card as your payment method.'
                  :
                  'Replace your current payment method'
              )
            }
          </p>
          {
            Boolean(innerVisible) ?
              null
              :
              <div className="edit-card-modal-header">
            <span className={cls('edit-card-modal-header-item', {
              active: step === 1,
            })}>
              {
                step === 1 && !cardIsValid?.[1] ?
                  <Icon role={'icon'} type={'edit_card_modal_progress'} />
                  :
                  <Icon role={'icon'} type={'edit_card_modal_complete'} />
              }
              {t('Card information')}
            </span>
                <Icon role={'icon'} type={'edit_card_modal_divider'} />
                <span className={cls('edit-card-modal-header-item', {
                  active: step === 2,
                })}>
              {
                step === 1 ?
                  <Icon role={'icon'} type={'edit_card_modal_empty'} />
                  :
                  !cardIsValid?.[2] ?
                    <Icon role={'icon'} type={'edit_card_modal_progress'} />
                    :
                    <Icon role={'icon'} type={'edit_card_modal_complete'} />
              }
                  {t('Billing details')}
            </span>
              </div>
          }
          <div className="change-form">
            <div className={cls('change-form-content', {
              hidden: step === 1 || Boolean(innerVisible),
            })}
            >
              <div className={'input-wrapper'}>
                  <span className="title">
                    {t('Street and house number')}
                  </span>
                <InputComponent type="text"
                                name={'street'}
                                placeholder={t('e.g. Sand Hill Road 3')}
                                value={data?.[2]?.street}
                                onChange={(e) => {
                                  setData((prev) => ({
                                    ...prev,
                                    2: {
                                      ...prev?.[2],
                                      street: e?.target?.value,
                                    },
                                  }));
                                }}
                />
              </div>
              <div className={'change-form-inner'}>
              <div className={'input-wrapper'}>
                  <span className="title">
                    {t('City or Town')}
                  </span>
                  <InputComponent type="text"
                                  name={'city'}
                                  placeholder={t('e.g. Silicon Valley')}
                                  value={data?.[2]?.city}
                                  onChange={(e) => {
                                    setData((prev) => ({
                                      ...prev,
                                      2: {
                                        ...prev?.[2],
                                        city: e?.target?.value,
                                      },
                                    }));
                                  }}
                  />
                </div>
                <div className={'input-wrapper'}>
                  <span className="title">
                    {t('Postal code')}
                  </span>
                  <InputComponent type="text"
                                  name={'code'}
                                  placeholder={t('e.g. 94024')}
                                  value={data?.[2]?.code}
                                  onChange={(e) => {
                                    setData((prev) => ({
                                      ...prev,
                                      2: {
                                        ...prev?.[2],
                                        code: e?.target?.value,
                                      },
                                    }));
                                  }}
                  />
                </div>
              </div>
              {
                isMobile ?
                <div className='input-wrapper'>
                <span className="title">
                  {t('Country')}
                </span>
                  <div className="ant-dropdown-trigger input-wrapper"
                       onClick={() => {
                         setInnerVisible('country');
                       }}
                  >
                    {data?.[2]?.country?.label || data?.[2]?.country?.name}
                    <ArrowSelectDown />
                  </div>
                  </div>
                  :
                <div className='input-wrapper'>
                  <span className="title">
                    {t('Country')}
                  </span>
                  <Dropdown destroyPopupOnHide={true}
                            getPopupContainer={(trigger) => trigger?.parentNode}
                            onOpenChange={value => setDropdown(prev => ({
                              ...prev,
                              country: {
                                visible: value,
                                search: '',
                              },
                            }))}
                            dropdownRender={() => (
                              <div className={'input-wrapper-dropdown'}>
                                <InputComponent type="text"
                                                name={'country'}
                                                placeholder={t('Search...')}
                                                prefix={<Icon type={'search_icon'} role="icon" />}
                                                value={dropdown?.country?.search}
                                                onChange={(e) => {
                                                  setDropdown((prev) => ({
                                                    ...prev,
                                                    country: {
                                                      ...prev?.country,
                                                      search: e?.target?.value,
                                                    },
                                                  }));
                                                }}
                                />
                                {
                                  !optionsCountry?.filter(el => el?.label?.toLowerCase()?.includes(dropdown?.country?.search?.toLowerCase()))?.length ?
                                    <div className={'dropdown-database-list empty input-wrapper-dropdown-options'}>
                                      <div className="dropdown-database-list-item"
                                           style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className={'link skeleton'}
                                          style={{ width: 120, height: 20, marginLeft: 8 }}
                                    />
                                      </div>
                                      <div className="empty-state_wrapper-select">
                                        {t('No options found')}
                                      </div>
                                      <p className={'empty-state_wrapper-text'}>
                                        {t('Try changing your search query or try again.')}
                                      </p>
                                    </div>
                                    :
                                    <div className="input-wrapper-dropdown-options">
                                      {
                                        optionsCountry?.filter(el => el?.label?.toLowerCase()?.includes(dropdown?.country?.search?.toLowerCase())).map(el => (
                                          <div className="input-wrapper-dropdown-option"
                                               key={el?.value}
                                               onClick={() => {
                                                 setDropdown((prev) => ({
                                                   ...prev,
                                                   country: {
                                                     visible: false,
                                                     search: '',
                                                   },
                                                 }));
                                                 setData((prev) => ({
                                                   ...prev,
                                                   2: {
                                                     ...prev?.[2],
                                                     country: { ...el },
                                                   },
                                                 }));
                                                 if (el?.label?.toLowerCase()?.includes('united states')) {
                                                   setData((prev) => ({
                                                     ...prev,
                                                     2: {
                                                       ...prev?.[2],
                                                       country: { ...el },
                                                       state: {
                                                         label: 'Alabama',
                                                         value: 'Alabama',
                                                       },
                                                     },
                                                   }));
                                                 } else {
                                                   setData((prev) => ({
                                                     ...prev,
                                                     2: {
                                                       ...prev?.[2],
                                                       country: { ...el },
                                                       state: null,
                                                     },
                                                   }));
                                                 }
                                               }}
                                          >
                                            {
                                              el?.label
                                            }
                                            {
                                              el?.id === data?.[2]?.country?.id ?
                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                     width="16"
                                                     height="16"
                                                     viewBox="0 0 16 16"
                                                     fill="none"
                                                     className={'checkmark'}
                                                >
                                                  <path d="M1.83333 10.0625L6 13.5L14.1667 2.5"
                                                        stroke="#225AEA"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                  />
                                                </svg>
                                                :
                                                null
                                            }
                                          </div>
                                        ))
                                      }
                                    </div>
                                }
                              </div>
                            )}
                            trigger={['click']}
                            open={dropdown?.country?.visible}
                  >
                    <div className="input-wrapper">
                      {data?.[2]?.country?.label || data?.[2]?.country?.name}
                      <ArrowSelectDown />
                    </div>
                  </Dropdown>
                  </div>
              }
              {
                data?.[2]?.country?.label?.toLowerCase()?.includes('united states') || data?.[2]?.country?.name?.toLowerCase()?.includes('united states') ?
                  isMobile ?
                  <div className='input-wrapper'>
                    <span className="title">
                      {t('Country')}
                    </span>
                      <div className="ant-dropdown-trigger input-wrapper"
                          onClick={() => {
                            setInnerVisible('state');
                          }}
                      >
                        {data?.[2]?.state?.label || data?.[2]?.state}
                        <ArrowSelectDown />
                      </div>
                    </div>
                    :
                    <div className='input-wrapper'>
                    <span className="title">
                      {t('State')}
                    </span>
                    <Dropdown destroyPopupOnHide={true}
                              getPopupContainer={(trigger) => trigger?.parentNode}
                              onOpenChange={value => setDropdown(prev => ({
                                ...prev,
                                state: {
                                  visible: value,
                                  search: '',
                                },
                              }))}
                              dropdownRender={() => (
                                <div className={'input-wrapper-dropdown state'}>
                                  <InputComponent type="text"
                                                  name={'state'}
                                                  placeholder={t('Search...')}
                                                  prefix={<Icon type={'search_icon'} role="icon" />}
                                                  value={dropdown?.state?.search}
                                                  onChange={(e) => {
                                                    setDropdown((prev) => ({
                                                      ...prev,
                                                      state: {
                                                        ...prev?.state,
                                                        search: e?.target?.value,
                                                      },
                                                    }));
                                                  }}
                                  />
                                  {
                                    !optionsState?.filter(el => el?.label?.toLowerCase()?.includes(dropdown?.state?.search?.toLowerCase()))?.length ?
                                      <div className={'dropdown-database-list empty input-wrapper-dropdown-options'}>
                                        <div className="dropdown-database-list-item"
                                             style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className={'link skeleton'}
                                          style={{ width: 120, height: 20, marginLeft: 8 }}
                                    />
                                        </div>
                                        <div className="empty-state_wrapper-select">
                                          {t('No options found')}
                                        </div>
                                        <p className={'empty-state_wrapper-text'}>
                                          {t('Try changing your search query or try again.')}
                                        </p>
                                      </div>
                                      :
                                      <div className="input-wrapper-dropdown-options">
                                        {
                                          optionsState?.filter(el => el?.label?.toLowerCase()?.includes(dropdown?.state?.search?.toLowerCase())).map(el => (
                                            <div className="input-wrapper-dropdown-option"
                                                 key={el?.value}
                                                 onClick={() => {
                                                   setDropdown((prev) => ({
                                                     ...prev,
                                                     state: {
                                                       visible: false,
                                                       search: '',
                                                     },
                                                   }));
                                                   setData((prev) => ({
                                                     ...prev,
                                                     2: {
                                                       ...prev?.[2],
                                                       state: { ...el },
                                                     },
                                                   }));
                                                 }}
                                            >
                                              {
                                                el?.label
                                              }
                                              {
                                                el?.label === data?.[2]?.state?.label || el?.label === data?.[2]?.state ?
                                                  <svg xmlns="http://www.w3.org/2000/svg"
                                                       width="16"
                                                       height="16"
                                                       viewBox="0 0 16 16"
                                                       fill="none"
                                                       className={'checkmark'}
                                                  >
                                                    <path d="M1.83333 10.0625L6 13.5L14.1667 2.5"
                                                          stroke="#225AEA"
                                                          strokeWidth="1.5"
                                                          strokeLinecap="round"
                                                          strokeLinejoin="round"
                                                    />
                                                  </svg>
                                                  :
                                                  null
                                              }
                                            </div>
                                          ))
                                        }
                                      </div>
                                  }
                                </div>
                              )}
                              trigger={['click']}
                              open={dropdown?.state?.visible}
                    >
                      <div className="input-wrapper">
                        {data?.[2]?.state?.label || data?.[2]?.state}
                        <ArrowSelectDown />
                      </div>
                    </Dropdown>
                    </div>
                  :
                  null
              }
            </div>
            <div className={cls('change-form-content', {
              hidden: step === 2 || Boolean(innerVisible),
            })}
            >
              <div className={'input-wrapper'}>
              <span className="title">
                {t('Full name')}
              </span>
                <InputComponent type="text"
                                name={'name'}
                                placeholder={t('Enter card holder name...')}
                                value={data?.[1]?.name}
                                onChange={(e) => {
                                  setData((prev) => ({
                                    ...prev,
                                    1: {
                                      ...prev?.[1],
                                      name: e?.target?.value,
                                    },
                                  }));
                                }}
                />
              </div>
              <div className={'input-wrapper'}>
                  <span className="title">
                    {t('Card number')}
              </span>
                {
                  !!instance ?
                    <CardComponent ref={cardRef}
                                   className="card-wrapper"
                                   styles={style[theme]}
                                   classes={classes}
                                   locale={locale}
                                   placeholder={placeholder}
                                   fonts={fonts}
                                   icon={false}
                                   disabled={true}
                                   onReady={onReady}
                                   reload={reload}
                    >
                      <div className={cls('complex-input', {
                        focused: focused,
                      })}>
                        <Icon role={'icon'} type={'edit_card_modal_input'} />
                        <CardNumber className={'number card-field'}
                                    onChange={handleChange}
                                    onFocus={() => setFocused(true)}
                                    onBlur={() => setFocused(false)}
                        />
                        <CardExpiry className={'expiration card-field'}
                                    onChange={handleChange}
                                    onFocus={() => setFocused(true)}
                                    onBlur={() => setFocused(false)}
                        />
                        <CardCVV className={'cvv card-field'}
                                 onChange={handleChange}
                                 onFocus={() => setFocused(true)}
                                 onBlur={() => setFocused(false)}
                        />
                      </div>
                      {
                        error ?
                          <span className="form-error">
                          {error}
                        </span>
                          :
                          null
                      }
                    </CardComponent>
                    :
                    null
                }

              </div>
            </div>
            <div className={cls('inner-modal', {
              hidden: innerVisible !== 'country',
            })}
            >
              <InputComponent type="text"
                              name={'country'}
                              placeholder={t('Search...')}
                              prefix={<Icon type={'search_icon'} role="icon" />}
                              value={dropdown?.country?.search}
                              onChange={(e) => {
                                setDropdown((prev) => ({
                                  ...prev,
                                  country: {
                                    ...prev?.country,
                                    search: e?.target?.value,
                                  },
                                }));
                              }}
              />
              {
                !optionsCountry?.filter(el => el?.label?.toLowerCase()?.includes(dropdown?.country?.search?.toLowerCase()))?.length ?
                  <div className={'dropdown-database-list empty input-wrapper-dropdown-options'}>
                    <div className="dropdown-database-list-item"
                         style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className={'link skeleton'}
                                          style={{ width: 120, height: 20, marginLeft: 8 }}
                                    />
                    </div>
                    <div className="empty-state_wrapper-select">
                      {t('No options found')}
                    </div>
                    <p className={'empty-state_wrapper-text'}>
                      {t('Try changing your search query or try again.')}
                    </p>
                  </div>
                  :
                  <div className="input-wrapper-dropdown-options">
                    {
                      optionsCountry?.filter(el => el?.label?.toLowerCase()?.includes(dropdown?.country?.search?.toLowerCase())).map(el => (
                        <div className="input-wrapper-dropdown-option"
                             key={el?.value}
                             onClick={() => {
                               setDropdown((prev) => ({
                                 ...prev,
                                 country: {
                                   visible: false,
                                   search: '',
                                 },
                               }));
                               setData((prev) => ({
                                 ...prev,
                                 2: {
                                   ...prev?.[2],
                                   country: { ...el },
                                 },
                               }));
                               setInnerVisible(null);
                               if (el?.label?.toLowerCase()?.includes('united states')) {
                                 setData((prev) => ({
                                   ...prev,
                                   2: {
                                     ...prev?.[2],
                                     country: { ...el },
                                     state: {
                                       label: 'Alabama',
                                       value: 'Alabama',
                                     },
                                   },
                                 }));
                               } else {
                                 setData((prev) => ({
                                   ...prev,
                                   2: {
                                     ...prev?.[2],
                                     country: { ...el },
                                     state: null,
                                   },
                                 }));
                               }
                             }}
                        >
                          {
                            el?.label
                          }
                          {
                            el?.id === data?.[2]?.country?.id ?
                              <svg xmlns="http://www.w3.org/2000/svg"
                                   width="16"
                                   height="16"
                                   viewBox="0 0 16 16"
                                   fill="none"
                                   className={'checkmark'}
                              >
                                <path d="M1.83333 10.0625L6 13.5L14.1667 2.5"
                                      stroke="#225AEA"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                />
                              </svg>
                              :
                              null
                          }
                        </div>
                      ))
                    }
                  </div>
              }
            </div>
            <div className={cls('inner-modal state', {
              hidden: innerVisible !== 'state',
            })}
            >
              <InputComponent type="text"
                              name={'state'}
                              placeholder={t('Search...')}
                              prefix={<Icon type={'search_icon'} role="icon" />}
                              value={dropdown?.state?.search}
                              onChange={(e) => {
                                setDropdown((prev) => ({
                                  ...prev,
                                  state: {
                                    ...prev?.state,
                                    search: e?.target?.value,
                                  },
                                }));
                              }}
              />
              {
                !optionsState?.filter(el => el?.label?.toLowerCase()?.includes(dropdown?.state?.search?.toLowerCase()))?.length ?
                  <div className={'dropdown-database-list empty input-wrapper-dropdown-options'}>
                    <div className="dropdown-database-list-item"
                         style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className={'link skeleton'}
                                          style={{ width: 120, height: 20, marginLeft: 8 }}
                                    />
                    </div>
                    <div className="empty-state_wrapper-select">
                      {t('No options found')}
                    </div>
                    <p className={'empty-state_wrapper-text'}>
                      {t('Try changing your search query or try again.')}
                    </p>
                  </div>
                  :
                  <div className="input-wrapper-dropdown-options">
                    {
                      optionsState?.filter(el => el?.label?.toLowerCase()?.includes(dropdown?.state?.search?.toLowerCase())).map(el => (
                        <div className="input-wrapper-dropdown-option"
                             key={el?.value}
                             onClick={() => {
                               setDropdown((prev) => ({
                                 ...prev,
                                 state: {
                                   visible: false,
                                   search: '',
                                 },
                               }));
                               setData((prev) => ({
                                 ...prev,
                                 2: {
                                   ...prev?.[2],
                                   state: { ...el },
                                 },
                               }));
                               setInnerVisible(null);
                             }}
                        >
                          {
                            el?.label
                          }
                          {
                            el?.label === data?.[2]?.state?.label || el?.label === data?.[2]?.state ?
                              <svg xmlns="http://www.w3.org/2000/svg"
                                   width="16"
                                   height="16"
                                   viewBox="0 0 16 16"
                                   fill="none"
                                   className={'checkmark'}
                              >
                                <path d="M1.83333 10.0625L6 13.5L14.1667 2.5"
                                      stroke="#225AEA"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                />
                              </svg>
                              :
                              null
                          }
                        </div>
                      ))
                    }
                  </div>
              }
            </div>
          </div>
        </div>
        {
          Boolean(innerVisible) ?
            null
            :
            <Divider type={'horizontal'}
                     style={{ margin: '16px 0' }}
            />
        }
        {
          Boolean(innerVisible) ?
            null
            :
            <div className={'collection-modal-btn-wrapper'}>
              {
                step === 1 ?
                  null
                  :
                  <ButtonComponent className="collection-modal-btn collection-modal-btn-create"
                                   onClick={() => {
                                     setStep(1);
                                   }}
                                   text={t('Previous step')}
                  />
              }
              <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                               onClick={onCancel}
                               text={t('Cancel')}
              />
              <ButtonComponent className="collection-modal-btn collection-modal-btn-save"
                               disabled={!cardIsValid?.[step]}
                               onClick={() => {
                                 if (step === 1) {
                                   setStep(2);
                                 } else tokenize();
                               }}
                               text={t(step === 1 ? 'Continue' : 'Add payment method')}
              />
            </div>
        }
        {
          isMobile ?
            null
            :
            <div style={{ height: 16 }} />
        }
      </Spin>
    </Modal>
  );
};

export default EditCardModal;
