import React from 'react';

const ProductDatabaseDashboard = ({ width, height }) => {
  return (
    <svg
      width={width ? width : 24}
      height={height ? height : 24}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.7166 12.3734C19.9018 11.6125 20 10.8176 20 9.9997C20 4.81435 16.0533 0.550797 11 0.0490723V9.29252L19.7166 12.3734Z"
        fill="#F9F9FC"
      />
      <path
        d="M19.0501 14.2591L9.66676 10.9425C9.26716 10.8013 9 10.4235 9 9.9997V0.0490723C3.94668 0.550797 0 4.81435 0 9.9997C0 15.5225 4.47715 19.9997 10 19.9997C13.9995 19.9997 17.4506 17.6518 19.0501 14.2591Z"
        fill="#F9F9FC"
      />
    </svg>
  );
};

export default ProductDatabaseDashboard;
