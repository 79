import React from 'react';

const Ideal = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
      <path
        d="M12.3121 1.13518L9.88258 0.931385L1.72021 0.808594V13.3682H10.433L13.3364 12.5786L15.3128 10.8654L16.2795 8.17053V5.05707L14.8338 2.55556L12.3121 1.13518Z"
        fill="white" />
      <path
        d="M1.97792 1.01927V12.9817H9.24576C13.6345 12.9817 16.0219 10.9373 16.0219 6.98518C16.0219 2.92373 13.4341 1.01927 9.24576 1.01927H1.97792ZM9.24576 0.398438C15.6818 0.398438 16.6428 4.52558 16.6428 6.98518C16.6428 11.2527 14.016 13.6028 9.24576 13.6028C9.24576 13.6028 1.51266 13.6028 1.35693 13.6028C1.35693 13.4464 1.35693 0.554392 1.35693 0.398438C1.51266 0.398438 9.24576 0.398438 9.24576 0.398438Z"
        fill="#0A0B09" />
      <path
        d="M6.46191 5.12951C6.46191 5.50356 6.46191 6.1054 6.46191 6.4793C6.66687 6.4793 6.87622 6.4793 6.87622 6.4793C7.16329 6.4793 7.42841 6.39633 7.42841 5.79382C7.42841 5.20499 7.13337 5.12951 6.87622 5.12951C6.87622 5.12951 6.66687 5.12951 6.46191 5.12951ZM13.3732 4.45815H14.0434C14.0434 4.45815 14.0434 5.98614 14.0434 6.4793C14.192 6.4793 14.6002 6.4793 15.0365 6.4793C14.7641 2.8119 11.8791 2.01953 9.25754 2.01953H6.46249V4.45953H6.87622C7.63051 4.45953 8.09915 4.97102 8.09915 5.79382C8.09915 6.64271 7.64177 7.14943 6.87622 7.14943H6.46249V12.0317H9.25754C13.5199 12.0317 15.0032 10.0523 15.0555 7.14943H13.3732V4.45815ZM11.5059 5.95985H11.9908L11.7691 5.19903H11.7288L11.5059 5.95985ZM10.4601 7.15024L11.2717 4.45805H12.2251L13.0365 7.15024H12.3388L12.1867 6.62945H11.3101L11.1576 7.15024H10.4601ZM10.1382 6.4793V7.14943H8.48026V4.45915H10.0847V5.12903C10.0847 5.12903 9.51373 5.12903 9.15052 5.12903C9.15052 5.22141 9.15052 5.32732 9.15052 5.44091H10.0341V6.11061H9.15052C9.15052 6.2451 9.15052 6.37191 9.15052 6.4793C9.52423 6.4793 10.1382 6.4793 10.1382 6.4793Z"
        fill="#D50172" />
      <path
        d="M5.58486 5.80329C5.58486 6.602 4.93769 7.24951 4.13899 7.24951C3.34067 7.24951 2.69287 6.602 2.69287 5.80329C2.69287 5.00522 3.34067 4.35742 4.13899 4.35742C4.93769 4.35742 5.58486 5.00522 5.58486 5.80329ZM2.97913 12.0296H5.30572V7.8879H2.97913V12.0296Z"
        fill="black" />
    </svg>
  );
};
export default Ideal;
