import React,{useState} from 'react';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import Icon from '../../../Icon';
import ThemeSwitcher from '../../../Components/ThemeSwitcher';
import LanguageSwitcher from '../../../Components/LanguageSwitcher/LanguageSwitcher';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';
import SubscriptionInfo from './SubscriptionInfo';
import AccountSettings from './AccountSettings';

const MenuDropdown = (
  {
    theme,
    changeTheme,
    setDisabledTransition,
    setLogout,
    setThemeModal,
    isAdmin,
    shopifyStore,
    autoDS,
    openModalShopifyConnect,
    openModalAutoDSConnect,
    disabledConnect,
    closeDropdown,
    isMobile,
    openSideBar,
    languages,
    changeLang,
    userSaveResultReset,
    optionsLanguages,
    setModal
  }) => {

  const { t } = useTranslation();

  const user = useSelector(state => state?.auth?.userInfo);

  const storeList = shopifyStore?.results || [];

  const handleOpenConnectModal = () => {
    if (disabledConnect) return;
    closeDropdown(false);
    setTimeout(() => openSideBar(false), 150);
    setTimeout(() => openModalShopifyConnect(), 300);
  };

  const handleOpenConnectAuto = () => {
    if (disabledConnect) return;
    closeDropdown(false);
    setTimeout(() => openSideBar(false), 150);
    setTimeout(() => openModalAutoDSConnect(), 300);
  };

  if (isAdmin) {
    return (
      <div className="user-dropdown-inner">
        <div className="user-dropdown-inner-info-wrapper">
          <div className="user-dropdown-inner-info">
            <span className="user-dropdown-inner-info-name">
              {`${user?.first_name} ${user?.last_name}`}
            </span>
            <span className="user-dropdown-inner-info-email">
              {user?.email}
            </span>
          </div>
          <div className="user-dropdown-inner-logout"
               onClick={() => {
                 closeDropdown(false);
                 setLogout(true);
               }}
          >
            <Icon type="logout" role="icon" />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="user-dropdown-inner">
        {
          isMobile ?
            <div className={'user-dropdown-inner-title'}>
              <span>
                {t('Profile settings')}
              </span>
              <span onClick={() => closeDropdown(false)}>
                <Icon role={'icon'} type={'close_modal'} />
              </span>
            </div>
            :
            null
        }
        {
          isMobile ?
            <Divider style={{ margin: '0 0 16px' }} />
            :
            null
        }
        <div className="user-dropdown-inner-info-wrapper">
          <div className="user-dropdown-inner-info">
            <span className="user-dropdown-inner-info-name">
              {`${user?.first_name} ${user?.last_name}`}
            </span>
            <span className="user-dropdown-inner-info-email">
              {user?.email}
            </span>
          </div>
          <div className="user-dropdown-inner-logout"
               onClick={() => {
                 closeDropdown(false);
                 setLogout(true);
               }}
          >
            <Icon type="logout" role="icon" />
          </div>
        </div>
        <Divider style={{ margin: '16px 0 0' }} />
        <SubscriptionInfo closeDropdown={closeDropdown} />
        <Divider style={{ margin: 0 }} />
        <AccountSettings closeDropdown={closeDropdown} />
        <Divider style={{ margin: 0 }} />
        <LanguageSwitcher 
            languages={languages}
            userInfo={user}
            changeLang={changeLang}
            userSaveResultReset={userSaveResultReset}
            isMobile={isMobile}
            setModal={setModal}
            closeParentDropdown={closeDropdown}
            optionsLanguages={optionsLanguages}
        />
        <ThemeSwitcher theme={theme}
                       changeTheme={changeTheme}
                       setDisabledTransition={setDisabledTransition}
                       isMobile={isMobile}
                       closeParentDropdown={closeDropdown}
                       setThemeModal={setThemeModal}
        />
        <Divider style={{ margin: 0 }} />
        {
          storeList?.length ?
            (
              <>
                <ul className="user-dropdown-inner-store-list">
                  {
                    storeList.map(({ id, name, domain }) => (
                      <li key={id} className="user-dropdown-inner-store-item">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             width="18"
                             height="18"
                             viewBox="0 0 18 18"
                             fill="none"
                        >
                          <path fill="#95BF47"
                                d="M13.5297 4.43017C13.5194 4.35828 13.4578 4.3172 13.4064 4.3172C13.3551 4.3172 12.3384 4.29666 12.3384 4.29666C12.3384 4.29666 11.486 3.47506 11.4038 3.38263C11.3216 3.30048 11.1573 3.32102 11.0957 3.34156C11.0957 3.34156 10.9314 3.3929 10.6644 3.47506C10.6233 3.33129 10.5514 3.1567 10.459 2.97184C10.1611 2.39672 9.70926 2.08862 9.17522 2.08862C9.13414 2.08862 9.10333 2.08862 9.06225 2.09889C9.04171 2.07835 9.03144 2.05781 9.0109 2.04754C8.77469 1.80106 8.47687 1.67782 8.11742 1.68809C7.42933 1.70863 6.74124 2.21186 6.1764 3.09508C5.78614 3.72154 5.48831 4.50206 5.39588 5.10799C4.6051 5.35447 4.05052 5.52905 4.02998 5.52905C3.62945 5.65229 3.61918 5.66256 3.56783 6.04255C3.54729 6.33011 2.49976 14.4331 2.49976 14.4331L11.2908 15.9531L15.101 15.0082C15.0907 15.0082 13.5399 4.50206 13.5297 4.43017ZM10.2228 3.61884C10.0174 3.68046 9.79142 3.75235 9.54494 3.83451C9.54494 3.48533 9.49359 2.99238 9.33954 2.58158C9.86331 2.66374 10.1201 3.2594 10.2228 3.61884ZM9.08279 3.96802C8.62064 4.1118 8.11742 4.26585 7.61419 4.4199C7.75797 3.87559 8.02499 3.34156 8.35363 2.98211C8.47687 2.8486 8.65145 2.70482 8.84658 2.62266C9.05198 3.02319 9.09306 3.58803 9.08279 3.96802ZM8.13796 2.15024C8.30228 2.15024 8.43579 2.18105 8.54876 2.26321C8.3639 2.35564 8.17904 2.49942 8.01472 2.68428C7.57311 3.1567 7.2342 3.88586 7.10069 4.59449C6.67962 4.728 6.26883 4.85124 5.89911 4.96421C6.14559 3.84478 7.08015 2.18105 8.13796 2.15024Z"
                          />
                          <path fill="#5E8E3E"
                                d="M13.4069 4.31746C13.3555 4.31746 12.3388 4.29692 12.3388 4.29692C12.3388 4.29692 11.4864 3.47532 11.4042 3.38289C11.3734 3.35208 11.3323 3.33154 11.2913 3.33154V15.9533L15.1014 15.0085C15.1014 15.0085 13.5506 4.50232 13.5404 4.43043C13.5198 4.35854 13.4582 4.31746 13.4069 4.31746Z"
                          />
                          <path fill="#EDFFE0"
                                d="M9.1762 6.27824L8.7346 7.9317C8.7346 7.9317 8.24164 7.70577 7.65625 7.74685C6.79358 7.7982 6.79358 8.3425 6.79358 8.47601C6.84493 9.21545 8.78595 9.37977 8.89891 11.1154C8.98107 12.4813 8.18002 13.4159 7.00924 13.4877C5.61253 13.5596 4.84229 12.738 4.84229 12.738L5.14011 11.4748C5.14011 11.4748 5.92063 12.0602 6.53683 12.0191C6.93735 11.9986 7.0914 11.6597 7.07086 11.4338C7.00924 10.4684 5.42767 10.53 5.32497 8.93816C5.24281 7.60307 6.11576 6.2577 8.04651 6.13446C8.79622 6.08311 9.1762 6.27824 9.1762 6.27824Z"
                          />
                        </svg>
                        <div>
                        <span className="user-dropdown-inner-store-name">
                          {name}
                        </span>
                          <span className="user-dropdown-inner-store-link">
                          {domain}
                        </span>
                        </div>
                      </li>
                    ))
                  }
                </ul>
              </>
            )
            :
            null
        }
        {
          autoDS?.email ?
            (
              <ul className="user-dropdown-inner-store-list"
                  style={{paddingTop: 0, paddingBottom: 0}}
              >
              <li className="user-dropdown-inner-store-item">
                <Icon type={'auto_ds'} role={'icon'} />
                <div>
                  <span className="user-dropdown-inner-store-name">
                    AutoDS
                  </span>
                  <span className="user-dropdown-inner-store-link">
                    {autoDS?.email}
                  </span>
                </div>
              </li>
              </ul>
            )
            :
            null
        }
        <>
          <span className={cls('text-link', { disabled: disabledConnect })}
                onClick={handleOpenConnectModal}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20"
                 height="20"
                 viewBox="0 0 20 20"
                 fill="none"
            >
              <path d="M10 5.625V10M10 10V14.375M10 10H5.625M10 10H14.375"
                    stroke="white"
                    strokeOpacity="0.36"
                    strokeWidth="1.5"
                    strokeLinecap="round"
              />
            </svg>
            {t('Connect Shopify Store')}
          </span>
          {
            autoDS?.email ?
              null
              :
              <Divider style={{ margin: 0 }} />
          }
          {
            autoDS?.email ?
              null
              :
              <span className={cls('text-link', { disabled: disabledConnect })}
                    onClick={handleOpenConnectAuto}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="20"
                     height="20"
                     viewBox="0 0 20 20"
                     fill="none"
                >
                  <path d="M10 5.625V10M10 10V14.375M10 10H5.625M10 10H14.375"
                    stroke="white"
                    strokeOpacity="0.36"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
                {t('Connect AutoDS Account')}
              </span>
          }
        </>
      </div>
    );
  }
};

export default MenuDropdown;
