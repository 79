import React, { useEffect, useState } from 'react';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import useDebounce from '../../../hooks/use-debounce';
import acc from 'accounting';
import utils from '../../../Utils/utils';
import Icon from '../../../Icon';
import { Divider, Dropdown, Input, Spin } from 'antd';
import Image from '../../../Components/Image';
import Images from '../../../Images';
import ProductView from '../../ProductsPage/components/product-view';
import './PageOptions.less';

const { Search } = Input;

const PageOptions = (
  {
    isMobile,
    setDisplayFormat,
    displayFormat,
    view,
    setView,
    setInputText,
    inputText,
    skeleton,
    disabled,
    connectHandler,
    searchErrors,
    resetSearchErrors,
    setSearchSuggestions,
    suggestions,
    suggestionsLoading,
    getStoreSuggestions,
    resetStoreSuggestions,
  }
) => {

  const {t} = useTranslation();
  const debouncedValue = useDebounce(inputText, 500);
  
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [focusedSearch, setFocusedSearch] = useState(false);

  function handleSearch (e) {
    setInputText(e.target.value.trim());
  }

  function handleSubmitSearch() {
    connectHandler();
  }

  function isValidUrl() {
    // eslint-disable-next-line
    if (view === 'products') return /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,8}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(inputText) && inputText.includes('/products/')
    // eslint-disable-next-line
    else return /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,8}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g.test(inputText)
  }

  function onKeyUp(e) {
    isValidUrl() && e.keyCode === 13 && handleSubmitSearch();
  }

  useEffect(() => {
    if (focusedSearch && view === 'stores' && debouncedValue?.length > 2) {
      setVisibleDropdown(true);
      getStoreSuggestions({value: debouncedValue});
    }
  }, [view, debouncedValue, focusedSearch]);

  useEffect(() => {
    const handleCloseSuggestionsSearch = (e) => {
      if (!e.target.closest('.search-suggestions-wrapper') && !e.target.closest('.search-input')) {
        setVisibleDropdown(false);
        resetStoreSuggestions();
      }
    };

    document.addEventListener('mousedown', handleCloseSuggestionsSearch);
    return () => {
      document.removeEventListener('mousedown', handleCloseSuggestionsSearch);
    };
  }, []);


  if (skeleton) return (
    <div className={'page-options-wrapper'}>
      <div className="search-options">
        <div className="search">
          <span className={'link skeleton'}
                style={{ width: isMobile ? '100%' : 400, height: 40 }}
          />
          <span className={'link skeleton'}
                style={{ width: isMobile ? '100%' : 140, height: 40 }}
          />
        </div>
      </div>
      {
        isMobile ?
          <Divider style={{ margin: '4px 0' }} />
          :
          null
      }
      <div className="display-options">
        <span className={'link skeleton'}
              style={{ width: isMobile ? 'calc(100% - 72px - 16px)' : 160, height: 40 }}
        />
        <span className={'link skeleton'}
              style={{ width: 72, height: 40 }}
        />
      </div>
    </div>
  );

  return (
    <div className={'page-options-wrapper'}>
      <div className="search-options">
        {
          view === 'stores' ?
            <div className="search">
              <Dropdown getPopupContainer={(trigger) => trigger.parentNode}
                        placement={'bottom'}
                        open={visibleDropdown}
                        dropdownRender={() => (
                          <div className="search-suggestions-wrapper">
                            <span className="search-suggestions-title">
                              <Icon type={'energy_bulb'} role={'icon'} />
                              {t('Suggestions')}
                            </span>
                            <Divider style={{ margin: '12px 0 8px' }} />
                            <Spin spinning={suggestionsLoading}>
                            <div className="search-suggestions-items">
                                {
                                  suggestions.length ?
                                    suggestions.map(el => (
                                      <div key={el?.id}
                                           className={'search-suggestions-item'}
                                           onClick={() => {
                                             setInputText(el?.custom_domain);
                                             setSearchSuggestions(el);
                                             setVisibleDropdown(false);
                                           }}
                                      >
                                        <div className="search-suggestions-item-info">
                                          <div className="logo">
                                            {
                                              el.favicon ?
                                                <img src={utils.changeImageSize(el?.favicon, 496)} alt=""
                                                     onError={utils.addDefaultSrc} />
                                                :
                                                <Image src={Images.defaultImg} small={true} />
                                            }
                                          </div>
                                          <div className="title">
                                            <h4>
                                              {el?.title}
                                            </h4>
                                            <span>
                                            {el?.custom_domain}
                                          </span>
                                          </div>
                                        </div>
                                        <span className={'search-suggestions-item-count'}>
                                          {acc.formatNumber(el?.products_count || 0, 0, ',', ',')}{' '}Products
                                        </span>
                                      </div>
                                    ))
                                    :
                                    <div className="search-suggestions-items-empty">
                                      <h3>
                                        {
                                          t('No results found')
                                        }
                                      </h3>
                                      <p>
                                        {
                                          t('Try changing your search requirements, try again or create new preset.')
                                        }
                                      </p>
                                    </div>
                                }
                              </div>
                            </Spin>
                          </div>)}
              >
                <Search placeholder={t('Insert Shopify store URL...')}
                        className={cls('search-input', {
                          'search-input-error': searchErrors,
                        })}
                        onChange={(e) => {
                          e.persist();
                          if (searchErrors) {
                            resetSearchErrors();
                          }
                          handleSearch(e);
                        }}
                        onKeyUp={onKeyUp}
                        onFocus={() => setFocusedSearch(true)}
                        onBlur={() => setFocusedSearch(false)}
                        defaultValue={inputText && inputText}
                        {...(inputText ? { value: inputText } : null)}
                        prefix={<Icon type="search_icon"
                                      role="icon"
                        />}
                />
              </Dropdown>
              <div className={cls('search-submit', {
                'disabled': !inputText || disabled || !isValidUrl(),
              })}
                   onClick={() => {
                     if (!inputText || disabled || !isValidUrl()) return;
                     handleSubmitSearch();
                   }}
              >
                <Icon type="gps" />
                {t('Start tracking')}
              </div>
              {(searchErrors && typeof searchErrors !== 'object') && <p className='sales-tracker-search-error'>
              <Icon type={'alert_triangle_error'} role={'icon'} />
              {searchErrors?.includes('Free trial') ?
                (<span>Free trial users can track up to 5 Stores and 5 Products. To track more, activate your membership <a href="/setting/plan">here.</a></span>)
                :
                (<span>{searchErrors}</span>)
              }
            </p>}
            </div>
            :
            <div className="search">
              <Search placeholder={t('Insert Shopify product URL...')}
                      className={cls('search-input', {
                        'search-input-error': searchErrors
                      })}
                      onChange={(e) => {
                        e.persist();
                        if (searchErrors) {
                          resetSearchErrors();
                        }
                        handleSearch(e);
                      }}
                      onKeyUp={onKeyUp}
                      onFocus={() => setFocusedSearch(true)}
                      onBlur={() => setFocusedSearch(false)}
                      defaultValue={inputText && inputText}
                      {...(inputText ? { value: inputText } : null)}
                      prefix={<Icon type="search_icon"
                                    role="icon"
                      />}
              />
              <div className={cls('search-submit', {
                'disabled': !inputText || disabled || !isValidUrl(),
              })}
                   onClick={() => {
                     if (!inputText || disabled || !isValidUrl()) return;
                     handleSubmitSearch();
                   }}
              >
                <Icon type="gps" />
                {t('Start tracking')}
              </div>
              {(searchErrors && typeof searchErrors !== 'object') && <p className='sales-tracker-search-error'>
              <Icon type={'alert_triangle_error'} role={'icon'} />
              {searchErrors?.includes('Free trial') ?
                (<span>Free trial users can track up to 5 Stores and 5 Products. To track more, activate your membership <a href="/setting/plan">here.</a></span>)
                :
                (<span>{searchErrors}</span>)
              }
            </p>}
            </div>
        }
      </div>
      {
        isMobile ?
          <Divider style={{ margin: '4px 0' }} />
          :
          null
      }
      <div className="display-options">
        <div className={'view-options'}>
          <div className={"product-view page-view"}
               onClick={() => {
                 setDisplayFormat('details');
                 setView(view === 'stores' ? 'products' : 'stores');
               }}
          >
            <div className={cls('product-view-btn', {
              'product-view-btn--active': view === 'stores',
            })}
            >
              {t('Stores')}
            </div>
            <div className={cls('product-view-btn', {
              'product-view-btn--active': view === 'products',
            })}
            >
              {t('Products')}
            </div>
          </div>
        </div>
        <ProductView view={displayFormat}
                     setView={(val) => setDisplayFormat(val)}
        />
      </div>
    </div>
  );
};

export default PageOptions;
