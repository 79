import React from 'react';
import { useSelector } from 'react-redux';

export default function Download(
  {
    onClick = () => null
  }) {

  const theme = useSelector(state => state.nav.theme);

  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         width="20"
         height="20"
         viewBox="0 0 20 20"
         fill="none"
         onClick={onClick}
    >
      <path
        d="M16.875 12.2917V15.2083C16.875 16.1288 16.1288 16.875 15.2083 16.875H4.79167C3.87119 16.875 3.125 16.1288 3.125 15.2083V12.2917M9.99999 12.5V3.125M9.99999 12.5L7.08333 9.58333M9.99999 12.5L12.9167 9.58333"
        stroke={theme === 'light' ? '#707BA0' : 'rgba(255, 255, 255, 0.361)'}
        strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
      />
    </svg>
  );
}
