import React, { useState } from 'react';
import acc from 'accounting';
import Icon from '../../../../Icon';
import utils from '../../../../Utils/utils';
import { titleSortFilters } from '../../../../Components/titleSortFilters';
import { Dropdown } from 'antd';
import IconWithText from '../../../../Components/Text';
import TrackingUserModal from './TrackingUserModal';

export const columnsDesktopFull = ({
  t,
  isMobile = false,
  setStoreProductsToShow,
  setModal,
  setVisible,
  isTrial,
  setRecordToDelete,
  view,
  connectStore,
  sortOrder,
  period
}) => {
  return [
    {
      title: (state) =>
        titleSortFilters({
          sortOrder,
          title: t('Store'),
          stateData: state,
          key: 'title',
        }),
      dataIndex: 'title',
      key: 'title',
      width: 380,
      fixed: isMobile ? false : 'left',
      sorter: true,
      render: (_, record) => {
        return (
          <div className="title-column product-database-table-cell">
            <img
              src={record?.logo}
              alt="images"
              width={40}
              height={40}
              onError={utils.addDefaultSrc}
              style={{ borderRadius: '8px' }}
            />
            <div className="title-column-top-tracker">
              <div className="title-column-title">{record.title}</div>
              <div className="title-column-wrapper">
                <a
                  className="title-column-link"
                  target={'_blank'}
                  href={`https://${record.custom_domain}`}
                  rel="noopener noreferrer"
                >
                  <span> {record.custom_domain}</span>
                </a>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: (state) =>
        titleSortFilters({
          title: t('Revenue'),
          stateData: state,
          key: period === 'week' ? 'week_revenue' : 'month_revenue',
        }),
      dataIndex: period === 'week' ? 'week_revenue' : 'month_revenue',
      key: period === 'week' ? 'week_revenue' : 'month_revenue',
      width: 148,
      sorter: true,
      showSorterTooltip: false,
      render: (_, record) => (
        <span className={'product-database-table-cell'}>
          ${acc.formatNumber(record.month_revenue, 2, ' ', ',')}
        </span>
      ),
    },
    {
      title: (state) =>
        titleSortFilters({
          title: t('Sales'),
          stateData: state,
          key: period === 'week' ? 'week_sales' : 'month_sales',
        }),
      dataIndex:period === 'week' ? 'week_sales' : 'month_sales',
      key: period === 'week' ? 'week_sales' : 'month_sales',
      width: 96,
      sorter: true,
      showSorterTooltip: false,
      render: (_, record) => (
        <span className={'product-database-table-cell'}>
          {record.month_sales}
        </span>
      ),
    },
    {
      title: (state) =>
        titleSortFilters({
          title: t('Tracked by'),
          stateData: state,
          key: 'tracked_by',
        }),
      dataIndex: 'tracked_by',
      key: 'tracked_by',
      width: 148,
      sorter: true,
      showSorterTooltip: false,
      render: (_, record) => (
        <span className={'product-database-table-cell'}>
          {record.tracked_by ?? 0}
        </span>
      ),
    },
    {
      title: (state) =>
        titleSortFilters({
          title: t('Products'),
          stateData: state,
          key: 'created_at',
        }),
      dataIndex: 'products_count',
      key: 'products_count',
      width: 148,
      sorter: true,
      showSorterTooltip: false,
      render: (_, record) => (
        <span
          className="clickable-item"
          onClick={() => {
            setStoreProductsToShow(record?.internal_shop_id);
            setVisible(true);
            setModal('storeProducts');
          }}
        >
          {record?.products_count ?? 0}
        </span>
      ),
    },
    {
      title: '',
      key: 'actions',
      width: 40,
      sorter: false,
      fixed: 'right',
      render: (record) => (
        <div
          className={
            'product-database-table-cell sales-tracker-store-table-cell cell-actions'
          }
        >
          <div className="product-actions">
            <span>
              <DropdownRender
                record={record}
                t={t}
                isMobile={isMobile}
                isTrial={isTrial}
                setModal={setModal}
                setRecordToDelete={setRecordToDelete}
                setVisible={setVisible}
                view={view}
                connectStore={connectStore}
              />
            </span>
          </div>
        </div>
      ),
    },
  ];
};

export const changeColumnsStore = () => {
  return columnsDesktopFull;
};

export const DropdownRender = ({
  record,
  t,
  isMobile,
  isTrial,
  setModal,
  setRecordToDelete,
  setVisible,
  view,
  connectStore,
  sortOrder
}) => {
  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [innerModal, setInnerModal] = useState(false);
  const handleInnerVisibleChange = (value) => {
    setVisibleDropdown(value);
  };

  return isMobile ? (
    <>
      <span
        className={'product-actions-options'}
        onClick={() => setInnerModal(true)}
      >
        <Icon type="three_dots" role="button" />
      </span>
      <TrackingUserModal
        setInnerModal={setInnerModal}
        view={view}
        visible={innerModal}
        record={record}
        setRecordToDelete={setRecordToDelete}
        setModal={setModal}
        isTrial={isTrial}
        connectStore={connectStore}
        setVisible={setVisible}
      />
    </>
  ) : (
    <Dropdown
      open={visibleDropdown}
      onOpenChange={handleInnerVisibleChange}
      dropdownRender={() => (
        <ul
          className={'filter-preset-action-dropdown database-action-dropdown'}
        >
          <li
            className={'action-dropdown_item'}
            onClick={() => window.open(record?.facebook_add_library, '_blank')}
          >
            <IconWithText
              size={24}
              icon={() => (
                <Icon
                  role={'icon'}
                  type={'search_facebook'}
                  width={16}
                  height={16}
                  isDark={false}
                />
              )}
              text={t(`Show in Facebook`)}
            />
          </li>
          <li
            className={`action-dropdown_item ${
              record?.is_tracked ? 'tracked' : ''
            } `}
            onClick={() => {
              if (record?.is_locked) navigate('/setting/plan');
              else {
                if (record?.is_tracked) {
                  setVisible(true);
                  if (isTrial) {
                    setModal('canNotRemoveTracking');
                  } else {
                    setRecordToDelete({
                      type: view.slice(0, -1),
                      data: { ...record },
                    });
                    setModal('deleteTracking');
                  }
                } else {
                  connectStore({
                    internal_shop_id: record?.internal_shop_id,
                  })
                }
              }
            }}
          >
            <IconWithText
              size={24}
              icon={() =>
                record?.is_tracked ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M6.45898 6.4585L13.5423 13.5418M13.5423 6.4585L6.45898 13.5418"
                      stroke="#D71313"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_3190_52051)">
                      <path
                        d="M9.99967 1.45825V6.87492M18.5413 9.99992H13.1247M9.99967 13.1249V18.5416M6.87467 9.99992H1.45801M9.99967 16.0416C6.66295 16.0416 3.95801 13.3366 3.95801 9.99992C3.95801 6.6632 6.66295 3.95825 9.99967 3.95825C13.3364 3.95825 16.0413 6.6632 16.0413 9.99992C16.0413 13.3366 13.3364 16.0416 9.99967 16.0416Z"
                        stroke="#225AEA"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_3190_52051">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                )
              }
              text={t(
                record?.is_locked
                  ? 'Upgrade Plan'
                  : record?.is_tracked
                  ? 'Stop tracking'
                  : 'Start tracking',
              )}
            />
          </li>
        </ul>
      )}
      destroyPopupOnHide={true}
      trigger={['click']}
      placement="bottomRight"
      getPopupContainer={() =>
        document.getElementById('top-store-top-products-table-body')
      }
      overlayClassName={'filter-preset-cell-menu-wrapper'}
    >
      <span className={'product-actions-options'}>
        <Icon type="three_dots" role="button" />
      </span>
    </Dropdown>
  );
};
