import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal';
import { Divider, Spin } from 'antd';
import cls from 'classname';
import InputComponent from '../Input';
import Icon from '../../Icon';
import ButtonComponent from '../Button';

const inputData = [
  {
    key: 'new',
    placeholder: 'Enter a new password...',
    label: 'New password'
  },
  {
    key: 'confirm',
    placeholder: 'Confirm new password...',
    label: 'Confirm new password'
  },
]

const CreateNewPassword = (
  {
    visible,
    setModal,
    loading,
    isMobile,
    userError,
    userErrorsReset,
    createNewPassword,
    saveResult,
    userSaveResultReset,
    code,
    setNewPassword,
  }
) => {

  const {t} = useTranslation();

  const [visiblePassword, setVisiblePassword] = useState({
    new: false,
    confirm: false,
  });
  const [inputPass, setInputPass] = useState({
    new: '',
    confirm: '',
  });

  const [error, setError] = useState({
    new: '',
    confirm: '',
  });

  useEffect(() => {
    setVisiblePassword({
      new: false,
      confirm: false,
    });
    setInputPass({
      new: '',
      confirm: '',
    });
    setError({
      new: '',
      confirm: '',
    });
    userSaveResultReset();
    userErrorsReset();
  }, [visible]);

  useEffect(() => {
    if (saveResult?.create) {
      setModal(null);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [saveResult]);

  useEffect(() => {
    if (userError) {
      setError(prev => ({
        ...prev
      }));
    } else {
      setError(prev => ({
        ...prev
      }));
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [userError]);

  return (
    <Modal handleOk={null}
           handleClose={() => {
             setModal(null);
           }}
           title={
            <div className="collection-modal-top">
              {setNewPassword ? t('Set new password') : t('Change password')}
            </div>
           }
           isMobile={isMobile}
           width={450}
           className="collection-list-modal collection-modal account-modal"
           open={visible}
           destroyOnClose
    >
      <Spin spinning={loading}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {setNewPassword ? t('Set your password to enable login with your e-mail.') :t('Your new password must be different from previously used passwords')}
          </p>
          <div className="change-form">
            {
              inputData.map((el) => (
                <div className={cls('input-wrapper', {
                  error: error?.[el?.key] && error?.[el?.key] !== 'Your password is strong.',
                })}
                     key={el?.key}
                >
                  <span className="title">
                    {t(el?.label)}
                  </span>
                  <InputComponent type={visiblePassword?.[el?.key] ? 'text' : 'password'}
                                  placeholder={t(el?.placeholder)}
                                  autoComplete="off"
                                  value={inputPass?.[el?.key]}
                                  onChange={(e) => {
                                    userErrorsReset();
                                    if (el?.key === 'new') {
                                      setError(prev => ({
                                        ...prev,
                                        new: e.target.value?.length <= 6
                                          || !/[0-9]/g.test(e.target.value)
                                          || !/[a-zA-Z]/g.test(e.target.value)
                                          || !/[!-\/:-@[-`{-~]/g.test(e.target.value) ?
                                          'Passwords should be a minimum of 6 characters and include upper and lower-case letters, numbers, and special characters'
                                          :
                                          'Your password is strong and fully compliant with the requirements.'
                                      }));
                                    } else if (el?.key === 'confirm') {
                                      setError(prev => ({
                                        ...prev,
                                        new: '',
                                        confirm: e.target.value === inputPass?.new ?
                                          ''
                                          :
                                          'Passwords do not match.'
                                      }));
                                    }
                                    setInputPass(prev => ({
                                      ...prev,
                                      [el?.key]: e.target.value,
                                    }));
                                  }}
                                  suffix={(
                                    <span onClick={() => setVisiblePassword(prev => ({
                                      ...prev,
                                      [el?.key]: !prev?.[el?.key],
                                    }))}>
                                  {
                                    visiblePassword?.[el?.key] ?
                                      <Icon type="eye" />
                                      :
                                      <Icon type="eye_invisible" />
                                  }
                                </span>
                                  )}
                  />
                  <span className={cls('error', {
                    confirm: error?.[el?.key] === 'Your password is strong.'
                  })}>
                    {
                      error?.[el?.key] ?
                        t(error?.[el?.key])
                        :
                        null
                }
              </span>
                </div>
              ))
            }
          </div>
        </div>
        <Divider type={'horizontal'}
                 style={{ margin: '16px 0' }}
        />
        <div className={'collection-modal-btn-wrapper'}>
          <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                           onClick={() => {
                             setModal(null);
                           }}
                           text={t('Cancel')}
          />
          <ButtonComponent className="collection-modal-btn collection-modal-btn-save"
                           disabled={loading || inputPass?.new !== inputPass?.confirm  || !inputPass?.new || !inputPass?.confirm}
                           onClick={() => {
                            createNewPassword(setNewPassword ? { password: inputPass?.new} :{ password: inputPass?.new , confirm_code: code});
                           }}
                           text={setNewPassword ? t('Set Password') :t('Change password')}
          />
        </div>
        {
          isMobile ?
            null
            :
            <div style={{ height: 16 }} />
        }
      </Spin>
    </Modal>
  );
};

export default CreateNewPassword;
