import React from 'react';
import cls from 'classname';
import acc from 'accounting';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import utils from '../../Utils/utils';
import Images from '../../Images';
import { Divider } from 'antd';

export default function Invoice(props) {
  const { invoice } = props;
  const {
    invoice_id,
    total,
    status,
    issued_date,
    payment_method,
    items,
    bill_from,
    billing,
    email,
    discount,
    credits_applied,
  } = invoice;
  const { t } = useTranslation();
  return (
    <div className="invoice">
      <div className="invoice-header">
        <div className="invoice-header-info">
          <span className="invoice-header-info-title">
            {t('Invoice')}
          </span>
          <div className="invoice-header-info-subtitle">
            <span>
              #{invoice_id}
            </span>
            <Divider type={'vertical'} style={{ margin: '0 8px' }} />
            <span>
              {
                payment_method ?
                  `${utils.toUpperLatter(payment_method?.card_type)} *${payment_method?.last_4}`
                  :
                  '-'
              }
            </span>
          </div>
        </div>
        <div className="invoice-header-logo">
          <svg xmlns="http://www.w3.org/2000/svg" width="38" height="46" viewBox="0 0 38 46" fill="none">
            <path
              d="M34.1284 9.36796L24.4411 2.40081C21.1927 0.0639881 16.8134 0.0639881 13.563 2.40081L3.87368 9.36796C1.44257 11.117 0 13.9282 0 16.9229V29.0793C0 32.0741 1.4405 34.8853 3.87368 36.6343L13.561 43.6015C16.8093 45.9383 21.1886 45.9383 24.439 43.6015L34.1263 36.6343C36.5574 34.8853 38 32.0741 38 29.0793V16.9229C38 13.9282 36.5595 11.117 34.1284 9.36796ZM15.7382 5.42238C17.6885 4.01988 20.3156 4.01988 22.2638 5.42238L30.4301 11.2943C31.0204 11.7192 31.0204 12.5958 30.4301 13.0207L22.2638 18.8926C20.3136 20.2951 17.6864 20.2951 15.7382 18.8926L7.57193 13.0207C6.9817 12.5958 6.9817 11.7192 7.57193 11.2943L15.7382 5.42238ZM34.277 29.0773C34.277 30.8737 33.4123 32.5609 31.9532 33.6107L22.2659 40.5778C20.3156 41.9803 17.6885 41.9803 15.7403 40.5778L6.05301 33.6107C4.59393 32.5609 3.72921 30.8737 3.72921 29.0773V16.9209C3.72921 16.9147 3.72921 16.9085 3.72921 16.9023C3.73128 16.0422 4.71156 15.5472 5.40911 16.0484L13.5651 21.9142C16.8134 24.251 21.1927 24.251 24.4432 21.9142L32.5991 16.0484C33.2988 15.5452 34.277 16.0402 34.279 16.9023C34.279 16.9085 34.279 16.9147 34.279 16.9209V29.0773H34.277Z"
              fill="#225AEA" />
            <path
              d="M24.3884 25.5787C22.7683 25.8964 21.6931 27.1772 20.8944 28.6498C20.6591 29.0829 20.6756 29.6275 21.0409 29.9183C21.3113 30.1328 21.6683 30.1204 21.9304 29.8956C22.9334 29.0417 24.1036 28.5591 24.861 28.3941C25.6184 28.2291 26.8628 28.1837 28.0701 28.5549C28.3879 28.6519 28.7222 28.5116 28.9039 28.2002C29.1515 27.7753 28.9864 27.2679 28.6314 26.9586C27.3395 25.8345 26.0332 25.2549 24.3884 25.5787Z"
              fill="#225AEA" />
            <path
              d="M13.6299 25.5787C15.25 25.8964 16.3252 27.1772 17.1239 28.6498C17.3592 29.0829 17.3426 29.6275 16.9774 29.9183C16.707 30.1328 16.35 30.1204 16.0879 29.8956C15.0849 29.0417 13.9147 28.5591 13.1573 28.3941C12.3999 28.2291 11.1555 28.1837 9.94819 28.5549C9.63037 28.6519 9.29604 28.5116 9.11443 28.2002C8.86678 27.7753 9.03188 27.2679 9.38685 26.9586C10.6788 25.8345 11.9851 25.2549 13.6299 25.5787Z"
              fill="#225AEA" />
          </svg>
        </div>
      </div>
      <div className={cls("invoice-status", {
        due: status?.id !== 1,
      })}
      >
        <span className={'invoice-status-text'}>
          {
            status?.id === 1 ?
              t('Invoice paid')
              :
              t('Total due')
          }
        </span>
        <span className={'invoice-status-date'}>
          {
            dayjs(issued_date).format('MMMM DD, YYYY')
          }
        </span>
        <span className={'invoice-status-text'}>
          ${acc.formatNumber(total / 100, 2, ' ', ',')}
        </span>
      </div>
      <Divider type={'horizontal'} style={{ margin: '24px 0 16px' }} />
      <div className="invoice-billing-info">
        <div className="invoice-billing-info-block">
          <span className="invoice-billing-info-block-title">
            {t('Bill from')}
          </span>
          <span className="invoice-billing-info-block-value">
            {bill_from?.name}
          </span>
          <span className="invoice-billing-info-block-value">
            {bill_from?.['VAT']}
          </span>
          <span className="invoice-billing-info-block-value"
                style={{ marginBottom: 0 }}
          >
            {`${bill_from?.['address_line_1']} ${bill_from?.['address_line_2'] || ''}, ${bill_from?.country} ${bill_from?.state || ''}, ${bill_from?.city}, ${bill_from?.postal_code || bill_from?.zip}`}
          </span>
        </div>
        <div className="invoice-billing-info-block">
          <span className="invoice-billing-info-block-title">
            {t('Bill to')}
          </span>
          <span className="invoice-billing-info-block-value">
            {billing?.company ? billing?.company : `${billing?.first_name} ${billing?.last_name}`}
          </span>
          <span className="invoice-billing-info-block-value">
            {billing?.company && billing?.['vat_number'] ? billing?.['vat_number'] : `${email}`}
          </span>
          <span className="invoice-billing-info-block-value"
                style={{ marginBottom: 0 }}
          >
            {`${billing?.['line_1'] || ''} ${bill_from?.['line_2'] || ''} ${billing?.country || ''} ${billing?.['line_1'] || billing?.state || ''}${bill_from?.['line_2'] || billing?.country || billing?.state ? ',' : ''} ${billing?.city || ''}${billing?.city && (billing?.postal_code || billing?.zip) ? ',' : ''} ${billing?.postal_code || billing?.zip || ''}`}
          </span>
        </div>
      </div>
      <Divider type={'horizontal'} style={{ margin: '24px 0 32px' }} />
      <div className="invoice-billing-details">
        <div className="invoice-billing-details-row">
          <span className="invoice-billing-details-row-title">
            {
              t('Plan')
            }
          </span>
          <span className="invoice-billing-details-row-title">
            {
              t('Bill cycle')
            }
          </span>
          <span className="invoice-billing-details-row-title">
            {
              t('Amount')
            }
          </span>
        </div>
        <Divider type={'horizontal'} style={{ margin: '12px 0 16px' }} />
        {
          items.map(item => (
            <div className="invoice-billing-details-row row-value" key={item?.plan?.plan?.id}>
              <span className="invoice-billing-details-row-value">
                {utils.toUpperLatter(t(item?.plan?.plan?.name) || t(item?.label))}
              </span>
              <span className="invoice-billing-details-row-value">
                {items?.[0]?.['bill_cycle'] || '-'}
              </span>
              <span className="invoice-billing-details-row-value">
                ${acc.formatNumber(item?.price / 100, 2, ' ', ',')}
              </span>
            </div>
          ))
        }
        {
          discount ?
            <div className="invoice-billing-details-row row-value">
              <span className="invoice-billing-details-row-value">
                {'Discount'}
              </span>
              <span className="invoice-billing-details-row-value" />
              <span className="invoice-billing-details-row-value">
                ${acc.formatNumber(discount / 100, 2, ' ', ',')}
              </span>
            </div>
            :
            null
        }
        {
          credits_applied ?
            <div className="invoice-billing-details-row row-value">
              <span className="invoice-billing-details-row-value">
                {'Credits applied'}
              </span>
              <span className="invoice-billing-details-row-value" />
              <span className="invoice-billing-details-row-value">
                ${acc.formatNumber(credits_applied / 100, 2, ' ', ',')}
              </span>
            </div>
            :
            null
        }
        <Divider type={'horizontal'}
                 style={{ margin: '12px 0 16px' }}
                 className={cls('divider', {
                   'divider-due': status?.id !== 1
                 })}
        />
        <div className="invoice-billing-details-row total-row">
          <span className="invoice-billing-details-row-value">
                {'Total'}
              </span>
          <span className="invoice-billing-details-row-value" />
          <span className={cls("invoice-billing-details-row-value total-value", {
            'total-value-due': status?.id !== 1
          })}>
            ${acc.formatNumber(total / 100, 2, ' ', ',')}
          </span>
        </div>
      </div>
      <div className={cls('invoice-footer', {
        due: status?.id !== 1,
      })}
      >
        <span className="invoice-footer-text">
          {t('Questions about your bill? Contact us at:')}
          <a href='mailto:billing@dropship.com'> billing@dropship.com</a>
        </span>
        <span className="invoice-footer-actions">
          {
            status?.id !== 1 ?
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M9.09477 3.15968C7.96228 2.51985 6.74621 2.36244 5.62072 2.614C4.04583 2.96602 2.70607 4.10685 2.06678 5.70483C0.75945 8.97264 2.4254 13.7539 9.69187 17.8367C9.88199 17.9435 10.114 17.9435 10.3042 17.8367C17.5706 13.7539 19.2365 8.97262 17.9292 5.70482C17.2899 4.10684 15.9501 2.96601 14.3752 2.614C13.2856 2.37045 12.111 2.51024 11.0096 3.10003C10.5346 3.3929 10.1824 3.73898 9.90337 4.18541C9.4841 4.85624 9.19506 5.80811 8.99158 7.27596L11.2736 9.55803C11.441 9.72542 11.4995 9.97303 11.4246 10.1976L10.5913 12.6976C10.4821 13.0251 10.1282 13.2021 9.80073 13.0929C9.47326 12.9837 9.29629 12.6298 9.40544 12.3023L10.1166 10.1688L7.88976 7.94191C7.75338 7.80553 7.68761 7.61383 7.71153 7.42245C7.9231 5.72993 8.24902 4.47387 8.84337 3.52291C8.92303 3.39546 9.00681 3.27456 9.09477 3.15968Z"
                  fill="#D71313" />
              </svg>
              :
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M10.3042 17.8367C17.5706 13.7539 19.2365 8.97262 17.9292 5.70482C17.2899 4.10684 15.9501 2.96601 14.3752 2.614C12.9432 2.29392 11.3645 2.63594 9.99798 3.7913C8.63141 2.63594 7.05275 2.29392 5.62072 2.614C4.04583 2.96602 2.70607 4.10685 2.06678 5.70483C0.759449 8.97264 2.4254 13.7539 9.69187 17.8367C9.88199 17.9435 10.114 17.9435 10.3042 17.8367Z"
                  fill="#16895A" />
              </svg>
          }
        </span>
      </div>
    </div>
  );
}
