import React from 'react';
import {Divider, Modal} from "antd";
import ButtonComponent from "../Button";
import cls from "classname";
import { useTranslation } from 'react-i18next';
import './TrialFinished.less'

const TrialFinished = ({visible, isMobile, theme, callBack}) => {

  const {t} = useTranslation();

  return (
    <Modal
      className="change-modal custom-modal trial-finished-modal"
      getContainer={() => document.getElementById('global-wrap')}
      {...(isMobile ? { transitionName: '' } : null)}
      open={visible}
      centered={!isMobile}
      width={512}
      footer={null}
      closable="false"
      onCancel={null}
      destroyOnClose
    >
      <div className='trial-finished-wrapper'>
        <div className={cls("trial-finished-image", {
          "trial-finished-image--dark": theme === 'dark'
        })} />
        <div className="trial-finished-title">
          {
            t('Upgrade to get full access')
          }
        </div>
        <div className="trial-finished-text">
          <p>
            {
              t('Upgrade your plan to access more tools')
            }
          </p>
        </div>
        <Divider style={{ margin: 0 }}/>
        <ButtonComponent text={t('Select plan to continue')}
                         onClick={() => callBack()}
                         className='trial-finished-button'
        />
      </div>
    </Modal>
  );
};

export default TrialFinished;
