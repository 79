import React from 'react';
import ButtonComponent from "../../../Components/Button";
import {useTranslation} from "react-i18next";
import AutoDSCreators from "../../AutoDS/reducer";
import {useDispatch} from "react-redux";
import Images from '../../../Images';
import Image from '../../../Components/Image';
import './AutoDSBanner.less';

const AutoDsBanner = ({isConnected}) => {

  const {t} = useTranslation();
  const dispatch = useDispatch();

  return (
    <div className={'auto-ds-banner'}>
      <div className="auto-ds-banner-info-wrapper">
        <div className="auto-ds-banner-info-logo">
          <Image src={Images.autoDsSuppliers} small={false} />
        </div>
        <div className="auto-ds-banner-info-text">
          <h3>
            {t('AutoDS Integration')}
          </h3>
          <p>
            {t('You can now import products from Dropship to AutoDS and fulfill your orders!')}
          </p>
        </div>
      </div>
      {
        isConnected ?
          <ButtonComponent className={'auto-ds-banner-btn disabled'}
                           text={null}
                           onClick={() => null}
          >
            <span>
              {t('Connected')}
            </span>
          </ButtonComponent>
          :
          <ButtonComponent className={'auto-ds-banner-btn'}
                           text={null}
                           onClick={() => {
                             dispatch(AutoDSCreators.changeVisibleModalAuto({
                               isVisibleModal: 'connect',
                               importProductId: null
                             }));
                           }}
          >
            <span>
              {t('Connect to AutoDS')}
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M8.3335 13.3334L11.0776 10.5893C11.403 10.2639 11.403 9.73626 11.0776 9.41083L8.3335 6.66675"
                    stroke="#707BA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </ButtonComponent>
      }
    </div>
  );
};

export default AutoDsBanner;
