import React,{useEffect,useState, useRef} from 'react';
import { Divider } from 'antd';
import {useTranslation} from "react-i18next";
import './AdLibraryAdsetsModal.less';
import AdLibraryItemSkeleton from './AdLibraryItemSkeleton';
import Images from '../../../Images';
import AdLibraryModalItem from './AdLibraryModalItem';


const AdLibraryAdsetsModal = (
  {
    isMobile=false,
    collationId,
    getAdLibraryAdsCollation,
    collations,
    skeleton,
    setDataToCalc,
    handleOpenModalShopifyConnect,
    adsLoading,
    setModal
  }) => {

  const {t} = useTranslation();
  const tableRef = useRef();
  const [pageNumber, setPageNumber] = useState(1);



  const transformedArray = collations?.adsets?.map(adset => ({
    ...adset,
    collation: collations.collation
}));


const onScrollHandlerFunc = (event) => {
  const tableContent = event.target;
  const maxTopScroll = tableContent.scrollHeight - tableContent.clientHeight;
  const currentTopScroll = tableContent.scrollTop;

  if (
    currentTopScroll >= maxTopScroll * 0.95 &&
    !adsLoading && 
    transformedArray?.length < +collations?.count 
  ) {
    setPageNumber((prev) => {
      const nextPage = prev + 1;
      if (nextPage <= Math.ceil(collations?.count / 30)) {
        return nextPage;
      }
      return prev; 
    });
  }
};
  
  useEffect(() => {
    // Fetch ads whenever pageNumber changes
    getAdLibraryAdsCollation(
      { 
        collation_id: collationId,
        page_number: pageNumber,
        page_size: 30,
      }
    );
  }, [pageNumber]);

  useEffect(() => {
    const tableContent = tableRef.current;
    if (!tableContent) {

      return;
    }
    tableContent.addEventListener('scroll', onScrollHandlerFunc);
    return () => tableContent.removeEventListener('scroll', onScrollHandlerFunc);
  }, [transformedArray?.length, collations?.ad_sets_count]);
  

  useEffect(() => {
    const tableContent = tableRef.current;
    if (tableContent && tableContent.dataset.scrollPos) {
      tableContent.scrollTop = tableContent.dataset.scrollPos;
    }
  }, [collations])

  return (
    <div className={'load-filter-preset-wrapper store-products-modal-wrapper'}>
      <div className="load-filter-preset-title">
        {t('Ads using this exact creative')}
      </div>
      <p className="load-filter-preset-subtitle">
          {t('Same content was re-used for the following ads. Such adsets are typically used to target different demographics and achieve better reach results')}
      </p>

      <Divider style={{margin:0}}/>

      <div className="adlibrary-page-items-wrapper" style={{maxHeight: '80vh'}}ref={tableRef}>
      {
          skeleton ?
            [1, 2, 3

            ].map(el => <AdLibraryItemSkeleton key={el} />)
            : transformedArray?.map((el) => {
              return(
              <AdLibraryModalItem
                key={el?.ad_archive_id}
                id={el?.ad_archive_id}
                setDataToCalc={setDataToCalc}
                handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
                data={el}
                getAdLibraryAdsCollation={getAdLibraryAdsCollation}
                skeleton={skeleton}
                setModal={setModal}
              />
            )})
        }
      </div>
    </div>
  );
};

export default AdLibraryAdsetsModal;
