import React from 'react';

const PortfolioDashboard = ({ width, height }) => {
  return (
    <svg
      width={width ? width : 20}
      height={height ? height : 18}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00195 3C7.00195 2.44772 7.44967 2 8.00195 2H12.002C12.5542 2 13.002 2.44772 13.002 3V4H15V18H16.0022C18.2113 18 20.0022 16.2091 20.0022 14V9C20.0022 8.97754 20.0015 8.95526 20 8.93318V8C20 5.79086 18.2091 4 16 4H15.002V3C15.002 1.34315 13.6588 0 12.002 0H8.00195C6.3451 0 5.00195 1.34315 5.00195 3V4H7V18H13V4H7.00195V3Z"
        fill="#F9F9FC"
      />
      <path
        d="M4 4H5V18H4.0022C1.79306 18 0.00219727 16.2091 0.00219727 14L0 8C0 5.79086 1.79086 4 4 4Z"
        fill="#F9F9FC"
      />
    </svg>
  );
};

export default PortfolioDashboard;
