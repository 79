import React from 'react';

const CompetitorsDashboard = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 9.8487 15.3729 11.5509 14.3199 12.9056L17.7072 16.2929C18.0977 16.6834 18.0977 17.3166 17.7072 17.7071C17.3166 18.0976 16.6835 18.0976 16.2929 17.7071L12.9057 14.3198C11.551 15.3729 9.84873 16 8 16C3.58172 16 0 12.4183 0 8Z"
        fill="#F9F9FC"
      />
    </svg>
  );
};

export default CompetitorsDashboard;
