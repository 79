import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Creators from '../reducer';
import dayjs from 'dayjs';
import { Switch } from 'antd';
import ButtonComponent from '../../../Components/Button';
import DropdownDateBlock from '../../ProductDatabasePage/components/DropdownDateBlock';
import Icon from '../../../Icon';
import './MainBlock.less';

const dateOptions = [
  { id: 0, name: 'Last 7 days' },
  { id: 1, name: 'Last 30 days' },
  { id: 2, name: 'Last 90 days' },
  { id: 3, name: 'Last 6 months' },
  { id: 4, name: 'Last 12 months' },
];

 const MainBlock = (
  {
    data,
    downloadSalesReport,
    downloadRevenueReport,
    downloadProductReport,
    showRawData = false,
    setShowRawData = null,
    rawDataAccess = false,
    isMobile
  }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dropdownFilters = useSelector(store => store.salesTracker.filters) || {
    report_period: {
      min: new Date(dayjs()).toISOString(),
      max: new Date(dayjs().subtract(30, 'days')).toISOString(),
    },
  };
  const checkedList = useSelector(store => store.salesTracker.checkedList) || [{
    id: 4,
    name: 'Last 30 days',
    value: 'Last 30 days',
    days: 30,
  }];
  const [visibleModal, setVisibleModal] = useState(false);

  const fileLoading = useSelector(state => state?.salesTracker?.fileLoading);

  const setDropdownFilters = (prop) => {
    let result = prop();
    dispatch(Creators.setFilters(result));
  };

  const setMemoCheckedList = (data) => {
    dispatch(Creators.setCheckedList(data));
  };

  const handleDownload = () => {
    if (data.report === 'sales') downloadSalesReport({
      id: data.id,
      name: `${data.title} - ${data.reportName}`,
      date_range: data.dates,
      raw_data: rawDataAccess && showRawData,
    });
    else if (data.report === 'product') downloadProductReport({
      id: data.id,
      name: `${data.title} - ${data.reportName}`,
      date_range: data.dates,
      raw_data: rawDataAccess && showRawData,
    });
    else downloadRevenueReport({
        id: data.id,
        name: `${data.title} - ${data.reportName}`,
        date_range: data.dates,
        raw_data: rawDataAccess && showRawData,
      });
  };

  return (
    <div className="report-main-block">
      <span className={'report-main-block-title'}>
        <span className='title'>
          {t('Overview')}
        </span>
        <span className='key'>
          {t('Store timezone')}:
          <span className="value">
            UTC {data?.timeZone ? data?.timeZone : '+0:00'}
          </span>
        </span>
      </span>

      {
        rawDataAccess ?
          <div className="report-main-block-switch">
            <Switch checked={showRawData}
                    onChange={(state) => setShowRawData(state)}
            />
            <span className="report-main-block-switch-text"
              onClick={() => setShowRawData((prev) => !prev)}
            >
              {t('Raw Data')}
            </span>
          </div>
          :
          null
      }

      <div className="report-main-block-actions">
        <ButtonComponent className={'report-main-block-btn'}
                         onClick={handleDownload}
                         disabled={fileLoading}
        >
          <Icon type="download" />
          {t('Download Report')}
        </ButtonComponent>

        <DropdownDateBlock limit={data?.timeInfo}
                           timeZone={data?.timeZone}
                           label={null}
                           customOverlayClassName={'dropdown-sales_tracker-wrapper'}
                           customInnerOverlayClassName={'dropdown-sales_tracker-wrapper-inner'}
                           id={'report_period'}
                           iconType={'database_calendar'}
                           data={dateOptions}
                           dropdownFilters={dropdownFilters}
                           setDropdownFilters={setDropdownFilters}
                           memoCheckedList={checkedList}
                           setMemoCheckedList={setMemoCheckedList}
                           withName
                           visibleModal={visibleModal}
                           setVisibleModal={setVisibleModal}
                           position={'bottomRight'}
                           isMobile={isMobile}
        />
      </div>
    </div>
  );
};

export default MainBlock;
