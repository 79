import React, { useState } from 'react';
import { connect } from 'react-redux';
import SettingCreators from '../../Containers/SettingPage/reducer';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import { PDFDownloadLink } from '@react-pdf/renderer';
import acc from 'accounting';
import utils from '../../Utils/utils';
import dayjs from 'dayjs';
import { Divider } from 'antd';
import Invoice from './Invoice';
import InvoicePDF from './InvoicePDF';
import Icon from '../../Icon';
import Modal from '../Modal';

const columns = [
  {
    label: 'Amount',
    key: 'total'
  },
  {
    label: 'Status',
    key: 'status'
  },
  {
    label: 'Plan',
    key: 'plan'
  },
  {
    label: 'Date',
    key: 'issued_date'
  },
  {
    label: 'Payment method',
    key: 'payment_method'
  },
  {
    label: 'Invoice',
    key: 'invoice_id'
  },
];

const EmptyState = (
  {
    t
  }
) => {

  return (
    <div className={'empty-state-product'}>
      <div className={'empty-state-product-skeleton'}>
        <span className={'link skeleton'}
              style={{ width: 12, height: 12, borderRadius: 4 }}
        />
        {
          [1,2,3,4].map(el => (
            <div key={el * 11}
                 style={{ zIndex: el, marginLeft: el === 1 ? 0 : '-21px', display: 'flex', alignItems: 'center' }}
                 className={'empty-state-product-skeleton-wrapper'}
            >
                <span className={'link skeleton'}
                      style={{ width: 20, height: 20 }}
                />
            </div>
          ))
        }
        <span className={'link skeleton'}
              style={{ width: 77, height: 8 }}
        />
        <span className={'link skeleton'}
              style={{ width: 48, height: 8 }}
        />
      </div>
      <h3>
        {
          t('No payments made yet..')
        }
      </h3>
      <p>
        {
          t('All your payments history will be displayed here.')
        }
      </p>
    </div>
  )
}

const PayHistory = (
  {
    payments = [],
    userInfo,
    paymentDetails,
    getPaymentDetails,
    isMobile,
    fetching
  },
) => {

  const { t } = useTranslation();

  const [modal, setModal] = useState(null);

  const showModal = (value) => {
    getPaymentDetails(value?.['invoice_id']);
    setTimeout(()=> setModal('invoice'), 100);
  };

  const renderCell = (payment, key) => {
    let result = '-';
    switch (key) {
      case 'total':
        result = `$${acc.formatNumber(payment?.total / 100 || 0, 2, ',', '.')}`;
        break;
      case 'status':
        result = (
          <span className={cls('payment-tag', payment?.status?.name)}>
            {payment?.status?.name}
          </span>
        );
        break;
      case 'plan':
        result = `${payment?.plan}`;
        break;
      case 'issued_date':
        result = `${dayjs(payment?.issued_date).format('DD MMMM, YYYY')}`;
        break;
      case 'payment_method':
        result = payment.payment_method ?
          `${utils.toUpperLatter(payment.payment_method?.card_type)} *${payment.payment_method?.last_4}`
          :
          '-';
        break;
      case 'invoice_id':
        result = (
          <span>
            {payment?.invoice_id}
            <Icon role="icon"
                  type="download"
                  onClick={() => {
                    showModal(payment);
                  }}
            />
          </span>
        );
        break;
      default:
        break;
    }
    return result;
  }

  return (
    <div className="billing-subpage-block">
      <div className="billing-subpage-block-title-wrapper">
        <span className="billing-subpage-block-title">
          {t('Payment history')}
        </span>
      </div>
      <Divider style={{ margin: isMobile ? '16px 0 0' : '16px 0' }} />
      <div className='billing-subpage-block-table-wrapper'>
        <div className="table-header">
          {
            columns.map(el => (
              <span className={cls("item", {
                widest: el?.label === 'Invoice',
                wide: el?.key === 'issued_date',
                wider: el?.key === 'payment_method',
              })}
                    key={el?.label}
              >
                {t(el?.label)}
              </span>
            ))
          }
        </div>
        {
          payments?.length ?
            <div className="table-body">
              {
                payments.map(payment => (
                  <div className="table-row"
                       key={payment?.invoice_id}
                  >
                    {
                      columns.map(column => (
                        <span className={cls('item', {
                          widest: column?.label === 'Invoice',
                          wide: column?.key === 'issued_date',
                          wider: column?.key === 'payment_method'
                        })}
                              key={column?.label}
                        >
                      {
                        renderCell(payment, column?.key)
                      }
                    </span>
                      ))
                    }
                  </div>
                ))
              }
            </div>
            :
            <EmptyState t={t} />
        }
      </div>
      <Modal handleOk={null}
             title={null}
             width={595}
             className="collection-modal account-modal forgot-password-modal"
             isMobile={isMobile}
             handleClose={() => setModal(null)}
             open={modal === 'invoice' && !!paymentDetails}
             destroyOnClose
      >
        <Invoice user={userInfo}
                 invoice={paymentDetails}
                 pdfLink={<PDFDownloadLink document={<InvoicePDF user={userInfo} invoice={paymentDetails}/>}
                                           fileName={`invoice_${paymentDetails?.['invoice_id']}.pdf`}
                                           className="invoice-pdf-btn"
                                           onClick={() => setModal(null)}
                 >
                   {
                     fetching ?
                       '...'
                       :
                       <Icon role="icon" type="download"/>
                   }
                 </PDFDownloadLink>}
        />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  payments: state?.setting?.payments?.results,
  userInfo: state.auth.userInfo,
  paymentDetails: state.setting.paymentDetailsResult,
  isMobile: state.nav.isMobile,
  fetching: state.setting.paymentDetailsFetching,
});

const mapDispatchToProps = (dispatch) => ({
  getPaymentDetails: (id) => dispatch(SettingCreators.getPaymentDetailsRequest({ id })),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayHistory);
