import React from 'react';
import { Divider, Spin } from 'antd';
import Modal from '../../../../Components/Modal';
import ButtonComponent from '../../../../Components/Button';
import { useTranslation } from 'react-i18next';

const DeleteModal = (
  {
    setModal,
    isMobile,
    modal,
    loading,
    removeCollection,
    collection
  }
) => {

  const {t} = useTranslation();

  return (
    <Modal handleOk={null}
           handleClose={() => setModal(null)}
           title={t(`Remove "_name_" collection?`, {name: collection?.collectionName})}
           isMobile={isMobile}
           width={450}
           className="collection-delete-modal collection-modal"
           open={modal === 'collection_delete'}
           destroyOnClose
    >
      <Spin spinning={loading}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            Are you sure you want to remove this collection with
            <span className={'bold'}> {collection?.collectionCount || collection?.products?.length} {collection?.collectionCount === 1 || collection?.products?.length === 1 ? 'product' : 'products'}</span>?
            This action can not be undone.
          </p>
        </div>
        <Divider type={'horizontal'}
                 style={{ margin: '0 0 16px' }}
        />
        <div className={'collection-modal-btn-wrapper'}>
          <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                           onClick={() => setModal(null)}
                           text={t('Cancel')}
          />
          <ButtonComponent className="collection-modal-btn collection-modal-btn-save red"
                           disabled={loading}
                           onClick={() => {
                             removeCollection(collection?.collectionID || collection?.id);
                             setModal(null);
                           }}
                           text={t('Remove')}
          />
        </div>
        {
          isMobile ?
            null
            :
            <div style={{ height: 16 }} />
        }
      </Spin>
    </Modal>
  );
};

export default DeleteModal;
