import React from "react";

const BannerDefaultSearch = ({ text }) => {
	return (
		<div className="default-search-banner">
			<p className="default-search-banner-text">{text}</p>
		</div>
	)   
};

export default BannerDefaultSearch;