import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { OverlayScrollbar } from '../../Components/ScrollBar';
import ImagesBlock from './components/ImagesBlock';
import NameProductBlock from './components/NameProductBlock';
import DescriptionBlock from './components/DescriptionBlock';
import OverviewBlock from './components/OverviewBlock';
import BenefitsBlock from './components/BenefitsBlock';
import SaturationBlock from './components/SaturationBlock';
import CompetitorsBlock from './components/CompetitorsBlock';
import SuppliersBlock from './components/SuppliersBlock';
import VideosBlock from './components/VideosBlock';
import NumbersBreakdownBlock from './components/NumbersBreakdownBlock';
import InterestBlock from './components/InterestBlock';
import MoreProductsBlock from './components/MoreProductsBlock';
import { BreadcrumbsComponent } from '../Breadcrumbs';
import BreadcrumbsActions from '../Breadcrumbs/actions';
import Creators from './reducer';
import SettingCreators from '../SettingPage/reducer';
import OnboardingActions from '../../Redux/OnboardingRedux';
import CollectionsActions from '../CollectionsPage/reducer';
import ShopifyStoreActions from '../ShopifyStore/reducer';
import AutoDSActions from '../AutoDS/reducer';
import utils from '../../Utils/utils';
import { Divider } from 'antd';
import './styles.less';

const ProductDetailsPage = (
  {
    product,
    fetching,
    error,
    userInfo,
    isMobile,
    theme,
    breadcrumbs,
    addBreadCrumb,
    removeBreadCrumb,
    clearProductDetails,
    getSubscriptionsList,
    activeSubscriptions,
    getCountries,
    getUserCountry,
    countries,
    getShopifyStore,
    getAutoStore,
    openModalShopify,
    openModalAutoDS,
    shopifyStores,
    autoDS,
    ...props
  }) => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const scrollRef = useRef(null);
  const { product_id } = useParams();
  const navigate = useNavigate();

  const breadCrumbs = breadcrumbs?.length ? breadcrumbs : null;
  const activeSubscriptionPlan = activeSubscriptions?.[0]?.plan?.name;
  const disabledImport = !userInfo?.subscriptions?.[0]?.plan?.price
    && userInfo?.subscriptions?.[0]?.plan?.plan?.name !== 'free'
    && userInfo?.role?.id !== 4;

  useEffect(() => {
    let pageName = utils.toUpperLatter(location.pathname.split('/')[1]);
    if (breadCrumbs === null) navigate(`/${pageName.toLowerCase()}`, { replace: true });
    document.title = pageName ? `${pageName} - Dropship` : 'Dropship';
    getSubscriptionsList('active');
    if (!countries?.length && !props?.isAdmin) getCountries();
    getUserCountry();
    getShopifyStore();
    getAutoStore();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (typeof window.FB !== 'undefined') delete window.FB;
  }, [product_id, activeSubscriptionPlan]);

  useEffect(() => {
    if (breadcrumbs?.length <= 2) {
      addBreadCrumb({
        name: product?.title,
      });
    }
    if (breadcrumbs?.length === 3) {
      removeBreadCrumb();
      addBreadCrumb({
        name: product?.title,
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [product?.title]);

  useEffect(() => {
    if (product_id && activeSubscriptionPlan) props.getProductDetails({ product_id });
    handleScrollToTop();
    return () => clearProductDetails();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [product_id, activeSubscriptionPlan]);

  const downloadFile = () => {
    props.exportInterests({ product_id: product?.product_id });
  };

  function handleScrollToTop() {
    let block = document.querySelector('.product-database-page.portfolio-page.product-details-page');
    if (isMobile) {
      block.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      if (scrollRef) scrollRef.current._osInstance.scroll(0, 500);
    }
  }

  const addToCollectionFunc = ({ id, isSave, name, defaultCollectionName }) => {
    if (!isSave) {
      dispatch(
        CollectionsActions.addToCollectionRequest({
          productID: id,
          productName: name,
          defaultCollectionName
        }),
      );
    } else {
      dispatch(
        CollectionsActions.removeFromCollectionRequest({
          productID: id,
          productName: name,
        }),
      );
    }
  };

  const handleSetVisible = () => {
    navigate('/setting/plan');
  };

  return (
    <OverlayScrollbar visibility="hidden" ref={scrollRef}>
      <div className="product-database-page portfolio-page product-details-page"
           ref={scrollRef}
      >
        <BreadcrumbsComponent breadCrumbs={breadCrumbs?.length ? breadCrumbs : []}
                              isMobile={isMobile}
                              isAdmin={false}
                              skeleton={fetching}
        />
        <div className={'product-details-page-main-info-wrapper'}>
          {
            isMobile ?
              <NameProductBlock title={product?.title}
                                isMobile={isMobile}
                                textLink={t('View Listing')}
                                link={product?.initial_url}
                                skeleton={fetching}
                                addToCollectionFunc={(id, isSave, name, defaultCollectionName) =>
                                  addToCollectionFunc({ id, isSave, name, defaultCollectionName })
                                }
                                product={product}
              />
              :
              null
          }
          <ImagesBlock images={product?.images || []}
                       skeleton={fetching}
                       isMobile={isMobile}
          />
          <div className={'product-details-page-main-info-text-wrapper'}>
            {
              isMobile ?
                null
                :
                <NameProductBlock title={product?.title}
                                  isMobile={isMobile}
                                  textLink={t('View Listing')}
                                  link={product?.initial_url}
                                  skeleton={fetching}
                                  addToCollectionFunc={(id, isSave, name, defaultCollectionName) =>
                                    addToCollectionFunc({ id, isSave, name, defaultCollectionName })
                                  }
                                  product={product}
                />
            }
            <DescriptionBlock product={product}
                              theme={theme}
                              isMobile={isMobile}
                              skeleton={fetching}
                              isShopifyConnected={!!shopifyStores?.length}
                              openModalShopify={openModalShopify}
                              openModalAutoDS={openModalAutoDS}
                              autoDS={autoDS}
                              disabledImport={disabledImport || !shopifyStores?.length} //only test branch
            />
          </div>
        </div>
        <div className="product-details-page-block-wrapper">
          <OverviewBlock product={product}
                         theme={theme}
                         isMobile={isMobile}
                         skeleton={fetching}
          />
        </div>
        {
          product?.benefits?.length || product?.drawbacks?.length ?
            (
              <div className="product-details-page-block-wrapper">
                <BenefitsBlock product={product}
                               skeleton={fetching}
                               isMobile={isMobile}
                />
              </div>
            )
            :
            null
        }
        {
          product?.competitors?.length ?
            (
              <div className={'product-details-page-block'}>
                <div className="product-details-page-block-wrapper saturation-wrapper">
                  <SaturationBlock saturation={product?.saturation || 0}
                                   isMobile={isMobile}
                                   skeleton={fetching}
                  />
                </div>
                <div className="product-details-page-block-wrapper competitors-wrapper"
                     style={{ padding: 0 }}
                >
                  <CompetitorsBlock product={product}
                                    isMobile={isMobile}
                                    skeleton={fetching}
                                    onOpenUpgradePlan={handleSetVisible}
                  />
                </div>
              </div>
            )
            :
            null
        }
        {
          product?.videos?.tiktok?.length || product?.videos?.facebook?.length ?
            (
              <div className="product-details-page-block-wrapper">
                <VideosBlock videos={product?.videos}
                             isMobile={isMobile}
                             fetching={fetching}
                             lang={userInfo?.language || 'en'}
                             onOpenUpgradePlan={handleSetVisible}
                />
              </div>
            )
            :
            null
        }
        <div className="product-details-page-block-wrapper suppliers-wrapper"
             style={{ padding: 0 }}
        >
          <SuppliersBlock product={product}
                          isMobile={isMobile}
                          skeleton={fetching}
                          onOpenUpgradePlan={handleSetVisible}
          />
        </div>
        <div className="product-details-page-block-wrapper">
          <NumbersBreakdownBlock product={product}
                                 isMobile={isMobile}
                                 skeleton={fetching}
          />
        </div>
        {
          product?.interests?.length ?
            <div className="product-details-page-block-wrapper interests-wrapper"
                 style={{ padding: 0 }}
            >
              <InterestBlock product={product}
                             isMobile={isMobile}
                             onDownloadFile={downloadFile}
                             skeleton={fetching}
                             onOpenUpgradePlan={handleSetVisible}
              />
            </div>
            :
            null
        }
        {
          product?.more_products?.length ?
            <div className="product-details-page-block-wrapper"
                 style={{ padding: 0, border: 'none' }}
            >
              <MoreProductsBlock product={product}
                                 isMobile={isMobile}
                                 skeleton={fetching}
                                 breadCrumbs={breadCrumbs}
                                 clearProductDetails={clearProductDetails}
              />
            </div>
            :
            null
        }
        <Divider type={'horizontal'}
                 style={{ margin: '28px auto 24px', width: 'calc(100% - 64px)', minWidth: 'unset' }}
        />
        <span className="scroll-to-top"
              onClick={handleScrollToTop}
        >
          <span>
            {t('Scroll to top')}
          </span>
          <svg xmlns="http://www.w3.org/2000/svg"
               width="20"
               height="20"
               viewBox="0 0 20 20"
               fill="none"
          >
            <path d="M4.79102 8.33333L9.99933 3.125L15.2077 8.33333M9.99933 16.875V3.75"
                  stroke="#225AEA"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
            />
          </svg>
        </span>
        {
          isMobile ?
            null
            :
            <div style={{ height: 28 }} />
        }
      </div>
    </OverlayScrollbar>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  userInfo: state.auth.userInfo,
  fetching: state.productDetails.loading,
  error: state.productDetails.errors,
  product: state.productDetails?.productDetails,
  isMobile: state.nav.isMobile,
  theme: state.nav.theme,
  breadcrumbs: state.breadcrumbs.totalBreadcrumbs,
  countries: state.onBoarding.countries,
  activeSubscriptions: state.setting.subscriptions,
  shopifyStores: state.shopifyStore.results,
  autoDS: state.autoDS,
});

const mapDispatchToProps = (dispatch) => ({
  getProductDetails: (params) =>
    dispatch(Creators.getProductDetailsRequest(params)),
  exportInterests: (params) =>
    dispatch(Creators.exportInterestsRequest(params)),
  addBreadCrumb: (params) => dispatch(BreadcrumbsActions.addBreadCrumb(params)),
  removeBreadCrumb: (params) =>
    dispatch(BreadcrumbsActions.removeBreadCrumb(params)),
  clearProductDetails: () => dispatch(Creators.clearProductDetails()),
  getSubscriptionsList: (filter) =>
    dispatch(SettingCreators.getSubscriptionsListRequest(filter)),
  getCountries: () => dispatch(OnboardingActions.countriesRequest()),
  getUserCountry: () => dispatch(SettingCreators.getUserCountryRequest()),
  getShopifyStore: () => dispatch(ShopifyStoreActions.getShopifyStoresRequest()),
  openModalShopify: (modal, initialUrl, importProductId) => dispatch(ShopifyStoreActions.changeVisibleModalShopify({
    isVisibleModal: modal,
    initialUrl,
    importProductId,
  })),
  openModalAutoDS: (modal, importProductId) => dispatch(AutoDSActions.changeVisibleModalAuto({
    isVisibleModal: modal,
    importProductId,
  })),
  getAutoStore: () => dispatch(AutoDSActions.getAutoStoreRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailsPage);
