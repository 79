import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import utils from '../../../Utils/utils';
import cls from 'classname';
import Icon from '../../../Icon';
import './PopularToday.less';

const data = [
  {
    title: 'store 1',
    id: '1',
    icon: 'https://fanjoy.co/cdn/shop/files/Fanjoy_Favicon_32x32_square_v3_89cfb2b5-ca86-4155-b7db-868fb38f4082_180x180_crop_center.png'
  },
  {
    title: 'store 2',
    id: '2',
    icon: 'https://fanjoy.co/cdn/shop/files/Fanjoy_Favicon_32x32_square_v3_89cfb2b5-ca86-4155-b7db-868fb38f4082_180x180_crop_center.png'
  },
  {
    title: 'store 3',
    id: '3',
    icon: 'https://fanjoy.co/cdn/shop/files/Fanjoy_Favicon_32x32_square_v3_89cfb2b5-ca86-4155-b7db-868fb38f4082_180x180_crop_center.png'
  },
  {
    title: 'store 4',
    id: '4',
    icon: 'https://fanjoy.co/cdn/shop/files/Fanjoy_Favicon_32x32_square_v3_89cfb2b5-ca86-4155-b7db-868fb38f4082_180x180_crop_center.png'
  },
  {
    title: 'store 5',
    id: '5',
    icon: 'https://fanjoy.co/cdn/shop/files/Fanjoy_Favicon_32x32_square_v3_89cfb2b5-ca86-4155-b7db-868fb38f4082_180x180_crop_center.png'
  },
  {
    title: 'store 6',
    id: '6',
    icon: 'https://fanjoy.co/cdn/shop/files/Fanjoy_Favicon_32x32_square_v3_89cfb2b5-ca86-4155-b7db-868fb38f4082_180x180_crop_center.png'
  },
  {
    title: 'store 7',
    id: '7',
    icon: 'https://fanjoy.co/cdn/shop/files/Fanjoy_Favicon_32x32_square_v3_89cfb2b5-ca86-4155-b7db-868fb38f4082_180x180_crop_center.png'
  },
  {
    title: 'store 8',
    id: '8',
    icon: 'https://fanjoy.co/cdn/shop/files/Fanjoy_Favicon_32x32_square_v3_89cfb2b5-ca86-4155-b7db-868fb38f4082_180x180_crop_center.png'
  },
  {
    title: 'store 9',
    id: '9',
    icon: 'https://fanjoy.co/cdn/shop/files/Fanjoy_Favicon_32x32_square_v3_89cfb2b5-ca86-4155-b7db-868fb38f4082_180x180_crop_center.png'
  },
  {
    title: 'store 10',
    id: '10',
    icon: 'https://fanjoy.co/cdn/shop/files/Fanjoy_Favicon_32x32_square_v3_89cfb2b5-ca86-4155-b7db-868fb38f4082_180x180_crop_center.png'
  },
  {
    title: 'store 11',
    id: '11',
    icon: 'https://fanjoy.co/cdn/shop/files/Fanjoy_Favicon_32x32_square_v3_89cfb2b5-ca86-4155-b7db-868fb38f4082_180x180_crop_center.png'
  },
  {
    title: 'store 12',
    id: '12',
    icon: 'https://fanjoy.co/cdn/shop/files/Fanjoy_Favicon_32x32_square_v3_89cfb2b5-ca86-4155-b7db-868fb38f4082_180x180_crop_center.png'
  },
  {
    title: 'store 13',
    id: '13',
    icon: 'https://fanjoy.co/cdn/shop/files/Fanjoy_Favicon_32x32_square_v3_89cfb2b5-ca86-4155-b7db-868fb38f4082_180x180_crop_center.png'
  },
  {
    title: 'store 14',
    id: '14',
    icon: 'https://fanjoy.co/cdn/shop/files/Fanjoy_Favicon_32x32_square_v3_89cfb2b5-ca86-4155-b7db-868fb38f4082_180x180_crop_center.png'
  },
  {
    title: 'store 15',
    id: '15',
    icon: 'https://fanjoy.co/cdn/shop/files/Fanjoy_Favicon_32x32_square_v3_89cfb2b5-ca86-4155-b7db-868fb38f4082_180x180_crop_center.png'
  },
  {
    title: 'store 16',
    id: '16',
    icon: 'https://fanjoy.co/cdn/shop/files/Fanjoy_Favicon_32x32_square_v3_89cfb2b5-ca86-4155-b7db-868fb38f4082_180x180_crop_center.png'
  },
  {
    title: 'store 17',
    id: '17',
    icon: 'https://fanjoy.co/cdn/shop/files/Fanjoy_Favicon_32x32_square_v3_89cfb2b5-ca86-4155-b7db-868fb38f4082_180x180_crop_center.png'
  },
];

const PopularToday = (
  {
    view,
    skeleton,
  }
) => {

  const {t} = useTranslation();
  const containerRef = useRef(null);

  const [scrollProps, setScrollProps] = useState({
    current: 0,
    max: 0
  });

  function handleClick(type) {
    if (!containerRef.current) return;
    let value = type === 'next' ?
      scrollProps?.current + 300 >= scrollProps?.max ?
        scrollProps?.max - 1
        :
        scrollProps?.current + 300
        :
      scrollProps?.current - 300 < 0 ?
        0
        :
        scrollProps?.current - 300;
    containerRef.current.scrollTo({
      left: value,
      behavior: 'smooth',
    });
    setScrollProps(prev => ({ ...prev, current: value  }));
  }

  useEffect(() => {
    if (containerRef.current) {
      const max = containerRef?.current?.scrollWidth - containerRef?.current?.clientWidth;
      setScrollProps({ current: 0, max, });
    }
  }, [containerRef.current]);

  if (skeleton) return (
    <div className="popular-today">
      <span className={'link skeleton'}
            style={{ width: 135, height: 20 }}
      />
      <div className="popular-today-items"
           style={{marginLeft: 12}}
      >
        {
          [1, 2, 3].map(el => (
            <div className="popular-today-item"
                 key={el}
                 style={{width: 120, height: 32}}
            >
              <span className={'link skeleton'}
                    style={{ width: 20, height: 20, borderRadius: '50%' }}
              />
              <span className={'link skeleton'}
                    style={{ width: 67, height: 20 }}
              />
            </div>
          ))
        }
      </div>
      <div className="popular-today-handlers">
        <span className={'link skeleton'}
              style={{ width: 20, height: 20 }}
        />
        <span className={'link skeleton'}
              style={{ width: 20, height: 20 }}
        />
      </div>
    </div>
  );


  return (
    <div className="popular-today">
      <span className="popular-today-title">
        {t(`Popular ${view} today:`)}
      </span>
      <div ref={containerRef} className="popular-today-items"
           onScroll={e => {
             setScrollProps(prev => ({ ...prev, current: e?.target?.scrollLeft}));
           }}
      >
        {
          data.map(el => (
            <div className="popular-today-item"
                 key={el?.id}
            >
              <img
                src={el?.icon}
                alt=""
                onError={utils.addDefaultSrc}
                width="20"
                height="20"
              />
              <span>
                {
                  el?.title
                }
              </span>
            </div>
          ))
        }
      </div>
      <div className="popular-today-handlers">
        <span className={cls("popular-today-handler prev", {
          disabled: scrollProps?.current === 0 || scrollProps?.max === 0,
        })}
              onClick={() => handleClick('prev')}
        >
          <Icon type={'arrow_next'} role={'icon'} />
        </span>
        <span className={cls('popular-today-handler next', {
          disabled: scrollProps?.current + 10 >= scrollProps?.max || scrollProps?.max === 0,
        })}
              onClick={() => handleClick('next')}
        >
          <Icon type={'arrow_next'} role={'icon'} />
        </span>
      </div>
    </div>
  );
};

export default PopularToday;
