import React, { useRef, useState, useEffect } from 'react';
import cls from "classname";
import ArrowSelectDown from "../../Icon/img/ArrowSelectDown";
import { Checkbox, DatePicker, Dropdown, Spin, Tooltip } from "antd";
import Icon from "../../Icon";
import dayjs from "dayjs";
import { calendarLocale, langMapISO } from "../../Utils/utils";
import ButtonComponent from "../../Components/Button";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import acc from "accounting";
import useDebounce from "../../hooks/use-debounce";

const { RangePicker } = DatePicker;

const OldDropDownCalendar = ({ title, countLoading, count = 0, handleCount, id, value, setValue, disabled, isStandard = false, adspot = false, products = false }) => {

  const { t } = useTranslation();
  const dropdownInner = useRef();

  dayjs.updateLocale('en-us', {
    week: {
      dow: 1,
    },
  });

  const data = [
    { id: 0, name: 'Last 7 days' },
    { id: 1, name: 'Last 30 days' },
    { id: 2, name: 'Last 90 days' },
    { id: 3, name: 'Last 6 months' },
    { id: 4, name: 'Last 12 months' },
  ];

  const lang = useSelector(store => store?.auth?.userInfo?.language) || 'en';

  const [visible, setVisible] = useState(false);
  const [innerVisible, setInnerVisible] = useState(false);
  const [checkedList, setCheckedList] = useState(null);
  const [checkAll, setCheckAll] = useState(false);
  const [dateValue, setDateValue] = useState(null);
  const [isApply, setIsApply] = useState(false);
  const [clickSame, setClickSame] = useState(null);

  const debouncedValue = useDebounce(dateValue, 500);

  const setInitialValue = () => {
    //dropdownFilters[id]?.id - check if option range was selected (last 30 days, etc)
    if (!value[id]?.id) {
      if (!value[id]?.min && !value[id]?.max) {
        setCheckedList(data);
        setCheckAll(true);
        setDateValue(null);
      } else {
        setDateValue(value[id]);
      }
    } else {
      setCheckedList([value[id]?.id]);
      setCheckAll(false);
      let quantity = value[id]?.id?.name.split(' ')[1];
      let val = value[id]?.id.name.split(' ')[2];
      let max = dayjs().format('YYYY-MM-DD');
      let min = dayjs().subtract(quantity, val).format('YYYY-MM-DD');
      setDateValue({ min: min, max: max });
    }
  };

  const handleVisibleChange = (value) => {
    if (value) setIsApply(false);
    if (!value && innerVisible) setInnerVisible(false);
    if (!value && !isApply) handleCancel();
    setVisible(value);
  };

  const handleInnerVisibleChange = (value) => {
    setInnerVisible(value);
  };

  const onCheckAllChange = e => {
    if (checkAll) return;
    const checked = e.target.checked;
    setCheckedList(checked ? data : null);
    setCheckAll(true);
    setDateValue(null);
    setCheckedList(data);
    setInnerVisible(false);
  };

  const onChange = e => {
    const { value } = e.target;
    let val = value;

    if ((checkedList && checkedList[0]?.name === val?.name && !checkAll) || !val) {
      setCheckAll(true);
      setDateValue(null);
      setCheckedList(data);
    } else {
      setCheckedList([val]);
      let quantity = val?.name.split(' ')[1];
      let value = val?.name.split(' ')[2];
      let max = dayjs().format('YYYY-MM-DD');
      let min = dayjs().subtract(quantity, value).format('YYYY-MM-DD');
      setDateValue({ min: min, max: max });
      setCheckAll(false);
    }
    setInnerVisible(false);
  };

  function getSelectValueInner() {
    return checkedList?.length === data.length ? t('All') : t(checkedList?.[0]['name']);
  }

  function handleCancel() {
    let currentPeriod;
    if (value[id]?.id) {
      currentPeriod = [value[id]?.id];
    } else {
      if (value[id]?.min && value[id]?.max) {
        currentPeriod = [{
          days: 0,
          id: 999,
          name: 'Custom',
          value: null,
        }];
      } else {
        currentPeriod = data;
      }
    }
    setCheckedList(currentPeriod);
    setTimeout(() => setInitialValue(), 0);
    if (visible) setVisible(false);
  }

  const handleApply = () => {
    if (disabled) return
    else {
      setIsApply(true);
      if (checkAll) {
        setValue(state => ({ ...state, [id]: { min: null, max: null, id: null } }));
      } else {
        if (checkedList?.length === 1) {
          if (checkedList[0].name === 'Custom') {
            setValue(state => ({ ...state, [id]: { ...dateValue, id: null } }));
          } else {
            setValue(state => ({ ...state, [id]: { ...dateValue, id: checkedList[0] } }));
          }
        }
      }
      setVisible(false);
    }
  };

  useEffect(() => {
    setInitialValue();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [value]);

  useEffect(() => {
    if (visible) {
      handleCount({
        id: id,
        value: { min: debouncedValue?.min, max: debouncedValue?.max }
      })
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [visible, debouncedValue]);

  return (
    <div className={cls('old-main-filter-dropdown', {
      'adspot-dropdown': adspot
    })} style={{ position: "relative" }}>
      <Dropdown
        overlayClassName={`old-main-filter-dropdown-content-wrapper old-calendar-dropdown-content-wrapper ${adspot ? 'adspot-dropdown'  : ''} ${products ? 'products' : ''}`}
        getPopupContainer={(trigger) => trigger.parentNode}
        onOpenChange={handleVisibleChange}
        placement={'bottomLeft'}
        trigger={['click']}
        open={visible}
        dropdownRender={() => (
          <div ref={dropdownInner} className={'old-dropdown-date-wrapper'}>

            <div className="old-dropdown-date-title">
              Date Range
            </div>

            <Spin spinning={countLoading}>

              <Dropdown
                overlayClassName={'old-dropdown-database-wrapper-inner'}
                destroyPopupOnHide={true}
                getPopupContainer={(trigger) => trigger.parentNode}
                onOpenChange={handleInnerVisibleChange}
                trigger={['click']}
                open={innerVisible}
                dropdownRender={() => (
                  <div className="old-dropdown-database-menu">

                    <ul className="old-dropdown-database-list">
                      {
                        checkedList?.[0]?.name === 'Custom' &&
                        <div className="old-dropdown-database-list-item">
                          <Checkbox checked={true}>
                            Custom
                          </Checkbox>
                        </div>
                      }
                      <div className="old-dropdown-database-list-item">
                        <Checkbox onChange={onCheckAllChange}
                          checked={checkAll}
                        >
                          {t('All')}
                        </Checkbox>
                      </div>
                      {
                        data.map(el => (
                          <div key={el.id} className="old-dropdown-database-list-item">
                            <Checkbox
                              value={el}
                              checked={checkedList?.some(checked => checked.id === el.id)}
                              onChange={onChange}
                            >
                              {el.name}
                            </Checkbox>
                          </div>
                        ))
                      }

                    </ul>
                  </div>
                )}
              >
                <div className={cls('old-dropdown-database', { 'old-ant-dropdown-open': innerVisible })}>
                  <Icon role="icon" type={'database_calendar'} />
                  <span className="old-dropdown-database_value">
                    {
                      getSelectValueInner()
                    }
                  </span>
                  <ArrowSelectDown />
                </div>
              </Dropdown>

              <div className="old-rangepicker-title">
                <span>{t('Start Date')}</span>
                <span>{t('End Date')}</span>
              </div>

              <RangePicker className={'old-dropdown-date-oldRangePicker'}
                value={[dateValue?.min ? dayjs(dateValue?.min) : null, dateValue?.max ? dayjs(dateValue?.max) : null]}
                open={true}
                format={'YYYY-MM-DD'}
                locale={calendarLocale(lang)}
                nextIcon={<Icon type={'arrow_datepicker_next'} role={'icon'} width={24} height={24} color={'#707ba0'} />}
                prevIcon={<Icon type={'arrow_datepicker_prev'} role={'icon'} width={24} height={24} color={'#707ba0'} />}
                clearIcon={<Icon role="icon" type="close_modal" color="#707BA0" width={10} height={10} opacity={1} />}
                onCalendarChange={(date, strings, info) => {
                  if (date.filter(el => el !== null).length) {
                    const inputs = document.querySelectorAll('.ant-picker-input');
                    //if date selected, set local and parent states
                    let min = (info?.range === 'end' && !date[0]) ? date[1]?.format('YYYY-MM-DD') : date[0]?.format('YYYY-MM-DD');
                    let max = info?.range === 'start' ? date[0]?.format('YYYY-MM-DD') : date[1]?.format('YYYY-MM-DD');
                    setDateValue({ min: min, max: max });

                    if (checkAll) setCheckAll(false);
                    setCheckedList([{
                      days: 0,
                      id: 999,
                      name: 'Custom',
                      value: null,
                    }]);

                    if (info.range === 'start') {
                      return inputs?.[1]?.firstElementChild?.focus();
                    } else if (info.range === 'end' && strings[0] === strings[1]) {
                      return inputs?.[0]?.firstElementChild?.focus();
                    }
                  } else {
                    setDateValue(null);
                  }
                }}
                getPopupContainer={() => dropdownInner.current}
                placeholder={['Select Start Date', 'Select End Date']}
                dateRender={current => {
                  return (
                    <div onClick={() => {
                      if (new Date(current.endOf('day')?.['$d']).toISOString() === dateValue?.min
                        || new Date(current.endOf('day')?.['$d']).toISOString() === dateValue?.max) {
                        setClickSame(new Date(current.endOf('day')?.['$d']).toISOString());
                        setTimeout(() => setClickSame(null), 400);
                      } else setClickSame(null);
                    }} className={cls('ant-picker-cell-inner', {
                      //style days after today
                      'ant-picker-future': dayjs().endOf('day') < current,
                      'ant-picker-cell-already-selected': clickSame === new Date(current.endOf('day')?.['$d']).toISOString(),
                    })}
                    >
                      {current.date()}
                    </div>
                  );
                }}
                disabledDate={current => dayjs().endOf('day') < current}
              />
              <div className="old-main-filter-dropdown-content-footer" style={{ marginTop: '53%' }}>
                <div className="results">
                  {t('Results')}: {acc.formatNumber(count, 0, ',', '.')}
                </div>
                <div className="old-dropdown-date-button-wrapper old-main-filter-dropdown-content-button-wrapper">
                  <div className="old-dropdown-date-info-wrapper">
                    <p className="old-dropdown-date-info-date">
                      {
                        checkAll
                          ? 'All Dates'
                          : `${dayjs(dateValue?.min).locale(langMapISO[lang]).format('MMM DD, YYYY')} - ${dayjs(dateValue?.max).locale(langMapISO[lang]).format('MMM DD, YYYY')}`
                      }
                    </p>
                  </div>
                  <ButtonComponent className={'old-dropdown-date-button'}
                    text={t('Cancel')}
                    onClick={handleCancel}
                  />
                  {
                    disabled ?
                      <Tooltip placement='top'
                        title={isStandard ? 'To preform this action, you would need to upgrade to Premium plan.' : 'To perform this action, you would need to upgrade to Standard or Premium plan.'}
                        overlayClassName={'subscription-tooltip scheduled skip'}
                        arrow={true}
                        trigger={'hover'}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        destroyTooltipOnHide={true}
                      >
                        <ButtonComponent text={t('Apply Filter')}
                          className={cls('button-apply', {
                            'button-apply-disabled': disabled
                          })}
                          onClick={() => null}
                        />
                      </Tooltip>
                      :
                      <ButtonComponent className={cls('old-dropdown-date-button apply', {
                        'apply-disabled': disabled
                      })}
                        text={t('Apply')}
                        onClick={handleApply}
                        disabled={false}
                      />
                  }
                </div>
              </div>
            </Spin>
          </div>
        )}
      >
        <div className='old-main-filter-dropdown-inner'>
          {title}
          <ArrowSelectDown />
        </div>
      </Dropdown>
    </div>
  );
};

export default OldDropDownCalendar;
