import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Creators from '../reducer';
import Chart from '../../../Components/Charts/Chart';
import dayjs from 'dayjs';
import utils from '../../../Utils/utils';
import Image from '../../../Components/Image';
import Images from '../../../Images';
import Icon from '../../../Icon';
import { Divider } from 'antd';
import AppUtils from '../../../Utils/utils';
import cls from 'classname';
import acc from 'accounting';

const PriceHistory = ({ product, isPD = true }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useSelector((store) => store?.nav?.isMobile);
  const loading = useSelector(
    (store) => store?.productDatabase?.productPriceLoading,
  );
  const data =
    useSelector((store) => store?.productDatabase?.productPriceData?.chart) ||
    [];
  const productPriceData =
    useSelector((store) => store?.productDatabase?.productPriceData) || {};
  const [isLineChart, setIsLineChart] = useState(true);

  useEffect(() => {
    dispatch(
      Creators.getProductPriceHistoryRequest({
        isPD: isPD,
        store_id: product?.store?.id,
        product_id: product?.id,
      }),
    );

    return () => {
      dispatch(Creators.resetProductPrice());
    };
  }, []);

  return (
    <div
      className={
        'load-filter-preset-wrapper breakdown-database-wrapper price-modal-wrapper'
      }
    >
      <div className="load-filter-preset-title price-modal">
        {t('Price dynamics')}
      </div>
      <Divider style={{ margin: '8px 0' }} />
      <div className={'price-modal-info'}>
        <div className={'price-modal-info-logo'}>
          {product?.main_image ? (
            <img
              src={product?.main_image}
              alt=""
              onError={utils.addDefaultSrc}
              width="40"
              height="40"
            />
          ) : (
            <Image src={Images.defaultImg} small={true} />
          )}
        </div>
        <span className="price-modal-info-title">{product?.title}</span>
      </div>
      {loading ? (
        <div className={'price-modal-chart-wrapper'}>
          <div className={'price-modal-period'} style={{ flexDirection: isMobile && "column", alignItems: isMobile && "flex-start", marginBottom: 16 }}>
            {isMobile ? (
              <>
                <span
                  className="link skeleton"
                  style={{ width: 68, height: 15, display: 'block' }}
                />
                <span
                  className="link skeleton"
                  style={{ width: 61, height: 20, display: 'block' }}
                />
                <span
                  className="link skeleton"
                  style={{ width: 169, height: 15, display: 'block' }}
                />
                <span
                  className="link skeleton"
                  style={{ width: isMobile ? "100%" : 72, height: 40, display: 'block' }}
                />
              </>
            ) : null}
            {isMobile ? null : (
              <>
                <span
                  className="link skeleton"
                  style={{ width: 50, height: 28, display: 'block', marginBottom: 8 }}
                />
                <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                  <span
                    className="link skeleton"
                    style={{ width: 280, height: 40, display: 'block' }}
                  />
                  <span
                    className="link skeleton"
                    style={{ width: 72, height: 40, display: 'block' }}
                  />
                </div>
              </>
            )}
          </div>
          <div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  marginTop: 6,
                }}
              >
                <span
                  className="link skeleton"
                  style={{ width: 33, height: 16, marginTop: 10 }}
                />
                <span
                  className="link skeleton"
                  style={{ width: 33, height: 16, marginTop: 10 }}
                />
                <span
                  className="link skeleton"
                  style={{ width: 33, height: 16, marginTop: 10 }}
                />
                <span
                  className="link skeleton"
                  style={{ width: 33, height: 16, marginTop: 10 }}
                />
                <span
                  className="link skeleton"
                  style={{ width: 33, height: 16, marginTop: 10 }}
                />
                <span
                  className="link skeleton"
                  style={{ width: 33, height: 16, marginTop: 10 }}
                />
                <span
                  className="link skeleton"
                  style={{ width: 33, height: 16, marginTop: 10 }}
                />
                <span
                  className="link skeleton"
                  style={{ width: 33, height: 16, marginTop: 10 }}
                />
              </div>
              <div style={{ width: 'calc(100% - 39px)', height: 250 }}>
                <span
                  className="link skeleton"
                  style={{ width: '100%', height: '100%', display: 'block' }}
                />
              </div>
            </div>
            <div
              style={{
                width: 'calc(100% - 39px)',
                marginLeft: 39,
                marginTop: 5,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              {[38, 38, 38, 40, 40, 41, 31, 31, 31, 37, 37].map((el, index) => (
                <span
                  key={el * index}
                  className="link skeleton"
                  style={{ width: el, height: 16 }}
                />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className={'price-modal-chart-wrapper'}>
          <div className={'price-modal-period'}>
            {isMobile ? null : <span className="price-modal-period-title">{t('Price')}</span>}
            <div className="price-modal-period-wrapper">
              <div className="price-modal-period-text">
                {isMobile ? <span>{t('Price')}</span> : null}
                <span className="price-modal-period-currency">
                  {`$${acc.formatNumber(product?.usd_price ? product?.usd_price : product?.price, '2', ',', '.')}`}
                </span>
                
                {isMobile ? null : <Divider type="vertical" />}
                <span className="price-modal-period-updated">
                  <Icon role={'icon'} type={'arrow_rotate'} />
                  {t('Updated')}{' '}
                  {AppUtils.durationAsString(
                    new Date().toISOString(),
                    productPriceData?.last_updated,
                    t,
                  )}
                </span>
              </div>
              <div className="chart-block_header">
                <div className="period-filter">
                  <div className="period-filter-wrapper-icons">
                    <div
                      className={cls('period-filter', {
                        active: isLineChart,
                      })}
                      onClick={() => setIsLineChart(true)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Icon type={`line_chart`} role={'icon'} />
                    </div>
                    <div
                      className={cls('period-filter', {
                        active: !isLineChart,
                      })}
                      onClick={() => setIsLineChart(false)}
                      style={{ cursor: 'pointer' }}
                    >
                      <Icon type={`bar_chart`} role={'icon'} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Chart
            type={'price_dynamics'}
            data={data.length ? [...data] : []}
            loading={false}
            isMobile={isMobile}
            height={250}
            chartType={!isLineChart && 'bar'}
          />
        </div>
      )}
    </div>
  );
};

export default PriceHistory;
