import React from 'react';
import cls from 'classname';
import Icon from '../../../Icon';
import { Input } from 'antd';
import CascadingDropdown from './CascadingDropdown';
import PresetsDropdown from './PresetsDropdown';
import { useTranslation } from 'react-i18next';

const { Search } = Input;

const PageOptions = (
  {
    visibleFilters,
    setVisibleFilters,
    isMobile,
    setModal,
    setVisible,
    changedFilters,
    sortOrder,
    setSortOrder,
    isTrial,
    skeleton,
    disabled,
    applyFilters,
    searchValue,
    setSearchValue,
    setPageNumber,
    adsLoading,
  }) => {
  const { t } = useTranslation();

  const disabledFilters = disabled || searchValue.length > 0;

  const handleSearch = (e) => {
    setSearchValue(e.target.value.trim());
    setVisibleFilters(false);
  };

  if (skeleton) {
    return (
      <div className="fadspot-page-products-filters">
        <div
          className="fadspot-page-products-filters-search"
          style={{ width: '100%' }}
        >
          <div
            className="main-filter-search"
            style={{ display: 'flex', gap: 12 }}
          >
            <span
              className={'link skeleton'}
              style={{ width: isMobile ? '75%' : 400, height: 40 }}
            />
            <span
              className={'link skeleton'}
              style={{ width: isMobile ? '25%' : 70, height: 40 }}
            />
            {!isMobile && (
              <span
                className={'link skeleton'}
                style={{ width: isMobile ? 0 : 100, height: 40 }}
              />
            )}
          </div>
          <div className="search-buttons-wrapper" style={{ gap: 12 }}>
            <span
              className={'link skeleton'}
              style={{ width: isMobile ? '50%' : 110, height: 40 }}
            />
            <span
              className={'link skeleton'}
              style={{ width: isMobile ? '50%' : 190, height: 40 }}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fadspot-page-products-filters">
      <div className="fadspot-page-products-filters-search">
        <div className="main-filter-search">
          <div className="search-options">
            <div className="filters-panel">
              <Search
                placeholder={t('Search for ads...')}
                className={'search-input'}
                onChange={(e) => {
                  e.persist();
                  handleSearch(e);
                }}
                onKeyDown={(e) => {
                  if (e.keyCode === 13 && searchValue.length && !adsLoading) {
                    setPageNumber(1);
                    applyFilters(true);
                  }
                }}
                defaultValue={searchValue && searchValue}
                {...(searchValue ? { value: searchValue } : null)}
                prefix={<Icon type="search_icon" role="icon" />}
              />
              {isMobile && (
                <div
                  className={cls('cascading-wrapper fadspot-page-filter-btn', {
                    disabled: searchValue.length === 0 || adsLoading,
                  })}
                  onClick={() => {
                    setPageNumber(1);
                    applyFilters(true);
                  }}
                >
                  <span
                    className={cls(
                      'fadspot-page-filters-preset-btn cascading-dropdown-value',
                      {
                        disabled: searchValue.length === 0 || adsLoading,
                      },
                    )}
                  >
                    {t('Search')}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="fadspot-page-products-filters-search-buttons">
          {!isMobile && (
            <div
              className={cls('cascading-wrapper fadspot-page-filter-btn', {
                disabled: searchValue.length === 0 || adsLoading,
              })}
              onClick={() => {
                setPageNumber(1);
                applyFilters(true);
              }}
            >
              <span
                className={cls(
                  'fadspot-page-filters-preset-btn cascading-dropdown-value',
                  {
                    disabled: searchValue.length === 0 || adsLoading,
                  },
                )}
              >
                {t('Search')}
              </span>
            </div>
          )}
          <div
            onClick={() => {
              if (disabledFilters) setSearchValue('');
              else setVisibleFilters((prev) => !prev)
            }}
            className={cls('cascading-wrapper fadspot-page-filter-btn', {
              active: visibleFilters,
            })}
          >
            <span
              className={cls(
                'fadspot-page-filters-preset-btn cascading-dropdown-value',
              )}
            >
              {
                disabledFilters ?
                  null
                  :
                  <Icon role={' icon'} type={'filter_icon'} />
              }
              {
                disabledFilters ?
                  t('Reset search')
                  :
                  t('Filters')
              }
            </span>
          </div>
          {isMobile && (
            <CascadingDropdown
              value={sortOrder}
              setValue={setSortOrder}
              isMobile={isMobile}
              isTrial={isTrial}
              disabled={disabled}
              setModal={setModal}
              setVisible={setVisible}
            />
          )}
        </div>
      </div>
      <div className="search-buttons-wrapper">
        <PresetsDropdown
          isMobile={isMobile}
          setModal={setModal}
          setVisible={setVisible}
          changedFilters={changedFilters}
          disabled={disabled}
        />
        {!isMobile && (
          <CascadingDropdown
            value={sortOrder}
            setValue={setSortOrder}
            isMobile={isMobile}
            isTrial={isTrial}
            disabled={disabled}
            setModal={setModal}
            setVisible={setVisible}
          />
        )}
      </div>
    </div>
  );
};

export default PageOptions;
