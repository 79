import React, { useState, useMemo } from 'react';
import cls from 'classname';
import { useSelector } from 'react-redux';
import { Dropdown, Tooltip } from 'antd';
import Icon from '../../../Icon';
import ArrowSelectDown from '../../../Icon/img/ArrowSelectDown';
import './CascadingDropdown.less';
import { useTranslation } from 'react-i18next';

const options = [
  {
    title: 'Reactions',
    key: 'reactions',
    items: ['like', 'love', 'care', 'haha', 'wow', 'sad', 'angry'],
  },
  {
    title: 'Most recent',
    key: 'most_recent',
  },
  {
    title: 'Oldest',
    key: 'oldest',
  },
  {
    title: 'Comments',
    key: 'comments',
  },
  {
    title: 'Shares',
    key: 'shares',
  },
  {
    title: 'Views',
    key: 'views',
  },
];

const CascadingDropdown = ({
  value,
  setValue,
  isMobile,
  isTrial,
  disabled = false,
  setModal,
  setVisible,
}) => {
  const { t } = useTranslation();

  const [visibleDropdown, setVisibleDropdown] = useState(false);
  const [innerVisible, setInnerVisible] = useState(false);
  const isDisabledTransition = useSelector(
    (state) => state.nav.disabledTransition,
  );

  const handleVisibleChange = (value) => !isTrial && !disabled && setVisibleDropdown(value);
  const handleInnerVisibleChange = (value) => setInnerVisible(value);

  const getSelectValue = () => {
    let result = options.filter((el) => el?.key === value);
    if (result?.length) return t(result?.[0]?.title);
    else return t(value);
  };

  return (
    <div
      className={cls('cascading-wrapper', {
        'disabled-transition': isDisabledTransition,
        disabled: isTrial || disabled,
      })}
    >
      {isMobile ? (
        <Tooltip
          placement="top"
          overlayClassName="product_name-tooltip details-tooltip"
          getPopupContainer={(trigger) => trigger.parentNode}
          title={isTrial ? 'Feature disabled during Trial' : null}
          destroyTooltipOnHide={true}
        >
          <span
            className="cascading-dropdown-value"
            onClick={() => {
              if (!isTrial || disabled) {
                setModal('sortOrderModal');
                setVisible(true);
              }
            }}
          >
            <div className="cascading-dropdown-value-checked" />
            <Icon role="icon" type={'sort'} />
            <span>{t('Sort by')}</span>
          </span>
        </Tooltip>
      ) : (
        <Dropdown
          overlayClassName={'cascading-dropdown-wrapper'}
          getPopupContainer={(trigger) => trigger.parentNode}
          onOpenChange={handleVisibleChange}
          placement={'bottomRight'}
          trigger={['click']}
          open={visibleDropdown}
          dropdownRender={() => (
            <div className="cascading-dropdown-menu-wrapper">
              <ul className="cascading-dropdown-menu">
                {options.map((el) =>
                  el?.items ? (
                    <div
                      key={el?.key}
                      className={cls('cascading-dropdown-menu-item--advanced', {
                        'cascading-dropdown-menu-item--advanced--active':
                          el?.items?.some((item) => item === value),
                      })}
                      onClick={() => setInnerVisible(!innerVisible)}
                    >
                      <Dropdown
                        overlayClassName={'cascading-dropdown-wrapper-inner'}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        onOpenChange={handleInnerVisibleChange}
                        placement={'bottom'}
                        trigger={['click']}
                        open={innerVisible}
                        dropdownRender={() => (
                          <div className="cascading-dropdown-menu-wrapper-inner">
                            <ul className="cascading-dropdown-menu-inner">
                              {el?.items.map((elem) => (
                                <li
                                  key={elem}
                                  className={cls(
                                    'cascading-dropdown-menu-item cascading-dropdown-menu-item-inner',
                                    {
                                      'cascading-dropdown-menu-item--active':
                                        elem === value,
                                    },
                                  )}
                                  onClick={() => {
                                    setVisibleDropdown(false);
                                    setTimeout(() => setValue(elem), 100);
                                  }}
                                >
                                  <Icon role="icon" type={`ad_spot_${elem}`} />
                                  <span>{t(elem)}</span>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      >
                        <div
                          className={cls('cascading-dropdown-inner', {
                            'ant-dropdown-open-inner': innerVisible,
                          })}
                        >
                          <span>{t(el?.title)}</span>
                          <ArrowSelectDown />
                        </div>
                      </Dropdown>
                    </div>
                  ) : (
                    <li
                      className={cls('cascading-dropdown-menu-item', {
                        'cascading-dropdown-menu-item--active':
                          el?.key === value,
                      })}
                      onClick={() => {
                        setVisibleDropdown(false);
                        setTimeout(() => setValue(el?.key), 300);
                      }}
                    >
                      <span>{t(el?.title)}</span>
                    </li>
                  ),
                )}
              </ul>
            </div>
          )}
        >
          <Tooltip
            placement="top"
            overlayClassName="product_name-tooltip details-tooltip"
            getPopupContainer={(trigger) => trigger.parentNode}
            title={isTrial ? 'Feature disabled during Trial' : null}
            destroyTooltipOnHide={true}
          >
            <div
              className={cls('cascading-dropdown', {
                'ant-dropdown-open': visibleDropdown,
              })}
            >
              <span
                className={cls('cascading-dropdown-value', {
                  'cascading-dropdown-value-inner': options.some((el) =>
                    el?.items?.includes(value),
                  ),
                })}
              >
                <Icon role="icon" type={'sort'} />
                <span>{t('Sort by')}</span>
                {!isMobile && (
                  <span className="cascading-dropdown-value-selected">
                    {options[0].items.includes(getSelectValue()) && (
                      <Icon role="icon" type={`ad_spot_${value}`} />
                    )}
                    {t(getSelectValue())}
                  </span>
                )}
              </span>
            </div>
          </Tooltip>
        </Dropdown>
      )}
    </div>
  );
};

export default CascadingDropdown;
