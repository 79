import React, { useState } from 'react';
import FilterPresetTable from './FilterPresetTable';
import ButtonComponent from '../../../Components/Button';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './LoadFilterPresetBlock.less';
import './FilterPresetTable.less';
import { Radio } from 'antd';
import { debounce } from '../../../Utils/utils';
import SearchComponent from '../../../Components/Search';

const LoadFilterPresetBlock = ({
  fetching,
  theme,
  setVisible,
  setRecordToDelete,
  setModal,
  updatePreset,
  setPageSize,
  setPageNumber,
  loadPreset,
  setLoadedPreset,
  setSortOrder,
  category,
  getPresets,
}) => {
  //initial table value
  const presetsOptions = useSelector((store) => store[category].presets);
  const [tab, setTab] = useState('your-presets');
  const [searchValue, setSearchValue] = useState('');
  const { t } = useTranslation();

  //selected - clicked and @bluePrimary bg
  const [selected, setSelected] = useState(null);
  //editable name row
  const [editable, setEditable] = useState(null);

  const handleTabChange = (e) => {
    setTab(e.target.value);
    setSelected(false);
    setSearchValue('');
  };

  const handleSearch = debounce(e => {
    setSearchValue(e.target.value);
    getPresets(e.target.value);
  }, 500);

  return (
    <div className={'load-filter-preset-wrapper'}>
      <div className="load-filter-preset-title">
        {t('Select search presets')}
      </div>
      <p className="load-filter-preset-subtitle">
        {category === 'adSpot'
          ? t('Save your favorite Ad Spot filters for quicker searching.')
          : category === 'competitors'
          ? t('load_preset_competitors_text')
          : category === 'productDatabase'
          ? t('Save your preferred Product database filters for faster searches.')
          : t('load_preset_text')}
      </p>
        <SearchComponent
          handleSearch={handleSearch}
          searchValue={searchValue}
          onKeyUp={null}
          value={null}
          onFocusCallback={null}
          placeholder={t('Search for preset...')}
        />
      <div className="preset-switcher">
        <Radio.Group
          onChange={handleTabChange}
          value={tab}
          className="load-filter-group"
        >
          <Radio.Button
            value="dropship-presets"
            className={`load-filter-group ${
              tab === 'dropship-presets' ? 'active' : ''
            }`}
          >
            {t('Dropship presets')}
          </Radio.Button>
          <Radio.Button
            value="your-presets"
            className={`load-filter-group ${
              tab === 'your-presets' ? 'active' : ''
            }`}
          >
            {t('Your presets')}
          </Radio.Button>
        </Radio.Group>
      </div>

      {/*user-presets table*/}
      {tab === 'your-presets' && (
        <FilterPresetTable
          filters={presetsOptions.users}
          fetching={fetching}
          theme={theme}
          isDropship={false}
          setRecordToDelete={setRecordToDelete}
          setModal={setModal}
          selected={selected}
          setSelected={setSelected}
          editable={editable}
          setEditable={setEditable}
          updatePreset={updatePreset}
          category={category}
          searchValue={searchValue}
        />
      )}
      {/*dropship-presets table*/}
      {tab === 'dropship-presets' && category !== 'competitors' && (
        <FilterPresetTable
          filters={presetsOptions.dropship}
          fetching={fetching}
          theme={theme}
          isDropship={true}
          setSelected={setSelected}
          selected={selected}
          editable={editable}
          setEditable={setEditable}
          category={category}
          searchValue={searchValue}
        />
      )}
      <div className="load-filter-preset-button-wrapper">
        <ButtonComponent
          className={'load-filter-preset-button'}
          text={t('Cancel')}
          onClick={() => {
            setSelected(null);
            setVisible(false);
          }}
        />
        <ButtonComponent
          className={'load-filter-preset-button apply'}
          text={t('Select preset')}
          onClick={() => {
            loadPreset(selected);
            setLoadedPreset(selected);
            //reset pagination on preset load
            setPageSize(50);
            setPageNumber(1);
            setSelected(null);
            setVisible(false);
            if (category !== 'adSpot') setSortOrder(null);
            else setSortOrder('most_recent');
          }}
          disabled={!selected}
        />
      </div>
    </div>
  );
};

export default LoadFilterPresetBlock;
