import React from 'react';
import Icon from '../../../Icon';
import acc from 'accounting';
import dayjs from 'dayjs';
import { Divider } from 'antd';

const InnerPageInfoBlockSkeleton = ({ isMobile}) => {
  return (
    <div className="inner-page-info">
      <div className="inner-page-info-block">
        <div className="title">
          <span className={'link skeleton'}
                style={{ width: 90, height: 20 }}
          />
        </div>
        <div className="value">
          <span className={'link skeleton'}
                style={{ width: 100, height: 20 }}
          />
        </div>
      </div>
      <div className="inner-page-info-block">
        <div className="title">
          <span className={'link skeleton'}
                style={{ width: 90, height: 20 }}
          />
        </div>
        <div className="value">
          <span className={'link skeleton'}
                style={{ width: 120, height: 20 }}
          />
        </div>
      </div>
      <div className="inner-page-info-block">
        <div className="title">
          <span className={'link skeleton'}
                style={{ width: 90, height: 20 }}
          />
        </div>
        <div className="value">
          <span className={'link skeleton'}
                style={{ width: 100, height: 20 }}
          />
        </div>
      </div>
      <div className="inner-page-info-block">
        <div className="title">
          <span className={'link skeleton'}
                style={{ width: 90, height: 20 }}
          />
        </div>
        <div className="value">
          <span className={'link skeleton'}
                style={{ width: 100, height: 20 }}
          />
        </div>
      </div>
      <div className="inner-page-info-block">
        <div className="title">
          <span className={'link skeleton'}
                style={{ width: 90, height: 20 }}
          />
        </div>
        <div className="value">
          <span className={'link skeleton'}
                style={{ width: 30, height: 20 }}
          />
          <Divider type={'vertical'} style={{ margin: '0 8px' }} />
          <span className={'link skeleton'}
                style={{ width: 60, height: 20 }}
          />
        </div>
      </div>
      <div className="inner-page-info-block">
        <div className="title">
          <span className={'link skeleton'}
                style={{ width: 90, height: 20 }}
          />
        </div>
        <div className="value">
          <span className={'link skeleton'}
                style={{ width: 30, height: 20 }}
          />
          <Divider type={'vertical'} style={{ margin: '0 8px' }} />
          <span className={'link skeleton'}
                style={{ width: 60, height: 20 }}
          />
        </div>
      </div>
      <div className="inner-page-info-block">
        <div className="title">
          <span className={'link skeleton'}
                style={{ width: 90, height: 20 }}
          />
        </div>
        <div className="value">
          {
            [1,2,3,4].map(el => <span key={el} className={'link skeleton'} style={{ width: 20, height: 20 }} />)
          }
        </div>
      </div>
    </div>
  );
};

export default InnerPageInfoBlockSkeleton;
