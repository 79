import React from 'react';
import cls from 'classname';

const NotificationError = (props) => (
  <svg  
    width={props.width ? props.width : '20'}
    height={props.height ? props.height : '20'}
    viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"  
    className={cls(props.className, 'icon icon-notification-error')}>
    <path d="M15 9L9 15M15 15L9 9M21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12Z" stroke="#D71313" strokeWidth="1.5" strokeLinecap="square"/>
  </svg>
  
);

export default NotificationError;
