import React from 'react';

const InnerPageTitleBlockSkeleton = ({isMobile, type = 'product'}) => {
  return (
    <div className="inner-page-title-block">
      <div className="inner-page-title-image">
        <span className={'link skeleton'}
              style={{ width: 40, height: 40 }}
        />
        <div className="title">
          <span className={'link skeleton'}
                style={{ width: 300, height: 24 }}
          />
          <div className="link" style={{marginTop: 4}}>
            <span className={'link skeleton'}
                  style={{ width: 70, height: 20 }}
            />
            <span className={'link skeleton'}
                  style={{ width: 20, height: 20 }}
            />
          </div>
        </div>
      </div>
      {
        isMobile ?
          null
          :
          <div className="inner-page-title-action">
            {
              type === 'product' ?
                <span className={'link skeleton'}
                      style={{ width: 67, height: 40 }}
                />
                :
                null
            }
            <span className={'link skeleton'}
                  style={{ width: 114, height: 40 }}
            />
            <span className={'link skeleton'}
                  style={{ width: 140, height: 40 }}
            />
          </div>
      }
    </div>
  );
};

export default InnerPageTitleBlockSkeleton;
