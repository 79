import React from 'react';

const Paypal = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <mask id="mask0_7546_10017" maskUnits="userSpaceOnUse" x="0" y="0" width="20"
            height="20">
        <path d="M0 0H20V20H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_7546_10017)">
        <path d="M0 0H20V20H0V0Z" fill="white" />
        <path
          d="M14.3034 7.28711C14.2923 7.35933 14.2789 7.43266 14.2645 7.50933C13.7645 10.0693 12.0589 10.9538 9.87895 10.9538H8.30784L8.23562 11.4093L7.66784 15.0127L7.45117 16.3616H10.1556L10.2423 15.8616L10.7123 12.9804L11.1045 12.9827H11.3978C13.3056 12.9827 14.7978 12.2082 15.2345 9.96822C15.4178 9.03266 15.3234 8.25155 14.8412 7.70155C14.6878 7.53177 14.5048 7.39133 14.3012 7.28711H14.3034Z"
          fill="#85D0FA" />
        <path
          d="M8.8844 7.29976C8.90145 7.18807 8.95807 7.08623 9.04393 7.01281C9.1298 6.93938 9.23919 6.89926 9.35217 6.89976H12.3177C12.6688 6.89976 12.9955 6.92198 13.2955 6.97087C13.4592 6.9978 13.6213 7.03378 13.7811 7.07865C13.9619 7.12959 14.1369 7.19922 14.3033 7.28642C14.4522 6.33976 14.3022 5.69642 13.79 5.11309C13.2255 4.47087 12.2077 4.19531 10.9044 4.19531H6.66662L5.01106 14.6353L4.94995 15.012H7.66773L8.2544 11.2931L8.8844 7.29976Z"
          fill="#003087" />
        <path fillRule="evenodd" clipRule="evenodd"
              d="M13.5445 7.01739C13.6619 7.04391 13.7779 7.0758 13.8923 7.11295C14.0401 7.16184 14.1767 7.21962 14.3034 7.28628C14.2729 7.47827 14.234 7.66883 14.1867 7.85739C13.6045 10.1485 11.9567 10.9529 9.87897 10.9529H8.30786L8.88564 7.2985L8.94897 6.89851H12.3179C12.7301 6.89656 13.1414 6.9364 13.5456 7.01739H13.5445Z"
              fill="#4195F7" />
      </g>
    </svg>
  );
};

export default Paypal;
