import React from 'react';

const DoubleArrows = () => {

  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.22987 2.66667C3.15708 1.64454 4.51742 1 6 1C8.76142 1 11 3.23858 11 6C11 6.2121 10.9868 6.42088 10.9613 6.62563C10.9271 6.89965 11.1216 7.14949 11.3956 7.18366C11.6696 7.21783 11.9194 7.02339 11.9536 6.74937C11.9842 6.50367 12 6.25356 12 6C12 2.68629 9.31371 0 6 0C4.3194 0 2.77027 0.691617 1.66667 1.80748V0.5C1.66667 0.223858 1.44281 0 1.16667 0C0.890524 0 0.666667 0.223858 0.666667 0.5V2.66667C0.666667 3.21895 1.11438 3.66667 1.66667 3.66667H3.83333C4.10948 3.66667 4.33333 3.44281 4.33333 3.16667C4.33333 2.89052 4.10948 2.66667 3.83333 2.66667H2.22987Z"
        fill="#A1AAC8"
      />
      <path
        d="M1.03871 5.37437C1.07288 5.10035 0.878442 4.85051 0.604422 4.81634C0.330402 4.78217 0.0805644 4.97661 0.0463946 5.25063C0.0157568 5.49633 0 5.74644 0 6C0 9.31371 2.68629 12 6 12C7.68461 12 9.23712 11.3051 10.3412 10.1845V11.5C10.3412 11.7761 10.5651 12 10.8412 12C11.1174 12 11.3412 11.7761 11.3412 11.5V9.33333C11.3412 8.78105 10.8935 8.33333 10.3412 8.33333H8.17456C7.89842 8.33333 7.67456 8.55719 7.67456 8.83333C7.67456 9.10948 7.89842 9.33333 8.17456 9.33333H9.77013C8.84292 10.3555 7.48258 11 6 11C3.23858 11 1 8.76142 1 6C1 5.7879 1.01318 5.57912 1.03871 5.37437Z"
        fill="#A1AAC8"
      />
    </svg>
  );
};
export default DoubleArrows;
