import React, {useEffect, useRef, useState} from 'react';
import cls from "classname";
import acc from "accounting";
import ArrowSelectDown from "../../../../Icon/img/ArrowSelectDown";
import {Divider, Dropdown, Tooltip} from "antd";
import ButtonComponent from "../../../../Components/Button";
import {useTranslation} from "react-i18next";
import Icon from "../../../../Icon";
import InputComponent from "../../../../Components/Input";

const DropdownList = ({title, id, value, setValue, data, disabled, isStandard=false, count, handleCount}) => {

  const {t} = useTranslation();

  const [visible, setVisible] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [search, setSearch] = useState('');
  const localData = useRef([]);

  useEffect(() => {
    if (!value[id]) {
      setCheckedList(data);
      setCheckAll(true);
      localData.current = [...data];
    } else {
      setCheckAll(false);
      setCheckedList([...value[id]]);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [visible]);

  useEffect(() => {
    if (visible) {
        handleCount({
          id: id,
          value: checkedList,
        });
    }
  }, [visible,checkedList]);

  useEffect(() => {
    localData.current = [...data];
  }, [data])

  useEffect(() => {
    if (checkedList.length === data.length) {
      localData.current = [...data];
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [checkedList])

  const handleVisibleChange = (value) => {
    setVisible(value);
    setSearch('');
  };

  const handleChangeSearch = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  //checkbox click handle
  const clickHandler = (val, e) => {
    e.target.classList.toggle('dropdown-database-list-item--active')
    if (checkedList.some(el => el === val.code)) {
      let res = checkedList.filter(el => el !== val.code)
      setCheckedList(res)
      setCheckAll(res.length === data.length);
    } else {
      let res = [...checkedList, val?.code]
      setCheckedList(res)
      setCheckAll(res.length === data.length);
    }
  }

  //main checkbox handler
  const allClickHandler = e => {
    let checked;
    if (e.target.classList.contains('dropdown-database-list-item-checkbox')) {
      e.target.classList.toggle('dropdown-database-list-item-checkbox--active');
      checked = e.target.classList.contains('dropdown-database-list-item-checkbox--active')
    } else {
      e.target.classList.toggle('dropdown-database-list-item--active')
      checked = e.target.classList.contains('dropdown-database-list-item--active')
    }
    setCheckedList(checked ? data : []);
    setCheckAll(checked);
    localData.current = [...data];
  }

  return (
    <div className={cls('main-filter-dropdown')} style={{position: "relative"}}>
      <Dropdown
        overlayClassName={'main-filter-dropdown-content-wrapper languages-dropdown-content-wrapper'}
        getPopupContainer={(trigger) => trigger.parentNode}
        onOpenChange={handleVisibleChange}
        placement={'bottom'}
        trigger={['click']}
        open={visible}
        dropdownRender={() => (
            <div>
              <InputComponent
                className={'dropdown-search'}
                prefix={<Icon type="database_search" role="icon"/>}
                placeholder={t('Search for language...')}
                value={search}
                onChange={handleChangeSearch}
              />
              <ul className="dropdown-database-list">
                {
                  search ?
                    null
                    :
                    (
                      <div className={cls("dropdown-database-list-item  test-item", {
                        'dropdown-database-list-item--active': checkAll
                      })}
                           onClick={allClickHandler}
                      >
                        <div className={cls('dropdown-database-list-item-checkbox', {
                          'dropdown-database-list-item-checkbox--active': checkAll
                        })}/>
                        All
                      </div>
                    )
                }
                {
                  !search || data.filter(el => [el?.name, el?.value, el?.code].some(el => el?.toLowerCase().includes(search.toLowerCase()))).length
                    ?
                  localData.current.map(el => (
                    <div key={el?.id}
                         onClick={(e) => clickHandler(el, e)}
                         className={cls('dropdown-database-list-item  test-item', {
                           'dropdown-database-list-item-hidden': !([el?.name, el?.value, el?.code].some(el => el?.toLowerCase().includes(search.toLowerCase()))),
                           'dropdown-database-list-item--active': checkedList.some(elem => el.code === elem) || checkAll,
                         })}>

                      <div className={cls('dropdown-database-list-item-checkbox', {
                        'dropdown-database-list-item-checkbox--active': checkedList.some(elem => el.code === elem) || checkAll,
                      })} />

                      {
                        el?.value
                      }
                    </div>
                  ))
                    :
                    <div className="dropdown-database-list-item" style={{ display: 'flex', alignItems: 'center' }}>
                      <div className="empty-state_icon-select">
                        <Icon width={24} height={24}
                              role="icon"
                              type="empty_warning"
                              fill={'#707ba0'}
                        />
                      </div>
                      {search &&
                        <div style={{ color: '#707ba0', marginLeft: 8, fontSize: '14px', lineHeight: '20px' }}
                             className="empty-state_wrapper-select">
                          {t('No results found')}
                        </div>}
                    </div>
                }
              </ul>
              <Divider style={{margin: '7px auto'}} type={"horizontal"} />
              <div className="main-filter-dropdown-content-button-wrapper">
                {
                  disabled ?
                    <Tooltip placement='top'
                             title={isStandard ? 'To preform this action, you would need to upgrade to Premium plan.' : 'To perform this action, you would need to upgrade to Standard or Premium plan.'}
                             overlayClassName={'subscription-tooltip scheduled skip'}
                             arrow={true}
                             trigger={'hover'}
                             getPopupContainer={(trigger) => trigger.parentNode}
                             destroyTooltipOnHide={true}
                    >
                      <ButtonComponent text={t('Apply Filter')}
                                       className={cls('button-apply', {
                                         'button-apply-disabled': disabled
                                       })}
                                       onClick={() => null}
                      />
                    </Tooltip>
                    :
                    <ButtonComponent text={t(`Show _name_ results`, {name: acc.formatNumber(count, 0, ',', '.')})}
                                     className={cls('button-apply', {
                                       'button-apply-disabled': disabled
                                     })}
                                     onClick={() => {
                                       setVisible(false);
                                       setValue(state => ({...state, [id]: checkedList?.length ? checkedList?.length === data?.length ? null : checkedList : null}))
                                     }}
                                     disabled={false}
                    />
                }
              </div>
            </div>
        )}
      >
        <div className='main-filter-dropdown-inner'>
          {title}
          <ArrowSelectDown/>
        </div>
      </Dropdown>

    </div>
  );
}

export default DropdownList;
