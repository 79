import React from 'react';

const MainBlockSkeleton = ({isMobile}) => {

  return (
    <div className="report-main-block">
      <span className={'report-main-block-title'}>
        <span className='title'>
          <span className={'link skeleton'}
                style={{ width: 80, height: 24 }}
          />
        </span>
        <span className={'link skeleton'}
              style={{ width: 170, height: 20 }}
        />
      </span>

      <div className="report-main-block-actions">
        <span className={'link skeleton'}
              style={{ width: isMobile ? '100%' : 160, height: 36 }}
        />
        <span className={'link skeleton'}
              style={{ width: isMobile ? '100%' : 180, height: 36 }}
        />
      </div>
    </div>
  );
};

export default MainBlockSkeleton;
