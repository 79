import { API, encryptedData } from '../../Services/Api';

const request = new API();

export const getProductPriceHistory = (data) =>
  data?.isPD ?
    request.api.get(`/product_database/products/chart/${data?.store_id}/${data?.product_id}/prices/`, null)
    :
    request.api.get(`/sales_tracker/products/chart/${data?.store_id}/${data?.product_id}/prices/`, null);
export const getProductDatabaseFilters = () => request.api.get(`product_database/choice_filters/`, null);
export const getProductDatabasePresets = (data) => request.api.get(`product_database/filter_presets/?q=${data}`, null);
export const createProductDatabasePreset = (data) => request.api.post(`product_database/filter_presets/`, encryptedData(data));
export const deleteProductDatabasePreset = (record) => request.api.delete(`product_database/filter_presets/${record.id}/`, null);
export const cancelDeleteProductDatabasePreset = (record) => request.api.delete(`product_database/filter_presets/${record.id}/`, null);
export const updateProductDatabasePreset = (data) => request.api.patch(`product_database/filter_presets/${data.record.id}/`, encryptedData({name: data.name}));
export const getProductsDatabase = (data) =>
  data?.hasOwnProperty('filters') ?
  request.api.post(`product_database/search/`, encryptedData(data))
    :
  request.api.post(`product_database/search/default/`, encryptedData(data))
export const getProductChart = (data) => {
  return data?.raw_data ?
    request.api.get(`/product_database/products/chart/${data.store_id}/${data.product_id}/raw_data/`, null)
    :
    request.api.get(`/product_database/products/chart/${data.store_id}/${data.product_id}/`, null);
}

export const deleteTrackingStoreByID = (record) => request.api.delete(`/sales_tracker/stores/${record?.store?.id}/`, null);
export const createTrackingStore = (data) => request.api.post(`/sales_tracker/stores/start_tracking/`, encryptedData(data));
export const deleteTrackingProductByID = (record) => request.api.delete(`/sales_tracker/products/${record.id}/`, null);
export const createTrackingProduct = (data) => request.api.post(`/sales_tracker/products/start_tracking/`, encryptedData(data));

