import React from 'react';
import { useTranslation } from 'react-i18next';
import ButtonComponent from '../../../Components/Button';
import './StoreTrackerPageOverviewBlocked.less';

const StoreTrackerPageOverviewBlocked = () => {

  const { t } = useTranslation();

  return (
    <div className="store-tracker-page-overview-blocked">
      <div className="store-tracker-page-overview-blocked-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M16.25 9.75V7.25C16.25 4.90279 14.3472 3 12 3C9.65279 3 7.75 4.90279 7.75 7.25V9.75M12 14V17M6.75 21.25H17.25C18.3546 21.25 19.25 20.3546 19.25 19.25V11.75C19.25 10.6454 18.3546 9.75 17.25 9.75H6.75C5.64543 9.75 4.75 10.6454 4.75 11.75V19.25C4.75 20.3546 5.64543 21.25 6.75 21.25Z"
            stroke="#F9F9FC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>
      <h4 className="store-tracker-page-overview-blocked-title">
        {t('Track store to see this...')}
      </h4>
      <p className="store-tracker-page-overview-blocked-text">
        Lorem ipsum dolor sit amet consectetur. Aliquam lectus volutpat eget sed ullamcorper. Orci ullamcorper nam diam
        velit pharetra.
      </p>
      <ButtonComponent className={'store-tracker-page-overview-blocked-btn'}
                       onClick={() => alert('Track')}
                       text={null}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
          <path
            d="M10.5002 1.45898V6.87565M19.0418 10.0007H13.6252M10.5002 13.1256V18.5423M7.37516 10.0007H1.9585M10.5002 16.0423C7.16344 16.0423 4.4585 13.3374 4.4585 10.0007C4.4585 6.66393 7.16344 3.95898 10.5002 3.95898C13.8369 3.95898 16.5418 6.66393 16.5418 10.0007C16.5418 13.3374 13.8369 16.0423 10.5002 16.0423Z"
            stroke="#707BA0" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
        <span>
          {t('Start tracking store')}
        </span>
      </ButtonComponent>
    </div>
  );
};

export default StoreTrackerPageOverviewBlocked;
