import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../Components/Modal';
import { Spin } from 'antd';
import Icon from '../../../../Icon';
import IconWithText from '../../../../Components/Text';

const TrackingUserModal = ({
  view,
  setInnerModal,
  visible,
  record,
  setModal,
  isTrial,
  setRecordToDelete,
  connectStore,
  connectProduct,
  setVisible,
}) => {
  const { t } = useTranslation();
  return (
    <Modal
      handleClose={() => setInnerModal(false)}
      title={t(`${view} actions`)}
      isMobile={true}
      width={450}
      className="collection-options-modal collection-modal product-actions-modal"
      open={visible}
      destroyOnClose
    >
      <Spin spinning={false}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {t(
              `You are able to view ${view} insights or stop tracking it from here.`,
            )}
          </p>
          <ul
            className={'filter-preset-action-dropdown database-action-dropdown'}
          >
            <li
              className={'action-dropdown_item'}
              onClick={() =>
                window.open(record?.facebook_add_library, '_blank')
              }
            >
              <IconWithText
                size={24}
                icon={() => (
                  <Icon
                    role={'icon'}
                    type={'search_facebook'}
                    width={16}
                    height={16}
                    isDark={false}
                  />
                )}
                text={t(`Show in Facebook`)}
              />
            </li>
            <li
              className={`action-dropdown_item ${
                record?.is_tracked ? 'tracked' : ''
              } `}
              onClick={() => {
                if (record?.is_locked) navigate('/setting/plan');
                else {
                  if (record?.is_tracked) {
                    setVisible(true);
                    if (isTrial) {
                      setModal('canNotRemoveTracking');
                    } else {
                      setRecordToDelete({
                        type: view.slice(0, -1),
                        data: { ...record },
                      });
                      setModal('deleteTracking');
                    }
                  } else {
                    if (view === 'stores') {
                      connectStore({
                        internal_shop_id: record?.internal_shop_id,
                      })
                    } else {
                      connectProduct({
                        internal_shop_id: record?.store?.internal_shop_id,
                        product_id: record?.id,
                        });
                    }
                  }
                }
              }}
            >
              <IconWithText
                size={24}
                icon={() =>
                  record?.is_tracked ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M6.45898 6.4585L13.5423 13.5418M13.5423 6.4585L6.45898 13.5418"
                        stroke="#D71313"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_3190_52051)">
                        <path
                          d="M9.99967 1.45825V6.87492M18.5413 9.99992H13.1247M9.99967 13.1249V18.5416M6.87467 9.99992H1.45801M9.99967 16.0416C6.66295 16.0416 3.95801 13.3366 3.95801 9.99992C3.95801 6.6632 6.66295 3.95825 9.99967 3.95825C13.3364 3.95825 16.0413 6.6632 16.0413 9.99992C16.0413 13.3366 13.3364 16.0416 9.99967 16.0416Z"
                          stroke="#225AEA"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_3190_52051">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )
                }
                text={t(
                  record?.is_locked
                    ? 'Upgrade Plan'
                    : record?.is_tracked
                    ? 'Stop tracking'
                    : 'Start tracking',
                )}
              />
            </li>
          </ul>
        </div>
      </Spin>
    </Modal>
  );
};

export default TrackingUserModal;
