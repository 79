import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Creators from '../../Containers/DashboardPage/reducer';
import { connect } from 'react-redux';
import cls from 'classname';
import { ArrowSelectDown } from '../../Icon/img';
import { Divider } from 'antd';
import LimitChangeModal from './LimitChangeModal';

export const TrackingLimits = (
  {
    isMobile,
    dashboardInfo,
    userInfo,
    loading,
    ...props
  }
) => {

  const {t} = useTranslation();

  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;

  const [modal, setModal] = useState(null);

  useEffect(() => {
    if (!Boolean(modal)) props.getDashboardInfo({});
  }, [modal]);

  return (
    <div className="billing-subpage-block">
      <div className="billing-subpage-block-title-wrapper">
        <span className='billing-subpage-block-title'>
          {t('Tracking limits')}
        </span>
        <span className={cls('billing-subpage-block-link', {
          disabled: loading || isTrial,
        })}
              onClick={() => {
                if (!loading && !isTrial) {
                  setModal('edit_limits');
              }}}
        >
          {t(isMobile ? 'Increase limits' : 'Increase tracking limits')}
          <ArrowSelectDown />
        </span>
        <span className='billing-subpage-block-note'>
          {t('Tracking limits reset at the end of each billing cycle.')}
        </span>
      </div>
      <Divider style={{ margin: '16px 0' }} />
      <div className="billing-subpage-block-limit-wrapper">
        <div className="info">
          <span className='title'>
            {t('Store tracking')}
          </span>
          <div className="stats">
            <div className="stat">
              <span className="value">
                {
                  dashboardInfo?.limits?.stores?.used || 0
                }
              </span>
              {
                isMobile ?
                  null
                  :
                  <span className="key">
                    {t('tracking')}
                  </span>
              }
            </div>
            /
            <div className="stat">
              <span className="value">
                {
                  dashboardInfo?.limits?.stores?.allowed || 0
                }
              </span>
              {
                isMobile ?
                  null
                  :
                  <span className="key">
                    {t('available')}
                  </span>
              }
            </div>
          </div>
        </div>
        <div className="track">
          <div className={cls('track-progress', {
            maximum: dashboardInfo?.limits?.stores?.used === dashboardInfo?.limits?.stores?.allowed
          })}
               style={{ width: `${((dashboardInfo?.limits?.stores?.used || 0) / (dashboardInfo?.limits?.stores?.allowed || 1)) * 100}%` }}
          />
        </div>
        {
          dashboardInfo?.limits?.stores?.used === dashboardInfo?.limits?.stores?.allowed ?
            <span className="track-progress-warning">
              {t('You’ve reached store tracking limit. Upgrade your plan or increase your tracking limits to track more stores.')}
            </span>
            :
            null
        }
      </div>
      <div className="billing-subpage-block-limit-wrapper">
        <div className="info">
          <span className='title'>
            {t('Product tracking')}
          </span>
          <div className="stats">
            <div className="stat">
              <span className="value">
                {
                  dashboardInfo?.limits?.products?.used || 0
                }
              </span>
              <span className="key">
                {
                  isMobile ?
                    null
                    :
                    <span className="key">
                      {t('tracking')}
                    </span>
                }
              </span>
            </div>
            /
            <div className="stat">
              <span className="value">
                {
                  dashboardInfo?.limits?.products?.allowed || 0
                }
              </span>
              <span className="key">
                {
                  isMobile ?
                    null
                    :
                    <span className="key">
                      {t('available')}
                    </span>
                }
              </span>
            </div>
          </div>
        </div>
        <div className="track">
          <div className={cls('track-progress', {
            maximum: dashboardInfo?.limits?.products?.used === dashboardInfo?.limits?.products?.allowed
          })}
               style={{ width: `${((dashboardInfo?.limits?.products?.used || 0) / (dashboardInfo?.limits?.products?.allowed || 1)) * 100}%` }}
          />
        </div>
        {
          dashboardInfo?.limits?.products?.used === dashboardInfo?.limits?.products?.allowed ?
            <span className="track-progress-warning">
              {t('You’ve reached product tracking limit. Upgrade your plan or increase your tracking limits to track more products.')}
            </span>
            :
            null
        }
      </div>
      <LimitChangeModal setModal={setModal}
        visible={modal === 'edit_limits'}
        isMobile={isMobile}
        data={{
          storeLimit: dashboardInfo?.limits?.stores?.allowed < 50 ? 50: dashboardInfo?.limits?.stores?.allowed,
          productLimit: dashboardInfo?.limits?.products?.allowed < 100 ? 100: dashboardInfo?.limits?.products?.allowed,
          subscription: userInfo?.subscriptions?.[0]
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  loading: state.salesTracker.loading,
  dashboardInfo: state.dashboard.result,
});

const mapDispatchToProps = (dispatch) => ({
  getDashboardInfo: (params) =>
    dispatch(Creators.getDashboardInfoRequest(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TrackingLimits);
