import { StyleSheet } from '@react-pdf/renderer';


const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: '40pt',
    position: 'relative',
  },
  logo: {
    width: 48,
    height: 48,
  },
  divider: {
    backgroundColor: '#F2F3F8',
    display: 'block',

    due: {
      backgroundColor: '#D71313'
    },

    vertical: {
      height: '100%',
      width: '1pt',
    },

    horizontal: {
      height: '1pt',
      width: '100%',
    }
  },
  invoice_header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '50pt',

    info: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',

      title: {
        fontFamily: 'Helvetica',
        fontSize: '24pt',
        fontWeight: 'bold',
        lineHeight: '32pt',
        color: '#151E3A'
      },

      subtitle: {
        marginTop: '4pt',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        id: {
          fontFamily: 'Helvetica',
          fontSize: '10pt',
          fontWeight: 'medium',
          lineHeight: '14pt',
          color: '#434C69'
        },

        method: {
          fontFamily: 'Helvetica',
          fontSize: '10pt',
          fontWeight: 'medium',
          lineHeight: '14pt',
          color: '#434C69'
        }
      },
    },
  },
  invoice_status: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    padding: '8pt 16pt',
    borderRadius: '6pt',
    marginTop: '24pt',
    backgroundColor: '#E7FFF1',

    due: {
      backgroundColor: '#FFF4F4',

      text: {
        color: '#D71313'
      },

      date: {
        color: '#D71313'
      }
    },

    text: {
      fontFamily: 'Helvetica',
      fontSize: '12pt',
      fontWeight: 'semibold',
      lineHeight: '16pt',
      color: '#16895A'
    },

    date: {
      fontFamily: 'Helvetica',
      fontSize: '12pt',
      fontWeight: 'medium',
      lineHeight: '16pt',
      color: '#16895A'
    },
  },
  invoice_billing_info: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'flex-start',
    justifyContent: 'space-between',

    block: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: 'calc(100% - 16px)',

      title: {
        fontFamily: 'Helvetica',
        fontSize: '10pt',
        fontWeight: 'bold',
        lineHeight: '16pt',
        display: 'block',
        marginBottom: '8pt',
        textTransform: 'uppercase',
        color: '#707ba0'
      },

      value: {
        fontFamily: 'Helvetica',
        fontSize: '12pt',
        fontWeight: 'medium',
        lineHeight: '16pt',
        display: 'block',
        marginBottom: '4pt',
        color: '#151e3a'
      }
    }
  },
  invoice_billing_details: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',

    row: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',

      title: {
        fontFamily: 'Helvetica',
        fontSize: '10pt',
        fontWeight: 'bold',
        lineHeight: '16pt',
        textTransform: 'uppercase',
        width: '33%',
        display: 'flex',
        alignItems: 'center',
        color: '#707ba0'
      },

      row_value: {
        marginBottom: '4pt',
      },

      value: {
        display: 'flex',
        alignItems: 'center',
        width: '33%',
        fontFamily: 'Helvetica',
        fontSize: '12pt',
        fontWeight: 'medium',
        lineHeight: '16pt',
        color: '#151e3a',

        due: {
          color: '#D71313'
        }
      },
    },

    total_row: {

      value: {
        fontWeight: 'semibold',
        color: '#16895A'
      }
    }
  },
  invoice_footer: {
    height: '52pt',
    padding: '16pt 24pt',
    position: 'absolute',
    bottom: '0pt',
    width: '100%',
    left: '0pt',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#E7FFF1',

    image: {
      width: '20pt',
      height: '20pt',
    },

    due: {
      backgroundColor: '#FFF4F4',
    },

    text: {
      fontFamily: 'Helvetica',
      fontSize: '10pt',
      fontWeight: 'medium',
      lineHeight: '16pt',
      color: '#707ba0',

      due: {
        color: '#D71313'
      },

      link: {
        color: '#16895A',

        due: {
          color: '#D71313'
        },
      },
    },

    actions: {
    }
  }
});

export default styles;
