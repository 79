import React from 'react';
import { useSelector } from 'react-redux';

export default function TableArrow({ className = '' }) {
  const theme = useSelector((state) => state.nav.theme);
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M1.33203 3.9999L3.52729 1.80464C3.78764 1.54429 4.20975 1.54429 4.4701 1.80464L6.66536 3.9999"
        stroke={theme === 'light' ? '#707BA0' : 'rgba(255, 255, 255, 0.361)'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
