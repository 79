import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import {
  checkboxOptions,
  inputOptions,
  statusOptions,
} from '../../../Utils/adLibraryMockedData';
import { Divider } from 'antd';
import ButtonComponent from '../../../Components/Button';
import Icon from '../../../Icon';
import InputFilter from './InputFilter';
import DropdownDateBlock from '../../ProductDatabasePage/components/DropdownDateBlock';
import DropdownBlockTest from '../../ProductDatabasePage/components/DropdownBlockTest';
import './FiltersBlock.less';

const creativeOptions = ['all', 'video', 'photo', 'carousel'];

const dateOptions = [
  { id: 0, name: 'Last 7 days' },
  { id: 1, name: 'Last 30 days' },
  { id: 2, name: 'Last 90 days' },
  { id: 3, name: 'Last 6 months' },
  { id: 4, name: 'Last 12 months' },
];

const initialState = {
  creativeType: 'all',
  checkedList: [...checkboxOptions.map((el) => el.id)],
  inputFilters: {
    ad_spend: { min: '', max: '' },
    ad_sets_count: { min: '', max: '' },
    total_reaches_count: { min: '', max: '' },
    running_days: { min: '', max: '' },
  },
  dropdownFilters: {
    created_at__gte: { min: null, max: null, id: null },
    last_seen_date__gte: { min: null, max: null, id: null },
    product_created_at__gte: { min: null, max: null, id: null },
    language: 'All',
    country: 'All',
    domain_tld: 'All',
    status: 'All',
    cta_types: 'All',
  },
};

const FiltersBlock = (
  {
    showFilters,
    isMobile,
    setIsChanged,
    setInputText,
    filters,
    setPageNumber,
    setFilterToPresets,
    creativeType,
    setCreativeType,
    checkedList,
    setCheckedList,
    inputFilters,
    setInputFilters,
    dropdownFilters,
    setDropdownFilters,
    applyFilters,
    setLoadedPreset
  },
) => {
  const { t } = useTranslation();
  const [visibleModal, setVisibleModal] = useState(null);
  const resetFilters = (withSearch = true) => {
    setCreativeType(initialState.creativeType);
    setCheckedList(initialState.checkedList);
    setInputFilters(initialState.inputFilters);
    setDropdownFilters(initialState.dropdownFilters);
    setIsChanged(false);
    if (withSearch) {
      setInputText('');
    }
    setPageNumber(1);
    setLoadedPreset(null)
  };

  useEffect(() => {
    const filtersChanged =
      creativeType !== initialState.creativeType ||
      JSON.stringify(checkedList) !== JSON.stringify(initialState.checkedList) ||
      JSON.stringify(inputFilters) !== JSON.stringify(initialState.inputFilters) ||
      JSON.stringify(dropdownFilters) !== JSON.stringify(initialState.dropdownFilters);

    setIsChanged(filtersChanged);
    if (filtersChanged) {
      setFilterToPresets({
        creativeType,
        checkedList,
        inputFilters,
        dropdownFilters
      })
    }

  }, [creativeType, checkedList, inputFilters, dropdownFilters]);

  // useEffect(() => {
  //   if (disableShowFilters) {
  //     resetFilters(false);
  //   }
  // }, [disableShowFilters]);

  return (
    <div className={cls('competitors-filter-wrapper adlibrary', {
      active: showFilters,
    })}
    >
      <div className="filters-wrapper">
        <div className="creative-wrapper">
          <span className="filters-title">
            {t('Creative type')}
          </span>
          <div className="creative-filter">
            {creativeOptions.map((el) =>(
                <span
                  className={cls('creative-option', {
                    active: creativeType.includes(el),
                  })}
                  key={el}
                  onClick={() => {
                    if (el === 'all') {
                      setCreativeType('all');
                    } else {
                      if (creativeType === 'all') {
                        setCreativeType([el]);
                      } else if (creativeType.includes(el)) {
                        const updatedType = creativeType.filter((elem) => elem !== el);
                        setCreativeType(updatedType.length === 0 ? 'all' : updatedType);
                      } else {
                        const updatedType =
                          [...creativeType, el].length === creativeOptions.length - 1
                            ? 'all'
                            : [...creativeType, el];
                        setCreativeType(updatedType);
                      }
                    }
                  }}
                >
                  {el === 'all' ? null : <Icon type={`ad_spot_${el}`} role={'icon'} />}
                  {el}
                </span>
              ))
            }
          </div>
          <span className="filters-title">
            {t('Other')}
          </span>
          <div className="input-filters-wrapper">
            {
              inputOptions.map(el => (
                <InputFilter key={el?.key}
                  label={el?.label}
                  placeholder={el?.placeholder}
                  id={el?.key}
                  setInputFilters={setInputFilters}
                  inputFilters={inputFilters}
                  withSeparate
                  tooltip={t(el.tooltip)}
                  isMobile={isMobile}
                />
              ))
            }
          </div>
        </div>
        {
          isMobile ?
            <Divider style={{ margin: '24px 0' }} />
            :
            null
        }
        <div className="categories-wrapper">
          <span className="filters-title">
            {t('Categories')}
            <sup>*</sup>
            <span className="filters-title-tag">
              beta
            </span>
          </span>
          <div className="categories-filter-wrapper">
            <div className={cls('categories-filter', {
              active: checkedList?.length === checkboxOptions?.length,
            })}
              onClick={() => setCheckedList(checkedList?.length === checkboxOptions?.length ? [] : [...checkboxOptions?.map(el => el?.id)])}
            >
              <span className="categories-filter-checkbox" />
              <span className="categories-filter-label">
                {
                  checkedList?.length === checkboxOptions?.length ?
                    t('Deselect all')
                    :
                    t('Select all')
                }
              </span>
            </div>
            {
              checkboxOptions.map(el => (
                <div className={cls('categories-filter', {
                  active: checkedList?.includes(el?.id),
                })}
                  onClick={() => {
                    if (checkedList?.includes(el?.id)) {
                      let res = [...checkedList]?.filter(elem => elem !== el?.id);
                      setCheckedList(res);
                    } else {
                      setCheckedList(prev => ([...prev, el?.id]));
                    }
                  }}
                  key={el?.value}
                >
                  <span className="categories-filter-checkbox" />
                  <span className="categories-filter-label">
                    {
                      t(el?.value)
                    }
                  </span>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <Divider style={{ margin: '24px 0' }} />
      <div className="dropdowns-wrapper">
        <DropdownBlockTest label={'Ad status'}
          id={'status'}
          dropdownFilters={dropdownFilters}
          setDropdownFilters={setDropdownFilters}
          iconType={'database_status'}
          searchPlaceholder={null}
          data={statusOptions}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          isMobile={isMobile}
          tooltip={t('Select the status of ads to filter your search.')}
        />
        <DropdownBlockTest label={'Call to action'}
          id={'cta_types'}
          dropdownFilters={dropdownFilters}
          setDropdownFilters={setDropdownFilters}
          iconType={'database_action'}
          searchPlaceholder={'Search for call to action...'}
          data={Array.isArray(filters?.call_to_action) ? filters.call_to_action : []}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          isMobile={isMobile}
          tooltip={t('Search ads by their call-to-action.')}
        />
        <DropdownBlockTest label={'Ad copy language'}
          id={'language'}
          dropdownFilters={dropdownFilters}
          setDropdownFilters={setDropdownFilters}
          iconType={'database_globe'}
          searchPlaceholder={'Search for ad copy language...'}
          data={Array.isArray(filters?.languages) ? filters?.languages : []}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          isMobile={isMobile}
          tooltip={t('Select the language of the ad copy to refine results.')}
        />
        <DropdownBlockTest label={'Country'}
          id={'country'}
          dropdownFilters={dropdownFilters}
          setDropdownFilters={setDropdownFilters}
          iconType={'database_globe'}
          searchPlaceholder={'Search for country...'}
          data={Array.isArray(filters?.countries) ? filters?.countries : []}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          isMobile={isMobile}
          tooltip={t('Select the country to view ads targeted to specific regions.')}
        />
        <DropdownBlockTest label={'Domain TLD'}
          id={'domain_tld'}
          dropdownFilters={dropdownFilters}
          setDropdownFilters={setDropdownFilters}
          iconType={'database_domain'}
          searchPlaceholder={'Search for domain LTD...'}
          data={Array.isArray(filters?.domain_tld) ? filters?.domain_tld : []}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          isMobile={isMobile}
          tooltip={t(`Filter ads by URL's top-level domain`)}
        />
        <DropdownDateBlock label={'Ad creation date'}
          id={'created_at__gte'}
          iconType={'database_calendar'}
          data={dateOptions}
          dropdownFilters={dropdownFilters}
          setDropdownFilters={setDropdownFilters}
          hideTimeZone={true}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          isMobile={isMobile}
          withName
          tooltip={t('Select a date range to find ads created within that period.')}
        />
        <DropdownDateBlock label={'Last seen date'}
          id={'last_seen_date__gte'}
          iconType={'database_calendar'}
          data={dateOptions}
          dropdownFilters={dropdownFilters}
          setDropdownFilters={setDropdownFilters}
          hideTimeZone={true}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          isMobile={isMobile}
          withName
          tooltip={t('Select the dates during which the ads were last updated by our system.')}
        />
        <DropdownDateBlock label={'Product creation date'}
          id={'product_created_at__gte'}
          iconType={'database_calendar'}
          data={dateOptions}
          dropdownFilters={dropdownFilters}
          setDropdownFilters={setDropdownFilters}
          hideTimeZone={true}
          visibleModal={visibleModal}
          setVisibleModal={setVisibleModal}
          isMobile={isMobile}
          withName
          tooltip={t('Select a date range to find products created within that period.')}
        />
      </div>
      {
        isMobile ?
          null
          :
          <Divider style={{ margin: '24px 0' }} />
      }
      <div className="filters-btn-wrapper">
        <ButtonComponent className={'filters-btn-reset'}
          text={t('Reset filter')}
          onClick={() => resetFilters()}
        />
        <ButtonComponent className={cls('filters-btn-search', {
          // disabled: inputText.length > 0,
        })}
          text={t('Search')}
          onClick={() => applyFilters()}
        />

      </div>
    </div>
  );
};

export default FiltersBlock;
