import React from 'react';
import cls from 'classname';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';
import Icon from '../../../Icon';
import './ChartBlock.less';

//diff titles for diff reports
const countTitle = {
  revenue: 'Total revenue',
  sales: 'Total sales',
  unique_products_sold: 'Total Unique Products Sold',
  ad_spot: 'Ad Engagement',
  ad_spot_date: 'Ad creation date',
};

const title = {
  revenue: 'Estimated total revenue and return',
  sales: 'Sales and Returns',
  unique_products_sold: 'Unique Products Sold and Returns',
  ad_spot: 'Ad engagement',
  ad_spot_date: 'Ad creation date',
};

const ChartBlock = ({
  type,
  subTitle,
  totalCount,
  link,
  isMobile = false,
  adsInfo = false,
  adsQuantity = 4,
  sameLandingPageToggleText = null,
  historicalDataViewToggleText = null,
  sameLandingPage = false,
  setSameLandingPage = () => null,
  historicalDataView = true,
  setHistoricalDataView = () => null,
  id = null,
  showRawData = false,
  rawTotalCount,
  returnsCount,
  rawReturnsCount,
  withReturns = false,
  isLineChart,
  setIsLineChart,
  ...props
}) => {
  const { t } = useTranslation();

  //total number format
  let totalFormat = (value) =>
    new Intl.NumberFormat(
      'en-US',
      type === 'revenue'
        ? { style: 'currency', currency: 'USD' }
        : { style: 'decimal', maximumFractionDigits: 0 },
    ).format(value);

  return (
    <div className="chart-block" id={id}>
      <div className="chart-block_header">
      <span className="chart-block_title">
          {
            t(title[type])
          }
        {
          !type.includes('ad_spot') && (
            <Divider type={'vertical'} style={{margin: '0 12px'}} />
          )
        }
        {!type.includes('ad_spot') && (
          <span style={{ display: isMobile ? 'block' : 'inline-block' }}
                className="chart-block_sub-title">{subTitle}</span>
        )}
        </span>
        {isMobile && (
          type.includes('ad_spot') ?
            <span className="chart-block_total-count">
                {
                  totalFormat(totalCount)
                }
              </span>
            :
            <div className={'chart-block_total-count-wrap'}>
              <div className="chart-block_total-count-wrapper basic">
                <div className={'chart-block_total-count-title-wrapper'}>
              <span className={'chart-block_total-count-color'}>
                <svg xmlns="http://www.w3.org/2000/svg"
                     width="20"
                     height="20"
                     viewBox="0 0 20 20"
                     fill="none"
                >
                  <rect x="6"
                        y="6"
                        width="8"
                        height="8"
                        rx="2"
                        fill="#225AEA"
                  />
                </svg>
              </span>
                  <span className={'chart-block_total-count-title'}>
                {
                  t(countTitle[type])
                }
              </span>
                </div>
                <span className={'chart-block_total-count'}>
              {
                totalFormat(totalCount)
              }
            </span>
              </div>
              {
                showRawData ?
                  <div className="chart-block_total-count-wrapper basic raw">
                    <div className={'chart-block_total-count-title-wrapper'}>
                  <span className={'chart-block_total-count-color'}>
                    <svg xmlns="http://www.w3.org/2000/svg"
                         width="20"
                         height="20"
                         viewBox="0 0 20 20"
                         fill="none"
                    >
                      <rect x="6"
                            y="6"
                            width="8"
                            height="8"
                            rx="2"
                            fill="#225AEA"
                      />
                    </svg>
                  </span>
                      <span className={'chart-block_total-count-title'}>
                    {
                      t(`Raw ${countTitle[type]}`)
                    }
                  </span>
                    </div>
                    <span className={'chart-block_total-count'}>
                  {
                    totalFormat(rawTotalCount)
                  }
                </span>
                  </div>
                  :
                  null
              }
              {
                withReturns ?
                  <div className="chart-block_total-count-wrapper returns">
                    <div className={'chart-block_total-count-title-wrapper'}>
                  <span className={'chart-block_total-count-color'}>
                    <svg xmlns="http://www.w3.org/2000/svg"
                         width="20"
                         height="20"
                         viewBox="0 0 20 20"
                         fill="none"
                    >
                      <rect x="6"
                            y="6"
                            width="8"
                            height="8"
                            rx="2"
                            fill="#225AEA"
                      />
                    </svg>
                  </span>
                      <span className={'chart-block_total-count-title'}>
                    {
                      t('Total Returns')
                    }
                  </span>
                    </div>
                    <span className={'chart-block_total-count'}>
                  {
                    totalFormat(returnsCount)
                  }
                </span>
                  </div>
                  :
                  null
              }
              {
                showRawData && withReturns ?
                  <div className="chart-block_total-count-wrapper returns raw">
                    <div className={'chart-block_total-count-title-wrapper'}>
                  <span className={'chart-block_total-count-color'}>
                    <svg xmlns="http://www.w3.org/2000/svg"
                         width="20"
                         height="20"
                         viewBox="0 0 20 20"
                         fill="none"
                    >
                      <rect x="6"
                            y="6"
                            width="8"
                            height="8"
                            rx="2"
                            fill="#225AEA"
                      />
                    </svg>
                  </span>
                      <span className={'chart-block_total-count-title'}>
                    {
                      t('Raw Total Returns')
                    }
                  </span>
                    </div>
                    <span className={'chart-block_total-count'}>
                  {
                    totalFormat(rawReturnsCount)
                  }
                </span>
                  </div>
                  :
                  null
              }
            </div>
        )}
        <div className="period-filter">
          {
            //link to report page
            link && (
              <Link
                className="chart-block_link"
                to={{
                  pathname: link,
                  state: {
                    name: t(title[type]),
                    link: `/sales-tracker/store/${title}`,
                  },
                }}
              >
                {t('View Report')}
                <Icon type={'arrow_link'} role={'icon'} />
              </Link>
            )
          }
          {historicalDataViewToggleText && (
            <div
              className={cls('period-filter-wrapper-icons', {
                mobile: isMobile,
              })}
            >
              <div
                className={cls('period-filter', {
                  active: !historicalDataView,
                })}
                onClick={() => setHistoricalDataView((prev) => !prev)} // false
                style={{ cursor: 'pointer' }}
              >
                {historicalDataViewToggleText?.[0]}
              </div>
              <div
                className={cls('period-filter', {
                  active: historicalDataView,
                })}
                onClick={() => setHistoricalDataView((prev) => !prev)} // true
                style={{ cursor: 'pointer' }}
              >
                {historicalDataViewToggleText?.[1]}
              </div>
            </div>
          )}
          <div className="period-filter-wrapper-icons">
            <div
              className={cls('period-filter', {
                active: isLineChart,
              })}
              onClick={() => setIsLineChart(true)}
              style={{ cursor: 'pointer' }}
            >
              <Icon type={`line_chart`} role={'icon'} />
            </div>
            <div
              className={cls('period-filter', {
                active: !isLineChart,
              })}
              onClick={() => setIsLineChart(false)}
              style={{ cursor: 'pointer' }}
            >
              <Icon type={`bar_chart`} role={'icon'} />
            </div>
          </div>
        </div>
      </div>
      {!isMobile && (
        <span className="chart-block_total-count-block">
          {
            type.includes('ad_spot') ?
              <span className="chart-block_total-count">
                {
                  totalFormat(totalCount)
                }
              </span>
              :
              <div className={'chart-block_total-count-wrap'}>
                <div className="chart-block_total-count-wrapper basic">
                  <div className={'chart-block_total-count-title-wrapper'}>
              <span className={'chart-block_total-count-color'}>
                <svg xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                >
                  <rect x="6"
                        y="6"
                        width="8"
                        height="8"
                        rx="2"
                        fill="#225AEA"
                  />
                </svg>
              </span>
                    <span className={'chart-block_total-count-title'}>
                {
                  t(countTitle[type])
                }
              </span>
                  </div>
                  <span className={'chart-block_total-count'}>
              {
                totalFormat(totalCount)
              }
            </span>
                </div>
                {
                  showRawData ?
                    <div className="chart-block_total-count-wrapper basic raw">
                      <div className={'chart-block_total-count-title-wrapper'}>
                  <span className={'chart-block_total-count-color'}>
                    <svg xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                    >
                      <rect x="6"
                            y="6"
                            width="8"
                            height="8"
                            rx="2"
                            fill="#225AEA"
                      />
                    </svg>
                  </span>
                        <span className={'chart-block_total-count-title'}>
                    {
                      t(`Raw ${countTitle[type]}`)
                    }
                  </span>
                      </div>
                      <span className={'chart-block_total-count'}>
                  {
                    totalFormat(rawTotalCount)
                  }
                </span>
                    </div>
                    :
                    null
                }
                {
                  withReturns ?
                    <div className="chart-block_total-count-wrapper returns">
                      <div className={'chart-block_total-count-title-wrapper'}>
                  <span className={'chart-block_total-count-color'}>
                    <svg xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                    >
                      <rect x="6"
                            y="6"
                            width="8"
                            height="8"
                            rx="2"
                            fill="#225AEA"
                      />
                    </svg>
                  </span>
                        <span className={'chart-block_total-count-title'}>
                    {
                      t('Total Returns')
                    }
                  </span>
                      </div>
                      <span className={'chart-block_total-count'}>
                  {
                    totalFormat(returnsCount)
                  }
                </span>
                    </div>
                    :
                    null
                }
                {
                  showRawData && withReturns ?
                    <div className="chart-block_total-count-wrapper returns raw">
                      <div className={'chart-block_total-count-title-wrapper'}>
                  <span className={'chart-block_total-count-color'}>
                    <svg xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                    >
                      <rect x="6"
                            y="6"
                            width="8"
                            height="8"
                            rx="2"
                            fill="#225AEA"
                      />
                    </svg>
                  </span>
                        <span className={'chart-block_total-count-title'}>
                    {
                      t('Raw Total Returns')
                    }
                  </span>
                      </div>
                      <span className={'chart-block_total-count'}>
                  {
                    totalFormat(rawReturnsCount)
                  }
                </span>
                    </div>
                    :
                    null
                }
              </div>
          }
          {type.includes('ad_spot') && (
            <>
              {subTitle && <Divider type={'vertical'} style={{margin: '0 12px'}} />}
              <span
                style={{ display: isMobile ? 'block' : 'inline-block' }}
                className="chart-block_sub-title"
              >
                {subTitle}
              </span>
            </>
          )}
        </span>
      )}
      {props.children}
    </div>
  );
};

export default ChartBlock;
