import React from 'react';
import MainBlock from './MainBlock';
import ChartBlock from './ChartBlock';
import Chart from '../../../Components/Charts/Chart';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import Icon from '../../../Icon';
import StoreTrackerPageOverviewBlocked from './StoreTrackerPageOverviewBlocked';
import StoreTrackerPageOverviewEmpty from './StoreTrackerPageOverviewEmpty';
import InnerPageInfoBlock from './InnerPageInfoBlock';

const StoreTrackerPageOverview = (
  {
    id,
    detailedInfoNew,
    minDate,
    maxDate,
    timeZone,
    downloadSalesReport,
    downloadRevenueReport,
    showRawData,
    setShowRawData,
    userInfo,
    isMobile,
    detailedInfo,
    isLineChartRevenue,
    setIsLineChartRevenue,
    setTab,
    setModal
  }) => {

  const {t} = useTranslation();

  return (
    <div className="store-tracker-page-tab-content store-tracker-page-overview">
      <InnerPageInfoBlock data={{
        type: 'store',
        tracked_by: detailedInfo?.total?.store_tracked_by,
        created_at: detailedInfoNew?.created_at,
        theme: detailedInfo?.total?.theme?.name,
        language: detailedInfoNew?.language,
        store_products: detailedInfoNew?.products_count,
        store_apps: detailedInfo?.total?.store_apps,
        social_channels: detailedInfo?.total?.social_channels,
      }}
                          setModal={setModal}
      />
      <MainBlock data={{
        title: detailedInfoNew?.title,
        link: detailedInfoNew?.custom_domain,
        logo: detailedInfoNew?.logo || detailedInfoNew?.favicon,
        report: 'sales',
        id: id,
        reportName: 'Store Report',
        dates: { min: minDate, max: maxDate },
        // timeInfo - 1st sale date
        timeInfo: detailedInfoNew?.first_sale_date,
        timeZone: timeZone,
      }}
                 downloadSalesReport={downloadSalesReport}
                 downloadRevenueReport={downloadRevenueReport}
                 showRawData={showRawData}
                 setShowRawData={setShowRawData}
                 rawDataAccess={userInfo?.raw_data_access}
      />
      <h4 className='store-tracker-page-tab-subtitle'>
        {t('Sales & Revenue')}
        <span onClick={() => setTab('sales_&_revenue')}>
          {t('View Report')}
          <Icon type={'arrow_link'} role={'icon'} />
        </span>
      </h4>
      <ChartBlock type={'revenue'}
                  subTitle={!isMobile ? minDate && maxDate ?
                      `${dayjs(minDate).format('MMM DD, YYYY')} - ${dayjs(maxDate).format('MMM DD, YYYY')}`
                      :
                      `${dayjs(detailedInfo?.charts?.revenue[0]?.date).format('MMM DD, YYYY')} - ${dayjs(detailedInfo?.charts?.revenue[detailedInfo?.charts?.revenue?.length - 1]?.date).format('MMM DD, YYYY')}`
                    :
                    null
                  }
                  totalCount={detailedInfo?.total ? detailedInfo?.total?.revenue : 0}
                  rawTotalCount={Object.keys(detailedInfo?.raw_total || {})?.length ? detailedInfo?.raw_total?.revenue : 0}
                  returnsCount={Object.keys(detailedInfo?.total || {})?.length ? detailedInfo?.total?.returns_revenue : 0}
                  rawReturnsCount={Object.keys(detailedInfo?.raw_total || {})?.length ? detailedInfo?.raw_total?.returns_revenue : 0}
                  showRawData={showRawData}
                  withReturns={true}
                  link={null}
                  isLineChart={isLineChartRevenue}
                  setIsLineChart={setIsLineChartRevenue}
      >
        <Chart data={
          detailedInfo?.charts ?
            [...detailedInfo?.charts?.revenue?.chart]
            :
            []
            || []
        }
               height={264}
               type={'revenue'}
               animation={false}
               isMobile={isMobile}
               withRawData={showRawData}
               withReturns={true}
               returnsData={detailedInfo?.charts?.revenue?.returns?.length ?
                 [...detailedInfo?.charts?.revenue?.returns]
                 :
                 []}
               rawReturnsData={detailedInfo?.charts?.revenue?.raw_data_returns?.length ?
                 [...detailedInfo?.charts?.revenue?.raw_data_returns]
                 :
                 []}
               rawData={detailedInfo?.charts?.revenue?.raw_data_chart?.length ?
                 [...detailedInfo?.charts?.revenue?.raw_data_chart]
                 :
                 []}
               chartType={!isLineChartRevenue && 'bar'}
        />
      </ChartBlock>
      <h4 className="store-tracker-page-tab-subtitle">
        {t('Products')}
        <span className="count">
          0
        </span>
        <span onClick={() => setTab('products')}>
          {t('View all products')}
          <Icon type={'arrow_link'} role={'icon'} />
        </span>
      </h4>
      {/*<StoreTrackerPageOverviewEmpty type={'products'} />*/}
      <StoreTrackerPageOverviewBlocked />
      <h4 className="store-tracker-page-tab-subtitle">
        {t('Competitors')}
        <span className="count">
          0
        </span>
        <span onClick={() => setTab('competitors')}>
          {t('View all competitors')}
          <Icon type={'arrow_link'} role={'icon'} />
        </span>
      </h4>
      <StoreTrackerPageOverviewEmpty type={'competitors'} />
      {/*<StoreTrackerPageOverviewBlocked />*/}
      <h4 className="store-tracker-page-tab-subtitle">
        {t('Facebook Ads')}
        <span className="count">
          0
        </span>
        <span onClick={() => setTab('facebook_ads')}>
          {t('View all Facebook ads')}
          <Icon type={'arrow_link'} role={'icon'} />
        </span>
      </h4>
      <StoreTrackerPageOverviewEmpty type={'facebook_ads'} />
      {/*<StoreTrackerPageOverviewBlocked />*/}
      <h4 className="store-tracker-page-tab-subtitle">
        {t('Apps')}
        <span className="count">
          0
        </span>
        <span onClick={() => setTab('apps')}>
          {t('View all apps')}
          <Icon type={'arrow_link'} role={'icon'} />
        </span>
      </h4>
      <StoreTrackerPageOverviewEmpty type={'apps'} />
      {/*<StoreTrackerPageOverviewBlocked />*/}
    </div>
  );
};

export default StoreTrackerPageOverview;
