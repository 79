import React from 'react';
import cls from 'classname';

import Icon from '../../Icon';
import './save-to-collections.less';
import { useSelector } from 'react-redux';

const SaveToCollectionComponent = (
  {
    isSave=false,
    saveFunc,
    isDisabled
  }) => {

  const collections = useSelector((state) => state.collections.collections.results) || [];
  const defaultCollectionName = collections?.filter(el => el?.default)?.[0]?.name || 'All Products';

  return (
    <span className={cls('favorite', {disabled: isDisabled})}
          onClick={() => {
            if(isDisabled) return
            saveFunc(isSave, defaultCollectionName);
          }}
    >
    <Icon type="bookmark_button" role="icon" outline={isSave} />
  </span>
  );
}

export default SaveToCollectionComponent;
