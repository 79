import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import acc from 'accounting';
import cls from 'classname';
import { Col, Divider, InputNumber, Slider } from 'antd';
import Icon from '../../../Icon';
import ProductDetailsCard from './ProductDetailsCard';
import InputComponent from '../../../Components/Input';
import './NumbersBreakdownBlock.less';

const calculator = {
  getFees: (price) => (acc.unformat(price, ',') / 100) * 2.9 + 0.3,
  getCPA: (price) => acc.unformat(price, ',') / 3,
  getRatio: ({
               price,
               cost,
               shipping,
             }) => cost === 0 && shipping === 0 ? 0 : acc.unformat(price, ',') / (cost + shipping),
  getBECPA: ({ price, cost, shipping, fees }) => acc.unformat(price, ',') - cost - shipping - fees,
  getBEROAS: ({ price, BECPA }) => acc.unformat(price, ',') / BECPA,
  getMargin: ({ price, CPA, BECPA }) => ((BECPA - CPA) / acc.unformat(price, ',')) * 100,
  getProfit: ({ orders, BECPA, CPA }) => orders * (BECPA - CPA),
};

const fieldList = [
  {
    id: 1,
    name: 'cost',
    label: 'Estimated cost of good sold',
    tooltip: 'The amount you expect to pay your supplier for each unit.',
  },
  {
    id: 2,
    name: 'shipping',
    label: 'Estimated shipping cost',
    tooltip: 'The shipping cost is based on the cheapest shipping option to the United States. If the seller does not ship to Unites States, we will display Canada, Australia or United Kingdom.',
  },
  {
    id: 3,
    name: 'fees',
    label: 'Estimated fees per order',
    tooltip: 'The estimated fee you expect to pay your payment processor per order. Calculated as 2.9% + $0.3 per order.',
  },
  {
    id: 4,
    name: 'CPA',
    label: 'Estimated CPA',
    tooltip: 'The amount you expect to spend for a conversion. It is calculated as a third of “Selling Price”.',
  },
];

const NumbersBreakdownBlock = (
  {
    skeleton,
    isMobile,
    product,
    productDatabase = false,
  }) => {

  const [state, setState] = useState({
    price: null,
    orders: null,
    cost: null,
    shipping: null,
    fees: null,
    CPA: null,
  });
  const [isChanged, setIsChanged] = useState(true);
  const theme = useSelector(state => state.nav.theme);

  const { t } = useTranslation();

  const defaultValue = {
    price: productDatabase ? acc.formatNumber(product?.usd_price, 2, '', ',') : product?.price ? acc.formatNumber(product?.price, 2, '', ',') : 0,
    orders: 25000,
    cost: product?.cost_min || 0,
    shipping: +product?.shipping || 0,
    fees: product?.price ? calculator.getFees(product?.price) : 0,
    CPA: product?.price ? calculator.getCPA(product?.price) : 0,
  };

  useEffect(() => {
    if (product?.product_id || productDatabase) saveDefaultValue();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [product?.product_id]);

  useEffect(() => {
    let fees = 0.3;
    let CPA = 0;
    if (state.price) {
      const parsedPrice = acc.unformat(state.price, ',');
      fees = calculator.getFees(parsedPrice);
      CPA = calculator.getCPA(parsedPrice);
    }
    setState((state) => ({ ...state, fees, CPA }));
  }, [state.price]);

  useEffect(() => {
    checkIsChanged();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [
    state.price,
    state.orders,
    state.cost,
    state.shipping,
    state.fees,
    state.CPA,
  ]);

  function saveDefaultValue() {
    setState((state) => ({ ...state, ...defaultValue }));
  }

  const memoRatio = useMemo(() => {
    if (!acc.unformat(state.price, ',')) return 0;
    return calculator.getRatio({
      price: state.price,
      cost: state.cost,
      shipping: state.shipping,
    });
  }, [state.price, state.cost, state.shipping]);

  const memoBECPA = useMemo(
    () =>
      calculator.getBECPA({
        price: state.price,
        cost: state.cost,
        shipping: state.shipping,
        fees: state.fees,
      }),
    [state.price, state.cost, state.shipping, state.fees],
  );

  const memoBEROAS = useMemo(
    () =>
      calculator.getBEROAS({
        price: state.price,
        BECPA: memoBECPA,
      }),
    [state.price, memoBECPA],
  );

  const memoMargin = useMemo(() => {
    if (!acc.unformat(state.price, ',')) return 0;
    return calculator.getMargin({
      price: state.price,
      CPA: state.CPA,
      BECPA: memoBECPA,
    });
  }, [state.price, state.CPA, memoBECPA]);

  const memoProfit = useMemo(
    () => {
      if (state.orders === null || memoBECPA === null ||
        state.CPA === null || state.shipping === null || state.cost === null) {
        return 'N/A';
      } else {
        return calculator.getProfit({
          orders: state.orders,
          BECPA: memoBECPA,
          CPA: state.CPA,
        });
      }
    },
    [state.orders, state.CPA, memoBECPA, state.cost, state.shipping],
  );

  function handleChange(event) {
    const { name, value } = event.target;
    const parsedValue = value.replace(/\$\s?|(,*)/g, '');

    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(parsedValue) && reg.test(parsedValue)) || parsedValue === '') {
      if (name === 'CPA' && Number(parsedValue) > 100) return;
      setState((state) => ({ ...state, [name]: parsedValue }));
    }
  }

  function handleChangeNumber(value, name) {
    if (acc.formatNumber(value, 2, '', ',') === acc.formatNumber(state[name], 2, '', ',')) return;
    setState((state) => ({ ...state, [name]: +value }));
  }

  function checkIsChanged() {
    if (
      Object.keys(defaultValue).some((key) => defaultValue[key] !== state[key])
    ) {
      if (!isChanged) setIsChanged(true);
    } else if (isChanged) setIsChanged(false);
  }

  function handleResetValue() {
    saveDefaultValue();
  }

  const renderHeaderSkeleton = (
    <>
      <span className={'link skeleton'}
            style={{ width: 120, height: 28 }}
      />
      <span className={'link skeleton'}
            style={{ width: 70, height: 20 }}
      />
    </>
  )

  const renderHeader = !productDatabase ?
    <>
      <span className="title">
        {t('Numbers Breakdown')}
      </span>
      <span className={cls('link', { disabled: !isChanged })}
            onClick={handleResetValue}
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="20"
             height="20"
             viewBox="0 0 20 20"
             fill="none"
        >
          <path stroke="#225AEA"
                d="M10.8244 7.50435L13.2453 3.26847C13.771 2.34877 14.9434 2.0212 15.874 2.53403C16.8116 3.05072 17.1503 4.22522 16.6306 5.15735L14.2434 9.43898M7.43991 9.84908C6.02826 11.5074 4.42741 12.008 2.29102 11.5554C3.14886 17.2416 13.0179 20.94 13.876 13.6882M15.1228 13.8254L6.57483 9.11482C6.79111 8.72691 7.00739 8.33901 7.22367 7.9511C7.67045 7.14977 8.68084 6.86015 9.48426 7.30315L15.1137 10.4072C15.9212 10.8525 16.2136 11.8689 15.7646 12.6744C15.5508 13.0581 15.3368 13.4417 15.1228 13.8254Z"
                strokeWidth="1.5"
                strokeLinecap="square"
                strokeLinejoin="round"
          />
        </svg>
        {t('Clear all')}
      </span>
    </>
    :
    <></>;

  const renderTooltipIcon = (text, className) => (
    <Icon type="attention_outline"
          role="button"
          titleText={text}
          tooltipProps={{
            placement: 'top',
            destroyTooltipOnHide: true,
            trigger: isMobile ? 'click' : 'hover',
            overlayClassName: cls('details-tooltip', className),
            getPopupContainer: (trigger) => trigger?.parentNode,
          }}
    />
  );

  const marksPrice = {
    0: '$0',
    500: '$500+',
  };

  const marksOrders = {
    0: '0',
    100000: '100,000+',
  };

  if (skeleton) return (
    <ProductDetailsCard wrapperClassName={productDatabase ? 'product-database-breakdown-card' : null}
                        header={renderHeaderSkeleton}
                        withLine={!isMobile}
    >
      <div className={cls('numbers-breakdown-card-slider-wrap', {
        'product-database-card-block-upper': productDatabase
      })}>
        <Col>
          <div className="numbers-breakdown-card">
            <span className="numbers-breakdown-card-label"
                  style={{display: 'flex', alignItems: 'center', gap: 8}}
            >
              <span className={'link skeleton'}
                    style={{ width: 79, height: 20 }}
              />
              <span className={'link skeleton'}
                    style={{ width: 16, height: 16, borderRadius: 50 }}
              />
            </span>
            <span className={'link skeleton'}
                  style={{ width: '100%', height: 8, marginTop: 20 }}
            />
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', marginTop: 12}}>
              <span className={'link skeleton'}
                    style={{ width: 30, height: 20 }}
              />
              <span className={'link skeleton'}
                    style={{ width: 60, height: 20 }}
              />
            </div>
            <span className={'link skeleton'}
                  style={{ width: '100%', height: 40, marginTop: 18 }}
            />
          </div>
        </Col>
        <Col>
          <div className="numbers-breakdown-card">
            <span className="numbers-breakdown-card-label"
                  style={{ display: 'flex', alignItems: 'center', gap: 8 }}
            >
              <span className={'link skeleton'}
                    style={{ width: 79, height: 20 }}
              />
              <span className={'link skeleton'}
                    style={{ width: 16, height: 16, borderRadius: 50 }}
              />
            </span>
            <span className={'link skeleton'}
                  style={{ width: '100%', height: 8, marginTop: 20 }}
            />
            <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              marginTop: 12,
            }}>
              <span className={'link skeleton'}
                    style={{ width: 30, height: 20 }}
              />
              <span className={'link skeleton'}
                    style={{ width: 60, height: 20 }}
              />
            </div>
            <span className={'link skeleton'}
                  style={{ width: '100%', height: 40, marginTop: 18 }}
            />
          </div>
        </Col>
        <Col className="numbers-breakdown-card-wrap pot_profit">
          <div className="numbers-breakdown-card">
            <span className="numbers-breakdown-card-label">
              <span className={'link skeleton'}
                    style={{ width: 79, height: 20 }}
              />
            </span>
            <span className="numbers-breakdown-card-result"
                  style={{ width: '100%' }}
            >
              <span className={'link skeleton'}
                    style={{ width: 100, height: 28 }}
              />
              <p>
                <span className={'link skeleton'}
                      style={{ width: 200, height: 20 }}
                />
              </p>
            </span>
          </div>
        </Col>
      </div>
      <div className={cls('numbers-breakdown-card-slider-wrap-small', {
        'product-database-card-block-upper': productDatabase,
      })}
           style={{ marginTop: 32 }}
      >
        {
          [1,2,3,4].map((el) => (
            <Col key={el}>
              <div className="numbers-breakdown-card">
                <span className="numbers-breakdown-card-label small"
                      style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                >
                  <span className={'link skeleton'}
                        style={{ width: 122, height: 20 }}
                  />
                  <span className={'link skeleton'}
                        style={{ width: 16, height: 16, borderRadius: 50 }}
                  />
                </span>
                <span className={'link skeleton'}
                      style={{ width: '100%', height: 40, marginTop: 8 }}
                />
              </div>
            </Col>
          ))}
      </div>
      <div className={cls('numbers-breakdown-card-slider-wrap-smallest', {
        'product-database-card-block-upper': productDatabase
      })}>
        <div className={'with-bg'}>
          <div className="numbers-breakdown-card">
                <span className="numbers-breakdown-card-label small"
                      style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                >
                  <span className={'link skeleton'}
                        style={{ width: 122, height: 20 }}
                  />
                  <span className={'link skeleton'}
                        style={{ width: 16, height: 16, borderRadius: 50 }}
                  />
                </span>
            <span className={'link skeleton'}
                  style={{ width: '100%', height: 40, marginTop: 8 }}
            />
          </div>
        </div>
        <div className={'with-bg'}>
          <div className="numbers-breakdown-card">
                <span className="numbers-breakdown-card-label small"
                      style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                >
                  <span className={'link skeleton'}
                        style={{ width: 122, height: 20 }}
                  />
                  <span className={'link skeleton'}
                        style={{ width: 16, height: 16, borderRadius: 50 }}
                  />
                </span>
            <span className={'link skeleton'}
                  style={{ width: '100%', height: 40, marginTop: 8 }}
            />
          </div>
        </div>
        <div className={'with-bg'}>
          <div className="numbers-breakdown-card">
                <span className="numbers-breakdown-card-label small"
                      style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                >
                  <span className={'link skeleton'}
                        style={{ width: 122, height: 20 }}
                  />
                  <span className={'link skeleton'}
                        style={{ width: 16, height: 16, borderRadius: 50 }}
                  />
                </span>
            <span className={'link skeleton'}
                  style={{ width: '100%', height: 40, marginTop: 8 }}
            />
          </div>
        </div>
        <div className={'with-bg'}>
          <div className="numbers-breakdown-card">
                <span className="numbers-breakdown-card-label small"
                      style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                >
                  <span className={'link skeleton'}
                        style={{ width: 122, height: 20 }}
                  />
                  <span className={'link skeleton'}
                        style={{ width: 16, height: 16, borderRadius: 50 }}
                  />
                </span>
            <span className={'link skeleton'}
                  style={{ width: '100%', height: 40, marginTop: 8 }}
            />
          </div>
        </div>
      </div>
    </ProductDetailsCard>
  )

  return (
    <ProductDetailsCard wrapperClassName={productDatabase ? 'product-database-breakdown-card' : null}
                        header={renderHeader}
                        withLine={!isMobile}
    >
      <div className={cls('numbers-breakdown-card-slider-wrap', {
        'product-database-card-block-upper': productDatabase,
      })}>
        <Col>
          <div className="numbers-breakdown-card">
            <span className="numbers-breakdown-card-label">
              {t('Selling price')}
              {renderTooltipIcon(t('This is the price you plan on charging your customers.'), 'price')}
            </span>
            {
              isMobile ?
                <span className={'mobile-description'}>
                  {
                    t('Slide selling price to see how your potential profit may change.')
                  }
                </span>
                :
                null
            }
            <Slider className="numbers-breakdown-card-slider"
                    value={acc.unformat(state['price'], ',')}
                    max={500}
                    marks={marksPrice}
                    open={false}
                    onChange={(value) => handleChangeNumber(value, 'price')}
                    onAfterChange={(value) =>
                      setState((state) => ({
                        ...state,
                        price: acc.formatNumber(value, 2, '', ','),
                      }))
                    }
            />
            <InputNumber className={cls('numbers-breakdown-card-input selling-price', {
              dark: theme === 'dark',
              'numbers-breakdown-card-input-product-database': productDatabase,
            })}
                         name="price"
                         value={state.price}
                         step={0.01}
                         decimalSeparator={','}
                         precision={2}
                         onChange={(value) => handleChangeNumber(value, 'price')}
                         stringMode
            />
          </div>
        </Col>
        <Col>
          <div className="numbers-breakdown-card">
            <span className="numbers-breakdown-card-label">
              {t('Example orders')}
              {renderTooltipIcon(t('The amount of units you expect to sell. This is the default value we put across all products.'), 'orders')}
            </span>
            {
              isMobile ?
                <span className={'mobile-description'}>
                  {
                    t('Slide example orders to see how your potential profit may change.')
                  }
                </span>
                :
                null
            }
            <Slider className="numbers-breakdown-card-slider"
                    defaultValue={25000}
                    value={acc.unformat(state.orders)}
                    max={100000}
                    marks={marksOrders}
                    open={false}
                    onChange={(value) => handleChangeNumber(value, 'orders')}
            />
            <InputComponent className={cls('numbers-breakdown-card-input', {
              'numbers-breakdown-card-input-product-database': productDatabase,
            })}
                            name="orders"
                            value={acc.formatNumber(state.orders, 0, ',', '')}
                            onChange={handleChange}
            />
          </div>
        </Col>
        <Col className="numbers-breakdown-card-wrap pot_profit">
          <div className="numbers-breakdown-card">
            <span className="numbers-breakdown-card-label">
              {t('Potential profit')}
            </span>
            <span className="numbers-breakdown-card-result">
              {typeof memoProfit === 'number' ?
                `≈ $${acc.formatNumber(memoProfit, 0, ',')}`
                :
                `≈ $${memoProfit}`
              }
              {
                isMobile ?
                  null
                  :
                  <p>
                    {t('Potential profit is calculated by subtracting the CPA from BECPA and multiplying the result by 25,000 units sold.')}
                  </p>
              }
            </span>
          </div>
        </Col>
      </div>
      {
        isMobile ?
          <Divider type={'horizontal'} style={{margin: '24px 0'}} />
          :
          null
      }
      <div className={cls('numbers-breakdown-card-slider-wrap-small', {
             'product-database-card-block-upper': productDatabase
           })}
           style={{marginTop: isMobile ? 0 : 32}}
      >
        {
          fieldList.map((el) => (
            <Col key={el.id}>
              <div className="numbers-breakdown-card">
              <span className="numbers-breakdown-card-label small">
                <span>
                  {t(el.label)}
                </span>
                {
                  renderTooltipIcon(
                    productDatabase && el.name === 'shipping'
                      ? 'The amount you expect to pay your supplier for each unit shipped.'
                      :
                      el?.tooltip, el.name)
                }
              </span>
                <InputNumber className={cls('numbers-breakdown-card-input', {
                  dark: theme === 'dark',
                  'numbers-breakdown-card-input-product-database': productDatabase,
                })}
                             name={el.name}
                             value={state[el.name]}
                             step={0.01}
                             decimalSeparator={','}
                             precision={2}
                             onChange={(value) => handleChangeNumber(value, el.name)}
                             stringMode
                />
              </div>
            </Col>
          ))}
      </div>
      <div className={cls('numbers-breakdown-card-slider-wrap-smallest', {
        'product-database-card-block-upper': productDatabase
      })}>
        <div style={isMobile ? {borderRadius: '16px 0 0 0'} : {}}
             className={'with-bg'}
        >
          <div className="numbers-breakdown-card">
            <span className="numbers-breakdown-card-label small">
              {t('P/C Ratio')}
              {renderTooltipIcon(t('The price to cost ratio is calculated by dividing the product cost with the selling price.'), 'p_c_ratio')}
            </span>
            <span className="value">
              {
                `${acc.formatNumber(memoRatio, 2, ',')}X`
              }
            </span>
          </div>
        </div>
        <div style={isMobile ? {borderRadius: '0 16px 0 0'} : {}}
             className={'with-bg'}
        >
          <div className="numbers-breakdown-card">
            <span className="numbers-breakdown-card-label small">
              {t('BECPA')}
              {renderTooltipIcon(t('The breakeven cost per acquisition (BECPA) is the maximum CPA you should aim to get to breakeven on the product.'), 'BECPA')}
            </span>
            <span className="value">
              {
                `$${acc.formatNumber(memoBECPA, 2, '', ',')}`
              }
            </span>
          </div>
        </div>
        <div style={isMobile ? { borderRadius: '0 0 0 16px' } : {}}
             className={'with-bg'}
        >
          <div className="numbers-breakdown-card">
            <span className="numbers-breakdown-card-label small">
              {t('BEROAS')}
              {renderTooltipIcon(t('The breakeven return on ad spend (BEROAS) is the minimum ROAS you should aim to get to breakeven on the product.'), 'BEROAS')}
            </span>
            <span className="value">
              {
                acc.formatNumber(memoBEROAS, 2, ',')
              }
            </span>
          </div>
        </div>
        <div style={isMobile ? { borderRadius: '0 0 16px 0' } : {}}
             className={'with-bg'}
        >
          <div className="numbers-breakdown-card">
            <span className="numbers-breakdown-card-label small">
              {t('Profit margin')}
              {renderTooltipIcon(t('This is the profit margin you can expect after taking into account all the numbers in the “Numbers Breakdown”.'), 'profit_margin')}
            </span>
            <span className="value">
              {
                `${acc.formatNumber(memoMargin, 2, ',')}%`
              }
            </span>
          </div>
        </div>
      </div>
    </ProductDetailsCard>
  );
};

export default NumbersBreakdownBlock;
