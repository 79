import React from 'react';

const Belfius = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_7546_10156)">
        <path d="M0 0H20V20H0V0Z" fill="white" />
        <path
          d="M9.9949 3.00103C8.54227 3.00103 7.08708 3.00921 5.63446 3.02457C4.0359 3.04094 3.0144 4.12076 3.00826 5.72411C2.9975 8.58435 2.99699 11.4523 3.00826 14.3161C3.01491 15.965 4.0487 16.9808 5.70409 16.988C7.46599 16.9962 9.22532 16.9921 10.9836 16.9921C12.8029 16.9921 14.6262 16.9768 16.4465 16.9998C16.8689 17.0054 17.002 16.901 17 16.4624C16.9821 12.8806 16.9969 9.30083 16.9846 5.72054C16.9846 4.11514 15.9621 3.03993 14.3661 3.02355C12.9073 3.00871 11.4608 3 10.0016 3L9.9949 3.00103ZM10.0026 7.0629C10.8817 7.0629 11.7583 7.06545 12.6365 7.07057C13.426 7.07517 13.7686 7.38325 13.7742 8.04035C13.7803 8.69643 13.3789 9.04956 12.5976 9.05672C11.7199 9.0644 10.8408 9.05672 9.96418 9.05672H9.9606C9.10243 9.05672 8.24222 9.06287 7.38457 9.05263C6.64059 9.04291 6.25401 8.69286 6.25043 8.05929C6.24684 7.39912 6.59195 7.07517 7.36512 7.07005C8.24325 7.06493 9.12343 7.0629 10.0026 7.0629ZM9.9949 10.9339C10.8853 10.9339 11.7742 10.9334 12.6646 10.938C13.3769 10.9415 13.768 11.3315 13.7558 11.9779C13.7445 12.5909 13.3743 12.9333 12.6646 12.9436C11.766 12.9564 10.8638 12.9477 9.96367 12.9477H9.96009C9.08093 12.9477 8.20075 12.9569 7.3216 12.9436C6.61039 12.9328 6.24121 12.5904 6.23046 11.9738C6.21868 11.3269 6.61038 10.941 7.32518 10.938C8.2156 10.9339 9.10448 10.9339 9.9949 10.9339Z"
          fill="#AA0024" />
      </g>
      <defs>
        <clipPath id="clip0_7546_10156">
          <rect width="20" height="20" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default Belfius;
