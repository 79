import { Trans } from 'react-i18next';
import React from 'react';
import { openNotificationWithIcon } from '../../../Components/Notification/index';
import SaveToCollectionMsg, { DefaultMsg } from '../../../Components/Notification/notification-message';
import { EventHandler } from '../../../Utils/event-handler';

const unSaveKey = `unsave-${Date.now()}`;
export const unSaveNotification = ({ params, isSave }) => {
  openNotificationWithIcon({
    key: unSaveKey,
    style: { width: '400px' },
    className: 'notification notification--save notification--save__with-btn',
    message: (
      <SaveToCollectionMsg keyForClose={unSaveKey}
                           text={null}
                           isSave={isSave}
                           isRestore={params?.restore}
                           icon={'notification_delete'}
      />
    ),
    // className: 'notification notification--create-collection',
    // message: (
    //   <DefaultMsg
    //     icon="notification_delete"
    //     text={
    //       <span className="restore-modal-notification">
    //         {message}
    //         <span
    //           onClick={() => {
    //             EventHandler.emit('restore-product');
    //           }}
    //         >
    //           <Trans i18nKey="Undo Action" />
    //         </span>
    //       </span>
    //     }
    //   />
    // ),
  });
};
