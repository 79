import React from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import { Tooltip } from 'antd';
import Icon from '../../../Icon';
import './QuickSearch.less';
import AppUtils from '../../../Utils/utils';
import { useSelector } from 'react-redux';

const QuickSearch = ({
  loadPreset,
  setLoadedPreset,
  loadedPreset,
  skeleton,
  isMobile,
  data = [],
  disabled = false,
}) => {
  const { t } = useTranslation();
  const theme = useSelector(state => state.nav.theme);

  if (skeleton)
    return (
      <div
        className={'quick-search'}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <div className={'quick-search-wrapper'}>
          {isMobile ? null : (
            <span className={'quick-search-title'} style={{ display: 'flex' }}>
                <span
                  className={'link skeleton'}
                  style={{ width: 92, height: 20 }}
                />
            </span>
          )}
          <div className="quick-search-items">
            {[1, 2, 3].map((el) => (
              <span
                key={el}
                className={'link skeleton'}
                style={{
                  width: 130 + el * 10,
                  height: 32,
                  marginRight: isMobile ? 0 : 12,
                }}
              />
            ))}
          </div>
        </div>
      </div>
    );

  return (
    <div className="quick-search">
      <div className={'quick-search-wrapper'}>
        {isMobile ? null : (
          <span className={'quick-search-title'}>{t('Quick search')}</span>
        )}
          <div className="quick-search-items">
            {data.map((el) =>
              el?.tooltip ? (
                <Tooltip
                  overlayClassName="product_name-tooltip details-tooltip"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  placement="top"
                  title={el?.tooltip}
                  destroyTooltipOnHide={true}
                  key={el?.id + 1}
                >
                  <div
                    className={cls('quick-search-item', {
                      active: loadedPreset?.id === el?.id,
                    })}
                    id={el?.id}
                    onClick={() => {
                      if (loadedPreset?.id !== el?.id) {
                        setLoadedPreset(el);
                        loadPreset(el);
                      }
                    }}
                  >
                    {(el?.light_icon && el.dark_icon) ? (
                      <img
                        src={theme === 'light' ? el?.light_icon : el?.dark_icon}
                        alt=""
                        onError={AppUtils.addDefaultSrc}
                        width="20" height="20"
                        style={{ marginRight: 5 }}
                      />
                    ) : <Icon role={'icon'} type={'quick_search_image'}/>}
                    {el?.name}
                  </div>
                </Tooltip>
              ) : (
                  <div
                    key={el?.id}
                    className={cls('quick-search-item', {
                      active: loadedPreset?.id === el?.id,
                      disabled: disabled,
                    })}
                    id={el?.id}
                    onClick={() => {
                      if (loadedPreset?.id !== el?.id && !disabled) {
                        setLoadedPreset(el);
                        loadPreset(el);
                      }
                    }}
                  >
                    {(el?.light_icon && el.dark_icon) ? (
                      <img
                        src={theme === 'light' ? el?.light_icon : el?.dark_icon}
                        alt=""
                        onError={AppUtils.addDefaultSrc}
                        width="20" height="20"
                        style={{ marginRight: 5 }}
                      />
                    ) : <Icon role={'icon'} type={'quick_search_image'}/>}
                    {el?.name}
                  </div>
              ),
            )}
          </div>
      </div>
    </div>
  );
};

export default QuickSearch;
