import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import {connect, useSelector} from 'react-redux';
import dayjs from 'dayjs';
import cls from "classname";
import Creators from './reducer';
import ShopifyCreators from "../ShopifyStore/reducer";
import AutoDSCreators from "../AutoDS/reducer";
import BreadcrumbsActions from '../Breadcrumbs/actions';
import {Spin, Modal} from 'antd';
import ChartBlock from './components/ChartBlock';
import MainBlock from './components/MainBlock';
import Chart from '../../Components/Charts/Chart';
import { BreadcrumbsComponent } from '../Breadcrumbs';
import TrackerTable from './components/TrackerTable';
import SalesTrackerNote from "./components/SalesTrackerNote";
import Icon from "../../Icon";
import StoreProductsModal from "./components/StoreProductsModal";
import StoreAppsModal from "./components/StoreAppsModal";
import InnerPageTitleBlock from './components/InnerPageTitleBlock';
import InnerPageInfoBlock from './components/InnerPageInfoBlock';
import ProductTrackerPageSkeleton from './components/ProductTrackerPageSkeleton';
import './styles.less';

const ProductTrackerPage = (
  {
    isAdmin,
    fetching,
    checkLoading,
    error,
    userInfo,
    isMobile,
    theme,
    setView,
    view,
    breadcrumbs,
    dispatch,
    getProductInfo,
    getProductInfoNew,
    getDetailedProductInfoCheckRequest,
    selectedProduct,
    downloadProductReport,
    filterDates,
    setFilters,
    setPeriod,
    getStoreProductsModal,
    getStoreProductsModalNext,
    resetStoreProductsModal,
    storeProductsModalTableDataLoading,
    storeProductsModalTableData,
    fetchingSpin,
    getProducts,
    shopifyStore,
    changeVisibleModalShopify,
    loading,
    autoDS,
    ...props
  }) => {


  const [firsLoading, setFirsLoading] = useState(true);

  const [showRawData, setShowRawData] = useState(false);

  const isVisibleModal = shopifyStore?.isVisibleModal;
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const breadCrumbs = breadcrumbs?.length ? [...breadcrumbs] : null;
  const { state } = location;

  const [modal, setModal] = useState(null);

  //table pagination options
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortOrder, setSortOrder] = useState(null);

  // type of chart view
  const [isLineChartRevenue, setIsLineChartRevenue] = useState(true);

  const products = useSelector(store => store?.salesTracker?.products?.results) || [];

  const selectedProductNew = products?.filter(el => +el.id === +id)?.[0] || {};

  const [data, setData] = useState([]);

  const minDate = filterDates?.min;
  const maxDate = filterDates?.max;
  const timeZone = selectedProductNew?.store_timezone ? selectedProductNew?.store_timezone.split('UTC')[1] : null;
  const isShowExtensionNotification = window.location?.search.includes('show_notification');
  const initialUrl = document?.location?.pathname + (document?.location?.search || '');

  const skeleton = firsLoading || fetching || checkLoading || fetchingSpin || (loading && !Object.keys(selectedProductNew).length);

  useEffect(() => {
    if (!Object.keys(selectedProductNew).length && isShowExtensionNotification) {
      getProducts({page: 1, page_size: 100});
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [selectedProductNew?.title])

  useEffect(() => {
    document.title = `Sales Tracker - Dropship`;
    if (isVisibleModal) changeVisibleModalShopify({ isVisibleModal: null, initialUrl: null, importProductId: null });
    if(!products.length && !isShowExtensionNotification) {
      navigate('/sales-tracker/products', {replace: true});
    }
    if (firsLoading) dispatch(Creators.resetIsAvailable());
    if (
      selectedProductNew?.first_sale_date
      && dayjs().diff(dayjs(selectedProductNew?.first_sale_date), 'days') < 30
      && dayjs(minDate) < dayjs(selectedProductNew?.first_sale_date)
    ) {
      const newData = dateOptions.filter(el => dayjs().utcOffset(timeZone ? timeZone : '+0:00').diff(dayjs(selectedProductNew?.first_sale_date), 'days') >= el.days);
      let quantity = newData[newData?.length - 1]?.value?.split(' ')?.[1]
      let value = newData?.[newData?.length - 1]?.value?.split(' ')?.[2]
      let period = newData?.[newData?.length - 1]?.id
      let min = dayjs().diff(dayjs(selectedProductNew?.first_sale_date), 'days') >= 1
      && dayjs().diff(dayjs(selectedProductNew?.first_sale_date), 'days') < 7 ?
        dayjs(selectedProductNew?.first_sale_date).format('YYYY-MM-DD')
        :
        dayjs().utcOffset(timeZone ? timeZone : '+0:00').subtract([1, 2].includes(period) ? quantity : quantity - 1, value).format('YYYY-MM-DD');
      let max = dayjs().diff(dayjs(selectedProductNew?.first_sale_date), 'days') >= 1
      && dayjs().diff(dayjs(selectedProductNew?.first_sale_date), 'days') < 7 ?
        dayjs().format('YYYY-MM-DD')
        :
        [1,2].includes(period) ? min : dayjs().utcOffset(timeZone ? timeZone : '+0:00').format('YYYY-MM-DD');
      setFilters({report_period: {min, max}});
      setPeriod(dayjs().diff(dayjs(selectedProductNew?.first_sale_date), 'days') >= 1
      && dayjs().diff(dayjs(selectedProductNew?.first_sale_date), 'days') < 7 ?
        [...newData]
        :
        [newData[newData?.length - 1]]);
    } else if (minDate === null || maxDate === null) {
      let max = new Date(dayjs()).toISOString();
      let min = new Date(dayjs().subtract(30, 'days')).toISOString();
      setFilters({report_period: {min, max}})
      setPeriod([{id: 4, name: 'Last 30 days', value: 'Last 30 days', days: 30}])
    } else {
      const min = () => dayjs().utcOffset(timeZone ? timeZone : '+0:00').subtract(29, 'days').format('YYYY-MM-DD');
      const max = () => dayjs().utcOffset(timeZone ? timeZone : '+0:00').format('YYYY-MM-DD');
      setFilters({report_period: {min: min(), max: max()}})
      setPeriod([{id: 4, name: 'Last 30 days', value: 'Last 30 days', days: 30}])
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (firsLoading) setFirsLoading(false)
    else {
      if (Object.keys(selectedProductNew).length ) {
        getDetailedProductInfoCheckRequest({id: id, store_id: selectedProductNew?.store?.id, date_range: {min: minDate, max: maxDate}});
        getProductInfo({
          page: pageNumber,
          page_size: null,
          ordering: null,
          id: id,
          store_id: selectedProductNew?.store?.id,
          date_range: {min: minDate, max: maxDate},
          raw_data: userInfo?.raw_data_access && showRawData
        });
        getProductInfoNew({id: id, store_id: selectedProductNew?.store?.id});
      }
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [minDate, maxDate, selectedProductNew?.title, showRawData])

  useEffect(() => {
    setData(selectedProduct?.table ? [...selectedProduct?.table.slice(0,49)] : [])
    setSortOrder(null)
  }, [selectedProduct])

  useEffect(() => {
    if (sortOrder) {
      let newData;
      let key = sortOrder?.includes('-') ? sortOrder?.split('-')?.[1] : sortOrder
      key = key === 'monthly_sales' ? 'sales' : key;
      if (sortOrder?.includes('-')) newData = [...selectedProduct?.table].sort((a, b) => b?.[key] - a?.[key]);
      else newData = [...selectedProduct?.table].sort((a, b) => a?.[key] - b?.[key]);
      setData([...newData.slice((pageNumber - 1) * (pageSize - 1), (pageSize * pageNumber) - 1)]);
    } else {
      setData(selectedProduct.table ? [...selectedProduct.table.slice((pageNumber - 1) * (pageSize - 1), (pageSize * pageNumber) - 1)] : []);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [sortOrder, pageSize, pageNumber])

  //breadcrumbs display handler
  useEffect(() => {
    if (breadCrumbs?.length >= 3) {
      props.removeBreadCrumb();
    } else if (breadCrumbs?.length === 2 && state) {
      props.addBreadCrumb({
        name: state?.name,
        link: state?.link,
      });
    } else {
      props.clearBreadCrumbs();
      props.addBreadCrumb({
        name: 'Sales tracker',
        link: state?.link || `/sales-tracker/products`,
      });
      props.addBreadCrumb({
        name: selectedProductNew?.title || (new URLSearchParams(window?.location?.search).get('title') ? decodeURIComponent(new URLSearchParams(window?.location?.search).get('title')) : id),
        link: state?.link || `/sales-tracker/products/product/${id}`,
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [state]);

  const handleChangePageSize = useCallback((value) => {
    setPageSize(value);
  }, []);

  const handleChangePageNumber = useCallback((value) => {
    setPageNumber(value);
  }, []);

  const handleSort = useCallback(({ order, columnKey }) => {
    if (order) {
      const ordering = order === 'ascend' ? columnKey : `-${columnKey}`;
      setSortOrder(ordering);
    } else {
      setSortOrder(null);
    }
  }, []);

  const modalBlocks = {
    store_products: <StoreProductsModal getStoreProductsModal={getStoreProductsModal}
                                       getStoreProductsModalNext={getStoreProductsModalNext}
                                       storeProductsModalTableDataLoading={storeProductsModalTableDataLoading}
                                       storeProductsModalTableData={storeProductsModalTableData}
                                       storeId={selectedProductNew?.store?.id}
                                       resetStoreProductsModal={resetStoreProductsModal}
                                       minDate={minDate}
                                       maxDate={maxDate}
                                       isMobile={isMobile}
    />,
    store_apps: <StoreAppsModal data={selectedProduct?.total?.store_apps} />,
  }

  const modalBlocksWidth = {
    store_products: 828,
    store_apps: 450
  }

  const handleOpenModalShopifyConnect = (importProductId) => {
    if (shopifyStore?.results?.length) {
      changeVisibleModalShopify({ isVisibleModal: 'import', initialUrl, importProductId });
    } else {
      changeVisibleModalShopify({ isVisibleModal: 'connect', initialUrl, importProductId: null });
    }
  };

  const handleOpenModalAutoDSConnect = (importProductId) => {
    if (autoDS?.email) {
      if (autoDS?.stores?.length) dispatch(AutoDSCreators.changeVisibleModalAuto({ isVisibleModal: 'import', importProductId }));
      else dispatch(AutoDSCreators.changeVisibleModalAuto({ isVisibleModal: 'import_unavailable', importProductId: null }));
    } else dispatch(AutoDSCreators.changeVisibleModalAuto({ isVisibleModal: 'connect', importProductId }));
  }

  if (skeleton) return <ProductTrackerPageSkeleton isMobile={isMobile} />

  return (
    <div className={'product-database-page sales-tracker-page sales-tracker-product-page'}>
      <BreadcrumbsComponent breadCrumbs={breadCrumbs}
                            isMobile={isMobile}
                            isAdmin={false}
                            hideTime={true}
                            timeInfo={selectedProductNew?.last_updated}
      />
      <SalesTrackerNote />
      <InnerPageTitleBlock data={{
        title: selectedProductNew?.title,
        link: selectedProductNew?.store?.custom_domain,
        logo: selectedProductNew?.main_image,
        record: selectedProductNew,
        totalInfo: selectedProduct?.total,
      }}
                           handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
                           handleOpenModalAutoDSConnect={handleOpenModalAutoDSConnect}
                           productPage={true}
                           isMobile={isMobile}
      />
      <InnerPageInfoBlock data={{
        type: 'product',
        tracked_by: selectedProduct?.total?.product_tracked_by,
        created_at: selectedProductNew?.created_at,
        theme: selectedProduct?.total?.theme?.name,
        language: selectedProductNew?.store?.language,
        store_products: selectedProductNew?.store?.products_count,
        store_apps: selectedProduct?.total?.store_apps,
        social_channels: selectedProduct?.total?.social_channels
      }}
                          setModal={setModal}
      />
      <MainBlock data={{
        title: selectedProductNew?.title,
        link: selectedProductNew?.store?.custom_domain,
        logo: selectedProductNew?.main_image,
        report: 'product',
        id: id,
        reportName: 'Product Report',
        dates: { min: minDate, max: maxDate },
        timeInfo: selectedProductNew?.first_sale_date,
        timeZone: timeZone,
      }}
                 downloadProductReport={downloadProductReport}
                 showRawData={showRawData}
                 setShowRawData={setShowRawData}
                 rawDataAccess={userInfo?.raw_data_access}
                 isMobile={isMobile}
      />
      <ChartBlock type={'revenue'}
                  subTitle={minDate && maxDate ?
                    `${dayjs(minDate).format('MMM DD, YYYY')} - ${dayjs(maxDate).format('MMM DD, YYYY')}`
                    :
                    `${dayjs(selectedProduct?.charts?.revenue[0]?.date).format('MMM DD, YYYY')} - ${dayjs(selectedProduct?.charts?.revenue[selectedProduct?.charts?.revenue?.length - 1]?.date).format('MMM DD, YYYY')}`}
                  totalCount={Object.keys(selectedProduct?.total || {}).length ? selectedProduct?.total?.revenue : 0}
                  rawTotalCount={Object.keys(selectedProduct?.raw_total || {}).length ? selectedProduct?.raw_total?.revenue : 0}
                  returnsCount={Object.keys(selectedProduct?.total || {})?.length ? selectedProduct?.total?.returns_revenue : 0}
                  rawReturnsCount={Object.keys(selectedProduct?.raw_total || {})?.length ? selectedProduct?.raw_total?.returns_revenue : 0}
                  link={false}
                  isMobile={isMobile}
                  showRawData={showRawData}
                  withReturns={true}
                  isLineChart={isLineChartRevenue}
                  setIsLineChart={setIsLineChartRevenue}
      >
        <Chart data={
          selectedProduct?.charts ?
            Object.keys(selectedProduct?.charts).length ?
              [...selectedProduct?.charts?.revenue?.chart]
              :
              []
            :
            []
        }
               type={'revenue'}
               animation={false}
               isMobile={isMobile}
               withRawData={showRawData}
               withReturns={true}
               returnsData={selectedProduct?.charts?.revenue?.returns?.length ?
                 [...selectedProduct?.charts?.revenue?.returns]
                 :
                 []}
               rawReturnsData={selectedProduct?.charts?.revenue?.raw_data_returns?.length ?
                 [...selectedProduct?.charts?.revenue?.raw_data_returns]
                 :
                 []}
               rawData={selectedProduct?.charts?.revenue?.raw_data_chart?.length ?
                 [...selectedProduct?.charts?.revenue?.raw_data_chart]
                 :
                 []}
               chartType={!isLineChartRevenue && 'bar'}
        />
      </ChartBlock>
      <TrackerTable fetching={firsLoading || fetching || fetchingSpin}
                    onSort={handleSort}
                    pageSize={pageSize}
                    setPageSize={handleChangePageSize}
                    pageNumber={pageNumber}
                    setPageNumber={handleChangePageNumber}
                    totalProducts={selectedProduct?.table?.length}
                    data={selectedProduct?.table || []}
                    localData={data || []}
                    sortOrder={sortOrder}
                    isChanged={true}
                    withSummary={true}
                    report={'product'}
      />
      <Modal
        className={cls('change-modal custom-modal', {
          'store-products-opened': modal === 'storeProducts' || modal === 'storeApps',
        })}
        getContainer={() => document.getElementById('global-wrap')}
        open={Boolean(modal)}
        {...(isMobile ? { transitionName: '' } : null)}
        centered={!isMobile}
        closeIcon={
          <Icon role="icon" type="close_modal" color="#707BA0" opacity={1} />
        }
        width={modalBlocksWidth[modal]}
        footer={null}
        closable="true"
        onCancel={() => {
          setModal(null);
        }}
        destroyOnClose
      >
        <Spin size="large" spinning={fetching}>
          {modalBlocks[modal]}
        </Spin>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  theme: state.nav.theme,
  loading: state.salesTracker.loading,
  fetching: state.salesTracker.selectedProductDataLoading,
  checkLoading: state.salesTracker.checkLoading,
  fetchingSpin: state.salesTracker.fetchingSpin,
  error: state.salesTracker.error,
  selectedProduct: state.salesTracker.selectedProduct,
  storeProductsModalTableDataLoading: state.salesTracker.storeProductsModalLoading,
  storeProductsModalTableData: state.salesTracker.storeProductsModalTableData,
  view: state.salesTracker.view,
  breadcrumbs: state.breadcrumbs.totalBreadcrumbs,
  filterDates: state.salesTracker.filters.report_period,
  shopifyStore: state.shopifyStore,
  autoDS: state.autoDS,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  setView: (data) => dispatch(Creators.setView(data)),
  addBreadCrumb: (data) => dispatch(BreadcrumbsActions.addBreadCrumb(data)),
  removeBreadCrumb: () => dispatch(BreadcrumbsActions.removeBreadCrumb()),
  clearBreadCrumbs: () => dispatch(BreadcrumbsActions.clearBreadCrumbs()),
  getProductInfo: (data) => dispatch(Creators.getDetailedProductInfoRequest(data)),
  getProductInfoNew: (data) => dispatch(Creators.getDetailedProductInfoNewRequest(data)),
  getProducts: (data) => dispatch(Creators.getTrackingProductsRequest(data)),
  getDetailedProductInfoCheckRequest: (data) => dispatch(Creators.getDetailedProductInfoCheckRequest(data)),
  downloadProductReport: (data) => dispatch(Creators.getProductReportRequest(data)),
  getStoreProductsModal: (data) => dispatch(Creators.getStoreProductsModalRequest(data)),
  getStoreProductsModalNext: (data) => dispatch(Creators.getStoreProductsModalNextRequest(data)),
  resetStoreProductsModal: () => dispatch(Creators.resetStoreProductsModal()),
  setFilters: (data) => dispatch(Creators.setFilters(data)),
  setPeriod: (data) => dispatch(Creators.setCheckedList(data)),
  changeVisibleModalShopify: (data) => dispatch(ShopifyCreators.changeVisibleModalShopify(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductTrackerPage);
