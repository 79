import React from 'react';
import NumbersBreakdownBlock from '../../ProductDetailsPage/components/NumbersBreakdownBlock';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Divider, Spin } from 'antd';
import Modal from '../../../Components/Modal';
import utils from '../../../Utils/utils';
import Image from '../../../Components/Image';
import Images from '../../../Images';
import Icon from '../../../Icon';
import cls from 'classname';
import TooltipColumnIcon from './TooltipColumnIcon';
import './NumbersBreakdownDatabaseBlock.less';

export const NumbersBreakdownDatabaseBlockContent = ({ product }) => {
  const { t } = useTranslation();

  return (
    <div className={'collection-modal-content'}>
      <p className={'collection-modal-text'}>
        {
          t('Input the cost of good sold and shipping cost per item to get the numbers breakdown onn the product. Other numbers can also be customized to your needs.')
        }
      </p>
      <div className={'product-database-table-cell'}>
        <div className={'product-database-table-image'}
              style={{ cursor: 'default' }}
        >
          {
            product?.main_image ?
              <img src={utils.changeImageSize(product?.main_image, 124)}
                    onError={utils.addDefaultSrc}
                    alt=""
                    width="64"
                    height="64"
              />
              :
              <Image src={Images.defaultImg}
                      small={true}
              />
          }
        </div>
        <div className={'product-info-wrapper'}
              style={{ cursor: 'default' }}
        >
          <p className={'product-name'}>
            {product?.title}
            <span className="product-is-deleted">
                {
                  product?.is_deleted_from_st ?
                    <Icon role="button"
                          type={`unavailable_store_triangle`}
                          className={`unavailable-store-triangle`}
                          width={18}
                          height={18}
                          titleText={t('This store has been closed or paused.')}
                          tooltipProps={{
                            placement: 'right',
                            trigger: 'hover',
                            destroyTooltipOnHide: true,
                            overlayClassName: cls('details-tooltip', `unavailable-store-triangle-tooltip`),
                            getPopupContainer: () => document.getElementById('product-database-table-body'),
                          }}
                    />
                    :
                    null
                }
              </span>
          </p>
          <div className={'info-wrapper-text'}>
            <a href={`https://${product?.store?.original_domain}/products/${product?.handle}`}
                target={'_blank'}
                rel="noopener noreferrer"
            >
              <span className={'product-url'}>
                {
                  product?.store?.all_domains?.length > 0 ?
                    <>
                      {product?.store?.custom_domain}
                      <TooltipColumnIcon record={product} />
                    </>
                    :
                    product?.store?.custom_domain
                }
              </span>
            </a>
            <Divider type={'vertical'}
                      style={{ margin: '0 8px' }}
            />
            <span className={'product-variants'}>
              {product?.images} {product?.images === 1 ? 'Image' : 'Images'}
            </span>
            <Divider type={'vertical'}
                      style={{ margin: '0 8px' }}
            />
            <span className={'product-variants'}>
              {product?.variants} {product?.variants === 1 ? 'Variant' : 'Variants'}
            </span>
          </div>
        </div>
      </div>
      <NumbersBreakdownBlock product={product}
                              isMobile={false}
                              skeleton={false}
                              productDatabase={true}
      />
    </div>
  )
};

const NumbersBreakdownDatabaseBlock = (
  {
    product,
    visible,
    setModal,
    loading,
  }) => {

  const { t } = useTranslation();
  const isMobile = useSelector(store => store?.nav?.isMobile);

  return (
    <Modal handleOk={null}
           handleClose={() => {
             setModal(null);
           }}
           title={t('Price details')}
           isMobile={isMobile}
           width={828}
           className="collection-list-modal collection-modal shopify-connect numbers-breakdown-modal"
           open={visible}
           destroyOnClose
    >
      <Spin spinning={loading}>
        <NumbersBreakdownDatabaseBlockContent product={product}/>
        {
          isMobile ?
            null
            :
            <div style={{ height: 16 }} />
        }
      </Spin>
    </Modal>
  );
};

export default NumbersBreakdownDatabaseBlock;
