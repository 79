import React from 'react';

const Mastercard = () => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clipPath="url(#clip0_7546_10159)">
        <path d="M0 0H20V20H0V0Z" fill="black" />
        <path
          d="M7.55534 13.6654C8.03685 13.6654 8.51365 13.5705 8.95851 13.3863C9.40337 13.202 9.80758 12.9319 10.1481 12.5914C10.4885 12.2509 10.7586 11.8467 10.9429 11.4019C11.1272 10.957 11.222 10.4802 11.222 9.9987C11.222 9.51718 11.1272 9.04039 10.9429 8.59553C10.7586 8.15066 10.4885 7.74645 10.1481 7.40597C9.80758 7.06549 9.40337 6.79541 8.95851 6.61114C8.51365 6.42687 8.03685 6.33203 7.55534 6.33203C6.58288 6.33203 5.65025 6.71834 4.96261 7.40597C4.27498 8.09361 3.88867 9.02624 3.88867 9.9987C3.88867 10.9712 4.27498 11.9038 4.96261 12.5914C5.65025 13.2791 6.58288 13.6654 7.55534 13.6654Z"
          fill="#EB001B" />
        <path
          d="M12.444 13.6654C12.9255 13.6654 13.4023 13.5705 13.8472 13.3863C14.292 13.202 14.6963 12.9319 15.0367 12.5914C15.3772 12.2509 15.6473 11.8467 15.8316 11.4019C16.0158 10.957 16.1107 10.4802 16.1107 9.9987C16.1107 9.51718 16.0158 9.04039 15.8316 8.59553C15.6473 8.15066 15.3772 7.74645 15.0367 7.40597C14.6963 7.06549 14.292 6.79541 13.8472 6.61114C13.4023 6.42687 12.9255 6.33203 12.444 6.33203C11.4715 6.33203 10.5389 6.71834 9.85129 7.40597C9.16365 8.09361 8.77734 9.02624 8.77734 9.9987C8.77734 10.9712 9.16365 11.9038 9.85129 12.5914C10.5389 13.2791 11.4715 13.6654 12.444 13.6654Z"
          fill="#F79E1B" />
        <path
          d="M9.99957 12.7323C10.3847 12.3888 10.6927 11.9676 10.9034 11.4965C11.114 11.0254 11.2225 10.515 11.2218 9.99896C11.2225 9.4829 11.114 8.97252 10.9034 8.50142C10.6927 8.03031 10.3847 7.60914 9.99957 7.26562C9.61462 7.60928 9.30671 8.03047 9.09607 8.50155C8.88542 8.97262 8.77681 9.48293 8.77735 9.99896C8.77659 10.515 8.88512 11.0254 9.09578 11.4965C9.30643 11.9676 9.61445 12.3888 9.99957 12.7323Z"
          fill="#FF5F00" />
      </g>
      <defs>
        <clipPath id="clip0_7546_10159">
          <path
            d="M0 4C0 1.79086 1.79086 0 4 0H16C18.2091 0 20 1.79086 20 4V16C20 18.2091 18.2091 20 16 20H4C1.79086 20 0 18.2091 0 16V4Z"
            fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Mastercard;
