import React from 'react';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import utc from 'dayjs/plugin/utc';
import acc from 'accounting';
import './NavHeader.less';

dayjs.extend(utc);

const NavHeader = ({ mainInfo, navigate, loading, isMobile }) => {
  const { t } = useTranslation();
  const { id, mode } = useParams();
  const { search } = useLocation();
  const skeleton = mainInfo?.mainInfoLoading;

  const views = search?.includes('internal_shop_id')
    ? [
        {
          name: 'Overview',
          key: 'overview',
        },
        {
          name: 'Ads',
          key: 'all_ads',
          count: mainInfo?.ads_count,
        },
        {
          name: 'Products',
          key: 'products',
          sub: ['general', 'detailed'],
          count: mainInfo?.store_products_count,
        },
      ]
    : [
        {
          name: 'Overview',
          key: 'overview',
        },
        {
          name: 'Ads',
          key: 'all_ads',
          count: mainInfo?.ads_count,
        },
      ];

  if (skeleton) {
    return (
      <div className="ad-spot-nav-header">
        <div
          className="ad-spot-nav-header-btn-wrapper"
          style={{ gap: 20, margin: '13px 0' }}
        >
          <span className="link skeleton" style={{ width: 100, height: 20 }} />
          <span className="link skeleton" style={{ width: 100, height: 20 }} />
          <span className="link skeleton" style={{ width: 100, height: 20 }} />
        </div>
      </div>
    );
  }

  return (
    <div className="ad-spot-nav-header">
      <div className="ad-spot-nav-header-btn-wrapper">
        {views.map((el) => (
          <div
            className={cls('ad-spot-nav-header-btn', {
              active:
                el?.key === mode ||
                (el?.sub && el?.sub?.some((elem) => mode.includes(elem))),
            })}
            key={el?.key}
            onClick={() => {
              if (
                el?.key !== mode ||
                (el?.sub && el?.sub?.some((elem) => !mode.includes(elem)))
              ) {
                navigate(
                  `/ad-spot/${id}/${el?.key}/${search
                    .split('&')
                    .filter((s) => !s.includes('same'))
                    .join('&')}`,
                );
              }
            }}
          >
            {t(el?.name)}
            {el?.count && (
              <span className="ad-spot-nav-header-btn-count">{acc.formatNumber(el?.count)}</span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NavHeader;
