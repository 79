import React from 'react';

const AdItemProductsGeneralSkeleton = (
  {
    isMobile
  }
) => {
  return (
    <div className='fadspot-page-products-general'>
      <div className='fadspot-page-products-general-filters' style={{ display: "flex", flexDirection: "column" }}>
        <span className="link skeleton" style={{width: isMobile ? 69 : 80, height: 25, marginBottom: isMobile && 16}}/>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div style={{ display: "flex", width: isMobile && "100%", gap: 12, justifyContent: isMobile ? "space-between" : "flex-start" }}>
            <span className="link skeleton" style={{width: isMobile ? "70%": 400, height: 40}}/>
            <span className="link skeleton" style={{width: isMobile ? "30%" : 82, height: 40}}/>
          </div>
          {!isMobile && 
          <span className="link skeleton" style={{width: 328, height: 40, marginTop: 16}}/>}
        </div>
        {isMobile && <span className="link skeleton" style={{width: "100%", height: 40, margin: "16px 0"}}/>}
        <div style={{ display: "flex", alignItems: "center", flexDirection: isMobile ? "column" : "row", gap: 12 }}>
          {isMobile ? (
          <div style={{ display: "flex", width: "100%" }}>
            <span className="link skeleton" style={{ width: "100%", height: 36}}/>
          </div>
          ) : (
          <>
            <span className="link skeleton" style={{width: 85, height: 36}}/>
            <span className="link skeleton" style={{width: 111, height: 36}}/>
            <span className="link skeleton" style={{width: 140, height: 36}}/>
            <span className="link skeleton" style={{width: 193, height: 36}}/>
          </>
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: 12, marginTop: 12 }}>
          <span className="link skeleton" style={{width: 80, height: 24}}/>
          <span className="link skeleton" style={{width: 241, height: 24}}/>
        </div>
      </div>
      {
        isMobile ?
          <div style={{marginTop: 32, display: "flex", width: '100%', alignItems: 'center', justifyContent: "space-between", padding: "0 16px"}}>
            <span className="link skeleton" style={{width: '100%', height: 508}}/>
          </div>
          :
          <div style={{marginTop: 32, display: "flex", width: '100%', alignItems: 'center', justifyContent: "center", gap: 10, flexWrap: 'wrap'}}>
            {
              [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20].map(el => <span className="link skeleton" key={el} style={{width: 268, height: 508}}/>)
            }
          </div>
      }
    </div>
  );
};

export default AdItemProductsGeneralSkeleton;
