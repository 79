import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import { Row, Col, Divider, Switch, Spin, Modal, Collapse } from 'antd';

import ProductDetailsCard from '../../ProductDetailsPage/components/ProductDetailsCard';
import ButtonComponent from '../../../Components/Button';
import MinMaxInput from './MinMaxInput';
import Icon from '../../../Icon';

import LoadFilterPresetBlock from './LoadFilterPresetBlock';
import DeleteFilterPresetBlock from './DeleteFilterPresetBlock';
import SaveFilterPresetBlock from './SaveFilterPresetBlock';
import DropdownDateBlock from './DropdownDateBlock';
import { useSelector } from 'react-redux';
import { DefaultMsgSaga } from '../../../Components/Notification/notification-message';
import openNotification, { openNotificationWithIcon } from '../../../Components/Notification/index';
import InputProductFilter from './InputProductFilter';
import FiltersBlockSkeleton from './FiltersBlockSkeleton';
import DropdownBlockTest from "./DropdownBlockTest";
import dayjs from "dayjs";
import {ArrowSelectDown} from "../../../Icon/img";
import QuickSearch from '../../AdSpotPage/components/QuickSearch';
import './FiltersBlock.less';
import './DatabaseProducts.less';
import RenamePresetModal from '../../ShopifySearch/components/RenamePresetModal';
import PageOptions from './PageOptions';
const {Panel} = Collapse;

const minMaxInputsData = [
  {
    field: 'price',
    iconType: 'database_price',
    text: 'Price',
    tooltip: 'The price products are sold for in USD'
  }, {
    field: 'sales',
    iconType: 'database_sales',
    text: 'Monthly sales',
    tooltip: 'Monthly sales amount per product'
  }, {
    field: 'revenue',
    iconType: 'database_revenue',
    text: 'Monthly revenue',
    tooltip: 'Monthly revenue generated per product'
  }, {
    field: 'products',
    iconType: 'database_store_products',
    text: 'Store products',
    tooltip: 'Amount of product listings in the store'
  }, {
    field: 'images',
    iconType: 'database_product_images',
    text: 'Product images',
    tooltip: 'Amount of images the product listing has'
  }, {
    field: 'variants',
    iconType: 'database_variants',
    text: 'Variants',
    tooltip: 'Amount of variants the product listing has'
  }];

const switchFilterOptions = [
  {text: 'Only Dropshipping Products', key: 'only_dropshipping', disabled: false, tag: 'beta'},
  {text: 'Only POD Products', key: 'only_print', disabled: false, tag: 'beta'},
  {text: 'Exclude Unavailable Products', key: 'exclude_unavailable', disabled: false},
];

const keyWords = ['Title', 'Description', 'Domain'];

const dateOptions = [
  { id: 0, name: 'Last 7 days' },
  { id: 1, name: 'Last 30 days' },
  { id: 2, name: 'Last 90 days' },
  { id: 3, name: 'Last 6 months' },
  { id: 4, name: 'Last 12 months' },
];

export const FiltersBlock = (
  {
    fetching,
    skeleton,
    isMobile,
    theme,
    savePreset,
    deletePreset,
    updatePreset,
    getProducts,
    showTable,
    setShowTable,
    pageSize,
    setPageSize,
    pageNumber,
    setPageNumber,
    sortOrder,
    setSortOrder,
    isChanged,
    setIsChanged,
    setEnabled,
    disabledSearch,
    currentScroll,
    productsLoading,
    getPresets,
    setView,
    view,
    setProductsAdd,
    productsAdd,
    resetProducts,
    rerenderKey,
    resetFilters,
    firstLoading,
    checkedList,
    setCheckedList,
    switchFilters,
    setSwitchFilters,
    inputFilters,
    setInputFilters,
    minMaxFilters,
    setMinMaxFilters,
    dropdownFilters,
    setDropdownFilters,
    loadedPreset,
    setLoadedPreset,
    inputText,
    setInputText
  }) => {

  const quickSearch = useSelector(store => store.productDatabase.presets.quick_search) || [];

  const currencyOptions = useSelector(store => store.productDatabase.filters.store_currency) || [];
  const domainOptions = useSelector(store => store.productDatabase.filters.domain_tld) || [];
  const languageOptions = useSelector(store => store.productDatabase.filters.store_language) || [];
  const plainOptions = useSelector(store => store.productDatabase.filters.categories) || [];

  const [showAdvanced, setShowAdvanced] = useState(false);

  const [visibleModal, setVisibleModal] = useState(null);
  const [showFilters, setShowFilters] = useState(false);

  //show/hide modal
  const [visible, setVisible] = useState(false);
  //initial search
  const [defaultSearch, setDefaultSearch] = useState(true);
  //set modal type
  const [modal, setModal] = useState('');
  //preset to delete from user presets
  const [recordToDelete, setRecordToDelete] = useState(null);

  const { t } = useTranslation();

  //if filters were changed from default
  const checkIfChanged = () => {
    if (
      Object.keys(switchFilters).some((key) => {
        if (key === 'exclude_unavailable') return switchFilters[key] !== true;
        return switchFilters[key] !== false;
      }) ||
      Object.keys(inputFilters).some((key) => inputFilters[key].include !== null || inputFilters[key].exclude !== null) ||
      Object.keys(minMaxFilters).some((key) => minMaxFilters[key].min !== null || minMaxFilters[key].max !== null) ||
      checkedList.length ||
      Object.keys(dropdownFilters).some((key) => {
        if (key === 'store_created_at' || key === 'product_created_at') {
          return dropdownFilters[key].min !== null || dropdownFilters[key].max !== null || dropdownFilters[key].id !== null;
        } else return dropdownFilters[key] !== 'All';
      })
    ) {
      if (!isChanged) setIsChanged(true);
    } else if (isChanged) setIsChanged(firstLoading);
  };

  //function to parse preset period dynamically
  const parseDates = key => {
    let quantity = dropdownFilters?.[key]?.id?.name?.split(' ')[1];
    let value = dropdownFilters?.[key]?.id?.name?.split(' ')[2];
    let max = new Date(dayjs().endOf('day')).toISOString().split('T')[0];
    let min = new Date(dayjs().endOf('day').subtract(quantity, value)).toISOString().split('T')[0];
    return {min: min, max: max}
  }

  //search api call
  const applyFilters = (new_search=false, data=null) => {
    //do not search without categories
    if ((!checkedList.length || disabledSearch) && !Boolean(data)) return;
    if (new_search) setDefaultSearch(false);

    const dataToSend = data ?
      data
      :
      {
        productsAdd,
        page: pageNumber,
        page_size: pageSize,
        ordering: [sortOrder],
        new_search: new_search,
        filters: {
          categories: checkedList,
          ...switchFilters,
          ...inputFilters,
          ...minMaxFilters,
          product_created_at: dropdownFilters?.product_created_at?.id ? parseDates('product_created_at') : {
            min: dropdownFilters?.product_created_at?.min,
            max: dropdownFilters?.product_created_at?.max
          },
          store_created_at: dropdownFilters?.store_created_at?.id ? parseDates('store_created_at') : {
            min: dropdownFilters?.store_created_at?.min,
            max: dropdownFilters?.store_created_at?.max
          },
          language: dropdownFilters.language === 'All' ? null : dropdownFilters.language,
          currency: dropdownFilters.currency === 'All' ? null : dropdownFilters.currency,
          domain_tld: dropdownFilters.domain_tld === 'All' ? null : dropdownFilters.domain_tld,
          global_search: inputText,
        },
      };

    getProducts(dataToSend);
    //hide banner, show table
    setShowTable(true);
    setEnabled(true);
    currentScroll.current = null;
  };

  //search when pagination/loaded preset changed except 1st render
  useEffect(() => {
    if (defaultSearch) {
      applyFilters(false, {
        "page": pageNumber,
        "page_size": pageSize,
        "ordering": [sortOrder],
        "timestamp": Math.floor((new Date().getTime())/3600000),
        productsAdd,
      })
    }
    else {
      applyFilters();
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [pageNumber, pageSize, sortOrder]);

  useEffect(() => {
    setShowFilters(false);
    setPageSize(50);
    setPageNumber(1);
    setSortOrder(null);
    setProductsAdd(view === 'overview');
  }, [view]);

  useEffect(() => {
    if (firstLoading.current) firstLoading.current = false;
    else {
      if (loadedPreset !== null) {
        applyFilters(true);
        if (!loadedPreset?.key?.includes('defined')) setLoadedPreset(null);
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [loadedPreset]);

  //check filters changes, disable/enable search btn
  useEffect(() => {
    checkIfChanged();
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [switchFilters, minMaxFilters, inputFilters, checkedList, dropdownFilters]);

  useEffect(() => {
    setCheckedList(plainOptions.map(el => el.id));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [plainOptions])

  //set filters values on preset load
  const loadPreset = (data) => {
    if (disabledSearch) {
      openNotification({
        message: t('You have reached your daily search limit, upgrade to unlock unlimited searches'),
        style: { minWidth: '716px' },
        type: 'error',
      });
    } else {
      let name = data.name;
      Object.keys(data.value).forEach(val => {
        if (val === 'categories') setCheckedList(() => ([...data.value[val]]));
        if (val === 'switchFilters') setSwitchFilters(state => ({...state, ...data.value[val]}));
        if (val === 'inputFilters') setInputFilters(state => ({...state, ...data.value[val]}));
        if (val === 'minMaxFilters') setMinMaxFilters(state => ({...state, ...data.value[val]}));
        if (val === 'dropdownFilters') setDropdownFilters(state => ({...state, ...data.value[val]}));
      });
      openNotificationWithIcon({
        style: {minWidth: '716px'},
        className: 'notification notification--save',
        message: (
          <DefaultMsgSaga
            title={t('The _name_ preset has been loaded', {name: name})}
            icon="notification_success"
            iconOutline={true}
            withTranslate={true}
            preset={name}
          />
        ),
      });
    }
  };

  //modal types
  const modalBlocks = {
    loadFilterPreset: <LoadFilterPresetBlock fetching={fetching}
                                             theme={theme}
                                             setVisible={setVisible}
                                             setRecordToDelete={setRecordToDelete}
                                             setModal={setModal}
                                             setPageSize={setPageSize}
                                             setPageNumber={setPageNumber}
                                             updatePreset={updatePreset}
                                             loadPreset={loadPreset}
                                             setLoadedPreset={setLoadedPreset}
                                             setSortOrder={setSortOrder}
                                             category={'productDatabase'}
                                             getPresets={getPresets}
    />,
    deleteFilterPreset: <DeleteFilterPresetBlock setModal={setModal}
                                                 deleteFilter={deletePreset}
                                                 record={recordToDelete}
    />,
    saveFilterPreset: <SaveFilterPresetBlock setVisible={setVisible}
                                             saveFilterPreset={savePreset}
                                             filters={{
                                               categories: [...checkedList],
                                               switchFilters: { ...switchFilters },
                                               inputFilters: { ...inputFilters },
                                               minMaxFilters: { ...minMaxFilters },
                                               dropdownFilters: { ...dropdownFilters },
                                             }}
                                             category={'productDatabase'}
    />,
    renameFilterPreset: (
      <RenamePresetModal
        setModal={setModal}
        visible={modal === 'renameFilterPreset'}
        record={recordToDelete}
        isMobile={isMobile}
        modal={modal}
        updatePreset={updatePreset}
        modalRename="renameFilterPreset"
        modalLoad="loadFilterPreset"
      />
    ),
  };

  const modalBlocksWidth = {
    loadFilterPreset: 450,
    deleteFilterPreset: 450,
    saveFilterPreset: 450,
  };

  //switch filters handler
  const onChangeSwitch = (value, name) => {
    if (value) {
      if (name === 'only_dropshipping') {
        setSwitchFilters(state => ({ ...state, [name]: value, 'only_print': false }))
      } else if (name === 'only_print') {
        setSwitchFilters(state => ({ ...state, [name]: value, 'only_dropshipping': false }))
      } else setSwitchFilters(state => ({ ...state, [name]: value }))
    }
    else setSwitchFilters(state => ({ ...state, [name]: value }));
  };

  //tag inputs include/exclude handler
  const onChangeInputFilter = (e, field, type) => {
    e.persist();
    setInputFilters(state => ({ ...state, [field]: { ...state[field], [type]: state[field][type] === null ? e.target.value.split(',')[0].trim() + ',' : state[field][type] + ',' + e.target.value.split(',')[0].trim() } }));
  };

  const onPasteInputFilter = (e, field, type) => {
    e.persist();
    let val = e.clipboardData.getData('text').split(',');
    for (let i = 0; i < val.length; i++) {
      setInputFilters(state => ({ ...state, [field]: { ...state[field], [type]: state[field][type] === null ? val?.[i].trim() + ',' : [...new Set([...state[field][type].split(','), val?.[i].trim()])].join(',') } }));
    }
  };

  const onChangeMinMaxFilter = useCallback((e, field, type) => {
    e.persist();
    const parsedValue = e.target.value.replace(/\$\s?|(,*)/g, '');
    const reg = /^-?\d*(\.\d*)?$/;
    if ((!isNaN(parsedValue) && reg.test(parsedValue)) || parsedValue === '') {
      setMinMaxFilters(state => ({ ...state, [field]: { ...state[field], [type]: e.target.value.trim().length ? +parsedValue : null } }));
    }
  }, []);

  const handleSearch =(new_search=false) => {
    resetProducts();
    if (pageSize === 50 && pageNumber === 1) applyFilters(new_search);
    else {
      setPageSize(50);
      setPageNumber(1);
      setDefaultSearch(false);
    }
  }

  if (skeleton) return <FiltersBlockSkeleton isMobile={isMobile}/>;
  return (
    <>
      <PageOptions setShowFilters={setShowFilters}
                   	setModal={setModal}
                    setVisible={setVisible}
                    visible={visible}
                    checkedList={checkedList}
                    plainOptions={plainOptions}
                    isChanged={isChanged}
                    isMobile={isMobile}
                    showFilters={showFilters}
                    disabledSearch={disabledSearch}
                    showTable={showTable}
                    view={view}
                    setView={setView}
                    inputText={inputText}
                    setInputText={setInputText}
                    applyFilters={applyFilters}
                    setPageSize={setPageSize}
                    setPageNumber={setPageNumber}
                    setSortOrder={setSortOrder}
                    setDefaultSearch={setDefaultSearch}
                    productsLoading={productsLoading}
                    resetProducts={resetProducts}
                    resetFilters={resetFilters}
      />
      {
        quickSearch?.length ?
          <QuickSearch loadPreset={loadPreset}
                       setLoadedPreset={setLoadedPreset}
                       loadedPreset={loadedPreset}
                       skeleton={false}
                       isMobile={isMobile}
                       data={[...quickSearch]}
          />
          :
          null
      }
      <div className={cls('product-database-filter-wrapper', {
            active: showFilters,
          })}>
        <ProductDetailsCard header={null}
                            skeleton={skeleton}
                            withLine={!isMobile}
                            headerClassName={'filters-header'}
                            bodyClassName={'filters-body pd-body'}
        >
          <Row className="product-main-block_wrapper" gutter={[48, 0]}>
            <Col xs={24} md={12} className="block-with-label block-with-label-custom">
              <div className="block-with-label_label">
                {t('Filters')}
                <Icon
                  type="attention_outline"
                  role="button"
                  titleText={'Min/Max ' + t('filter options')}
                  tooltipProps={{
                    placement: 'right',
                    destroyTooltipOnHide: true,
                    trigger: isMobile ? 'click' : 'hover',
                  }}
                />
              </div>
              {
                minMaxInputsData
                  .map(el => (
                    <MinMaxInput data={minMaxFilters}
                                field={el.field}
                                iconType={el.iconType}
                                text={el.text}
                                tooltip={el.tooltip}
                                key={el.iconType}
                                onChange={onChangeMinMaxFilter}
                                theme={theme}
                    />)
                  )
              }
            </Col>

            <div className="categories-wrapper">
            {isMobile ? <Divider style={{ margin: "12px 0 24px" }}/> : null}
              <span className="filters-title">
                {t('Categories')}
                <sup className="important">*</sup>
                <span className="categories-tag">
                  beta
                </span>
              </span>
              <div className="categories-filter-wrapper">
                <div className={cls('categories-filter', {
                  active: checkedList?.length === plainOptions?.length,
                })}
                  onClick={() => setCheckedList(checkedList?.length === plainOptions?.length ? [] : [...plainOptions?.map(el => el?.id)])}
                >
                  <span className="categories-filter-checkbox" />
                  <span className="categories-filter-label">
                    {
                      checkedList?.length === plainOptions?.length ?
                        t('Deselect all')
                        :
                        t('Select all')
                    }
                  </span>
                </div>
                {
                  plainOptions.map(el => (
                    <div className={cls('categories-filter', {
                      active: checkedList?.includes(el?.id),
                    })}
                      onClick={() => {
                        if (checkedList?.includes(el?.id)) {
                          let res = [...checkedList]?.filter(elem => elem !== el?.id);
                          setCheckedList(res);
                        } else {
                          setCheckedList(prev => ([...prev, el?.id]));
                        }
                      }}
                      key={el?.value}
                    >
                      <span className="categories-filter-checkbox" />
                      <span className="categories-filter-label">
                        {
                          t(el?.value)
                        }
                      </span>
                    </div>
                  ))
                }
              </div>
            </div>

            <Collapse collapsible={'header'} ghost={true}>
              <Panel key={1} showArrow={false} header={
                <div className="advanced-filters-toggle-wrapper" onClick={() => setShowAdvanced(!showAdvanced)}>
                  <div className="advanced-filters-toggle-divider"/>
                  <span className={cls('advanced-filters-toggle')}
                  >{t('Advanced Filters')} <ArrowSelectDown/> </span>
                  <div className="advanced-filters-toggle-divider"/>
                </div>
              }>
              <div className="dropdowns-wrapper">
                  <DropdownDateBlock
                    label={'Product creation date'}
                    id={'product_created_at'}
                    iconType={'database_calendar'}
                    data={dateOptions}
                    dropdownFilters={dropdownFilters}
                    setDropdownFilters={setDropdownFilters}
                    isMobile={isMobile}
                    hideTimeZone={true}
                    withName
                    visibleModal={visibleModal}
                    setVisibleModal={setVisibleModal}
                    tooltip={t('Select a date range to find products created within that period.')}
                    topIcon
                  />

                  <DropdownDateBlock
                    label={'Store creation date'}
                    id={'store_created_at'}
                    iconType={'database_calendar'}
                    data={dateOptions}
                    dropdownFilters={dropdownFilters}
                    setDropdownFilters={setDropdownFilters}
                    isMobile={isMobile}
                    hideTimeZone={true}
                    withName
                    visibleModal={visibleModal}
                    setVisibleModal={setVisibleModal}
                    tooltip={t('Select a date range to find stores created within that period.')}
                    topIcon
                  />

                  <DropdownBlockTest
                    label={'Store language'}
                    id={'language'}
                    dropdownFilters={dropdownFilters}
                    setDropdownFilters={setDropdownFilters}
                    iconType={'database_globe'}
                    searchPlaceholder={'Search for language...'}
                    data={languageOptions}
                    tooltip={t('Select the language of the store to refine results.')}
                    topIcon
                  />

                  <DropdownBlockTest
                    label={'Store currency'}
                    id={'currency'}
                    dropdownFilters={dropdownFilters}
                    setDropdownFilters={setDropdownFilters}
                    iconType={'database_revenue'}
                    searchPlaceholder={'Search for currency...'}
                    data={currencyOptions}
                    tooltip={t('Select the currency of the store to refine results.')}
                    topIcon
                  />

                  <DropdownBlockTest
                    label={'Domain TLD'}
                    id={'domain_tld'}
                    dropdownFilters={dropdownFilters}
                    setDropdownFilters={setDropdownFilters}
                    iconType={'database_domain'}
                    searchPlaceholder={'Search for TLD...'}
                    data={domainOptions}
                    tooltip={t(`Filter stores by URL's top-level domain.`)}
                    topIcon
                  />
              </div>
                <div className="filters-text-wrapper">
                  {isMobile ? <Divider style={{ margin: "12px 0 24px" }}/> : null}
                  <span className="filters-title">
                    {t('Filters by keywords')}
                    <Icon type={'attention_outline'}
                      role="button"
                      width={16}
                      height={16}
                      tooltipProps={{
                        trigger: 'hover',
                        placement: 'right',
                        overlayClassName: 'filters-title-tooltip',
                        getPopupContainer: (trigger) => trigger.parentNode,
                      }}
                      titleText={t('Filters by keywords')}
                    />
                  </span>
                  {isMobile ? null : <span className="filters-description">{t('To add more than 1 keywords - separate words by commas.')}</span>}
                </div>
                <div gutter={[24, 0]} style={{display: 'flex', marginBottom: isMobile ? 12 : 24, flexDirection: isMobile ? 'column' : 'row', padding: isMobile ? '0 24px' : 0}}>
                  <Col xs={24} md={12} style={{maxWidth: isMobile ? '100%' : '50%', paddingRight: isMobile ? 0 : 12, paddingLeft: isMobile ? 0 : 24}}>
                    <div className="input-filter-wrapper">
                      {keyWords.map(el =>
                        <InputProductFilter key={rerenderKey.current + 'include_' + el.toLowerCase()}
                                            el={el}
                                            onChangeInputFilter={onChangeInputFilter}
                                            onPasteInputFilter={onPasteInputFilter}
                                            inputFilters={inputFilters}
                                            setInputFilters={setInputFilters}
                                            isPd={true}
                                            placeholder='Enter keywords...'
                                            type={'include'}/>
                      )}
                    </div>
                  </Col>

                  <Col xs={24} md={12} style={{maxWidth: isMobile ? '100%' : '50%', paddingLeft: isMobile ? 0 : 12, paddingRight: isMobile ? 0 : 24, marginTop: isMobile ? 12 : 0}}>
                    <div className="input-filter-wrapper">
                      {keyWords.map(el =>
                        <InputProductFilter key={'exclude_' + el.toLowerCase()}
                                            el={el}
                                            onChangeInputFilter={onChangeInputFilter}
                                            onPasteInputFilter={onPasteInputFilter}
                                            inputFilters={inputFilters}
                                            setInputFilters={setInputFilters}
                                            placeholder='Enter keywords...'
                                            type={'exclude'}/>,
                      )}
                    </div>
                  </Col>
                </div>
              </Panel>
            </Collapse>


            <Col xs={24} md={24}>
              <div className="switch-wrapper" style={{ marginBottom: isMobile && 16 }}>
                <div className="switch-options-wrapper">
                  {switchFilterOptions.map(el =>
                    <div key={el.key + 1} className="switch-option">
                      <Switch checked={switchFilters[el.key]}
                              name={el.key}
                              key={el.key}
                              onChange={(state) => onChangeSwitch(state, el.key)}
                              disabled={el.disabled}
                      />
                      <p onClick={() => onChangeSwitch(!switchFilters[el.key], el.key)}
                            style={{cursor: 'pointer', pointerEvents: el.disabled ? 'none': 'unset'}}
                      >
                        {t(el.text)}
                        {
                          el?.tag ?
                            <span className={'switch-option-tag'}>{el?.tag}</span>
                            :
                            null
                        }
                      </p>
                    </div>)}
                </div>
                <div className="switch-button-wrapper">
                  <ButtonComponent className={'switch-button-reset'}
                                  text={t('Reset filter')}
                                  onClick={resetFilters}
                  />
                  <ButtonComponent className={'switch-button-apply'}
                                  text={t('Search')}
                                  onClick={() => handleSearch(true)}
                                  disabled={disabledSearch || !checkedList.length || productsLoading}
                  />
                </div>
              </div>
            </Col>
          </Row>

          <Modal
            className="change-modal custom-modal"
            getContainer={() => document.getElementById('global-wrap')}
            {...(isMobile ? { transitionName: '' } : null)}
            open={visible}
            centered={!isMobile}
            closeIcon={
              <Icon role="icon" type="close_modal" color="#707BA0" opacity={1}/>
            }
            width={modalBlocksWidth[modal]}
            footer={null}
            closable="true"
            onCancel={() => setVisible(false)}
            destroyOnClose
          >
            <Spin size="large" spinning={fetching}>
              {modalBlocks[modal]}
            </Spin>
          </Modal>
        </ProductDetailsCard>
      </div>
      {showFilters   && <Divider style={{ margin: 0 }}/>}
    </>
  );
};

export default FiltersBlock;
