import React from 'react';
import {useTranslation} from "react-i18next";
import Creators from "./reducer";
import Modal from "../../Components/Modal";
import {connect} from "react-redux";

const ModalAutoDSUnavailable = ({isMobile, autoDS, changeVisibleModalAutoDS}) => {

  const { isVisibleModal } = autoDS;
  const { t } = useTranslation();


  const closeModalAutoDS = () => changeVisibleModalAutoDS({isVisibleModal: null, importProductId: null})

  const handleClose = () => {
    if (isVisibleModal === 'import_unavailable') closeModalAutoDS();
  }

  return (
    <Modal
      className={'autods-import-unavailable'}
      title={t('Import Unavailable')}
      open={isVisibleModal === 'import_unavailable'}
      width={596}
      handleOk={() => {
      }}
      handleClose={handleClose}
      isMobile={isMobile}
      destroyOnClose={true}
      footer={false}
    >
        <p>
          {t('Please create a store first to import products to AutoDS.')}
        </p>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state.nav.isMobile,
  autoDS: state.autoDS,
});

const mapDispatchToProps = (dispatch) => ({
  changeVisibleModalAutoDS: (data) => dispatch(Creators.changeVisibleModalAuto(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalAutoDSUnavailable);
