import React from 'react';
import {useTranslation} from "react-i18next";
import { Divider, Spin } from 'antd';
import ButtonComponent from "../Button";
import './CancelTrialModal.less';
import Images from "../../Images";
import cls from "classname";
import acc from "accounting";
import utils from "../../Utils/utils";
import DiscountCard from "../DiscountCard";

const CancelTrialModal = (
  {
    isMobile,
    setSubModal,
    user,
    callBack,
    fetching,
    currentSubscription,
    priceType,
    isCurrentPlan,
    discount,
    error,
    discountFetching,
    handleCheckDiscount,
    handleClearError,
    handleClearDiscount,
    setModal
  }
) => {

  const {t} = useTranslation();
  let discountCash = 0;

  let price = currentSubscription?.plan?.price * utils.multiplyPriceType?.[currentSubscription?.plan?.price_type?.id];
  let billing = isCurrentPlan ? currentSubscription?.plan?.price_type?.value : t(utils.toUpperLatter(priceType));

  if (discount) {
    discountCash = discount.type === 'value' ? discount.value : (price / 100) * discount.value;
  }

  return (
      <Spin spinning={fetching}>
        <div className='cancel-sub-wrapper cancel-trial-wrapper'>
          <div className="cancel-sub-title">
            {t('Confirmation')}
          </div>
          <div className="cancel-sub-text">
            <p>
              {t('Skip the trial and pay now to remove all trial limitations')}
            </p>
          </div>

          <div className={'cancel-sub-plan-info'}>
            <span className={'cancel-sub-plan-info-title'}>
              {currentSubscription?.plan?.plan?.name}
            </span>
            <Divider type={'horizontal'} style={{margin: '16px 0'}} />
            <div className={'cancel-sub-plan-info-stats'}>
              <span className={'cancel-sub-plan-info-key'}>{t('Billing')}</span>
              <span className={'cancel-sub-plan-info-key'}>{t('Amount')}</span>
            </div>
            <div className={'cancel-sub-plan-info-stats'}>
              <span className={'cancel-sub-plan-info-value'}>{billing}</span>
              <span className={'cancel-sub-plan-info-value'}>
                {`$${acc.formatNumber(discount ? (price - discountCash) : currentSubscription?.next_payment_amount / 100, discount ? 2 : (currentSubscription?.next_payment_amount / 100) === Math.floor(currentSubscription?.next_payment_amount / 100) ? 0 : 2, ',', ',')}`}
              </span>

            </div>
          </div>

          <DiscountCard
            key={'discount'}
            onSubmit={handleCheckDiscount}
            error={error}
            clearError={handleClearError}
            discount={discount}
            clearDiscount={handleClearDiscount}
            fetching={discountFetching}
            style={{padding: 0, marginTop: 16}}
            isMobile={isMobile}
            priceType={priceType}
          />

          <div className={'collection-modal-btn-wrapper'}>
            <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                             onClick={() => {
                               setModal(false);
                               setSubModal('');
                             }}
                             text={t('Keep trial')}
            />
            <ButtonComponent text={t('Pay now')}
                             onClick={() => {
                               if (discount) {
                                 if ((price - discountCash) > 0) {
                                   if (user?.card || (
                                     discount?.duration_type === "forever" &&
                                     discount?.type === "percent" &&
                                     +discount?.value === 100
                                   )) {
                                     callBack({discount_code: discount?.id})
                                   } else setSubModal('addCard')
                                 } else callBack({discount_code: discount?.id})
                               } else {
                                 if (currentSubscription?.next_payment_amount > 0) {
                                   if (user?.card || (
                                     discount?.duration_type === "forever" &&
                                     discount?.type === "percent" &&
                                     +discount?.value === 100
                                   )) {
                                     callBack({discount_code: discount?.id})
                                   } else setSubModal('addCard')
                                 } else callBack({discount_code: discount?.id})
                               }
                             }}
                             className='collection-modal-btn collection-modal-btn-save'
            />
          </div>
          {
            isMobile ?
              null
              :
              <div style={{ height: 16 }} />
          }
        </div>
      </Spin>
  );
};

export default CancelTrialModal;
