import React from 'react';

const FiltersBlockSkeleton = ({isMobile=false}) => {
  return (
    <>
      <div className={'page-options-wrapper'}>
      <div className="search-options">
          <div className="search">
            <span className={'link skeleton'}
                  style={{ width: 400, height: 40 }}
            />
            <span className={'link skeleton'}
                  style={{ width: 70, height: 40 }}
            />
          </div>
          <span className={'link skeleton'}
                style={{ width: 92, height: 40 }}
          />
        </div>
        <div className={'view-options'}>
          <span className={'link skeleton'}
                style={{ width: isMobile ? 'calc(100% - 129px - 72px - 24px)' : 129, height: 40 }}
          />
          {
            isMobile ?
              <span className={'link skeleton'}
                    style={{ width: 129, height: 40 }}
              />
              :
              null
          }
          <span className={'link skeleton'}
                style={{ width: 72, height: 40 }}
          />
        </div>
      </div>
      <div className={'fadspot-page-quick-search-wrapper skeleton'}>
        {isMobile ? null : (
          <span className={'fadspot-page-quick-search-title'}>
            <span className={'link skeleton'} style={{ width: 92, height: 20, display: "block" }} />
          </span>
        )}
        <div className="fadspot-page-quick-search-items" style={{ flexDirection: "row" }}>
          {
            [1, 2, 3].map(el => (
              <span key={el} className={'link skeleton'}
                    style={{ width: isMobile ? '33.3%' : 101 + el * 10, height: 32 }} />
            ))
          }
        </div>
      </div>
    </>
  )
}

export default FiltersBlockSkeleton
