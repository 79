import React from 'react';
import ButtonComponent from "../Button";
import {Divider, Spin} from "antd";
import {useTranslation} from "react-i18next";
import './UnpauseModal.less';
import { useSelector } from 'react-redux';

const UnpauseModal = ({callBack, fetching}) => {

  const {t} = useTranslation();
  const isMobile = useSelector(state => state.nav.isMobile);

  return (
    <Spin spinning={fetching}>
      <div className='cancel-sub-wrapper unpause-sub-wrapper'>
        <div className="cancel-sub-title">
          {t('Reactivate Paused Membership')}
        </div>
        <div className="cancel-sub-text">
          <p>{
            t('To access all features at Dropship again, press on the Confirm button below to reactivate your paused membership.')}</p>
        </div>
        <Divider type={'horizontal'} style={{margin: '0 auto 24px'}} />
          <ButtonComponent text={t('Confirm')}
                           onClick={() => {
                             callBack();
                           }}
                           className='unpause-sub-button'
                           style={{width: 'calc(100% - 32px)', margin: '0 auto'}}
          />
      </div>
      {
        isMobile ?
          null
          :
          <div style={{ height: 16 }} />
      }
    </Spin>
  );
};

export default UnpauseModal;
