import React from 'react';
import { useSelector } from 'react-redux';

const DatabaseRevenue = (props) => {
  const theme = useSelector((state) => state?.nav?.theme);
  return (
    <svg
      width={props.width ? props.width : '20'}
      height={props.height ? props.height : '20'}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0001 6.14616V5.28968M10.0001 13.8545V14.711M11.8548 7.43088C11.4846 6.91888 10.7926 6.5744 10.0001 6.5744H9.76217C8.71101 6.5744 7.85888 7.25611 7.85888 8.09703V8.1624C7.85888 8.7639 8.28367 9.31376 8.95616 9.58276L11.044 10.4179C11.7165 10.6869 12.1413 11.2368 12.1413 11.8382C12.1413 12.7153 11.2526 13.4263 10.1563 13.4263H10.0001C9.20753 13.4263 8.51556 13.0818 8.14533 12.5698M17.7084 10.0003C17.7084 14.2575 14.2573 17.7087 10.0001 17.7087C5.74289 17.7087 2.29175 14.2575 2.29175 10.0003C2.29175 5.74313 5.74289 2.29199 10.0001 2.29199C14.2573 2.29199 17.7084 5.74313 17.7084 10.0003Z"
        stroke={theme === 'light' ? "#A1AAC8" : "rgba(255, 255, 255, 0.26)"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default DatabaseRevenue;
