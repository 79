import React from 'react';

const StoreTrackerPageCompetitors = () => {
  return (
    <div>

    </div>
  );
};

export default StoreTrackerPageCompetitors;
