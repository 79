import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import cls from 'classname';
import Icon from '../../../Icon';
import './CascadingDropdown.less';

const PresetsDropdown = ({
  isMobile,
  setVisible,
  setModal,
  changedFilters,
  disabled,
}) => {
  const { t } = useTranslation();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  if (isMobile) return null;

  const handleVisibleChange = () => {
    setIsDropdownOpen((prevState) => !prevState);
  };

  return (
    <div className={cls("cascading-wrapper fadspot-page-presents-dropdown", {
      "disabled": disabled,
    })}>
      <Dropdown
        overlayClassName={'cascading-dropdown-wrapper'}
        getPopupContainer={(trigger) => trigger.parentNode}
        onOpenChange={handleVisibleChange}
        placement={'bottomRight'}
        trigger={['click']}
        open={isDropdownOpen}
        dropdownRender={() => (
          <div className="cascading-dropdown-menu-wrapper presets-menu-wrapper">
            <ul className="cascading-dropdown-menu">
              <li
                className={'cascading-dropdown-menu-item'}
                onClick={() => {
                  setVisible(true);
                  setIsDropdownOpen(false);
                  setModal('loadFilterPreset');
                }}
              >
                <span>
                  <Icon role={'icon'} type={'load_preset'} />
                  {t('Load preset')}
                </span>
              </li>
              <li
                className={cls('cascading-dropdown-menu-item', {
                  'disabled': !changedFilters,
                })}
                onClick={() => {
                  if (changedFilters) {
                    setVisible(true);
                    setIsDropdownOpen(false);
                    setModal('saveFilterPreset');
                  }
                }}
              >
                <span>
                  <Icon role={'icon'} type={'save_preset'} />
                  {t('Save this search as a preset')}
                </span>
              </li>
            </ul>
          </div>
        )}
      >
        <div
          className={cls('cascading-dropdown', {
            'ant-dropdown-open': isDropdownOpen,
          })}
        >
          <span className={'cascading-dropdown-value'}>
            <Icon role={'icon'} type={'presets'} />
            <span>{t('Presets')}</span>
            <Icon role={'icon'} type={'arrow_select_down'} />
          </span>
        </div>
      </Dropdown>
    </div>
  );
};

export default PresetsDropdown;
