import React from 'react';
import cls from 'classname';
import { Divider } from 'antd';
import SwipeableButton from '../../Components/ButtonSwipeable';
import ButtonComponent from "../../Components/Button";

export const footerModal = ({
  isMobile,
  handleOk,
  handleCancel,
  textBtnOk,
  isDisabledBtnOk,
  textBtnCancel,
  deleteType,
  swipeBtn,
  textSwipeBtn,
  bigBtn,
  textBigBtn,
  textBtnCancelNew,
  upgradeConfirm,
  handleClose,
  ...props
}) => {
  if (bigBtn) {
    return (
      <div className="modal-footer-sub-btn-wrapper">
        {
          !upgradeConfirm && <ButtonComponent text={textBtnCancelNew}
                                              onClick={handleClose}
                                              disabled={isDisabledBtnOk}
                                              className='collection-modal-btn collection-modal-btn-cancel'
          />
        }
        <ButtonComponent
          text={textBigBtn}
          onClick={handleOk}
          disabled={isDisabledBtnOk}
          className={cls('collection-modal-btn collection-modal-btn-save', {
            'red': deleteType,
            'wide': upgradeConfirm
          })}
        />
      </div>
    )
  }
  if (swipeBtn) {
    return [
      <SwipeableButton
        key="submit"
        text={textSwipeBtn}
        text_unlocked="Confirmed"
        onSuccess={handleOk}
        deleteType={deleteType}
        onFailure={() => {}}
        isMobile={isMobile}
      />,
    ];
  }
  if (isMobile) {
    return (
      <>
        <Divider type={'horizontal'}
                 style={{ margin: '16px 0' }}
        />
        <div className="double-btn-wrp">
          <ButtonComponent
            className={cls('collection-modal-btn collection-modal-btn-cancel')}
            onClick={handleCancel}
            disabled={isDisabledBtnOk}
          >
            {textBtnCancel}
          </ButtonComponent>

          <ButtonComponent
            className={cls('collection-modal-btn collection-modal-btn-save', { 'red': deleteType })}
            onClick={handleOk}
            disabled={isDisabledBtnOk}
          >
            {textBtnOk}
          </ButtonComponent>
        </div>
        <div style={{ height: 16 }} />
      </>
    );
  }
  return (
    <div className="double-btn-wrapper">
      {props.divider ? <Divider /> : null}
      <div className="double-btn-block">
        <ButtonComponent
          key="back"
          className="collection-modal-btn collection-modal-btn-cancel"
          style={{ minWidth: 81, letterSpacing: 0.07 }}
          onClick={handleCancel}
          disabled={isDisabledBtnOk}
        >
          {textBtnCancel}
        </ButtonComponent>
        <ButtonComponent
          key="submit"
          className={cls('collection-modal-btn collection-modal-btn-save', { 'red': deleteType })}
          style={{
            minWidth: 91,
            letterSpacing: 0.07,
            ...(props.width && { width: props.width }),
          }}
          onClick={handleOk}
          disabled={isDisabledBtnOk}
        >
          {textBtnOk}
        </ButtonComponent>
      </div>
    </div>
  );
};
