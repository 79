import React from 'react';

const Dashboard = () => (
  <svg
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7414 0.955724C9.7284 0.126901 8.2716 0.1269 7.2586 0.955723L1.0086 6.06936C0.370224 6.59167 0 7.37293 0 8.19774V17.25C0 18.7688 1.23122 20 2.75 20H15.25C16.7688 20 18 18.7688 18 17.25V8.19774C18 7.37293 17.6298 6.59167 16.9914 6.06936L10.7414 0.955724ZM4.75 14.5C4.33579 14.5 4 14.8358 4 15.25C4 15.6642 4.33579 16 4.75 16H13.25C13.6642 16 14 15.6642 14 15.25C14 14.8358 13.6642 14.5 13.25 14.5H4.75Z"
      fill="#F9F9FC"
    />
  </svg>
);

export default Dashboard;
