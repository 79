import NavCreators from '../../Redux/NavRedux';
import AdSpotCreators from '../AdSpotPage/reducer';

/* eslint-disable import/no-named-as-default-member */
export default {
  changeSizeSidebar: NavCreators.navSetProp,
  setAdSpotFilters: AdSpotCreators.setSelectedFilters,
  setAdSpotQuickPreset: AdSpotCreators.setSelectedQuickPreset,
  setAdSpotSort: AdSpotCreators.setSelectedSort,
  setAdSpotDefaultSearch: AdSpotCreators.setDefaultSearch,
  changeTheme: NavCreators.navSetProp,
};
