import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Dropdown, theme } from 'antd';
import ArrowSelectDown from '../../Icon/img/ArrowSelectDown';
import Icon from '../../Icon';
import './styles.less';


const LanguageSwitcher = ({
  languages,
  userInfo,
  changeLang,
  userSaveResultReset,
  isMobile,
  setModal,
  closeParentDropdown,
  optionsLanguages
}) => {
  const { t, i18n } = useTranslation();
  const [visibleLanguage, setVisibleLanguage] = useState(false);
  const handleVisibleChange = (value) => setVisibleLanguage(value);

  useEffect(() => {
    i18n.changeLanguage(userInfo?.language);
    dayjs.locale(userInfo?.language === 'zh-hans' ? 'zh-cn' : userInfo?.language);
    userSaveResultReset();
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [userInfo?.language]);

  return (
    isMobile ?
      <div className='language-switcher'
        id="language-switcher"
      >
        <div className="language-switcher-inner"
          onClick={() => {
            setModal('change_language')
            closeParentDropdown(false);
          }}
        >
          <Icon type={'language'}
            role="icon"
          />
          <div>
            {t('Language')}
          </div>
          <span>{optionsLanguages?.filter(el => el.value === userInfo?.language)?.[0]?.label}
            <ArrowSelectDown />
          </span>
        </div>
      </div>
      :


      <div className='language-switcher'
        id="language-switcher"
      >
        <Dropdown destroyPopupOnHide={true}
          getPopupContainer={(trigger) => trigger?.parentNode}
          onOpenChange={handleVisibleChange}
          dropdownRender={() => (
            <div className={'language-switcher-dropdown'}>
              {
                optionsLanguages.map((option) => (
                  <div key={option?.value}
                    className={'language-switcher-dropdown-option'}
                    onClick={() => {
                      setVisibleLanguage(false);
                      changeLang(option.value);
                    }}
                  >
                    <span>{option?.label}</span>
                    {
                      option?.value === userInfo?.language ?
                        <svg xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          className={'checkmark'}
                        >
                          <path d="M1.83333 10.0625L6 13.5L14.1667 2.5"
                            stroke="#225AEA"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        :
                        null
                    }
                  </div>
                ))
              }
            </div>
          )}
          trigger={['click']}
          open={visibleLanguage}
        >
          <div className={'language-switcher-inner'}>
            <Icon type={'language'}
              role="icon"
            />

            <div>
              {t('Language')}
            </div>

            <span>
              {optionsLanguages?.filter(el => el.value === userInfo?.language)?.[0]?.label}
              <ArrowSelectDown />
            </span>
          </div>
        </Dropdown>
      </div>

  )
}

export default LanguageSwitcher