import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../Components/Modal';
import { Divider, Spin } from 'antd';

const AdLibraryItemActiveAdsModal = (
  {
    visible,
    data,
    setModal,
    isMobile
  }
) => {

  const { t } = useTranslation();

  return (
    <Modal handleClose={() => setModal(null)}
           title={`${t('Active ads and Audience reach')} for ${data}`}
           isMobile={isMobile}
           width={828}
           className="collection-options-modal collection-modal product-actions-modal aditem-info-modal"
           open={visible}
           destroyOnClose
    >
      <Spin spinning={false}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {
              t(`Same content was re-used for the following ads. Such adsets are typically used to target different demographics and achieve better reach results.`)
            }
          </p>
          <Divider style={{ marginBottom: '24px 0' }} />
          <div>
            Charts
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default AdLibraryItemActiveAdsModal;
