import React from 'react';

const EUFlag = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="12.75 3 22.5 18">
      <g filter="url(#filter0_ddddd_7115_9685)">
        <path d="M32.25 3H15.75C14.0931 3 12.75 4.34315 12.75 6V18C12.75 19.6569 14.0931 21 15.75 21H32.25C33.9069 21 35.25 19.6569 35.25 18V6C35.25 4.34315 33.9069 3 32.25 3Z" fill="#112F95" />
        <path opacity="0.15" d="M32.25 3H15.75C14.0932 3 12.75 4.34325 12.75 6V18C12.75 19.6567 14.0932 21 15.75 21H32.25C33.9067 21 35.25 19.6567 35.25 18V6C35.25 4.34325 33.9067 3 32.25 3ZM34.5 18C34.5 19.2405 33.4905 20.25 32.25 20.25H15.75C14.5095 20.25 13.5 19.2405 13.5 18V6C13.5 4.7595 14.5095 3.75 15.75 3.75H32.25C33.4905 3.75 34.5 4.7595 34.5 6V18Z" fill="black" />
        <path d="M24 6.12524L23.8088 6.71324H23.1908L23.691 7.07624L23.4998 7.66424L24 7.30124L24.5003 7.66424L24.309 7.07624L24.8093 6.71324H24.1913L24 6.12524Z" fill="#F6CD46" />
        <path d="M24.1913 16.9237L24 16.3357L23.8088 16.9237H23.1908L23.691 17.2867L23.4998 17.8747L24 17.5117L24.5003 17.8747L24.309 17.2867L24.8093 16.9237H24.1913Z" fill="#F6CD46" />
        <path d="M18.8947 12.4065L19.3949 12.7695L19.2037 12.1815L19.7039 11.8185H19.0859L18.8947 11.2305L18.7034 11.8185H18.0854L18.5857 12.1815L18.3944 12.7695L18.8947 12.4065Z" fill="#F6CD46" />
        <path d="M21.4469 6.8092L21.2564 7.3972H20.6377L21.1379 7.7602L20.9474 8.3482L21.4469 7.9852L21.9472 8.3482L21.7567 7.7602L22.2562 7.3972H21.6382L21.4469 6.8092Z" fill="#F6CD46" />
        <path d="M19.5788 8.67749L19.3875 9.26549H18.7695L19.2698 9.62924L19.0785 10.2172L19.5788 9.85349L20.079 10.2172L19.8878 9.62924L20.388 9.26549H19.77L19.5788 8.67749Z" fill="#F6CD46" />
        <path d="M19.77 14.3707L19.5788 13.7827L19.3875 14.3707H18.7695L19.2698 14.7345L19.0785 15.3225L19.5788 14.9587L20.079 15.3225L19.8878 14.7345L20.388 14.3707H19.77Z" fill="#F6CD46" />
        <path d="M21.6382 16.2397L21.4469 15.6517L21.2564 16.2397H20.6377L21.1379 16.6027L20.9474 17.1907L21.4469 16.8277L21.9472 17.1907L21.7567 16.6027L22.2562 16.2397H21.6382Z" fill="#F6CD46" />
        <path d="M29.9145 11.8185H29.2965L29.1053 11.2305L28.914 11.8185H28.296L28.7963 12.1815L28.605 12.7695L29.1053 12.4065L29.6055 12.7695L29.4143 12.1815L29.9145 11.8185Z" fill="#F6CD46" />
        <path d="M26.553 6.8092L26.3618 7.3972H25.7438L26.2433 7.7602L26.0528 8.3482L26.553 7.9852L27.0525 8.3482L26.862 7.7602L27.3623 7.3972H26.7435L26.553 6.8092Z" fill="#F6CD46" />
        <path d="M28.1123 9.62924L27.9211 10.2172L28.4213 9.85349L28.9216 10.2172L28.7303 9.62924L29.2306 9.26549H28.6126L28.4213 8.67749L28.2301 9.26549H27.6121L28.1123 9.62924Z" fill="#F6CD46" />
        <path d="M28.6126 14.3707L28.4213 13.7827L28.2301 14.3707H27.6121L28.1123 14.7345L27.9211 15.3225L28.4213 14.9587L28.9216 15.3225L28.7303 14.7345L29.2306 14.3707H28.6126Z" fill="#F6CD46" />
        <path d="M26.7435 16.2397L26.553 15.6517L26.3618 16.2397H25.7438L26.2433 16.6027L26.0528 17.1907L26.553 16.8277L27.0525 17.1907L26.862 16.6027L27.3623 16.2397H26.7435Z" fill="#F6CD46" />
        <path opacity="0.2" d="M32.25 3.75H15.75C14.5073 3.75 13.5 4.75725 13.5 6V6.75C13.5 5.50725 14.5073 4.5 15.75 4.5H32.25C33.4928 4.5 34.5 5.50725 34.5 6.75V6C34.5 4.75725 33.4928 3.75 32.25 3.75Z" fill="white" />
      </g>
      <defs>
        <filter id="filter0_ddddd_7115_9685" x="0" y="0" width="48" height="60" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feMorphology radius="0.5" operator="erode" in="SourceAlpha" result="effect1_dropShadow_7115_9685" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0823529 0 0 0 0 0.117647 0 0 0 0 0.227451 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_7115_9685" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feMorphology radius="1.5" operator="erode" in="SourceAlpha" result="effect2_dropShadow_7115_9685" />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0823529 0 0 0 0 0.117647 0 0 0 0 0.227451 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="effect1_dropShadow_7115_9685" result="effect2_dropShadow_7115_9685" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feMorphology radius="3" operator="erode" in="SourceAlpha" result="effect3_dropShadow_7115_9685" />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="3" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0823529 0 0 0 0 0.117647 0 0 0 0 0.227451 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="effect2_dropShadow_7115_9685" result="effect3_dropShadow_7115_9685" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feMorphology radius="6" operator="erode" in="SourceAlpha" result="effect4_dropShadow_7115_9685" />
          <feOffset dy="12" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0823529 0 0 0 0 0.117647 0 0 0 0 0.227451 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="effect3_dropShadow_7115_9685" result="effect4_dropShadow_7115_9685" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feMorphology radius="12" operator="erode" in="SourceAlpha" result="effect5_dropShadow_7115_9685" />
          <feOffset dy="24" />
          <feGaussianBlur stdDeviation="12" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0.0823529 0 0 0 0 0.117647 0 0 0 0 0.227451 0 0 0 0.04 0" />
          <feBlend mode="normal" in2="effect4_dropShadow_7115_9685" result="effect5_dropShadow_7115_9685" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect5_dropShadow_7115_9685" result="shape" />
        </filter>
      </defs>
    </svg>
  )
}

export default EUFlag;