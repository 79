import React, { useEffect, useState } from 'react';
import Modal from '../../../Components/Modal';
import SearchComponent from '../../../Components/Search';
import cls from 'classname';
import Icon from '../../../Icon';
import { Divider, Dropdown, Spin } from 'antd';
import { debounce } from '../../../Utils/utils';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import ButtonComponent from '../../../Components/Button';
import EmptyState from './EmptyState';

const LoadPresetModal = (
  {
    visible,
    setModal,
    setPageSize,
    setPageNumber,
    loadPreset,
    setLoadedPreset,
    setSortOrder,
    category,
    loading,
    isMobile,
    setRecordToDelete,
  }) => {

  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [selected, setSelected] = useState(null);
  const [dropdown, setDropdown] = useState(false);

  const presetsOptions = useSelector(store => store[category].presets);

  const handleVisibleChange = (id) => setDropdown(dropdown === id ? null : id);

  const handleSearch = debounce(e => {
    setSearchValue(e.target.value);
  }, 300);

  useEffect(() => {
    setSearchValue('');
    setSelected(null);
  }, [visible]);

  return (
    <Modal handleOk={null}
           handleClose={() => {
             setModal(null);
           }}
           title={t('Select search presets')}
           isMobile={isMobile}
           width={450}
           className="collection-list-modal collection-modal"
           open={visible}
           destroyOnClose
    >
      <Spin spinning={loading}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {category ===  'adLibrary' ?
              t('Save your preferred Ad Library filters for faster searches.') :
              t('Save your preferred Competitor Research filters for faster searches.')
            }
          </p>
          <SearchComponent handleSearch={handleSearch}
                           onKeyUp={null}
                           value={null}
                           onFocusCallback={null}
                           placeholder={t('Search for preset...')}
          />
          {
            presetsOptions.users?.filter(el => el?.name.includes(searchValue)).length ?
              <div className={'collection-modal-list preset-list'}>
                {
                  presetsOptions.users?.filter(el => el?.name.includes(searchValue)).map((el, index) => (
                    <div key={el?.id}
                         className={cls('collection-modal-list-item preset-item', {
                           active: selected?.id === el?.id,
                           last: presetsOptions.users?.length - 1 === index && presetsOptions.users?.length !== 1,
                           only: presetsOptions.users?.length === 1,
                         })}
                    >
                      <div className={'collection-modal-list-item-content-select-icon'}
                           onClick={() => setSelected(el)}
                      />
                      <div className={'collection-modal-list-item-content'}
                           onClick={() => setSelected(el)}
                      >
                        <span className={'collection-modal-list-item-content-title'}>
                          {el?.name}
                        </span>
                      </div>
                      {
                        isMobile ?
                          <span className={'sales-tracker-top-page-header-btn'}
                                onClick={() => {
                                  setRecordToDelete(el);
                                  setModal('load_preset_options');
                                }}
                          >
                            <Icon type={'three_dots'} role={'icon'} />
                          </span>
                          :
                          <Dropdown destroyPopupOnHide={true}
                                    getPopupContainer={(trigger) => trigger.parentNode}
                                    onOpenChange={() => handleVisibleChange(el?.id)}
                                    placement={'bottomRight'}
                                    dropdownRender={() => (
                                      <div className={'sales-tracker-top-page-header-dropdown'}>
                                        <div className="sales-tracker-top-page-header-dropdown-option"
                                             onClick={() => {
                                               setDropdown(false);
                                               setRecordToDelete(el);
                                               setModal('rename_preset');
                                             }}
                                        >
                                          <Icon type="rename_preset" role="icon" />
                                          <span>
                                        {t('Rename')}
                                      </span>
                                        </div>
                                        <div className={'sales-tracker-top-page-header-dropdown-option red'}
                                             onClick={() => {
                                               setDropdown(false);
                                               setRecordToDelete(el);
                                               setModal('delete_preset');
                                             }}
                                        >
                                          <Icon type={'remove_preset'}
                                                role={'icon'}
                                          />
                                          <span>
                                        {t('Remove')}
                                      </span>
                                        </div>
                                      </div>
                                    )}
                                    trigger={['click']}
                                    open={dropdown === el?.id}
                          >
                            <span className={'sales-tracker-top-page-header-btn'}>
                              <Icon type={'three_dots'} role={'icon'} />
                            </span>
                          </Dropdown>
                      }
                    </div>
                  ))
                }
              </div>
              :
              <EmptyState searchValue={searchValue} />
          }
        </div>
        <Divider type={'horizontal'}
                 style={{ margin: '24px 0' }}
        />
        <div className={'collection-modal-btn-wrapper'}>
          <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                           onClick={() => {
                             setModal(null);
                           }}
                           text={t('Cancel')}
          />
          <ButtonComponent className="collection-modal-btn collection-modal-btn-save"
                           disabled={!Boolean(selected)}
                           onClick={() => {
                             loadPreset(selected);
                             setLoadedPreset(selected);
                             setPageSize(50);
                             setPageNumber(1);
                             setSelected(null);
                             if (category !== 'adSpot') setSortOrder(null);
                             else setSortOrder('most_recent');
                             setModal(null);
                           }}
                           text={t('Select preset')}
          />
        </div>
        {
          isMobile ?
            null
            :
            <div style={{ height: 16 }} />
        }
      </Spin>
    </Modal>
  );
};

export default LoadPresetModal;
