import React from 'react';
import cls from 'classname';

const DatabaseSales = (props) => (
  <svg
    width={props.width ? props.width : '20'}
    height={props.height ? props.height : '20'}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.45825 2.29199H1.89183C2.48768 2.29199 3.0007 2.71257 3.11756 3.29685L4.73196 11.3689C4.88777 12.1479 5.57179 12.7087 6.36626 12.7087H14.8976C15.6857 12.7087 16.3661 12.1566 16.5285 11.3853L17.705 5.797C17.814 5.27904 17.4189 4.79199 16.8895 4.79199H3.74992M7.70825 15.8337C7.70825 16.409 7.24188 16.8753 6.66659 16.8753C6.09129 16.8753 5.62492 16.409 5.62492 15.8337C5.62492 15.2584 6.09129 14.792 6.66659 14.792C7.24188 14.792 7.70825 15.2584 7.70825 15.8337ZM15.2083 15.8337C15.2083 16.409 14.7419 16.8753 14.1666 16.8753C13.5913 16.8753 13.1249 16.409 13.1249 15.8337C13.1249 15.2584 13.5913 14.792 14.1666 14.792C14.7419 14.792 15.2083 15.2584 15.2083 15.8337Z"
      stroke="#707BA0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default DatabaseSales;
