import React from 'react';
import { useTranslation } from 'react-i18next';
import TopPageLimits from '../../Containers/SalesTrackerPage/components/TopPageLimits';
import Icon from '../../Icon';
import ConfirmEmail from '../../Containers/DashboardPage/components/ConfirmEmail';
import './styles.less';

export const DashboardWelcome = ({
  skeleton,
  stInfo,
  handleNavigate,
  isMobile,
  isDisabled,
  isDemo,
  showConfirm
}) => {
  const { t } = useTranslation();

  if (skeleton) {
    return (
      <div className="welcome-block skeleton">
        <div className="title-wrapper">
          <div className="title-wrapper-icon">
            <span className={'link skeleton'} style={{ width: 40, height: 40, padding: '0 20px' }} />
          </div>
          <div className="sales-tracker-top-page-subheader">
            <span className={'link skeleton'} style={{ width: 240, height: 22, marginBottom: 2 }} />
            <span className={'link skeleton'} style={{ width: 338, height: 20  }} />
          </div>
        </div>
        <div className={'sales-tracker-top-page-limits-wrapper'}>
          <span className={'link skeleton'} style={{ width: 345, height: isMobile ? 72 : 36 }} />
        </div>
      </div>
    );
  }
  return (
    <div className="welcome-block">
     {
          showConfirm && isMobile ?
            <ConfirmEmail isMobile={isMobile} />
            :
            null
        }
      <div className="welcome-block-header">
        <div className="welcome-block-title-wrapper">
          <div className="welcome-block-title-wrapper-icon">
            <Icon type={`dashboard`} role={'icon'} />
          </div>
          <div className="welcome-block-subheader">
            <div className="welcome-block-title">
              {t('Dashboard')}
            </div>
            <div className="welcome-block-subtitle">
              {t('An overview of our solutions')}
            </div>
          </div>
        </div>
        {!isDemo && (
          <TopPageLimits
            pointer
            view={'both'}
            limits={stInfo}
            t={t}
            firstOnClick={() => {
              if (!isDisabled) handleNavigate('/sales-tracker/stores/', {
                state: {
                  name: 'All Products',
                  link: '/sales-tracker/stores/',
                }
              })
            }}
            secondOnClick={() => {
              if (!isDisabled) handleNavigate('/sales-tracker/products/', {
                state: {
                  name: 'All Products',
                  link: '/sales-tracker/products/',
                }
              })
            }
            }
          />
        )}
      </div>
    </div>
  );
};

export default DashboardWelcome;
