import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import utils from '../../../Utils/utils';
import Image from '../../../Components/Image';
import Images from '../../../Images';
import TooltipColumnIcon from '../../ProductDatabasePage/components/TooltipColumnIcon';
import IconWithText from '../../../Components/Text';
import Icon from '../../../Icon';
import { Dropdown } from 'antd';
import './InnerPageTitleBlock.less';


const InnerPageTitleBlock = (
  {
    data,
    handleOpenModalShopifyConnect,
    handleOpenModalAutoDSConnect,
    productPage,
    isMobile,
  }) => {

  const { t } = useTranslation();

  const [visibleDropdown, setVisibleDropdown] = useState(false);

  const handleInnerVisibleChange = (value) => {
    setVisibleDropdown(value);
  };

  return (
    <div className="inner-page-title-block">
      <div className="inner-page-title-image">
        <div className="image">
          {
            data?.logo ?
              <img src={data?.logo}
                   alt=""
                   onError={utils.addDefaultSrc}
              />
              :
              <Image src={Images.defaultImg} small={true} />
          }
        </div>
        <div className="title">
          <span>
            {data?.title}
          </span>
          <div className="link">
            <a target={'_blank'}
               href={data?.link ? `https://${data?.link}` : '#'}
               rel="noopener noreferrer">
              <span>{data?.link}</span>
            </a>
            <TooltipColumnIcon record={data?.record} isST={!productPage} />
          </div>
        </div>
      </div>
      {
        isMobile ?
          null
          :
          <div className="inner-page-title-action">
            {
              productPage ?
                <Dropdown open={visibleDropdown}
                          onOpenChange={handleInnerVisibleChange}
                          dropdownRender={() => (
                            <ul className={'inner-page-title-action-dropdown'}>
                              <li className="inner-page-title-action-dropdown-item"
                                  onClick={() => {
                                    setVisibleDropdown(false);
                                    handleOpenModalShopifyConnect(`${data?.store?.id}_${data?.id}`);
                                  }}
                              >
                                <IconWithText icon={() => <Icon type={'shopify_btn_icon'} role={'icon'} isLogo={true} />}
                                              text={t('to Shopify')}
                                />
                              </li>
                              <li className="inner-page-title-action-dropdown-item"
                                  onClick={() => {
                                    setVisibleDropdown(false);
                                    handleOpenModalAutoDSConnect({
                                      'source': 'shopify',
                                      'product_id': `${data?.store?.id}_${data?.id}`,
                                    });
                                  }}
                              >
                                <IconWithText icon={() => <Icon type={'auto_ds'} role={'icon'} />}
                                              text={t('to AutoDS')}
                                />
                              </li>
                            </ul>
                          )}
                          destroyPopupOnHide={true}
                          trigger={['click']}
                          placement="bottomRight"
                          getPopupContainer={(triggerNode) => triggerNode}
                          overlayClassName={'filter-preset-cell-menu-wrapper'}
                >
                  <span className={'inner-page-title-action-btn'}>
                    {t('Import')}
                  </span>
                </Dropdown>
                :
                null
            }
            <span className={'inner-page-title-action-btn'}
                  onClick={() => window.open(data?.totalInfo?.facebook_add_library, '_blank')}
            >
              <Icon role={'icon'} type={'search_facebook'} />
              {t('Ad library')}
            </span>
            <span className={'inner-page-title-action-btn'}
                  // todo start/stop tracking
                  onClick={() => alert(data?.record?.title)}
            >
              <svg xmlns="http://www.w3.org/2000/svg"
                   width="20"
                   height="20"
                   viewBox="0 0 20 20"
                   fill="none"
              >
                <path d="M6.45898 6.4585L13.5423 13.5418M13.5423 6.4585L6.45898 13.5418"
                      stroke="#D71313"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                />
              </svg>
              {t('Stop tracking')}
            </span>
          </div>
      }
    </div>
  );
};

export default InnerPageTitleBlock;
