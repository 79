import React, { memo, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import { connect, useSelector } from 'react-redux';
import BreadcrumbsActions from '../Breadcrumbs/actions';
import Creators from './reducer';
import ShopifyCreators from '../ShopifyStore/reducer';
import AutoDSCreators from '../AutoDS/reducer';
import dayjs from 'dayjs';
import cls from 'classname';
import { Spin, Modal } from 'antd';
import StoreTrackerPageSkeleton from './components/StoreTrackerPageSkeleton';
import Icon from '../../Icon';
import NumbersBreakdownDatabaseBlock from '../ProductDatabasePage/components/NumbersBreakdownDatabaseBlock';
import FreePlanErrorModal from './components/FreePlanErrorModal';
import { CanNotRemoveTrackingContent } from '../ProductDatabasePage/components/CanNotRemoveTracking';
import UntrackableDeleteModal from './components/UntrackableDeleteModal';
import StoreProductsModal from './components/StoreProductsModal';
import StoreAppsModal from './components/StoreAppsModal';
import PriceHistory from '../ProductDatabasePage/components/PriceHistory';
import { BreadcrumbsComponent } from '../Breadcrumbs';
import InnerPageTitleBlock from './components/InnerPageTitleBlock';
import StoreTrackerPageTabs from './components/StoreTrackerPageTabs';
import StoreTrackerPageOverview from './components/StoreTrackerPageOverview';
import StoreTrackerPageSales from './components/StoreTrackerPageSales';
import StoreTrackerPageCompetitors from './components/StoreTrackerPageCompetitors';
import StoreTrackerPageAds from './components/StoreTrackerPageAds';
import StoreTrackerPageProducts from './components/StoreTrackerPageProducts';
import StoreTrackerPageApps from './components/StoreTrackerPageApps';
import './styles.less';
import { useTranslation } from 'react-i18next';

const StoreTrackerPage = (
  {
    isAdmin,
    fetching,
    checkLoading,
    detailedStoreLoading,
    detailedStoreNewLoading,
    error,
    userInfo,
    isMobile,
    salesTracker,
    setView,
    view,
    breadcrumbs,
    dispatch,
    getDetailedStoreInfo,
    getDetailedStoreInfoNew,
    getDetailedStoreInfoCheckRequest,
    getProductListByStore,
    getStoreProductsModal,
    getStoreProductsModalNext,
    resetStoreProductsModal,
    storeProductsModalTableDataLoading,
    storeProductsModalTableData,
    selectedStoreTableDataLoading,
    connectProduct,
    deleteProduct,
    getExpandableChart,
    downloadSalesReport,
    downloadRevenueReport,
    filterDates,
    setFilters,
    setPeriod,
    fetchingSpin,
    getStores,
    shopifyStore,
    changeVisibleModalShopify,
    activeView,
    setActiveView,
    filtersLoading,
    ...props
  }) => {
  //state to show skeleton

  const {t} = useTranslation();

  const [firsLoading, setFirsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('overview');

  const skeleton = firsLoading || fetching || checkLoading || detailedStoreLoading || detailedStoreNewLoading || fetchingSpin;

  const isVisibleModal = shopifyStore?.isVisibleModal;
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const breadCrumbs = breadcrumbs?.length ? [...breadcrumbs] : null;
  const { state } = location;

  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;

  //3 charts info + selected store main info
  const detailedInfo = useSelector(store => store?.salesTracker?.selectedStore) || [];

  const stores = useSelector(store => store?.salesTracker?.stores?.results) || [];
  const searchResults = useSelector(store => store?.salesTracker?.stores?.searchResults) || [];

  const shopifyStores = useSelector(store => store['shopifyStore'].results) || [];
  const autoDS = useSelector(store => store?.autoDS);
  const initialUrl = document?.location?.pathname + (document?.location?.search || '');

  const detailedInfoNew = stores?.filter(el => +el.id === +id)?.[0] || searchResults?.filter(el => +el.id === +id)?.[0] || {};
  //3 charts page table data
  const storeProducts = useSelector(store => store?.salesTracker?.selectedStoreTableData) || [];

  const record = { data: { ...detailedInfoNew } };

  const minDate = filterDates?.min;
  const maxDate = filterDates?.max;
  const timeZone = detailedInfoNew?.store_timezone ? detailedInfoNew?.store_timezone.split('UTC')[1] : null;

  //table pagination options
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [sortOrder, setSortOrder] = useState(null);
  // modal show/hide
  const [showRawData, setShowRawData] = useState(false);
  const [modal, setModal] = useState('');
  ////numbers breakdown row to calc
  const [recordToCalc, setRecordToCalc] = useState(null);
  const [recordToPriceHistory, setRecordToPriceHistory] = useState(null);

  // const showFreePlanError = salesTracker?.showFreePlanError;
  const showFreePlanError = false;
  const isShowExtensionNotification = window.location?.search.includes('show_notification');

  // type of chart view
  const [isLineChartRevenue, setIsLineChartRevenue] = useState(true);

  useEffect(() => {
    if (!Object.keys(detailedInfoNew).length && isShowExtensionNotification) {
      getStores({ page: 1, page_size: 50 });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [detailedInfoNew?.title]);

  useEffect(() => {
    document.title = `Sales Tracker - Dropship`;
    if (isVisibleModal) changeVisibleModalShopify({ isVisibleModal: null, initialUrl: null, importProductId: null });
    if (!stores.length && !isShowExtensionNotification) navigate('/sales-tracker/stores', { replace: true });
    if (firsLoading) dispatch(Creators.resetIsAvailable());
    if (
      detailedInfoNew?.first_sale_date
      && dayjs().diff(dayjs(detailedInfoNew?.first_sale_date), 'days') < 30
      && dayjs(minDate) < dayjs(detailedInfoNew?.first_sale_date)
    ) {
      const newData = dateOptions.filter(el => dayjs().utcOffset(timeZone ? timeZone : '+0:00').diff(dayjs(detailedInfoNew?.first_sale_date), 'days') >= el.days);
      let quantity = newData[newData?.length - 1]?.value?.split(' ')?.[1];
      let value = newData?.[newData?.length - 1]?.value?.split(' ')?.[2];
      let period = newData?.[newData?.length - 1]?.id;
      let min = dayjs().diff(dayjs(detailedInfoNew?.first_sale_date), 'days') >= 1
      && dayjs().diff(dayjs(detailedInfoNew?.first_sale_date), 'days') < 7 ?
        dayjs(detailedInfoNew?.first_sale_date).format('YYYY-MM-DD')
        :
        dayjs().utcOffset(timeZone ? timeZone : '+0:00').subtract([1, 2].includes(period) ? quantity : quantity - 1, value).format('YYYY-MM-DD');
      let max = dayjs().diff(dayjs(detailedInfoNew?.first_sale_date), 'days') >= 1
      && dayjs().diff(dayjs(detailedInfoNew?.first_sale_date), 'days') < 7 ?
        dayjs().format('YYYY-MM-DD')
        :
        [1, 2].includes(period) ? min : dayjs().utcOffset(timeZone ? timeZone : '+0:00').format('YYYY-MM-DD');
      setFilters({ report_period: { min, max } });
      setPeriod(dayjs().diff(dayjs(detailedInfoNew?.first_sale_date), 'days') >= 1
      && dayjs().diff(dayjs(detailedInfoNew?.first_sale_date), 'days') < 7 ?
        [...newData]
        :
        [newData[newData?.length - 1]]);
    } else if (minDate === null || maxDate === null) {
      let max = new Date(dayjs()).toISOString();
      let min = new Date(dayjs().subtract(30, 'days')).toISOString();
      setFilters({ report_period: { min, max } });
      setPeriod([{ id: 4, name: 'Last 30 days', value: 'Last 30 days', days: 30 }]);
    } else {
      const min = () => dayjs().utcOffset(timeZone ? timeZone : '+0:00').subtract(29, 'days').format('YYYY-MM-DD');
      const max = () => dayjs().utcOffset(timeZone ? timeZone : '+0:00').format('YYYY-MM-DD');
      setFilters({ report_period: { min: min(), max: max() } });
      setPeriod([{ id: 4, name: 'Last 30 days', value: 'Last 30 days', days: 30 }]);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  useEffect(() => {
    if (!firsLoading) {
      getDetailedStoreInfoCheckRequest({ id: id, date_range: { min: minDate, max: maxDate } });
      getDetailedStoreInfo({
        id: id,
        date_range: { min: minDate, max: maxDate },
        raw_data: userInfo?.raw_data_access && showRawData,
      });
      getDetailedStoreInfoNew({ id: id });
      getProductListByStore({
        page: pageNumber,
        store_id: id,
        page_size: pageSize,
        ordering: sortOrder,
        date_range: { min: minDate, max: maxDate },
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [minDate, maxDate, showRawData, firsLoading]);

  useEffect(() => {
    if (firsLoading) setFirsLoading(false);
    else {
      getProductListByStore({
        page: pageNumber,
        store_id: id,
        page_size: pageSize,
        ordering: sortOrder,
        date_range: { min: minDate, max: maxDate },
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [pageNumber, pageSize, sortOrder]);

  //breadcrumbs display management
  useEffect(() => {
    if (breadCrumbs?.length >= 3) {
      props.removeBreadCrumb();
    } else if (breadCrumbs?.length === 2 && state) {
      props.addBreadCrumb({
        name: state?.name,
        link: state?.link,
      });
    } else if (breadCrumbs?.length < 2) {
      props.addBreadCrumb({
        name: detailedInfoNew?.title || (new URLSearchParams(window?.location?.search).get('title') ? decodeURIComponent(new URLSearchParams(window?.location?.search).get('title')) : id),
        link: state?.link || `/sales-tracker/stores/store/${id}`,
      });
    } else {
      props.clearBreadCrumbs();
      props.addBreadCrumb({
        name: 'Sales tracker',
        link: `/sales-tracker/stores`,
      });
      props.addBreadCrumb({
        name: detailedInfoNew?.title || (new URLSearchParams(window?.location?.search).get('title') ? decodeURIComponent(new URLSearchParams(window?.location?.search).get('title')) : id),
        link: state?.link || `/sales-tracker/stores/store/${id}`,
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [state]);

  useEffect(() => {
    if (showFreePlanError) {
      setModal('freePlanError');
    }
  }, [showFreePlanError]);

  const modalBlocks = {
    breakdown: <NumbersBreakdownDatabaseBlock product={recordToCalc} />,
    freePlanError: <FreePlanErrorModal setModal={setModal}
                                       reset={props.resetFreePlanError}
    />,
    canNotRemoveTracking: <CanNotRemoveTrackingContent setModal={setModal} isMobile={isMobile} />,
    store_products: <StoreProductsModal getStoreProductsModal={getStoreProductsModal}
                                        getStoreProductsModalNext={getStoreProductsModalNext}
                                        storeProductsModalTableDataLoading={storeProductsModalTableDataLoading}
                                        storeProductsModalTableData={storeProductsModalTableData}
                                        storeId={id}
                                        resetStoreProductsModal={resetStoreProductsModal}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                        isMobile={isMobile}
    />,
    store_apps: <StoreAppsModal data={detailedInfo?.total?.store_apps} />,
    priceHistory: <PriceHistory product={recordToPriceHistory} isPD={false} />,
    untrackableDelete: <UntrackableDeleteModal setModal={setModal}
                                               record={record}
                                               deleteStore={props.deleteStore}
                                               deleteProduct={null}
                                               view={'stores'}
    />,
  };

  const modalBlocksWidth = {
    breakdown: 1040,
    freePlanError: 512,
    canNotRemoveTracking: 450,
    store_products: 828,
    store_apps: 450,
    priceHistory: 1040,
    untrackableDelete: 512,
  };

  const tabContent = {
    'overview': <StoreTrackerPageOverview detailedInfoNew={detailedInfoNew}
                                          id={id}
                                          minDate={minDate}
                                          maxDate={maxDate}
                                          timeZone={timeZone}
                                          downloadSalesReport={downloadSalesReport}
                                          downloadRevenueReport={downloadRevenueReport}
                                          showRawData={showRawData}
                                          setShowRawData={setShowRawData}
                                          userInfo={userInfo}
                                          isMobile={isMobile}
                                          detailedInfo={detailedInfo}
                                          isLineChartRevenue={isLineChartRevenue}
                                          setIsLineChartRevenue={setIsLineChartRevenue}
                                          setTab={setActiveTab}
                                          setModal={setModal}
    />,
    'sales_&_revenue': <StoreTrackerPageSales />,
    'competitors': <StoreTrackerPageCompetitors />,
    'facebook_ads': <StoreTrackerPageAds />,
    'products': <StoreTrackerPageProducts />,
    'apps': <StoreTrackerPageApps />,
  };

  const handleOpenModalShopifyConnect = (importProductId) => {
    if (shopifyStores.length) {
      dispatch(ShopifyCreators.changeVisibleModalShopify({ isVisibleModal: 'import', initialUrl, importProductId }));
    } else {
      dispatch(ShopifyCreators.changeVisibleModalShopify({
        isVisibleModal: 'connect',
        initialUrl,
        importProductId: null,
      }));
    }
  };

  const handleOpenModalAutoDSConnect = (importProductId) => {
    if (autoDS?.email) {
      if (autoDS?.stores?.length) dispatch(AutoDSCreators.changeVisibleModalAuto({
        isVisibleModal: 'import',
        importProductId,
      }));
      else dispatch(AutoDSCreators.changeVisibleModalAuto({
        isVisibleModal: 'import_unavailable',
        importProductId: null,
      }));
    } else dispatch(AutoDSCreators.changeVisibleModalAuto({ isVisibleModal: 'connect', importProductId }));
  };

  if (skeleton) return <StoreTrackerPageSkeleton isMobile={isMobile} />;

  return (
    <div className={'product-database-page sales-tracker-page sales-tracker-store-page'}>
      <BreadcrumbsComponent breadCrumbs={breadCrumbs}
                            isMobile={isMobile}
                            isAdmin={false}
                            hideTime={true}
                            timeInfo={null}
      />
      <InnerPageTitleBlock data={{
        title: detailedInfoNew?.title,
        link: detailedInfoNew?.custom_domain,
        logo: detailedInfoNew?.logo || detailedInfoNew?.favicon,
        record: detailedInfoNew,
        totalInfo: detailedInfo?.total,
      }}
                           handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
                           handleOpenModalAutoDSConnect={handleOpenModalAutoDSConnect}
                           productPage={false}
                           isMobile={isMobile}
      />
      <StoreTrackerPageTabs active={activeTab}
                            setActive={setActiveTab}
      />
      {
        tabContent[activeTab]
      }
      <Modal
        className={cls('change-modal custom-modal', {
          'store-products-opened': modal === 'storeProducts' || modal === 'storeApps',
          'collection-list-modal collection-modal': modal === 'canNotRemoveTracking',
        })}
        getContainer={() => document.getElementById('global-wrap')}
        open={Boolean(modal)}
        {...(isMobile ? { transitionName: '' } : null)}
        centered={!isMobile}
        closeIcon={
          <Icon role="icon" type="close_modal" color="#707BA0" opacity={1} />
        }
        width={modalBlocksWidth[modal]}
        footer={null}
        closable="true"
        onCancel={() => {
          setModal(null);
          props.resetFreePlanError();
        }}
        destroyOnClose
        title={modal === 'canNotRemoveTracking' && t('Can not remove tracking')}
      >
        <Spin size="large" spinning={fetching}>
          {modalBlocks[modal]}
        </Spin>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAdmin: state.auth.isAdmin,
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  fetching: state.salesTracker.loading,
  checkLoading: state.salesTracker.checkLoading,
  detailedStoreLoading: state.salesTracker.detailedStoreLoading,
  detailedStoreNewLoading: state.salesTracker.detailedStoreNewLoading,
  fetchingSpin: state.salesTracker.fetchingSpin,
  salesTracker: state.salesTracker,
  selectedStoreTableDataLoading: state.salesTracker.selectedStoreTableDataLoading,
  storeProductsModalTableDataLoading: state.salesTracker.storeProductsModalLoading,
  storeProductsModalTableData: state.salesTracker.storeProductsModalTableData,
  error: state.salesTracker.error,
  view: state.salesTracker.view,
  activeView: state.salesTracker.activeView,
  breadcrumbs: state.breadcrumbs.totalBreadcrumbs,
  filterDates: state.salesTracker.filters.report_period,
  shopifyStore: state.shopifyStore,
  filtersLoading: state?.salesTracker?.productCards?.filtersLoading,
});

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  setView: (data) => dispatch(Creators.setView(data)),
  setActiveView: (data) => dispatch(Creators.setActiveView(data)),
  addBreadCrumb: (data) => dispatch(BreadcrumbsActions.addBreadCrumb(data)),
  removeBreadCrumb: () => dispatch(BreadcrumbsActions.removeBreadCrumb()),
  getStores: (data) => dispatch(Creators.getTrackingStoresRequest(data)),
  clearBreadCrumbs: () => dispatch(BreadcrumbsActions.clearBreadCrumbs()),
  getDetailedStoreInfo: (data) => dispatch(Creators.getDetailedStoreInfoRequest(data)),
  getDetailedStoreInfoNew: (data) => dispatch(Creators.getDetailedStoreInfoNewRequest(data)),
  getDetailedStoreInfoCheckRequest: (data) => dispatch(Creators.getDetailedStoreInfoCheckRequest(data)),
  getProductListByStore: (data) => dispatch(Creators.getProductListByStoreRequest(data)),
  getStoreProductsModal: (data) => dispatch(Creators.getStoreProductsModalRequest(data)),
  getStoreProductsModalNext: (data) => dispatch(Creators.getStoreProductsModalNextRequest(data)),
  resetStoreProductsModal: () => dispatch(Creators.resetStoreProductsModal()),
  deleteStore: (data) => dispatch(Creators.deleteTrackingStoreByIDRequest(data)),
  connectProduct: (data) => dispatch(Creators.createTrackingProductFromStoreRequest(data)),
  deleteProduct: (data) => dispatch(Creators.deleteTrackingProductByIDFromStoreRequest(data)),
  getExpandableChart: (data) => dispatch(Creators.getExpandableChartRequest(data)),
  downloadSalesReport: (data) => dispatch(Creators.getSalesReportRequest(data)),
  downloadRevenueReport: (data) => dispatch(Creators.getRevenueReportRequest(data)),
  setFilters: (data) => dispatch(Creators.setFilters(data)),
  setPeriod: (data) => dispatch(Creators.setCheckedList(data)),
  resetFreePlanError: () => dispatch(Creators.resetFreePlanError()),
  changeVisibleModalShopify: (data) => dispatch(ShopifyCreators.changeVisibleModalShopify(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(StoreTrackerPage));
