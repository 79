/* eslint-disable import/no-named-as-default-member */
/* User sagas */

import { takeLatest, put, call } from 'redux-saga/effects';
import AuthActions from '../LoginPage/reducer';
import * as services from './services';
import UserActions, { UserTypes as constants } from './reducer';
import { sendExtensionMessage } from '../../Utils/extension';
import { push } from 'connected-react-router';
import { openNotificationWithIcon } from '../../Components/Notification';
import { DefaultMsg } from '../../Components/Notification/notification-message';
import React from 'react';

const actions = {
  getUserInfoActions: {
    request: UserActions.userRequest,
    success: UserActions.userSuccess,
    errors: UserActions.userFailure,
  },
  updateUserInfoActions: {
    request: UserActions.userSaveRequest,
    success: UserActions.userSaveSuccess,
    errors: UserActions.userSaveFailure,
  },
  updateUserAvatarInfoActions: {
    request: UserActions.userSaveAvatarRequest,
    success: UserActions.userSaveAvatarSuccess,
    errors: UserActions.userSaveAvatarFailure,
  },
  changePasswordActions: {
    request: UserActions.changePasswordRequest,
    success: UserActions.changePasswordSuccess,
    errors: UserActions.changePasswordFailure,
  },
  sendCodePasswordActions: {
    request: UserActions.sendCodePasswordRequest,
    success: UserActions.sendCodePasswordSuccess,
    errors: UserActions.sendCodePasswordFailure,
  },
  confirmCodePasswordActions: {
    request: UserActions.confirmCodePasswordRequest,
    success: UserActions.confirmCodePasswordSuccess,
    errors: UserActions.confirmCodePasswordFailure,
  },
  createNewPasswordActions: {
    request: UserActions.createNewPasswordRequest,
    success: UserActions.createNewPasswordSuccess,
    errors: UserActions.createNewPasswordFailure,
  },
  setPasswordActions: {
    request: UserActions.setPasswordRequest,
    success: UserActions.setPasswordSuccess,
    errors: UserActions.setPasswordFailure,
  },
  changeEmailsActions: {
    request: UserActions.changeEmailsRequest,
    success: UserActions.changeEmailsSuccess,
    errors: UserActions.changeEmailsFailure,
  },
  verifyEmailActions: {
    request: UserActions.verifyEmailRequest,
    success: UserActions.verifyEmailSuccess,
    errors: UserActions.verifyEmailFailure,
  },
  confirmEmailsActions: {
    request: UserActions.confirmEmailsRequest,
    success: UserActions.confirmEmailsSuccess,
    errors: UserActions.confirmEmailsFailure,
  },
  changeLanguageActions: {
    request: UserActions.changeLanguageRequest,
    success: UserActions.changeLanguageSuccess,
    errors: UserActions.changeLanguageFailure,
  },
};

const eventsOptions = {
  [constants.USER_REQUEST]: {
    api: services.getUserInfo,
    actions: actions.getUserInfoActions,
  },
  [constants.USER_SAVE_REQUEST]: {
    api: services.updateUserInfo,
    actions: actions.updateUserInfoActions,
  },
  [constants.USER_SAVE_AVATAR_REQUEST]: {
    api: services.updateUserInfo,
    actions: actions.updateUserAvatarInfoActions,
  },
  [constants.CHANGE_PASSWORD_REQUEST]: {
    api: services.changePassword,
    actions: actions.changePasswordActions,
  },
  [constants.SEND_CODE_PASSWORD_REQUEST]: {
    api: services.sendCodePassword,
    actions: actions.sendCodePasswordActions,
  },
  [constants.CONFIRM_CODE_PASSWORD_REQUEST]: {
    api: services.confirmCodePassword,
    actions: actions.confirmCodePasswordActions,
  },
  [constants.CREATE_NEW_PASSWORD_REQUEST]: {
    api: services.createNewPassword,
    actions: actions.createNewPasswordActions,
  },
  [constants.SET_PASSWORD_REQUEST]: {
    api: services.createNewPassword,
    actions: actions.setPasswordActions,
  },
  [constants.CHANGE_EMAILS_REQUEST]: {
    api: services.changeEmails,
    actions: actions.changeEmailsActions,
  },
  [constants.VERIFY_EMAIL_REQUEST]: {
    api: services.verifyEmail,
    actions: actions.verifyEmailActions,
  },
  [constants.CONFIRM_EMAILS_REQUEST]: {
    api: services.confirmEmails,
    actions: actions.confirmEmailsActions,
  },
  [constants.CHANGE_LANGUAGE_REQUEST]: {
    api: services.changeLanguage,
    actions: actions.changeLanguageActions,
  },
};

function* apiGenerator(action) {
  const provider = eventsOptions[action.type];

  try {
    const params = action.payload;
    const response = yield call(provider.api, params);
    if (response.data) {

      if (action.type === 'CONFIRM_EMAILS_REQUEST' && response.ok) {
        openNotificationWithIcon({
          key: `open${Date.now()}`,
          style: { width: '400px' },
          className: 'notification notification_rename',
          message: (
            <DefaultMsg text={null}
              icon="notification_success"
              title={`Your Email has been successfully verified`}
            />
          ),
        });
      }
      if (action.type === 'CREATE_NEW_PASSWORD_REQUEST' && response.data?.message &&
        response.data.status_code === 400) {
        openNotificationWithIcon({
          key: `open${Date.now()}`,
          style: { width: '400px' },
          className: 'notification notification_rename',
          message: (
            <DefaultMsg text={null}
              icon="notification_error"
              title={response.data?.message[0]?.detail ? response.data?.message[0]?.detail : response.data?.message?.detail}
            />
          ),
        });
      }
      if (action.type === 'CREATE_NEW_PASSWORD_REQUEST' && response.data?.message && response.ok) {
        openNotificationWithIcon({
          key: `open${Date.now()}`,
          style: { width: '400px' },
          className: 'notification notification_rename',
          message: (
            <DefaultMsg text={null}
              icon="notification_success"
              title={response.data?.message?.detail}
            />
          ),
        });
      }
      if (response.data?.message && response.ok) {
        yield put(provider.actions.success(response?.data?.message));
        if (action.type === 'CHANGE_EMAILS_REQUEST' || action.type === 'VERIFY_EMAIL_REQUEST') {
          openNotificationWithIcon({
            key: `open${Date.now()}`,
            style: { width: '400px' },
            className: 'notification notification_rename',
            message: (
              <DefaultMsg text={(
                <span>
                  Please check the email sent to {params?.new_email}.
                  {
                    action.type === 'VERIFY_EMAIL_REQUEST' ?
                      null
                      :
                      ' The new email address will not be active until it is confirmed.'
                  }
                </span>
              )}
                icon="notification_success"
                title={'Email verification sent'}
                textIsWide={true}
              />
            ),
          });
        } else if (action.type === 'CHANGE_PASSWORD_REQUEST') {
          openNotificationWithIcon({
            key: `open${Date.now()}`,
            style: { width: '400px' },
            className: 'notification notification_rename',
            message: (
              <DefaultMsg text={null}
                icon="notification_success"
                title={`Password has been successfully changed`}
              />
            ),
          });
        }
        else if (action.type === 'SET_PASSWORD_REQUEST') {
          openNotificationWithIcon({
            key: `open${Date.now()}`,
            style: { width: '400px' },
            className: 'notification notification_rename',
            message: (
              <DefaultMsg text={null}
                icon="notification_success"
                title={response.data?.message?.detail}
              />
            ),
          });
        }
      } else if (response.data?.message && !response.ok) {
        yield put(provider.actions.errors({ errors: response.data?.message }));
      } else if (response.data && !response.ok) {
        const error = Object.values(response.data).length
          ? Object.values(response.data)[0]
          : null;
        yield put(provider.actions.errors({ errors: error }));
      } else {
        yield put(provider.actions.success(response.data));
        yield put(AuthActions.updateProfile(response.data));
        sendExtensionMessage({
          action: 'updateProfile',
          data: { ...response.data }
        });
        if (action.type === constants.USER_REQUEST && params?.isCheckout) {
          localStorage.setItem('completely_finished', 'true');
          yield put(push('/dashboard'));
        } else if (action.type === 'CHANGE_LANGUAGE_REQUEST') {
          openNotificationWithIcon({
            key: `open${Date.now()}`,
            style: { width: '400px' },
            className: 'notification notification_rename',
            message: (
              <DefaultMsg text={null}
                icon="notification_success"
                title={`Language has been successfully changed`}
              />
            ),
          });
        }
      }
    } else {
      yield put(provider.actions.errors({ errors: 'some error' }));
    }
  } catch (errors) {
    yield put(provider.actions.errors({ errors }));
  }
}
function* apiSetPassword(action) {
  const provider = eventsOptions[action.type];

  try {
    const params = action.payload;
    const response = yield call(provider.api, params);
    if (response.data) {
      if (response.ok) {
        yield put(provider.actions.success(response.data));
        yield put(UserActions.userRequest());
      } else {
        const error = Object.values(response.data).length
          ? Object.values(response.data)[0]
          : null;
        yield put(provider.actions.errors({ errors: error }));
      }
    } else {
      yield put(provider.actions.errors({ errors: 'some error' }));
    }
  } catch (errors) {
    yield put(provider.actions.errors({ errors }));
  }
}

export default function* apiSaga() {
  yield takeLatest(constants.USER_REQUEST, apiGenerator);
  yield takeLatest(constants.USER_SAVE_REQUEST, apiGenerator);
  yield takeLatest(constants.USER_SAVE_AVATAR_REQUEST, apiGenerator);
  yield takeLatest(constants.CHANGE_PASSWORD_REQUEST, apiGenerator);
  yield takeLatest(constants.SEND_CODE_PASSWORD_REQUEST, apiGenerator);
  yield takeLatest(constants.CONFIRM_CODE_PASSWORD_REQUEST, apiGenerator);
  yield takeLatest(constants.CREATE_NEW_PASSWORD_REQUEST, apiGenerator);
  yield takeLatest(constants.SET_PASSWORD_REQUEST, apiSetPassword);
  yield takeLatest(constants.CHANGE_EMAILS_REQUEST, apiGenerator);
  yield takeLatest(constants.VERIFY_EMAIL_REQUEST, apiGenerator);
  yield takeLatest(constants.CONFIRM_EMAILS_REQUEST, apiGenerator);
  yield takeLatest(constants.CHANGE_LANGUAGE_REQUEST, apiGenerator);
}
