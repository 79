import React from 'react';

const Bancontact = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="8" viewBox="0 0 20 8" fill="none">
      <path
        d="M17.3149 1.92188H16.4417H12.4012H11.528L10.936 2.55366L9.02679 4.59642L8.43479 5.2282H7.56158H3.59513H2.72192L3.29913 4.5894L3.57293 4.28755L4.15014 3.64875H3.27693H2.15212H1.52311C1.04211 3.64875 0.649902 4.03483 0.649902 4.49814V6.14078V6.29521C0.649902 6.76554 1.04211 7.14461 1.52311 7.14461H1.76731H10.9434H11.602C12.083 7.14461 12.7416 6.85679 13.0598 6.51282L14.5843 4.87019L17.3149 1.92188Z"
        fill="#004E91" />
      <path
        d="M18.4768 0.00701969C18.9578 0.00701969 19.35 0.393109 19.35 0.856416V2.65348C19.35 3.12381 18.9578 3.50288 18.4768 3.50288H18.0032H16.723H15.8498L16.4344 2.8711L16.723 2.55521L17.3076 1.92343H11.5208L8.43492 5.22273H2.68506L6.8069 0.800257L6.9623 0.631782C7.28791 0.287812 7.94651 0 8.42752 0H8.62732H18.4768V0.00701969Z"
        fill="#FFDD00" />
    </svg>
  );
};

export default Bancontact;
