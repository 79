import React from 'react';
import utils from '../../../Utils/utils';
import SalesTrackerNoteSkeleton from './SalesTrackerNoteSkeleton';
import InnerPageTitleBlockSkeleton from './InnerPageTitleBlockSkeleton';
import InnerPageInfoBlockSkeleton from './InnerPageInfoBlockSkeleton';
import MainBlockSkeleton from './MainBlockSkeleton';
import { Divider } from 'antd';
import './ProductTrackerPageSkeleton.less';

const ProductTrackerPageSkeleton = ({ isMobile }) => {
  return (
    <div className="product-database-page sales-tracker-page sales-tracker-product-page">
      <div className={'custom-breadcrumbs skeleton'}
           style={{ alignItems: 'center' }}
      >
        <span className={'link skeleton'}
              style={{ width: 110, height: 20 }}
        />
        <span className={'link skeleton'}
              style={{ width: 16, height: 16 }}
        />
        <span className={'link skeleton'}
              style={{ width: 200, height: 20 }}
        />
      </div>
      <SalesTrackerNoteSkeleton />
      <InnerPageTitleBlockSkeleton isMobile={isMobile} />
      <InnerPageInfoBlockSkeleton isMobile={isMobile} />
      <MainBlockSkeleton isMobile={isMobile} />
      <div className="chart-block">
        <div className="chart-block_header">
          <div className="chart-block_title"
               style={{ display: 'flex', alignItems: 'center' }}>
            <span className={'link skeleton'}
                  style={{ width: isMobile ? 50 : 200, height: 20 }}
            />
            <Divider type={'vertical'} style={{ margin: '0 12px' }} />
            <span className={'link skeleton'}
                  style={{ width: isMobile ? 30 : 157, height: 16 }}
            />
          </div>
          <span className={'link skeleton'}
                style={{ width: isMobile ? '100%' : 72, height: 40, marginTop: isMobile ? 16 : 0 }}
          />
        </div>
        <div className="chart-block_total-count-block"
             style={{ display: 'flex', alignItems: isMobile ? 'flex-start' : 'center', gap: isMobile ? 16 : 30, marginTop: 16, flexDirection: isMobile ? 'column' : 'row' }}
        >
          <div className="chart-block_total-count"
               style={{ marginLeft: 0 }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              <span className={'link skeleton'}
                    style={{ width: 14, height: 14 }}
              />
              <span className={'link skeleton'}
                    style={{ width: 88, height: 20 }}
              />
            </div>
            <span className={'link skeleton'}
                  style={{ width: 123, height: 28, marginTop: 4, marginLeft: 20 }}
            />
          </div>
          <div className="chart-block_total-count"
               style={{ marginLeft: 0 }}
          >
            <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
              <span className={'link skeleton'}
                    style={{ width: 14, height: 14 }}
              />
              <span className={'link skeleton'}
                    style={{ width: 88, height: 20 }}
              />
            </div>
            <span className={'link skeleton'}
                  style={{ width: 123, height: 28, marginTop: 4, marginLeft: 20 }}
            />
          </div>
        </div>
        <span className={'link skeleton'}
              style={{ width: '100%', height: 308, marginTop: 16 }}
        />
      </div>
      <div className="product-page-table-skeleton">
        <div className="product-page-table-skeleton-row product-page-table-skeleton-row-header">
          <div>
            <span className={'link skeleton'}
                  style={{ width: 31, height: 20 }}
            />
            <span className={'link skeleton'}
                  style={{ width: 16, height: 16 }}
            />
          </div>
          {
            [1, 2, 3, 4].map(el => (
              <div key={el}>
                <span className={'link skeleton'}
                      style={{ width: 55, height: 20 }}
                />
                <span className={'link skeleton'}
                      style={{ width: 16, height: 16 }}
                />
              </div>
            ))
          }
        </div>
        {
          [11,12,13,14].map(elem => (
            <div key={elem} className="product-page-table-skeleton-row">
              <div>
            <span className={'link skeleton'}
                  style={{ width: 90, height: 20 }}
            />
              </div>
              {
                [1, 2, 3, 4].map(el => (
                  <div key={el}>
                <span className={'link skeleton'}
                      style={{ width: utils.randomNum(30, 90), height: 20 }}
                />
                  </div>
                ))
              }
            </div>
          ))
        }
      </div>
    </div>
  );
};

export default ProductTrackerPageSkeleton;
