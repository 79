import React from 'react';
import { useTranslation } from 'react-i18next';
import './SalesTrackerNote.less';

const SalesTrackerNote = () => {
  const { t } = useTranslation();

  return (
    <div className="sales-tracker-note">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M9.64009 3.25861C10.706 1.46588 13.3016 1.46588 14.3675 3.2586L21.8515 15.8453C22.9415 17.6784 21.6205 20.0007 19.4878 20.0007H4.51984C2.38717 20.0007 1.06616 17.6784 2.15611 15.8453L9.64009 3.25861ZM12.0039 8.00073C12.4181 8.00073 12.7539 8.33652 12.7539 8.75073V12.7507C12.7539 13.1649 12.4181 13.5007 12.0039 13.5007C11.5897 13.5007 11.2539 13.1649 11.2539 12.7507V8.75073C11.2539 8.33652 11.5897 8.00073 12.0039 8.00073ZM13.0039 15.5007C13.0039 16.053 12.5562 16.5007 12.0039 16.5007C11.4516 16.5007 11.0039 16.053 11.0039 15.5007C11.0039 14.9484 11.4516 14.5007 12.0039 14.5007C12.5562 14.5007 13.0039 14.9484 13.0039 15.5007Z"
              fill="#F6B60E" />
      </svg>
      <div className="sales-tracker-note-text">
        <span>{t('Sales data are estimates and may contain error margins.')}</span>
        <p>
          {t('Error margins increase for products with lower sales volume and decrease for products with higher sales volume.')}
        </p>
      </div>
    </div>
  );
};

export default SalesTrackerNote;
