import React from 'react';
import { useSelector } from 'react-redux';

const PinIcon = ({active}) => {

  const theme = useSelector((state) => state.nav.theme);

  return !active ?
    (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          d="M6.97917 13.0212L10.1743 16.2164C11.1396 17.1817 12.7925 16.6406 13.0001 15.2913L13.6163 11.2858C13.7 10.7421 14.0465 10.2747 14.5424 10.0367L16.8378 8.9349C17.8736 8.43772 18.1075 7.06626 17.2951 6.25385L13.7465 2.70526C12.9341 1.89285 11.5627 2.12677 11.0655 3.16256L9.96369 5.45797C9.72566 5.95388 9.25826 6.3004 8.71458 6.38404L4.7091 7.00027C3.3598 7.20785 2.81869 8.86075 3.78401 9.82607L6.97917 13.0212ZM6.97917 13.0212L6.98529 13.0151M6.97917 13.0212L3.125 16.8754"
          stroke={theme === 'light' ? '#A1AAC8' : 'rgba(255, 255, 255, 0.259)'}
          strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    )
    :
    (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          d="M3.34207 10.2681L6.09528 13.0213L2.68306 16.4336C2.43898 16.6776 2.43898 17.0734 2.68306 17.3174C2.92714 17.5615 3.32286 17.5615 3.56694 17.3174L6.97917 13.9052L9.73238 16.6584C11.0597 17.9857 13.3324 17.2417 13.6178 15.3864L14.2341 11.381C14.2864 11.0412 14.5029 10.749 14.8129 10.6003L17.1083 9.49846C18.5325 8.81485 18.8541 6.92909 17.7371 5.81202L14.1885 2.26344C13.0714 1.14637 11.1857 1.46801 10.502 2.89222L9.40024 5.18763C9.25147 5.49758 8.95935 5.71415 8.61955 5.76643L4.61406 6.38265C2.75878 6.66808 2.01476 8.94081 3.34207 10.2681Z"
          fill={theme === 'light' ? '#151E3A' : '#f2f3f8'}
        />
      </svg>
    )
};

export default PinIcon;
