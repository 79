import React, { useEffect, useRef, useState } from 'react';
import InnerHTML from 'dangerously-set-html-content';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import cls from 'classname';
import { OverlayScrollbar } from '../../../Components/ScrollBar';
import ProductDetailsCard from './ProductDetailsCard';
import Icon from '../../../Icon';
import { langMapISO } from '../../../Utils/utils';
import ButtonComponent from '../../../Components/Button';
import './VideosBlock.less';

const VideosBlock = (
  {
    fetching,
    isMobile,
    videos,
    lang,
    onOpenUpgradePlan,
  }) => {

  const theme = useSelector(state => state?.nav?.theme);

  /* TikTok, Facebook */

  const [facebookInstances, setFacebookInstances] = useState([]);
  const [tiktokData, setTiktokData] = useState({});
  const [loading, setLoading] = useState(false);
  const [disabledPrev, setDisabledPrev] = useState(true);
  const [disabledNext, setDisabledNext] = useState(false);
  const scrollRef = useRef(null);
  const { t } = useTranslation();

  const firstTiktokId = videos?.['tiktok'].length ? videos?.['tiktok'][0].url : null;
  const firstFacebookId = videos?.['facebook'].length ? videos?.['facebook'][0].url : null;

  const [selected, setSelected] = useState('');
  const [videosList, setVideosList] = useState(null);
  const skeletonList = [1, 2, 3];
  const isEmptyState = !videos?.[selected.toLowerCase()]?.length;

  useEffect(() => {
    setSelected(firstTiktokId ? 'Tiktok' : firstFacebookId ? 'Facebook' : null);
    if (firstTiktokId) {
      if (Object.keys(tiktokData)?.length) setTiktokData({});
      try {
        videos['tiktok'].forEach(el => {
          createEmbedMarkup(el);
        });
      } catch (e) {
        console.log('some err', e);
      }
    }
    if (firstTiktokId || firstFacebookId) {
      setVideosList([...videos['facebook'], ...videos['tiktok']]);
    }
    if (firstFacebookId) {
      initFbVideo();
    } else {
      if (selected === 'Facebook') handleChangeSelected('Tiktok');
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [firstTiktokId, firstFacebookId]);

  function createEmbedMarkup(dataLink) {
    if (!loading) setLoading(true);

    if (!dataLink?.is_locked) {
      try {
        fetch(`https://www.tiktok.com/oembed?url=${dataLink?.url}`)
          .then((response) => {
            if (response.ok) return response.json();
          })
          .then((data) => {
            setTiktokData((tiktokData) => ({
              ...tiktokData,
              [dataLink.id]: data.html,
            }));
          })
          .catch(e => console.log('tiktok error', e));
      } catch (e) {
        console.log('tiktok error', e);
      }
    }
    setLoading(false);
  }

  const handleChangeSelected = (platform) => {
    if (platform === selected) return;
    setSelected(platform);
    if (platform === 'TikTok' && facebookInstances?.length) {
      facebookInstances.forEach(video => video.pause());
    }
    if (disabledNext) setDisabledNext(false);
    if (scrollRef && scrollRef.current) {
      const { position } = scrollRef.current.osInstance().scroll();
      if (position.x) {
        if (!disabledPrev) setDisabledPrev(true);
        scrollRef.current
          .osInstance()
          .scroll({ x: 0 });
      }
    }
  };

  function handleNavigateVideo(step, isDisabled) {
    if (isDisabled) return;
    if (scrollRef) {
      const { max, position } = scrollRef.current.osInstance().scroll();

      if (step === 'next') {
        if (max.x > 0 && max.x < 340) {
          if (disabledPrev) setDisabledPrev(false);
          if (!disabledNext) setDisabledNext(true);
        } else if (max.x - 340 > position.x) {
          if (disabledPrev) setDisabledPrev(false);
          if (disabledNext) setDisabledNext(false);
        } else {
          if (!disabledNext) setDisabledNext(true);
          if (disabledPrev) setDisabledPrev(false);
        }
        scrollRef.current
          .osInstance()
          .scroll({ x: position.x + 340 });
      } else {
        if (position.x > 340) {
          if (disabledNext) setDisabledNext(false);
        } else {
          if (!disabledPrev) setDisabledPrev(true);
          if (disabledNext) setDisabledNext(false);
        }
        scrollRef.current
          .osInstance()
          .scroll({ x: position.x - 340 });
      }
    }
  }

  const selectedPlatformBlock = (firstTiktokId || firstFacebookId) ?
    (
      <div className={'product-details-card-header-toggle'}
           style={{ cursor: (firstTiktokId && firstFacebookId) ? 'pointer' : 'not-allowed' }}
           onClick={() => (firstTiktokId && firstFacebookId) && handleChangeSelected(selected === 'Tiktok' ? 'Facebook' : 'Tiktok')}
      >
        <span className={cls('product-details-card-header-toggle-option', {
          active: selected === 'Tiktok',
        })}>
            {
              theme === 'light' ?
                <svg xmlns="http://www.w3.org/2000/svg"
                     width="20"
                     height="20"
                     viewBox="0 0 20 20"
                     fill="none"
                >
                  <path fill="#151E3A"
                        d="M17.2608 8.51127C15.7778 8.51127 14.4047 8.04005 13.2835 7.23923V13.0606C13.2835 15.9727 10.9216 18.3334 8.00806 18.3334C6.92098 18.3334 5.91058 18.0049 5.07125 17.4416C3.66095 16.4951 2.73242 14.8859 2.73242 13.0606C2.73242 10.1486 5.09438 7.78794 8.00814 7.788C8.25028 7.78788 8.49213 7.80432 8.73201 7.83709V8.48344L8.73186 10.7534C8.50098 10.6802 8.25485 10.6404 7.99948 10.6404C6.66662 10.6404 5.58633 11.7204 5.58633 13.0523C5.58633 13.9941 6.12633 14.8096 6.91377 15.2068C7.24027 15.3714 7.60901 15.4642 7.9995 15.4642C9.32962 15.4642 10.4081 14.3886 10.4126 13.0606V1.66675H13.2834V2.03366C13.2936 2.14335 13.3082 2.25262 13.3272 2.36118C13.5264 3.49706 14.2059 4.46754 15.1491 5.05579C15.7825 5.45094 16.5144 5.65984 17.2609 5.6587L17.2608 8.51127Z"
                  />
                </svg>
                :
                <svg xmlns="http://www.w3.org/2000/svg"
                     width="20"
                     height="20"
                     viewBox="0 0 20 20"
                     fill="none"
                >
                  <path fill="#F2F3F8"
                        d="M17.2628 8.51127C15.7798 8.51127 14.4067 8.04005 13.2854 7.23923V13.0606C13.2854 15.9727 10.9235 18.3334 8.01001 18.3334C6.92293 18.3334 5.91253 18.0049 5.0732 17.4416C3.66291 16.4951 2.73438 14.8859 2.73438 13.0606C2.73438 10.1486 5.09634 7.78794 8.01009 7.788C8.25223 7.78788 8.49408 7.80432 8.73396 7.83709V8.48344L8.73382 10.7534C8.50294 10.6802 8.2568 10.6404 8.00143 10.6404C6.66857 10.6404 5.58829 11.7204 5.58829 13.0523C5.58829 13.9941 6.12828 14.8096 6.91572 15.2068C7.24222 15.3714 7.61097 15.4642 8.00145 15.4642C9.33157 15.4642 10.41 14.3886 10.4146 13.0606V1.66675H13.2854V2.03366C13.2955 2.14335 13.3101 2.25262 13.3291 2.36118C13.5284 3.49706 14.2079 4.46754 15.1511 5.05579C15.7844 5.45094 16.5163 5.65984 17.2628 5.6587L17.2628 8.51127Z"
                  />
                </svg>
            }
          Tiktok
        </span>
        <span className={cls('product-details-card-header-toggle-option', {
          active: selected === 'Facebook',
        })}>
            <svg xmlns="http://www.w3.org/2000/svg"
                 width="20"
                 height="20"
                 viewBox="0 0 20 20"
                 fill="none"
            >
              <path fill="#008CFF"
                    d="M7.98605 18.0884V12.5468H6.26614V10.0001H7.98605V8.90286C7.98605 6.06629 9.26938 4.75286 12.0527 4.75286C12.5796 4.75286 13.4893 4.8561 13.8629 4.95934V7.26629C13.6661 7.24638 13.3226 7.23619 12.8995 7.23619C11.5328 7.23619 11.006 7.75286 11.006 9.09962V10.0001H13.7296L13.2629 12.5464H11.0097V18.2732C15.1358 17.7734 18.3327 14.2601 18.3327 10.0001C18.3327 5.39777 14.6017 1.66675 9.99935 1.66675C5.39703 1.66675 1.66602 5.39777 1.66602 10.0001C1.66602 13.9083 4.35613 17.1878 7.98605 18.0884Z"
              />
            </svg>
          Facebook
        </span>
      </div>
    )
    :
    null;

  const renderHeader = () => {
    let result;

    if (isEmptyState) {
      result = <>
        <span className="title">
          {t('No Videos Found')}
        </span>
        {selectedPlatformBlock}
      </>;
    } else {
      result = <>
        <span className="title">
          {
            t('Content')
          }
        </span>
        {selectedPlatformBlock}
        {
          isMobile || videos[selected.toLowerCase()].length < 4 ?
            null
            :
            (
              <div className={'content-btn-wrapper'}>
                <span className={cls('content-btn content-btn-prev', {
                  disabled: disabledPrev,
                })}
                      onClick={() => handleNavigateVideo('previous', disabledPrev)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg"
                       width="20"
                       height="20" viewBox="0 0 20 20"
                       fill="none"
                  >
                    <path stroke="#151E3A"
                          d="M8.33398 13.3334L11.0781 10.5893C11.4035 10.2639 11.4035 9.73626 11.0781 9.41083L8.33398 6.66675"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <span className={cls('content-btn content-btn-next', {
                  disabled: disabledNext,
                })}
                      onClick={() => handleNavigateVideo('next', disabledNext)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg"
                       width="20"
                       height="20" viewBox="0 0 20 20"
                       fill="none"
                  >
                    <path stroke="#151E3A"
                          d="M8.33398 13.3334L11.0781 10.5893C11.4035 10.2639 11.4035 9.73626 11.0781 9.41083L8.33398 6.66675"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                    />
                  </svg>
                </span>
              </div>)
        }
      </>;
    }

    return result;
  };

  const renderHeaderSkeleton = (
    <>
      <span className={'link skeleton'}
            style={{ width: 73, height: 28 }}
      />
      <span className={'link skeleton'}
            style={{ width: isMobile ? '100%' : 209, height: 40, marginLeft: 'auto' }}
      />
    </>
  );

  function initFbVideo() {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId: process.env.REACT_APP_FACEBOOK_APP_ID,
        xfbml: true,
        status: true,
        version: 'v3.2',
      });

      // Get Embedded Video Player API Instance
      window.FB.Event.subscribe('xfbml.ready', function(msg) {
        if (msg.type === 'video') {
          setFacebookInstances(facebookInstances => [...facebookInstances, msg.instance]);
        }
      });
    };
  }

  function addFBScript() {
    if (videos?.['facebook']?.length) {
      return (
        <Helmet>
          <script async defer src={`https://connect.facebook.net/${langMapISO[lang]}/sdk.js`} />
        </Helmet>
      );
    } else {
      return null;
    }
  }

  const lockedVideo = (
    <div className={cls('block-locked block-locked_video', { 'tiktok': selected === 'Tiktok' })}>
      <div className="block-locked-info-wrap">
        <Icon role="icon" type="lock_top_stores" />
      </div>
      <div className="block-locked-info-wrap_text">
          <span className="block-locked-title">
            {t('Locked Content')}
          </span>
        <span className="block-locked-text">
            {t('Upgrade your plan to unlock')}
          </span>
      </div>
      <ButtonComponent className="block-locked-btn"
                       onClick={() => onOpenUpgradePlan(true)}
      >
        {t('Upgrade Plan')}
      </ButtonComponent>
    </div>
  );

  if (fetching || loading) {
    return (
      <ProductDetailsCard header={loading ? renderHeader() : renderHeaderSkeleton}
                          withLine={!isMobile}
      >
        <div className="videos-card-wrap" style={{ overflow: 'hidden' }}>
          {
            (isMobile ? [1] : [...skeletonList]).map((el) => (
              <span className={'link skeleton'}
                    key={el}
                    style={{ width: isMobile ? '100%' : 279, height: 365, marginRight: isMobile ? 0 : 16 }}
              />
            ))
          }
        </div>
      </ProductDetailsCard>
    );
  } else {
    return (
      <ProductDetailsCard header={renderHeader()}
                          withLine={!isMobile}
                          bodyClassName={'with-scroll-body'}
      >
        <OverlayScrollbar visibility="hidden" ref={scrollRef}>
          <div className="videos-card-wrap">
            {addFBScript()}
            {
              !loading && videosList?.map(video => (
                <div key={`${video.platform}_${video?.id}`}
                     className={cls('videos-card', {
                       disabled: video.platform !== selected.toLowerCase() || isEmptyState,
                     })}
                >
                  {
                    video.platform === 'tiktok' ?
                      (selected === 'Tiktok' && !video?.is_locked ?
                        <InnerHTML html={tiktokData?.[video?.id]} /> : lockedVideo)
                      :
                      (
                        video?.is_locked ?
                          lockedVideo
                          :
                          <div className="fb-video"
                               data-href={video.url}
                               data-allowfullscreen="true"
                               data-width="290"
                               data-show-text="true"
                          />
                      )
                  }
                </div>
              ))
            }

            {
              isEmptyState ?
                (
                  <div className={'videos-card empty'}>
                    <Icon role={'icon'}
                          type={'competitors_empty'}
                    />
                  </div>
                )
                :
                null
            }
          </div>
        </OverlayScrollbar>
      </ProductDetailsCard>
    );
  }
};

export default VideosBlock;
