import React, { useEffect, useState } from 'react';
import { changeGreetingMessage } from '../../Utils/utils';
import { connect } from 'react-redux';
import BreadcrumbsActions from '../Breadcrumbs/actions';
import Creators from './reducer';
import UniversityVideoCard from './components/UniversityVideoCard';
import UniversityPageSkeleton from './components/skeletons/UniversityPageSkeleton';
import UniversityPageCourseWrapper from './components/UniversityPageCourseWrapper';
import TopPageHeader from '../SalesTrackerPage/pages/components/TopPageHeader';
import './styles.less';

const UniversityPage = (
  {
    isMobile,
    addBreadCrumb,
    clearBreadCrumbs,
    getCourses,
    getAdditionalChapters,
    additionalChapters,
    courses,
    loading,
    setSelectedChapter,
    setIsShort,
  }) => {

  const [greetingMessage, setGreetingMessage] = useState('Welcome');

  useEffect(() => {
    changeGreetingMessage(greetingMessage, setGreetingMessage);
    getCourses();
    getAdditionalChapters();
    setIsShort(false);
    document.title = 'University - Dropship';
    clearBreadCrumbs();
    addBreadCrumb({
      name: 'Dropship University',
      link: `/dropship-university`,
    });
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, []);

  if (loading) return <UniversityPageSkeleton isMobile={isMobile} />

  return (
    <div className={'university-page'}>
      <TopPageHeader icon={'university_dashboard'}
                     title={'Dropship University'}
                     subtitle={'Learn dropshipping from experts'}
      />
      {
        courses?.results?.map(el => (
          <UniversityPageCourseWrapper info={el}
                                       key={el?.id}
                                       disabled={false}
                                       skeleton={loading}
                                       isComingSoon={false}
          />
          ),
        )
      }
      {
        additionalChapters?.results?.map(el => (
          <div key={el?.id}>
            <div className="university-page-tools-wrapper">
              <div className="university-page-tools-header">
                      <h4 className="university-page-tools-title">
                        {el?.title}
                      </h4>
                    </div>
            </div>
            <div className="university-page-cards" key={el?.[0]?.id}>
              {
                el?.lessons.map(lesson => <UniversityVideoCard key={lesson?.id}
                                                               info={lesson}
                                                               chapter={el}
                                                               setSelected={setSelectedChapter}
                                                               setIsShort={setIsShort}
                />)
              }
            </div>
          </div>
        ))
      }
    </div>
  );
};

const mapStateToProps = (state) => ({
  isMobile: state.nav.isMobile,
  courses: state.universityPage.courses,
  additionalChapters: state.universityPage.additionalChapters,
  loading: state.universityPage.loading,
});

const mapDispatchToProps = (dispatch) => ({
  addBreadCrumb: (data) => dispatch(BreadcrumbsActions.addBreadCrumb(data)),
  clearBreadCrumbs: () => dispatch(BreadcrumbsActions.clearBreadCrumbs()),
  getCourses: () => dispatch(Creators.getCoursesRequest()),
  getAdditionalChapters: () => dispatch(Creators.getAdditionalChaptersRequest()),
  setSelectedChapter: (data) => dispatch(Creators.setSelectedChapter(data)),
  setIsShort: (data) => dispatch(Creators.setIsShort(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UniversityPage);
