import React, { useState } from 'react';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import { Divider, Dropdown } from 'antd';

const options = [
  // {    key: 'is_active',
  //   text: 'Active ads'
  // },
  {
    key: 'total_reaches_count',
    text: 'EU reach'
  },
  {
    key: 'ad_sets_count',
    text: 'Adsets'
  },
  {
    key: 'ad_spend',
    text: 'Ad spend'
  },
  {
    key: 'last_seen_date',
    text: 'Last seen date'
  },
  {
    key: 'created_at',
    text: 'Creation Date'
  },
];

const SortDropdown = (
  {
    isMobile,
    order,
    setSort,
    setVisibleModal
  }
) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [ordering, setOrdering] = useState(order?.includes('-') ? '-' : '');
  const [key, setKey] = useState(order?.replace('-', '') || '');

  const handleVisibleChange = (value) => setVisible(value);

  const handleOrderingChange = (newOrdering) => {
    setOrdering(newOrdering);
    if (key) {
      setSort(`${newOrdering}${key}`);
    }
  };

  const handleKeyChange = (newKey) => {
    if (order?.includes(newKey)) {
      setKey('');
      setSort(null);
    } else {
      setKey(newKey);
      setSort(`${ordering}${newKey}`);
    }
    setVisible(false);
  };

  return (
    isMobile ?
      <span className={'presets sort'}
            onClick={() => setVisibleModal('sort_modal')}
      >
        <svg xmlns="http://www.w3.org/2000/svg"
             width="20"
             height="20"
             viewBox="0 0 20 20"
             fill="none"
        >
          <path stroke="#707BA0"
                d="M5.00133 3.9585V16.0418M5.00133 16.0418L2.5 13.5418M5.00133 16.0418L7.5 13.5418M9.79167 5.62516H16.875M13.125 14.3752H16.875M11.4583 10.0002H16.875"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
          />
        </svg>
        {t('Sort by')}
      </span>
      :
      <Dropdown destroyPopupOnHide={true}
                getPopupContainer={(trigger) => trigger.parentNode}
                onOpenChange={handleVisibleChange}
                placement="bottomRight"
                dropdownRender={() => (
                  <div className={'sales-tracker-top-page-header-dropdown sort'}>
                    <span className="sort-header">
                      {t('Sort results from')}
                    </span>
                    <div className="sort-header-options">
                      <span className={cls("sort-header-option", {
                        active: ordering === '-'
                      })}
                            onClick={() => handleOrderingChange('-')}
                      >
                        {t('High to low')}
                      </span>
                      <span className={cls('sort-header-option', {
                        active: ordering === '',
                      })}
                            onClick={() => handleOrderingChange('')}
                      >
                        {t('Low to high')}
                      </span>
                    </div>
                    <Divider style={{ margin: '12px 0 8px' }} />
                    <div className="sort-options">
                      {
                        options.map(el => (
                          <span className={cls("sort-option", {
                            active: key === el?.key
                          })}
                                key={el?.key}
                                onClick={() => handleKeyChange(el?.key)}
                          >
                            {el?.text}
                          </span>
                        ))
                      }
                    </div>
                  </div>
                )}
                trigger={['click']}
                open={visible}
      >
        <span className={'presets sort'}>
          <svg xmlns="http://www.w3.org/2000/svg"
               width="20"
               height="20"
               viewBox="0 0 20 20"
               fill="none"
          >
            <path stroke="#707BA0"
                  d="M5.00133 3.9585V16.0418M5.00133 16.0418L2.5 13.5418M5.00133 16.0418L7.5 13.5418M9.79167 5.62516H16.875M13.125 14.3752H16.875M11.4583 10.0002H16.875"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
            />
          </svg>
          {t('Sort by')}
        </span>
      </Dropdown>
  );
};

export default SortDropdown;
