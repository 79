import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import cls from 'classname';
import utils from '../../../Utils/utils';
import Icon from '../../../Icon';
import './OverviewAdvertiserSlider.less';

const OverviewAdvertiserSlider = (
  {
    data = [],
    title = '',
    isMobile = false,
  }
) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();
  const containerRef = useRef(null);

  const [scrollProps, setScrollProps] = useState({
    current: 0,
    max: 0
  });

  const handleClick = (type) => {
    if (!containerRef.current) return;
    const value = type === 'next' ?
      scrollProps?.current + 300 >= scrollProps?.max ?
        scrollProps?.max - 1
        :
        scrollProps?.current + 300
        :
      scrollProps?.current - 300 < 0 ?
        0
        :
        scrollProps?.current - 300;
    containerRef.current.scrollTo({
      left: value,
      behavior: 'smooth',
    });
    setScrollProps(prev => ({ ...prev, current: value  }));
  }

  useEffect(() => {
    if (containerRef.current) {
      const max = containerRef?.current?.scrollWidth - containerRef?.current?.clientWidth;
      setScrollProps({ current: 0, max, });
    }
  }, [containerRef.current]);

  return (
    <div className="overview-advertiser-slider">
      {isMobile ? null : (
        <span className="overview-advertiser-slider-title">
          {title}
        </span>
      )}
      <div ref={containerRef} className="overview-advertiser-slider-items"
           onScroll={e => {
             setScrollProps(prev => ({ ...prev, current: e?.target?.scrollLeft}));
           }}
      >
        {
          data.map(el => (
            <div 
              className="overview-advertiser-slider-item"
              key={el?.id}
              onClick={() => navigate(`/ad-spot/${id}/all_ads/${search.split('&').filter(s => !s.includes('same')).join('&')}&same=${el?.id}`)}
            >
              <div className="overview-advertiser-slider-item-image">
                <img
                  src={el?.icon}
                  alt=""
                  onError={utils.addDefaultSrc}
                  width="20"
                  height="20"
                />
                {
                  el?.is_verified ?
                    <span className="overview-advertiser-slider-item-image-checkmark">
                      <Icon role={'icon'} type={'ad_spot_checkmark'} width={12} height={12} />
                    </span>
                    :
                    null
                }
              </div>
              <span className="overview-advertiser-slider-item-name">
                {
                  el?.name
                }
              </span>
              <span className="overview-advertiser-slider-item-total_ads">
                {
                  el?.total_ads 
                } {t('ads')}
              </span>
            </div>
          ))
        }
      </div>
      <div className="overview-advertiser-slider-arrows">
        <span className={cls("overview-advertiser-slider-arrow prev", {
          disabled: scrollProps?.current === 0 || scrollProps?.max === 0,
        })}
              onClick={() => handleClick('prev')}
        >
          <Icon type={'arrow_next'} role={'icon'} />
        </span>
        <span className={cls('overview-advertiser-slider-arrow next', {
          disabled: scrollProps?.current + 10 >= scrollProps?.max || scrollProps?.max === 0,
        })}
              onClick={() => handleClick('next')}
        >
          <Icon type={'arrow_next'} role={'icon'} />
        </span>
      </div>
    </div>
  );
};

export default OverviewAdvertiserSlider;