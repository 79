import React from 'react';
import { useTranslation } from 'react-i18next';
import InputComponent from '../../../Components/Input';
import './InputFilter.less';
import Icon from '../../../Icon';

const InputFilter = ({
  placeholder,
  label,
  id,
  setInputFilters,
  inputFilters,
  withSeparate = false,
  tooltip,
  isMobile
}) => {
  const { t } = useTranslation();

  const handleChange = (e, type) => {
    const rawValue = e.target.value.replace(/,/g, '');
    if (!isNaN(rawValue) || rawValue === '') {
      setInputFilters((prev) => ({
        ...prev,
        [id]: {
          ...prev?.[id],
          [type]: rawValue ? +rawValue : '',
        },
      }));
    }
  };

  const formatNumber = (number) => {
    if (number === '' || number == null) return '';
    return withSeparate
      ? Number(number).toLocaleString('en-US')
      : number;
  };

  return (
    <div className="input-filter">
      <div className="select-block_label-wrapper">
        <span className="input-filter-label">{t(label)}</span>
        {tooltip && <div className='tooltip-wrap'>
          <Icon type={'attention_outline'}
            role={'button'}
            width={16}
            height={16}
            tooltipProps={{
              placement: 'right',
              trigger: isMobile ? 'click' : 'hover',
              arrow: true,
              destroyTooltipOnHide: true,
              overlayClassName: 'info-text_tooltip',
              getPopupContainer: (trigger) => trigger.parentNode,
            }}
            titleText={t(tooltip)}
          />
        </div>
        }
      </div>
      <div className="input-filter-input-wrapper">
        <InputComponent
          suffix="MIN"
          className="filter-input"
          placeholder={t(placeholder)}
          onChange={(e) => handleChange(e, 'min')}
          type="text"
          value={formatNumber(inputFilters?.[id]?.min)}
        />
        <InputComponent
          suffix="MAX"
          className="filter-input"
          placeholder={t(placeholder)}
          onChange={(e) => handleChange(e, 'max')}
          type="text"
          value={formatNumber(inputFilters?.[id]?.max)}
        />
      </div>
    </div>
  );
};

export default InputFilter;
