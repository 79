import React, { useState,useEffect } from 'react';
import { Divider } from 'antd';
import InputComponent from '../Input';
import { useTranslation } from 'react-i18next';
import ChangePasswordModal from './ChangePasswordModal';
import UserCreators from '../../Containers/User/reducer';
import { connect } from 'react-redux';
import ForgotPasswordModal from './ForgotPasswordModal';
import CreateNewPassword from './CreateNewPasswordModal';

const Security = (
  {
    isMobile,
    setModal,
    modal,
    saveFetching,
    userError,
    userInfo,
    saveResult,
    userErrorsReset,
    userSaveResultReset,
    changePassword,
    verifyEmail,
    sendCodePassword,
    createNewPassword,
    code,
    getUserInfo
  }
) => {
  const {t} = useTranslation();
  const [resendForgotPassword, setResendForgotPassword] = useState(false);
  const [deadlineResend, setDeadlineResend] = useState(null);

  useEffect(()=>{
    if(saveResult?.create){

      getUserInfo()
    }
  },[saveResult])

  const handleSendCodePassword = () => {
    sendCodePassword(userInfo?.email);
  };

  return (
    <div className="account-subpage-block">
        <span className="account-subpage-block-title">
          {t('Security')}
        </span>
      <Divider type={'horizontal'}
               style={{ margin: '16px 0' }}
      />
      <div className="account-subpage-block-email">
        <div className="input-wrapper">
          <span className="input-label">
            {t('Password')}
          </span>
          <InputComponent placeholder={userInfo.password_created ? '●'.repeat(10) : ''}
                          disabled
          />
        </div>
        <div className="btn-wrapper">
          <span onClick={() => {userInfo.password_created ?  setModal('change_password') : setModal('create_new_password')}}>
            {userInfo.password_created ?  t('Change password') : t('Set Password')}
          </span>
        </div>
      </div>
      <ChangePasswordModal visible={modal === 'change_password'}
                           setModal={setModal}
                           loading={saveFetching}
                           isMobile={isMobile}
                           userError={userError?.detail}
                           userErrorsReset={userErrorsReset}
                           changePassword={changePassword}
                           saveResult={saveResult}
                           userSaveResultReset={userSaveResultReset}
                           handleSendCodePassword={handleSendCodePassword}
                           setResendFetching={setResendForgotPassword}
                           setDeadline={setDeadlineResend}
      />
      <ForgotPasswordModal visible={modal === 'forgot_password'}
                           email={userInfo.email}
                           verifyEmail={verifyEmail}
                           setModal={setModal}
                           handleSendCodePassword={handleSendCodePassword}
                           setResendFetching={setResendForgotPassword}
                           setDeadline={setDeadlineResend}
                           resendFetching={resendForgotPassword}
                           deadline={deadlineResend}
      />
      <CreateNewPassword
        visible={modal === 'create_new_password'}
        setModal={setModal}
        loading={saveFetching}
        isMobile={isMobile}
        userError={userError?.detail}
        userErrorsReset={userErrorsReset}
        createNewPassword={createNewPassword}
        saveResult={saveResult}
        userSaveResultReset={userSaveResultReset}
        handleSendCodePassword={handleSendCodePassword}
        setResendFetching={setResendForgotPassword}
        setDeadline={setDeadlineResend}
        code={code}
        setNewPassword={!userInfo.password_created}
      />
    </div>
    
  );
};
const mapStateToProps = (state) => ({
  isMobile: state.nav.isMobile,
  userError: state.users.errors,
  saveResult: state.users.saveResult,
  saveFetching: state.users.saveFetching,
  userInfo: state.auth.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  userSaveResultReset: () => dispatch(UserCreators.userSaveResultReset()),
  userErrorsReset: () => dispatch(UserCreators.userErrorsReset()),
  changePassword: (password) => dispatch(UserCreators.changePasswordRequest(password)),
  verifyEmail: (email) => dispatch(UserCreators.verifyEmailRequest(email)),
  sendCodePassword: (email) => dispatch(UserCreators.sendCodePasswordRequest(email)),
  createNewPassword: (password, confirm_code) =>
    dispatch(UserCreators.createNewPasswordRequest(password, confirm_code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Security);
