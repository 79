import React, { useEffect, useState } from 'react';
import { Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import AuthActions from '../../Containers/LoginPage/reducer';
import { connect } from 'react-redux';
import cls from 'classname';
import { openNotificationWithIcon } from '../Notification';
import { useGoogleLogin } from '@react-oauth/google';
import AppleSignin from 'react-apple-signin-auth';
import Icon from '../../Icon';
import { DefaultMsg } from '../Notification/notification-message';
import DisconnectSocialModal from './DisconnectSocialModal';

const ConnectedAccounts = (
  {
    userInfo,
    isMobile,
    socialAuthConnectResult,
    socialAuthDeleteResult,
    socialAuthReset,
    socialAccountSave,
    socialAuthDelete,
    socialAuthConnect,
  }
) => {

  const { accounts, password_created } = userInfo;

  const {t} = useTranslation();

  const [modal, setModal] = useState(null);
  const [socialType, setSocialType] = useState(null);

  function disconnectAccount(acc) {
    setModal(null);
    const accountsNum = Object.values(accounts)?.filter((el) => !!el)?.length;
    if (!password_created && accountsNum < 2) {
      openNotificationWithIcon({
        key: `open${Date.now()}`,
        style: { width: '430px' },
        className: 'notification notification_rename',
        message: (
          <DefaultMsg text={null}
                      icon="notification_warning"
                      title={t(`Please set a password for your Dropship account before you disconnect ${socialType.charAt(0).toUpperCase() + socialType.slice(1)} account`)}
          />
        ),
      });
    } else {
      socialAccountSave(acc);
      socialAuthDelete(acc);
    }
  }

  function connectAccount(acc, dataResponse) {
    socialAuthConnect(dataResponse, acc);
    socialAccountSave(acc);
  }

  const responseApple = (response) => {
    if (response?.authorization?.id_token) {
      connectAccount('apple', response.authorization?.id_token);
    }
  };

  const responseGoogle = useGoogleLogin({
    onSuccess: (response) => {
      if (response && response?.access_token) {
        connectAccount('google', response.access_token);
      }
    },
    onFailure: (response) => {
      if (response && response?.access_token) {
        connectAccount('google', response.access_token);
      }
    }
  });

  useEffect(() => {
    if (socialAuthDeleteResult || socialAuthConnectResult) {
      socialAuthReset();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [socialAuthDeleteResult, socialAuthConnectResult]);

  return (
    <div className="account-subpage-block">
      <span className="account-subpage-block-title">
        {t('Connected accounts')}
      </span>
      <Divider type={'horizontal'}
               style={{ margin: '16px 0 0' }}
      />
      <div className="account-subpage-block-accounts">
        <div className="account-wrapper">
          <span className="account-title">
            <Icon role="icon" type="google_icon" />
            Google
          </span>
          <span className="account-domain">
            {
              accounts?.google ?
                accounts?.google
                :
                null
            }
          </span>
          <span className={cls('account-action', {
            connected: accounts?.google,
          })}
                onClick={() => {
                  setSocialType('google');
                  if (accounts?.google) {
                    setModal('disconnect_social');
                  } else {
                    responseGoogle();
                  }
                }}
          >
            {
              accounts?.google ?
                t('Disconnect')
                :
                t('Connect')
            }
          </span>
        </div>
        <div className="account-wrapper">
          <span className="account-title">
            <Icon role="icon" type="apple_icon" />
            Apple
          </span>
          <span className="account-domain">
            {
              accounts?.apple ?
                accounts?.apple
                :
                null
            }
          </span>
          <AppleSignin authOptions={{
            clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
            scope: 'email name',
            redirectURI: process.env.REACT_APP_BASE_URI + '/login',
            state: 'state',
            nonce: 'nonce',
            usePopup: true,
          }}
                       className="apple-auth-btn"
                       noDefaultStyle={false}
                       onSuccess={responseApple}
                       onError={responseApple}
                       skipScript={false}
                       render={(renderProps) => (
                         <span className={cls('account-action', {
                           connected: accounts?.apple,
                         })}
                               onClick={() => {
                                 setSocialType('apple');
                                 if (accounts?.apple) {
                                   setModal('disconnect_social');
                                 } else {
                                   renderProps.onClick();
                                 }
                               }}
                         >
                           {
                             accounts?.apple ?
                               t('Disconnect')
                               :
                               t('Connect')
                           }
                         </span>
                       )}
          />
        </div>
      </div>
      <DisconnectSocialModal isMobile={isMobile}
                             setModal={setModal}
                             visible={modal === 'disconnect_social'}
                             socialType={socialType}
                             disconnectAccount={disconnectAccount}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  socialAuthConnectResult: state.auth.socialAuthConnectResult,
  socialAuthDeleteResult: state.auth.socialAuthDeleteResult,
  isMobile: state.nav.isMobile,
});

const mapDispatchToProps = (dispatch) => ({
  socialAuthConnect: (accessToken, type, user) => dispatch(AuthActions.socialAuthConnectRequest(accessToken, type, user)),
  socialAuthDelete: (type) => dispatch(AuthActions.socialAuthDeleteRequest(type)),
  socialAuthReset: () => dispatch(AuthActions.socialAuthReset()),
  socialAccountSave: (name) => dispatch(AuthActions.socialAccountSave(name)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ConnectedAccounts);
