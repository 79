import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Modal from '../../../Components/Modal';
import { Divider, Spin } from 'antd';
import IconWithText from '../../../Components/Text';
import Icon from '../../../Icon';
import cls from 'classname';

const DatabaseProductsOptionsModal = (
  {
    setModal,
    isMobile,
    visible,
    record,
    toggleConnectProduct,
    toggleConnectStore,
    handleOpenModalShopifyConnect,
    handleOpenModalAutoDSConnect,
    setCompetitor,
    view,
  }
) => {

  const { t } = useTranslation();
	const theme = useSelector(state => state?.nav?.theme);

  return (
    <Modal handleClose={() => setModal(false)}
           title={t('Product actions')}
           isMobile={isMobile}
           width={450}
           className="collection-options-modal collection-modal product-actions-modal"
           open={visible}
           destroyOnClose
    >
      <Spin spinning={false}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {
              t('You are able to import product or start tracking it from here.')
            }
          </p>
          <ul className={'filter-preset-action-dropdown database-action-dropdown'}>
            <li className="action-dropdown_item"
								onClick={() => {
								setCompetitor(record.title)
								window.open('/competitor-research', '_blank')
								}}>
						<IconWithText
								size={24}
								icon={() => <Icon type={'search_icon'} role={'icon'} color={theme === 'light' ? '#434C69' : 'rgba(255, 255, 255, 0.259)'}/> }
								text={t('Find competitors')}
						/>
						</li>
            {view === 'details' ?
              <>
                <Divider type={'horizontal'}
                        style={{ margin: 0 }}
                />
                <li className={cls('action-dropdown_item active', { 'tracked': record?.is_tracked })}
                    onClick={() => {
                      setModal(false);
                      toggleConnectProduct(record);
                    }}
                >
                  <IconWithText size={24}
                                icon={() => record?.is_tracked ?
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                      fill="none">
                                    <path d="M6.45898 6.4585L13.5423 13.5418M13.5423 6.4585L6.45898 13.5418"
                                          stroke="#D71313" strokeWidth="1.5" strokeLinecap="round" />
                                  </svg>
                                  :
                                  <Icon type={'database_price'} role={'icon'} />
                                }
                                text={record?.is_tracked ? t('Stop tracking product') : t('Start tracking product')}
                  />
                </li>
              </>
            : null}
            <li className={cls('action-dropdown_item active', { 'tracked': record?.store?.is_tracked })}
                onClick={() => {
                  setModal(false);
                  toggleConnectStore(record);
                }}
            >
              <IconWithText size={24}
                            icon={() => record?.store?.is_tracked ?
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                   fill="none">
                                <path d="M6.45898 6.4585L13.5423 13.5418M13.5423 6.4585L6.45898 13.5418"
                                      stroke="#D71313" strokeWidth="1.5" strokeLinecap="round" />
                              </svg>
                              :
                              <Icon type={'tracking_store_database'} role={'icon'} />
                            }
                            text={record?.store?.is_tracked ? t('Stop tracking store') : t('Start tracking store')}
              />
            </li>
            {view === 'details' ? <Divider type={'horizontal'} style={{ margin: 0 }} /> : null }
            <li className="action-dropdown_item"
                onClick={() => {
                  setModal(false);
                  handleOpenModalShopifyConnect(`${record?.store?.id}_${record?.id}`);
                }}
            >
              <IconWithText size={24}
                            icon={() => <Icon type={'shopify_btn_icon'} role={'icon'} isLogo={true} />}
                            text={t('Import to Shopify')}
              />
            </li>
            <li className="action-dropdown_item"
                onClick={() => {
                  setModal(false);
                  handleOpenModalAutoDSConnect({
                    'source': 'shopify',
                    'product_id': `${record?.store?.id}_${record?.id}`,
                  });
                }}
            >
              <IconWithText icon={() => <Icon type={'auto_ds'} role={'icon'} width={18} height={18} />}
                            text={t('Import to AutoDS')}
              />
            </li>
          </ul>
        </div>
      </Spin>
    </Modal>
  );
};

export default DatabaseProductsOptionsModal;
