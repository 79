import {API, encryptedData} from '../../Services/Api';
const request = new API();

export const getAdLibraryFilters = () =>
  request.api.get(`ad_library/choice_filters/`, null);

export const getAdLibraryPresets = () =>
  request.api.get(`ad_library/filter_presets/`, null);

export const createAdLibraryPreset = (data) =>
  request.api.post(`ad_library/filter_presets/`, encryptedData(data));

export const deleteAdLibraryPreset = (record) =>
  request.api.delete(`ad_library/filter_presets/${record.id}/`, null);

export const updateAdLibraryPreset = (data) =>
  request.api.patch(`ad_library/filter_presets/${data.record.id}/`, encryptedData({name: data.name}));

export const getAdLibraryAds = (data) => {
  const buildQueryParams = (params) =>
    Object.entries(params)
      .filter(([_, value]) => value !== undefined && value !== null)
      .map(([key, value]) => 
        Array.isArray(value) 
          ? value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&') 
          : `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');

  const queryString = buildQueryParams(data);

  return request.api.get(`ad_library/ads/search/?${queryString}`);
};

export const getAdLibraryAdsDefault = (data) => {
  const { page_size, page_number, ordering } = data;
  let url = `ad_library/ads/search/default/?page_size=${page_size}&page_number=${page_number}`;
  
  if (ordering) {
    url += `&ordering=${ordering}`;
  }
  
  return request.api.get(url);
};

export const getAdLibraryAdsCollation = (data) => {
  const { page_size, page_number, collation_id } = data;

  return request.api.get(
    `ad_library/collations/${collation_id}/adsets/?page_size=${page_size}&page_number=${page_number}`
  );
};