import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../Components/Modal';
import { Divider, Spin } from 'antd';
import IconWithText from '../../../Components/Text';
import Icon from '../../../Icon';
import cls from 'classname';

const UserTrackingsActionsModal = (
  {
    view,
    setInnerModal,
    visible,
    record,
    setModal,
    isTrial,
    setRecordToDelete
  }
) => {

  const { t } = useTranslation();

  return (
    <Modal handleClose={() => setInnerModal(false)}
           title={t(`${view} actions`)}
           isMobile={true}
           width={450}
           className="collection-options-modal collection-modal product-actions-modal"
           open={visible}
           destroyOnClose
    >
      <Spin spinning={false}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {
              t(`You are able to view ${view} insights or stop tracking it from here.`)
            }
          </p>
          <ul className={'filter-preset-action-dropdown database-action-dropdown'}>
            <li className={'action-dropdown_item active'}
                onClick={() => {
                  setInnerModal(false);
                  if (record?.scraping_status === 'untrackable' && (!record?.month_revenue && !record?.week_revenue && !record?.yesterday_revenue && !record?.day_revenue)) {
                    setRecordToDelete({type: 'store', data: {...record}})
                    setModal('untrackable_delete_tracking');
                  } else if (record?.scraping_status === 'gathering') {
                    setModal('gathering_data');
                  } else {
                    window.open(`/sales-tracker/stores/store/${record?.id}`, '_self');
                  }
                }}
            >
              <IconWithText size={24}
                            icon={() => <Icon type={'product_insights'} role={'icon'} />}
                            text={t(`${view} insights`)}
              />
            </li>
            <li className={'action-dropdown_item active tracked'}
                onClick={() => {
                  setInnerModal(false);
                  if (isTrial && record?.scraping_status !== 'untrackable') {
                    setModal('can_not_remove_tracking');
                  } else {
                    setRecordToDelete({type: 'store', data: {...record}})
                    setModal('delete_tracking');
                  }
                }}
            >
              <IconWithText size={24}
                            icon={() =>
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                                   fill="none">
                                <path d="M6.45898 6.4585L13.5423 13.5418M13.5423 6.4585L6.45898 13.5418"
                                      stroke="#D71313" strokeWidth="1.5" strokeLinecap="round" />
                              </svg>
                            }
                            text={t(`Stop tracking ${String(view).charAt(0).toLowerCase() + String(view).slice(1)}`)}
              />
            </li>
          </ul>
        </div>
      </Spin>
    </Modal>
  );
};

export default UserTrackingsActionsModal;
