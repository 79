import React from 'react';
import SupplierCardSkeleton from "./SupplierCardSkeleton";

const SuppliersPageSkeleton = ({isMobile}) => {

  return (
    <div>
      <div className="sales-tracker-top-page-header">
        <div className="title-wrapper">
          {
            isMobile ?
              null
              :
              <span className={'link skeleton'} style={{ width: 40, height: 40 }} />
          }
          <div className="sales-tracker-top-page-subheader">
          <span className={'link skeleton'} style={{ width: 70, height: 16 }} />
            <span className={'link skeleton'} style={{ width: 252, height: 16, marginTop: 4 }} />
          </div>
        </div>
      </div>
      <div className={'auto-ds-banner'}>
        <div className="auto-ds-banner-info-wrapper">
          <div className="auto-ds-banner-info-logo">
            <span className={'link skeleton'} style={{ width: '100%', height: '100%' }} />
          </div>
          <div className="auto-ds-banner-info-text">
            <span className={'link skeleton'} style={{ width: 140, height: 20 }} />
            <span className={'link skeleton'} style={isMobile ? { width: 280, height: 40 } : { width: 561, height: 20 }} />
          </div>
        </div>
        <span className={'link skeleton'} style={{ width: 176, height: 40 }} />
      </div>

      <div className="suppliers-page-cards-wrapper">
        {
          [1, 2, 3].map(el => <SupplierCardSkeleton key={el} />)
        }
      </div>
    </div>
  );
};

export default SuppliersPageSkeleton;
