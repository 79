import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import OnboardingActions from '../../Redux/OnboardingRedux';
import SettingCreators from '../../Containers/SettingPage/reducer';
import { openNotificationWithIcon } from '../Notification';
import { ArrowSelectDown } from '../../Icon/img';
import { Divider } from 'antd';
import Icon from '../../Icon';
import { DefaultMsg } from '../Notification/notification-message';
import EditCardModal from './EditCardModal';

const creditCardType = {
  'Visa': 'visa',
  'visa': 'visa',
  'American Express': 'american_express',
  'american_express': 'american_express',
  'mastercard': 'master_card',
  'MasterCard': 'master_card',
  'Bancontact': 'bancontact',
  'Belfius': 'belfius',
  'Ideal': 'ideal',
  'Ing': 'ing',
  'Kbc': 'kbc',
  'Maestro': 'maestro',
  'Paypal': 'paypal',
  'SEPA': 'sepa',
  'Sofort': 'sofort',
}

const PayMethod = (
  {
    userInfo,
    isMobile,
    changeStripeCard,
    theme,
    onBoarding,
    createIntent,
    clearIntent,
    defaultUserCountry,
    getCountries,
    changePayments,
    saveFetching,
    saveResult,
    errors,
    resetErrors,
    resetResult
  }
) => {

  const {t} = useTranslation();
  const [modal, setModal] = useState(null);
  const [spin, setSpin] = useState(false);

  useEffect(() => {
    if (errors?.change_payments) {
      openNotificationWithIcon({
        key: `open${Date.now()}`,
        style: { width: '400px' },
        className: 'notification notification_delete',
        message: (
          <DefaultMsg text={'Something went wrong, kindly try adding your new payment method again.'}
                      icon="notification_warning"
                      title={'Something went wrong'}
          />
        ),
      });
      resetErrors();
    }
    if (saveResult?.change_payments) {
      openNotificationWithIcon({
        key: `open${Date.now()}`,
        style: { width: '400px' },
        className: 'notification notification_delete',
        message: (
          <DefaultMsg text={'You can now update your plan or increase tracking limits limits much faster.'}
                      icon="payment_added"
                      title={'Payment method was successfully added'}
          />
        ),
      });
      resetResult();
    }
    setSpin(false);
    if(modal) setModal(null);
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [saveResult, errors]);

  return (
    <div className="billing-subpage-block">
      <div className="billing-subpage-block-title-wrapper">
        <span className='billing-subpage-block-title'>
          {t('Payment method')}
        </span>
        <span className='billing-subpage-block-link'
              onClick={() => {
                if (userInfo?.use_stripe) {
                  changeStripeCard();
              } else {
                  setModal('edit_card');
                }
              }}
        >
          {
            isMobile ?
                t( Boolean(userInfo?.card) ?
                'Replace method'
                :
                'Add method')
              :
              t( Boolean(userInfo?.card) ?
                'Replace payment method'
                :
                'Add payment method')
          }
          <ArrowSelectDown />
        </span>
      </div>
      <Divider style={{ margin: '16px 0' }} />
      {
        !Boolean(userInfo?.card) ?
          <div className="billing-subpage-block-card-wrapper empty">
            <span className="title">
              {t('No payment method added')}
            </span>
            <span className="text">
              {t('Add payment method to pay faster.')}
            </span>
          </div>
          :
          <div className="billing-subpage-block-card-wrapper payment-type-icon">
            {
              creditCardType?.[userInfo?.card?.card_type] ?
                <Icon role="icon" type={creditCardType?.[userInfo?.card?.card_type]}/>
                :
                null
            }
            <div className="info">
              <span className="title">
                {`${userInfo?.card?.card_type.charAt(0).toUpperCase() + userInfo?.card?.card_type.slice(1)} ${t('ending')} ${userInfo?.card?.last_4}`}
              </span>
              <span className="text">
                {`${t('Expires')} ${userInfo?.card?.expire_month}/${userInfo?.card?.expire_year?.slice(2)}`}
              </span>
            </div>
          </div>
      }
      <EditCardModal visible={modal === 'edit_card'}
                     isMobile={isMobile}
                     setModal={setModal}
                     loading={onBoarding?.intentFetching || saveFetching || spin}
                     isAdd={!Boolean(userInfo?.card)}
                     theme={theme}
                     onBoarding={onBoarding}
                     intent={{
                       result: onBoarding?.intentResult,
                       error: onBoarding?.intentError,
                       createIntent: createIntent,
                       clearIntent: clearIntent,
                       amount: null,
                     }}
                     billingInfo={{ country: defaultUserCountry}}
                     userInfo={userInfo}
                     getCountries={getCountries}
                     changePayments={changePayments}
                     setSpin={setSpin}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  theme: state.nav.theme,
  onBoarding: state?.onBoarding,
  defaultUserCountry: state.setting.userCountry,
  saveFetching: state.setting.saveFetching,
  saveResult: state.setting.saveResult,
  errors: state.setting.errors,
});

const mapDispatchToProps = (dispatch) => ({
  changeStripeCard: () => dispatch(OnboardingActions.changeStripeCardRequest()),
  createIntent: data => dispatch(OnboardingActions.createIntentRequest(data)),
  clearIntent: () => dispatch(OnboardingActions.createIntentReset()),
  getCountries: () => dispatch(OnboardingActions.countriesRequest()),
  changePayments: (data) => dispatch(SettingCreators.changePaymentsRequest(data)),
  resetErrors: () => dispatch(SettingCreators.settingsErrorsReset()),
  resetResult: () => dispatch(SettingCreators.settingsSaveResultReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PayMethod);
