import React, { useState, useRef, useEffect } from 'react';
import { Table } from 'antd';
import cls from 'classname';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import utils from '../../../../Utils/utils';
import acc from 'accounting';
import ButtonComponent from '../../../../Components/Button';
import Chart from '../../../../Components/Charts/Chart';
import ArrowSelectDown from '../../../../Icon/img/ArrowSelectDown';
import Icon from '../../../../Icon';
import { changeColumnsProduct } from './columnsProducts';
import { changeColumnsStore } from './columnsStore';
import '../SalesTrackerTopPage.less';
import './SalesTrackerTopPageTable.less';
import './SalesTrackerTopPageTableItem.less';
import UserTrackingsEmpty from '../../components/UserTrackingsEmpty';

const SalesTopTrackerTopTable = ({
  view,
  expandedRowKeys,
  setExpandedRowKeys,
  activePeriod,
  isMobile,
  setStoreProductsToShow,
  setVisible,
  setModal,
  showAllCharts,
  topProducts,
  topStores,
  loading,
  setRecordToPriceHistory,
  setRecordToDelete,
  period,
  isTrial,
  connectStore,
  connectProduct,
  visible,
  onSort,
  sortOrder
}) => {
  const { t } = useTranslation();
  const [localPeriod, setLocalPeriod] = useState(activePeriod?.filter);
  const [chartTypeMap, setChartTypeMap] = useState({});
  const theme = useSelector((state) => state.nav.theme);

  const tableRef = useRef();

  const columns =
    view === 'stores'
      ? changeColumnsStore()({
          t,
          isMobile,
          setStoreProductsToShow,
          setVisible,
          setModal,
          isTrial,
          setRecordToDelete,
          view,
          connectStore,
          sortOrder,
          period
        })
      : changeColumnsProduct()({
          t,
          isMobile,
          setRecordToPriceHistory,
          setVisible,
          setModal,
          visible,
          isTrial,
          setRecordToDelete,
          view,
          connectProduct,
          sortOrder,
          period
        });

  useEffect(() => {
    if (showAllCharts) {
      setExpandedRowKeys(dataWithKeys.map((item) => item.key));
    } else {
      setExpandedRowKeys([]);
    }
  }, [showAllCharts]);

  useEffect(() => {
    const initialChartTypes = dataWithKeys.reduce((acc, record) => {
      acc[record.key] = 'line';
      return acc;
    }, {});
    setChartTypeMap(initialChartTypes);
  }, []);

  const handleExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys((prev) => [...prev, record.key]);
    } else {
      setExpandedRowKeys((prev) => prev.filter((key) => key !== record.key));
    }
  };

  useEffect(() => {
    setTimeout(() => setLocalPeriod(period), 500);
  }, [period]);

  const dataProductsSource = topProducts?.length
    ? topProducts?.map((u) => ({ ...u, key: u.product_id }))
    : [];
  const dataStoresSource = topStores?.length
    ? topStores?.map((u) => ({ ...u, key: u.product_id }))
    : [];

  const dataSource = view === 'stores' ? dataStoresSource : dataProductsSource;

  const dataWithKeys = dataSource.map((item, index) => ({
    ...item,
    key: item.id || index,
  }));

  const mouseOver = (e) => {
    let target = e.target;
    const { scrollWidth, clientWidth } = target;
    if (scrollWidth > clientWidth) target.classList.add('overflowing');
  };

  const mouseLeave = (e) => {
    let target = e.target;
    target.classList.remove('overflowing');
  };

  const getTotalInfo = (record) =>
    view === 'stores'
      ? [
          {
            title: t('Estimated total revenue'),
            value: `$${acc.formatNumber(
              record?.[`${localPeriod}_revenue`] || 0,
              2,
              ',',
              '.',
            )}`,
            withDivider: !isMobile,
          },
        ]
      : [
          {
            title: t('Estimated total revenue'),
            value: `$${acc.formatNumber(
              record?.[`${localPeriod}_revenue`] || 0,
              2,
              ',',
              '.',
            )}`,
            withDivider: !isMobile,
          },
        ];

  const toggleChartType = (key, type) => {
    setChartTypeMap((prev) => {
      if (prev[key] === type) return prev;
      return { ...prev, [key]: type };
    });
  };

  return (
    <div
      id={'top-store-top-products-table-body'}
      className={cls(
        'products-details-table-body product-database-table-body',
        'products',
        {
          'products-details--empty':
            dataProductsSource.length === 0 || dataStoresSource.length === 0,
        },
      )}
    >
    {dataWithKeys.length === 0 ? (
      <UserTrackingsEmpty view={view} witOutButton/>
    ) : (
      <Table
        components={{
          // eslint-disable-next-line react/prop-types
          table: ({ className, children, ...other }) => (
            <>
              <table
                {...other}
                ref={tableRef}
                className={cls(className, 'other-wrapper')}
              >
                {children}
              </table>
            </>
          ),
        }}
        className="list-table"
        loading={loading}
        rowClassName={(record) =>
          record['is_locked'] ? 'details-row details-row_locked' : 'details-row'
        }
        dataSource={dataWithKeys}
        columns={columns}
        pagination={false}
        onChange={(pagination, filters, sorter) => {
          onSort(sorter);
        }}
        scroll={{
          y: `calc(${
            isMobile ? '100dvh - 180px' : '100dvh - 82px'
          }`,
        }}
        sticky={{
          offsetHeader: 0,
          offsetScroll: 0,
          getContainer: () =>
            document.querySelector('.product-database-table-body'),
        }}
        expandable={{
          expandedRowKeys: expandedRowKeys,
          expandedRowRender: (record) => {
            const totalInfo = getTotalInfo(record);
            return (
              <div
                className={`expanded-content ${
                  expandedRowKeys.includes(record.product_id) ? 'active' : ''
                }`}
              >
                <div className={cls('sales-tracker-top-table-item')}>
                  <div className="sales-tracker-top-table-item-header">
                    <div className="total-info">
                      {totalInfo.map((el) => (
                        <React.Fragment key={el?.title}>
                          <div className="total-info-block">
                            <div className="title">{el?.title}</div>
                            <div
                              className={cls('value', {
                                clickable:
                                  Boolean(el?.onClick) && !record?.is_locked,
                              })}
                              onClick={
                                Boolean(el?.onClick) && !record?.is_locked
                                  ? el?.onClick
                                  : null
                              }
                            >
                              {record?.is_locked
                                ? t('Hidden')
                                : el?.title.includes('Tracked By')
                                ? `${el?.value} Others`
                                : el?.value}
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                    </div>

                    <div className="actions">
                      <ButtonComponent
                        className={cls('sales-tracker-top-table-button', {
                          'sales-tracker-top-table-button-active':
                            record?.is_tracked,
                        })}
                        onClick={() => {
                          if (record?.is_locked) navigate('/setting/plan');
                          else {
                            if (record?.is_tracked) {
                              setVisible(true);
                              if (isTrial) {
                                setModal('canNotRemoveTracking');
                              } else {
                                setRecordToDelete({
                                  type: view.slice(0, -1),
                                  data: { ...record },
                                });
                                setModal('deleteTracking');
                              }
                            } else {
                              view === 'stores'
                                ? connectStore({
                                    internal_shop_id: record?.internal_shop_id,
                                  })
                                : connectProduct({
                                  internal_shop_id: record?.store?.internal_shop_id,
                                  product_id: record?.id,
                                  });
                            }
                          }
                        }}
                        text={''}
                      >
                        <Icon
                          role={'icon'}
                          type={record?.is_locked ? 'unlock' : 'start_tracking'}
                        />
                        {t(
                          record?.is_locked
                            ? 'Upgrade Plan'
                            : record?.is_tracked
                            ? 'Stop tracking'
                            : 'Start tracking',
                        )}
                      </ButtonComponent>
                      <div className="period-filter-wrapper-icons">
                        <div
                          className={cls('period-filter', {
                            active: chartTypeMap[record.key] === 'line',
                          })}
                          onClick={() => toggleChartType(record.key, 'line')}
                          style={{ cursor: 'pointer' }}
                        >
                          <Icon type={`line_chart`} role={'icon'} />
                        </div>
                        <div
                          className={cls('period-filter', {
                            active: chartTypeMap[record.key] === 'bar',
                          })}
                          onClick={() => toggleChartType(record.key, 'bar')}
                          style={{ cursor: 'pointer' }}
                        >
                          <Icon type={`bar_chart`} role={'icon'} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sales-tracker-top-table-item-chart">
                    {!record?.is_locked ? (
                      <Chart
                        height={isMobile ? null : '275px'}
                        data={
                          record?.aggregations?.[localPeriod]?.revenue
                            ? [...record.aggregations[localPeriod].revenue]
                            : []
                        }
                        type={'revenue'}
                        animation={false}
                        isMobile={isMobile}
                        chartType={
                          chartTypeMap[record.key] === 'bar' ? 'bar' : 'line'
                        }
                      />
                    ) : null}
                    {record?.is_locked ? (
                      <div
                        className={cls('hidden-chart', {
                          'hidden-chart-dark': theme === 'dark',
                        })}
                      >
                        <Chart
                          height={'275px'}
                          data={
                            record?.aggregations?.[localPeriod]?.revenue
                              ? [...record.aggregations[localPeriod].revenue]
                              : []
                          }
                          type={'revenue'}
                          animation={false}
                          chartType={!isLineChart && 'bar'}
                        />
                        <div className="hidden-chart-text">
                          <div className="hidden-chart-status">
                            {t('Access Limited')}
                          </div>
                          <div className="hidden-chart-title">
                            {isBasicPlan ? (
                              <>
                                <p>
                                  {t(
                                    'Upgrade to Standard plan to unlock Top Stores, or go',
                                  )}
                                </p>
                                <p>
                                  {t(
                                    'Premium plan to unlock Top Stores and Top Products.',
                                  )}
                                </p>
                              </>
                            ) : (
                              t(
                                'Upgrade to Premium plan to unlock Top Products.',
                              )
                            )}
                          </div>
                          <ButtonComponent
                            className={
                              'sales-tracker-top-table-button hidden-chart-button'
                            }
                            onClick={() => navigate('/setting/plan')}
                            text={t('Upgrade Plan')}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {view === 'stores' &&
                  Object.keys(
                    record?.best_selling_products?.[localPeriod]
                      ? record?.best_selling_products?.[localPeriod]
                      : {},
                  ).length ? (
                    <div className={'top-performing-products-wrapper'}>
                      <div className="top-performing-products-title">
                        {t('Top Best Selling Products')}
                      </div>
                      <div className={'top-performing-products'}>
                        {(Object.values(
                          record?.best_selling_products?.[localPeriod],
                        )?.length
                          ? Object.values(
                              record?.best_selling_products?.[localPeriod],
                            )
                          : []
                        ).map((item) => {
                          return (
                            <div
                              key={item?.logo}
                              className={'top-performing-product'}
                            >
                              <div
                                className={cls('image-wrapper', {
                                  'image-wrapper-hidden': record?.is_locked,
                                })}
                              >
                                {item?.logo ? (
                                  <img
                                    src={utils.changeImageSize(item?.logo, 124)}
                                    alt=""
                                    onError={utils.addDefaultSrc}
                                    width="62"
                                    height="62"
                                  />
                                ) : null}
                              </div>
                              <div className="title-wrapper">
                                {!record?.is_locked ? (
                                  <a
                                    href={`https://${record?.original_domain}/products/${item?.handle}`}
                                    target={'_blank'}
                                    rel="noopener noreferrer"
                                    onMouseOver={mouseOver}
                                    onMouseLeave={mouseLeave}
                                    data-text={item?.title}
                                  >
                                    {item?.title}
                                  </a>
                                ) : (
                                  <span>Hidden</span>
                                )}
                                <div className={'price-info'}>
                                  <div className="price-info-title">
                                    {t('Price') + ` -  `}
                                  </div>
                                  <div
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      setRecordToPriceHistory({
                                        store: { id: record?.internal_shop_id },
                                        id: item?.id,
                                        main_image: item?.logo,
                                        title: item?.title,
                                        link: record?.custom_domain,
                                        price: acc.formatNumber(
                                          item?.price,
                                          2,
                                          ',',
                                          '.',
                                        ),
                                        variants: record.variants,
                                        images: record?.images,
                                      });
                                      setModal('priceHistory');
                                      setVisible(true);
                                    }}
                                  >
                                    {`$${acc.formatNumber(
                                      item?.price,
                                      2,
                                      ',',
                                      '.',
                                    )}`}
                                  </div>
                                  <div className="vertical-divider-small" />
                                  <span className="price-info-title">
                                    {t('Revenue') + ` - `}
                                  </span>
                                  <span className="price-info-value">
                                    {`$${acc.formatNumber(
                                      item?.revenue,
                                      2,
                                      ',',
                                      '.',
                                    )}`}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            );
          },
          onExpand: handleExpand,
          expandIcon: ({ expanded, onExpand, record }) => (
            <div
              className={`tablet-arrow ${expanded ? 'expanded' : ''}`}
              onClick={(e) => onExpand(record, e)}
            >
              <ArrowSelectDown />
            </div>
          ),
        }}
        sortDirections={['descend', 'ascend', null]}
      />
    )}
    </div>
  );
};

export default React.memo(SalesTopTrackerTopTable);
