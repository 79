import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal';
import { Divider, Spin } from 'antd';
import cls from 'classname';
import InputComponent from '../Input';
import Icon from '../../Icon';
import ButtonComponent from '../Button';

const inputData = [
  {
    key: 'current',
    placeholder: 'Enter current password...',
    label: 'Current password'
  },
  {
    key: 'new',
    placeholder: 'Enter a new password...',
    label: 'New password'
  },
  {
    key: 'confirm',
    placeholder: 'Confirm new password...',
    label: 'Confirm new password'
  },
]

const ChangePasswordModal = (
  {
    visible,
    setModal,
    loading,
    isMobile,
    userError,
    userErrorsReset,
    changePassword,
    saveResult,
    userSaveResultReset,
    handleSendCodePassword,
    setDeadline,
    setResendFetching,
  }
) => {

  const {t} = useTranslation();

  const [visiblePassword, setVisiblePassword] = useState({
    current: false,
    new: false,
    confirm: false,
  });
  const [inputPass, setInputPass] = useState({
    current: '',
    new: '',
    confirm: '',
  });

  const [error, setError] = useState({
    current: '',
    new: '',
    confirm: '',
  });

  const forgotPassword = () => {
    setModal('forgot_password');
    setDeadline(Date.now() + 1000 * 30);
    setResendFetching(true);
    handleSendCodePassword();
  };

  const handleChange = (e, key) => {
    userErrorsReset();
    if (key === 'new') {
      setError(prev => ({
        ...prev,
        new: e.target.value?.length <= 6
          || !/[0-9]/g.test(e.target.value)
          || !/[a-zA-Z]/g.test(e.target.value)
          || !/[!-\/:-@[-`{-~]/g.test(e.target.value) ?
          'Passwords should be a minimum of 6 characters and include upper and lower-case letters, numbers, and special characters'
          :
          'Your password is strong and fully compliant with the requirements.',
        confirm: e.target.value === inputPass?.confirm ?
          ''
          :
          'Passwords do not match.',
      }));
    } else if (key === 'confirm') {
      setError(prev => ({
        ...prev,
        new: '',
        confirm: e.target.value === inputPass?.new ?
          ''
          :
          'Passwords do not match.'
      }));
    }
    setInputPass(prev => ({
      ...prev,
      [key]: e.target.value,
    }));
  };

  useEffect(() => {
    setVisiblePassword({
      current: false,
      new: false,
      confirm: false,
    });
    setInputPass({
      current: '',
      new: '',
      confirm: '',
    });
    setError({
      current: '',
      new: '',
      confirm: '',
    });
    userSaveResultReset();
    userErrorsReset();
  }, [visible]);

  useEffect(() => {
    if (saveResult?.create) {
      setModal(null);
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [saveResult]);

  useEffect(() => {
    if (userError) {
      setError(prev => ({
        ...prev,
        new: userError
      }));
    } else {
      setError(prev => ({
        ...prev,
        new: ''
      }));
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [userError]);

  return (
    <Modal handleOk={null}
           handleClose={() => {
             setModal(null);
           }}
           title={
            <div className="collection-modal-top">
              {t('Change password')}
              <p className={'collection-modal-text forgot-password'} onClick={forgotPassword}>
                {t('Forgot password?')}
              </p>
            </div>
           }
           isMobile={isMobile}
           width={450}
           className="collection-list-modal collection-modal account-modal"
           open={visible}
           destroyOnClose
    >
      <Spin spinning={loading}>
        <div className={'collection-modal-content'}>
          <p className={'collection-modal-text'}>
            {t('Enter your current password, and add a new one.')}
          </p>
          <div className="change-form">
            {
              inputData.map((el) => (
                <div className={cls('input-wrapper', {
                  error: error?.[el?.key] && error?.[el?.key] !== 'Your password is strong.',
                })}
                     key={el?.key}
                >
                  <span className="title">
                    {t(el?.label)}
                  </span>
                  <InputComponent type={visiblePassword?.[el?.key] ? 'text' : 'password'}
                                  placeholder={t(el?.placeholder)}
                                  autoComplete="off"
                                  value={inputPass?.[el?.key]}
                                  onChange={(e) => handleChange(e, el?.key)}
                                  suffix={(
                                    <span onClick={() => setVisiblePassword(prev => ({
                                      ...prev,
                                      [el?.key]: !prev?.[el?.key],
                                    }))}>
                                      {
                                        visiblePassword?.[el?.key] ?
                                          <Icon type="eye" />
                                          :
                                          <Icon type="eye_invisible" />
                                      }
                                    </span>
                                )}
                  />
                  <span className={cls('error', {
                    confirm: error?.[el?.key] === 'Your password is strong.',
                    confirm: error?.[el.key] === 'Your password is strong and fully compliant with the requirements.'
                  })}>
                    {
                      error?.[el?.key] ?
                        t(error?.[el?.key])
                        :
                        null
                }
              </span>
                </div>
              ))
            }
          </div>
        </div>
        <Divider type={'horizontal'}
                 style={{ margin: '16px 0' }}
        />
        <div className={'collection-modal-btn-wrapper'}>
          <ButtonComponent className="collection-modal-btn collection-modal-btn-cancel"
                           onClick={() => {
                             setModal(null);
                           }}
                           text={t('Cancel')}
          />
          <ButtonComponent className="collection-modal-btn collection-modal-btn-save"
                           disabled={loading || inputPass?.new !== inputPass?.confirm || !inputPass?.current || !inputPass?.new || !inputPass?.confirm}
                           onClick={() => {
                             changePassword({ current: inputPass?.current, password: inputPass?.new });
                           }}
                           text={t('Change password')}
          />
        </div>
        {
          isMobile ?
            null
            :
            <div style={{ height: 16 }} />
        }
      </Spin>
    </Modal>
  );
};

export default ChangePasswordModal;
