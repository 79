import React from 'react';
import utils from '../../../Utils/utils';
import Image from '../../../Components/Image';
import Icon from '../../../Icon';
import Images from '../../../Images';
import ButtonComponent from '../../../Components/Button';
import { useTranslation } from 'react-i18next';
import '../../SalesTrackerPage/components/MainBlockNew.less';

const MainBlockNewAdSpot = ({ data, skeleton, isMobile = false }) => {
  const { t } = useTranslation();

  const mouseOver = (e) => {
    let target = e.target;
    const { scrollWidth, clientWidth } = target;
    if (scrollWidth > clientWidth) target.classList.add('overflowing');
  };

  const mouseLeave = (e) => {
    let target = e.target;
    target.classList.remove('overflowing');
  };

  if (skeleton) {
    return (
      <div className="report-main-block-new fadspot">
        <div className="main-info-wrapper">
          <span
            className="link skeleton"
            style={{
              width: isMobile ? '100%' : 120,
              height: 40,
              marginBottom: isMobile ? 16 : 0,
            }}
          />
          <span
            className="link skeleton"
            style={{ width: isMobile ? '100%' : 120, height: 40 }}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="report-main-block-new fadspot">
      <div className="main-info-wrapper">
        <div className="main-info fad-spot">
          <div className={'main-block-logo'}>
            {data?.advertiser?.icon ? (
              <img
                src={data?.advertiser?.icon}
                alt=""
                onError={utils.addDefaultSrc}
                width="36"
                height="36"
              />
            ) : (
              <Image src={Images.defaultImg} small={true} />
            )}
          </div>

          <div className={`main-title ${'adSpot'}` }>
            <span className="report-main-block_title fad-spot">
              <span
                onMouseOver={mouseOver}
                onMouseLeave={mouseLeave}
                data-text={data?.advertiser?.name}
              >
                {data?.advertiser?.name}
              </span>
              {data?.advertiser?.is_verified ? (
                <Icon role={'icon'} type={'ad_spot_checkmark'} />
              ) : null}
            </span>

            {data?.domain ? (
              <div className="main-link">
                <a
                  className={'report-main-block_link chart-block_link'}
                  target={'_blank'}
                  href={data?.domain ? `https://${data?.domain}` : '#'}
                  rel="noopener noreferrer"
                >
                  <span>{data?.domain}</span>
                </a>
              </div>
            ) : null}
          </div>
          {/*{*/}
          {/*  isShopify ?*/}
          {/*    null*/}
          {/*    :*/}
          {/*    <div className={'main-title-stat'}>*/}
          {/*      <h3>*/}
          {/*        {t('Brand Tracked By')}*/}
          {/*      </h3>*/}
          {/*      <span>*/}
          {/*        {acc.formatNumber(data?.tracked_by, 0, ',')} {t('Others')}*/}
          {/*      </span>*/}
          {/*    </div>*/}
          {/*}*/}
        </div>
        <div className={'report-main-block-new-button-wrapper'}>
          <ButtonComponent
            className={'report-main-block-new-button'}
            text={''}
            onClick={() => window.open(data?.advertiser?.url, '_blank')}
          >
            <Icon
              role={'icon'}
              type={'search_facebook'}
              width={18}
              height={18}
            />
            {t('View page')}
          </ButtonComponent>
          {/*<ButtonComponent className={'report-main-block-new-button track'}*/}
          {/*                 text={''}*/}
          {/*                 onClick={() => console.log('click')}*/}
          {/*>*/}
          {/*  <Icon role={'icon'} type={'ad_spot_views'} color={'white'}/>*/}
          {/*  {t('Track Website')}*/}
          {/*</ButtonComponent>*/}
        </div>
      </div>
    </div>
  );
};

export default MainBlockNewAdSpot;
