import React from 'react';
import PropTypes from 'prop-types';
import './TopPageLimits.less';

const getClassForLimitsTotal = (creditsTotal, creditsLeft) => {
  const percentageLeft = (creditsLeft / creditsTotal) * 100;

  if (percentageLeft > 50) {
    return 'red';
  } else if (percentageLeft > 25 && percentageLeft <= 50) {
    return 'orange';
  } else if (percentageLeft >= 0 && percentageLeft <= 25) {
    return 'green';
  }
  return '';
};

const TopPageLimits = ({ view, limits, t, firstOnClick, secondOnClick,pointer }) => {
  return (
    <div className="top-page-limits-wrapper">
      <div className="top-page-limits">
        <div className="top-page-limits-title">
          {t('Tracking limit')}
        </div>
        {view === 'stores' && (
          <>
            <div className="limits-vertical-divider" />
            <div className="limits-values">
              <span
                className={`${getClassForLimitsTotal(
                  limits?.stores?.allowed, limits?.stores?.used
                )}`}
              >{limits?.stores?.used}</span>/
              <span className="second-color">{limits?.stores?.allowed}</span>
              <span className="limits-values-title">{t('Stores')}</span>
            </div>
          </>
        )}
        {view === 'products' && (
          <>
            <div className="limits-vertical-divider" />
            <div className="limits-values">
              <span
                className={`${getClassForLimitsTotal(
                  limits?.products?.allowed, limits?.products?.used
                )}`}
              >{limits?.products?.used}</span>/
              <span className="second-color">{limits?.products?.allowed}</span>
              <span className="limits-values-title">{t('Products')}</span>
            </div>
          </>
        )}
        {view === 'both' && (
          <div className="both-limits-wrapper">
            <div className="limits-vertical-divider" />
            <div className={`limits-values ${pointer ? 'pointer' : ''}`} onClick={firstOnClick}>
              <span
                className={`${getClassForLimitsTotal(
                  limits?.stores?.allowed, limits?.stores?.used
                )}`}
              >
                {limits?.stores?.used}
              </span>/
              <span className="second-color">{limits?.stores?.allowed}</span>
              <span className="limits-values-title">{t('Stores')}</span>
            </div>
            <div className="limits-vertical-divider-mobile" />
            <div className={`limits-values ${pointer ? 'pointer' : ''}`} onClick={secondOnClick}>
              <span
                className={`${getClassForLimitsTotal(
                  limits?.products?.allowed, limits?.products?.used
                )}`}
              >
                {limits?.products?.used}
              </span>/
              <span className="second-color">{limits?.products?.allowed}</span>
              <span className="limits-values-title">{t('Products')}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
};

TopPageLimits.propTypes = {
  view: PropTypes.string.isRequired,
  limits: PropTypes.shape({
    stores: PropTypes.shape({
      used: PropTypes.number.isRequired,
      allowed: PropTypes.number.isRequired,
    }),
    products: PropTypes.shape({
      used: PropTypes.number.isRequired,
      allowed: PropTypes.number.isRequired,
    }),
  }).isRequired,
  t: PropTypes.func.isRequired,
  firstOnClick: PropTypes.func,
  secondOnClick: PropTypes.func,
  pointer: PropTypes.bool
};

export default TopPageLimits;
