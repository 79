import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { connect } from 'react-redux';
import SalesTrackerCreators from './reducer';
import PlanCreators from '../../Redux/PlanRedux';
import UserCreators from '../User/reducer';
import BreadcrumbsCreators from '../Breadcrumbs/actions';
import { useTranslation } from 'react-i18next';
import TopPageHeader from './pages/components/TopPageHeader';
import PageOptions from './components/PageOptions';
import UserTrackings from './components/UserTrackings';
import VideoTutorialModal from '../ShopifySearch/components/VideoTutorialModal';
import LimitChangeModal from '../../Components/BillingSubPage/LimitChangeModal';
import DeleteTrackingModal from './components/DeleteTrackingModal';
import CanNotRemoveTracking from '../ProductDatabasePage/components/CanNotRemoveTracking';
import GatheringDataModal from './components/GatheringDataModal';
import UntrackableDeleteModal from './components/UntrackableDeleteModal';
import NumbersBreakdownDatabaseBlock from '../ProductDatabasePage/components/NumbersBreakdownDatabaseBlock';
import PopularToday from './components/PopularToday';
import './styles.less';

const SalesTrackerPage = (
  {
    userInfo,
    isMobile,
    salesTracker,
    setView,
    dashboardInfo,
    getStores,
    getProducts,
    deleteProduct,
    deleteStore,
    connectProduct,
    connectStore,
    connectStoreNew,
    resetSearchErrors,
    getStoreSuggestions,
    resetStoreSuggestions,
    getPlans,
    getUserInfo,
    clearBreadCrumbs,
    addBreadCrumb,
    limits,
  }) => {

  const {t} = useTranslation();

  const {stores, loading, searchErrors, fetchingSpin, suggestions, suggestionsLoading} = salesTracker;

  const skeleton = loading;

  const isTrial = userInfo?.subscriptions?.[0]?.payment_status?.id === 6;

  const { view } = useParams();
  const navigate = useNavigate();
  const isYearly = userInfo?.subscriptions?.[0]?.plan?.price_type?.id !== 'monthly';

  const [firstLoading, setFirstLoading] = useState(true);
  const [displayFormat, setDisplayFormat] = useState('details');
  const [inputText, setInputText] = useState('');
  const [modal, setModal] = useState(null);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [searchSuggestions, setSearchSuggestions] = useState(null);

  useEffect(() => {
    //reset input errors
    resetSearchErrors();
  }, [])

  function viewHandler(view) {
    navigate(`/sales-tracker/${view}`);
    setView(view);
    setInputText('');
    setDisplayFormat('details');
    resetSearchErrors();
  }

  function disconnectHandler(data) {
    if (view === 'stores') {
      deleteStore(data);
    } else {
      deleteProduct(data);
    }
  }

  function connectHandler() {
    if (view === 'stores') {
      searchSuggestions === null ?
        connectStore(inputText)
        :
        connectStoreNew({ ...searchSuggestions, fullData: true })
      setSearchSuggestions(null);
      setInputText('');
    } else {
      connectProduct(inputText);
    }
  }

  useEffect(() => {
    document.title = `Sales Tracker - Dropship`;
    getPlans();
    getUserInfo();
    clearBreadCrumbs();
    addBreadCrumb({
      name: t('Sales tracker'),
      link: `/sales-tracker/${view}`,
    });
  }, []);

  useEffect(() => {
    if (firstLoading) {
      setFirstLoading(false);
    } else {
      if (view === 'stores') {
        getStores({page: 1,page_size: 50});
      } else {
        getProducts({page: 1, page_size: 100});
      }
    }
  }, [JSON.stringify(userInfo?.subscriptions?.[0])]);

  return (
    <div className={'product-database-page sales-tracker-page'}>
      <TopPageHeader icon={'sales_tracker_dashboard'}
                     title={'My trackings'}
                     subtitle={'Track sales of stores and products'}
                     takeTourHandler={() => window.Intercom('startTour', 433088)}
                     watchTutorialHandler={() => setModal('tutorial')}
                     increaseLimitsHandler={() => setModal('edit_limits')}
                     skeleton={skeleton}
                     isMobile={isMobile}
                     withCredits={false}
                     withLimits={limits}
                     isYearly={isYearly}
                     isTrial={isTrial}
      />
      <PageOptions setDisplayFormat={setDisplayFormat}
                   displayFormat={displayFormat}
                   isMobile={isMobile}
                   view={view}
                   setView={viewHandler}
                   setInputText={setInputText}
                   inputText={inputText}
                   skeleton={skeleton}
                   disabled={fetchingSpin}
                   connectHandler={connectHandler}
                   searchErrors={searchErrors}
                   resetSearchErrors={resetSearchErrors}
                   setSearchSuggestions={setSearchSuggestions}
                   suggestions={suggestions}
                   suggestionsLoading={suggestionsLoading}
                   getStoreSuggestions={getStoreSuggestions}
                   resetStoreSuggestions={resetStoreSuggestions}
      />
      <PopularToday view={view}
                    skeleton={skeleton}
      />
      <UserTrackings view={view}
                     displayFormat={displayFormat}
                     setModal={setModal}
                     setRecordToDelete={setRecordToDelete}
                     skeleton={skeleton}
      />

      <LimitChangeModal setModal={setModal}
                        visible={modal === 'edit_limits'}
                        isMobile={isMobile}
                        data={{
                          storeLimit: dashboardInfo?.limits?.stores?.allowed < 50 ? 50: dashboardInfo?.limits?.stores?.allowed,
                          productLimit: dashboardInfo?.limits?.products?.allowed < 100 ? 100: dashboardInfo?.limits?.products?.allowed,
                          subscription: userInfo?.subscriptions?.[0]
                        }}
      />
      <DeleteTrackingModal setModal={setModal}
                           visible={modal === 'delete_tracking'}
                           isMobile={isMobile}
                           record={recordToDelete}
                           disconnectHandler={disconnectHandler}
                           view={view}
      />
      <UntrackableDeleteModal setModal={setModal}
                              visible={modal === 'untrackable_delete_tracking'}
                              isMobile={isMobile}
                              record={recordToDelete}
                              disconnectHandler={disconnectHandler}
                              view={view}
      />
      <GatheringDataModal setModal={setModal}
                          visible={modal === 'gathering_data'}
                          isMobile={isMobile}
      />
      <CanNotRemoveTracking setModal={setModal}
                            visible={modal === 'can_not_remove_tracking'}
                            isMobile={isMobile}
      />
      <NumbersBreakdownDatabaseBlock product={recordToDelete}
                                     loading={false}
                                     visible={modal === 'numbers_breakdown'}
                                     setModal={setModal}
      />
      <VideoTutorialModal visible={modal === 'tutorial'}
                          setModal={setModal}
                          isMobile={isMobile}
                          link={'https://www.youtube.com/watch?v=ViFABtDOpDw'}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  isMobile: state.nav.isMobile,
  salesTracker: state.salesTracker,
  dashboardInfo: state.dashboard.result,
  limits: state.salesTracker.stores?.limits,
});

const mapDispatchToProps = (dispatch) => ({
  setView: (data) => dispatch(SalesTrackerCreators.setView(data)),
  getStores: (data) => dispatch(SalesTrackerCreators.getTrackingStoresRequest(data)),
  getProducts: (data) => dispatch(SalesTrackerCreators.getTrackingProductsRequest(data)),
  deleteProduct: (data) => dispatch(SalesTrackerCreators.deleteTrackingProductByIDRequest(data)),
  deleteStore: (data) => dispatch(SalesTrackerCreators.deleteTrackingStoreByIDRequest(data)),
  connectProduct: (data) => dispatch(SalesTrackerCreators.createTrackingProductRequest(data)),
  connectStore: (data) => dispatch(SalesTrackerCreators.createTrackingStoreRequest(data)),
  connectStoreNew: (data) => dispatch(SalesTrackerCreators.createTrackingStoreNewRequest(data)),
  resetSearchErrors: () => dispatch(SalesTrackerCreators.resetSearchErrors()),
  getStoreSuggestions: (data) => dispatch(SalesTrackerCreators.getStoreSuggestionsRequest(data)),
  resetStoreSuggestions: () => dispatch(SalesTrackerCreators.resetStoreSuggestions()),
  getPlans: () => dispatch(PlanCreators.plansRequest()),
  getUserInfo: () => dispatch(UserCreators.userRequest()),
  addBreadCrumb: (data) => dispatch(BreadcrumbsCreators.addBreadCrumb(data)),
  clearBreadCrumbs: () => dispatch(BreadcrumbsCreators.clearBreadCrumbs()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesTrackerPage);
