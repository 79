import React, { useEffect, useState } from 'react';
import cls from 'classname';
import { Form } from 'antd';
import utils from '../../Utils/utils';
import ButtonComponent from '../Button';
import InputComponent from '../Input';

function RestorePasswordForm({ error, ...props }) {

  const [errorText, setErrorText] = useState(null);
  const [, forceUpdate] = useState({}); // To disable submit button at the beginning.
  const [isDisabled, setIsDisabled] = useState(true);

  const [form] = Form.useForm();

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    if (error) {
      if (typeof error === 'string') {
        setErrorText(error);
      } else {
        setErrorText(Object.values(error)[0]);
      }
    }
  }, [error]);

  const onFinish = values => {
    props.onSubmit(values);
  };

  const onFinishFailed = ({ errorFields }) => {
    if (errorFields) setErrorText(errorFields[0]['errors']);
  };

  function handleClearError(e, field) {
    form.setFields([
      {
        name: field,
        errors: [],
      },
    ]);
    setErrorText(null);
  }

  function handleChange(e) {
    const { name, value } = e.target;

    handleClearError(e, name);

    if (utils.validateEmail(value)) {
      if (isDisabled) setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }


  return (
    <Form form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          className="login-form restore"
          layout="vertical"
    >
      <Form.Item name="email"
                 validateStatus={errorText ? 'error' : null}
                 rules={[
                   { type: 'email', message: 'The email you entered is incorrect' },
                 ]}
                 className={cls('restore-password-input')}
      >
        <InputComponent
          placeholder="Email address"
          name="email"
          type="email"
          onChange={(e) => {
            handleChange(e);
          }}
        />
      </Form.Item>
      <Form.Item noStyle shouldUpdate={true}>
        {
          errorText ?
            <div className={cls('error-text error-text-restore', { 'error-text_empty': !errorText })}>
              <span>{errorText || 'Please enter a valid email address.'}</span>
            </div>
            :
            null
        }
      </Form.Item>

      <ButtonComponent htmlType="submit"
                       className="login-form-submit"
                       style={{ width: '100%' }}
                       disabled={isDisabled || !form.getFieldValue('email')}
      >
        Send Email
      </ButtonComponent>
    </Form>
  );
}

export default RestorePasswordForm;
