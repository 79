import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal';
import { Spin, Statistic } from 'antd';
import Images from '../../Images';

const ForgotPasswordModal = (
  {
    visible,
    setModal,
    isMobile,
    email='',
    handleSendCodePassword,
    resendFetching, 
    setResendFetching,
    deadline,
    setDeadline
  }
) => {
  const { Countdown } = Statistic;
  const { t } = useTranslation();
    
  const handleResend = () => {
    handleSendCodePassword();
    handleResetTime();
  }
  
  const handleResetTime = () => {
    setDeadline(Date.now() + 1000 * 30);
    setResendFetching(true);
  };

  const onFinish = () => {
    setResendFetching(false);
    setDeadline(null);
  };

  const resendBlock = (
    <div
      className="check-email"
    >
      {resendFetching ? (
        <div className="resend-timer">
          <Countdown
            title=""
            value={deadline}
            onFinish={onFinish}
            format="ss"
            prefix={<span>{t('Resend in')}</span>}
            suffix={<span> {t('sec...')}</span>}
          />
          <Spin />
        </div>
      ) : (
        <span className='resend-link' onClick={handleResend}>
          {t('Resend')}
        </span>
      )}
    </div>
  );
  return (
    <Modal handleOk={null}
           handleClose={() => {
             setModal(null);
           }}
           title={t('You’ve Got Mail!')}
           isMobile={isMobile}
           width={560}
           className="collection-modal account-modal forgot-password-modal"
           open={visible}
           destroyOnClose
    >
    <div className="account-subpage-forgot-password">
      <div className="forgot-password-subtitle">
          {t('Check the email letter sent to')}{' '}
          <span className="email-text">{email}</span>
      </div>
      <img src={Images.verifyEmailMobile} alt="" />
      <div className="forgot-password-subtitle dont-see-confirm">
         {t('Don’t see a confirmation email?')} {resendBlock} 
      </div>
    </div>
    </Modal>
  );
};

export default ForgotPasswordModal;
