import React, { useState,useEffect } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'antd';
import { Menu } from './components';
import ArrowSelectDown from '../../Icon/img/ArrowSelectDown';
import LogoutModal from './components/LogoutModal';
import actions from './actions';
import ShopifyStoreCreators from '../ShopifyStore/reducer';
import Creators from '../AutoDS/reducer';
import Creator from './reducer';
import ThemeModal from './components/ThemeModal';
import UserCreators from '../../Containers/User/reducer';
import SelectLanguageModal from '../../Components/AccountSubPage/SelectLanguageModal';
import SettingCreators from '../SettingPage/reducer';
import './styles.less';

const UserComponent = (
  {
    user,
    nav,
    shopifyStore,
    autoDS,
    isAdmin,
    changeLang,
    languages,
    userSaveResultReset,
    getLanguagesList,
    ...props
  }) => {

  const [visible, setVisible] = useState(false);
  const[modal, setModal] = useState(null);
  const {
    first_name: firstName,
    last_name: lastName,
    email,
  } = user;
  useEffect(()=>{
    if(!languages.length) getLanguagesList();
  },[])
  const { theme, isShowSideBar, isMobile } = nav;
  const { changeTheme, setDisabledTransition, showLogout, showThemeModal, openSideBar } = props;

  const disabledConnect = false;
  const initialUrl = document?.location?.pathname + (document?.location?.search || '');

  const optionsLanguages = languages ?
  languages.map((el) => ({
    label: el.name,
    value: el.value,
  }))
  :
  [];

  const handleVisibleChange = (value) => {
    if (value && !isAdmin) {
      props.getShopifyStore();
      props.getAutoStore();
    }
    setVisible(value);
  };

  const handleOpenModalShopifyConnect = () => {
    props.openModalShopifyConnect(initialUrl);
    setVisible(false);
  };

  const handleOpenModalAutoConnect = () => {
    props.openModalAutoConnect();
    setVisible(false);
  };

  return (
    <div className="user-dropdown" id="user-dropdown">
      <Dropdown destroyPopupOnHide={true}
                getPopupContainer={() => {
                  return isMobile ?
                    document.getElementById('global-wrap')
                    :
                    document.getElementById('user-dropdown')
                }}
                onOpenChange={handleVisibleChange}
                dropdownRender={() =>
                  <Menu theme={theme}
                        isMobile={isMobile}
                        changeTheme={changeTheme}
                        setDisabledTransition={setDisabledTransition}
                        setLogout={showLogout}
                        setThemeModal={showThemeModal}
                        isAdmin={isAdmin}
                        shopifyStore={shopifyStore}
                        autoDS={autoDS}
                        openModalShopifyConnect={handleOpenModalShopifyConnect}
                        openModalAutoDSConnect={handleOpenModalAutoConnect}
                        disabledConnect={disabledConnect}
                        closeDropdown={setVisible}
                        openSideBar={openSideBar}
                        languages={languages}
                        changeLang={changeLang}
                        userSaveResultReset={userSaveResultReset}
                        setModal={setModal}
                        optionsLanguages={optionsLanguages}
                  />
                }
                trigger={['click']}
                open={visible}
      >
        <div className={'user-dropdown-outer'}>
          {
            isShowSideBar ?
              <div className="user-dropdown-outer-info-wrapper">
                <div className="user-dropdown-outer-info">
                  <span className={'user-dropdown-outer-info-name'}>
                    {`${firstName} ${lastName}`}
                  </span>
                  <span className={'user-dropdown-outer-info-email'}>
                    {email}
                  </span>
                </div>
                <ArrowSelectDown />
              </div>
              :
              <svg xmlns="http://www.w3.org/2000/svg"
                   width="20"
                   height="20"
                   viewBox="0 0 20 20"
                   fill="none"
                   className={'user-dropdown-outer-icon'}
              >
                <path stroke={theme === 'light' ? '#A1AAC8' : 'rgba(255,255,255, .36)'}
                      d="M4.88089 15.7625C5.97554 14.1634 7.77677 13.1243 10.0001 13.1243C12.2234 13.1243 14.0246 14.1634 15.1193 15.7625M4.88089 15.7625C6.24226 16.9726 8.03533 17.7077 10.0001 17.7077C11.9648 17.7077 13.7579 16.9726 15.1193 15.7625M4.88089 15.7625C3.29247 14.3505 2.29175 12.2918 2.29175 9.99935C2.29175 5.74215 5.74289 2.29102 10.0001 2.29102C14.2573 2.29102 17.7084 5.74215 17.7084 9.99935C17.7084 12.2918 16.7077 14.3505 15.1193 15.7625M12.7084 8.33268C12.7084 9.82845 11.4959 11.041 10.0001 11.041C8.50431 11.041 7.29175 9.82845 7.29175 8.33268C7.29175 6.83691 8.50431 5.62435 10.0001 5.62435C11.4959 5.62435 12.7084 6.83691 12.7084 8.33268Z"
                      strokeWidth="1.5"
                      strokeLinejoin="round"
                />
              </svg>
          }
        </div>
      </Dropdown>
      <LogoutModal />
      <ThemeModal setVisiblePopup={setVisible} />
      <SelectLanguageModal isMobile={isMobile}
            setModal={setModal}
            visible={modal === 'change_language'}
            optionsLanguages={optionsLanguages}
            changeLang={changeLang}
            userInfo={user}
            setVisiblePopup={setVisible}
          />
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.userInfo,
  isAdmin: state.auth.isAdmin,
  nav: state.nav,
  shopifyStore: state.shopifyStore,
  autoDS: state.autoDS,
  languages: state.setting.languages,
});

const mapDispatchToProps = (dispatch) => ({
  getUserInfo: () => dispatch(Creator.userRequest()),
  changeTheme: theme => dispatch(actions.changeTheme('theme', theme)),
  openSideBar: value => dispatch(actions.changeTheme('isShowMenu', value)),
  showLogout: value => dispatch(actions.changeTheme('showLogout', value)),
  showThemeModal: value => dispatch(actions.changeTheme('showTheme', value)),
  setDisabledTransition: disabledTransition => dispatch(actions.setDisabledTransition('disabledTransition', disabledTransition)),
  getShopifyStore: () => dispatch(ShopifyStoreCreators.getShopifyStoresRequest()),
  openModalShopifyConnect: initialUrl => dispatch(ShopifyStoreCreators.changeVisibleModalShopify({
    isVisibleModal: 'connect',
    initialUrl,
  })),
  openModalAutoConnect: () => dispatch(Creators.changeVisibleModalAuto({
    isVisibleModal: 'connect',
    importProductId: null,
  })),
  getAutoStore: () => dispatch(Creators.getAutoStoreRequest()),
  changeLang: (lang) => dispatch(UserCreators.changeLanguageRequest(lang)),
  userSaveResultReset: () => dispatch(UserCreators.userSaveResultReset()),
  getLanguagesList: () => dispatch(SettingCreators.getLanguagesListRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserComponent);
