import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import acc from 'accounting';
import utils from '../../../Utils/utils';
import dayjs from 'dayjs';
import cls from 'classname';
import Creator from '../reducer';
import Creators from '../../ShopifyStore/reducer';
import AutoDSCreators from '../../AutoDS/reducer';
import { Spin, Tooltip } from 'antd';
import Icon from '../../../Icon';
import Image from '../../../Components/Image';
import Images from '../../../Images';
import ButtonComponent from '../../../Components/Button';
import NumbersBreakdownDatabaseBlock from '../../ProductDatabasePage/components/NumbersBreakdownDatabaseBlock';
import StopTrackingModal from '../../ShopifySearch/components/StopTrackingModal';
import CanNotRemoveTracking from '../../ProductDatabasePage/components/CanNotRemoveTracking';
import { DropdownRender } from './DatabaseProductsColumns';

const DatabaseProductsCard = (
  {
    data,
    isMobile,
    handleOpenModalShopifyConnect,
    handleOpenModalAutoDSConnect,
    toggleConnectProduct,
    toggleConnectStore,
    setModal,
    handleSetRecordToCalc,
    setCompetitor,
    view
  }) => {

  const {t} = useTranslation();

  return (
    <div className={'database-product-card'}>
      <div className={'database-product-card-image'}
           style={{ cursor: 'default' }}
      >
        {
          data.main_image ?
            <img src={utils.changeImageSize(data?.main_image, 124)}
                   onError={utils.addDefaultSrc}
                   alt=""
            />
            :
            <Image src={Images.defaultImg} />
        }
      </div>
      <div className={'database-product-card-info'}>
        <Tooltip overlayClassName="product_name-tooltip details-tooltip"
                 title={data?.title}
                 trigger={isMobile ? 'click' : 'hover'}
                 destroyTooltipOnHide={true}
                 getPopupContainer={(triggerNode) => triggerNode?.parentNode}
        >
          <span className={'database-product-card-title'}>
            {data?.title}
          </span>
        </Tooltip>
        <div className={'database-product-card-price'}>
          <span className={'main'}
                onClick={() => {
                  handleSetRecordToCalc(data);
                  setModal('numbers_breakdown');
                }}
          >
            ${acc.format(data.usd_price, 2, ',', '.')}
            {
              data.usd_price_max &&
              <span>
                - ${acc.format(data.usd_price_max, 2, ',', '.')}
              </span>
            }
          </span>
          <span className={'original'}>
            {
              (data.original_price > 0 || data.original_price_max > 0) ?
                <span>
                  {
                    acc.format(data.original_price, 2, ',', '.')}
                  {
                    data.original_price_max > 0 &&
                    <span>
                      - {acc.format(data.original_price_max, 2, ',', '.')}
                    </span>
                  }
                  {
                    (data.original_price > 0 || data.original_price_max > 0) &&
                    data.store.currency
                  }
                </span>
                :
                null
            }
          </span>
        </div>
				<div className="database-product-card-stat">
					<span className={'title'}>
						{
							t('Monthly sales')
						}
					</span>
					<span className={'value'}>
						{
							`${acc.format(data.monthly_sales, 0, ',', '.')}`
						}
					</span>
				</div>
				<div className="database-product-card-stat">
					<span className={'title'}>
						{
							t('Monthly revenue')
						}
					</span>
					<span className={'value'}>
						{
							`$${acc.format(data.monthly_revenue, 0, ',', '.')}`
						}
					</span>
				</div>
        <div className="database-product-card-stat">
          <span className={'title'}>
            {
              t('Creation date')
            }
          </span>
          <span className={'value'}>
            {
              data.created_at ?
                dayjs(data.created_at, 'YYYY-MM-DDTHH:mm:ssZ').utc().format('MMMM DD, YYYY')
                :
                t('No Data')
            }
          </span>
        </div>
        <div className="database-product-card-stat last">
          <span className={'title'}>
            {
              t('Links')
            }
          </span>
          <div className={'value links'}>
            {
              data.quick_search.map(el => {
              if (el?.platform === 'aliexpress') return (
                <a key={el.platform}
                  href={el.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="link with-img"
                >
                  <Icon role="button"
                        type={`search_${el.platform}`}
                        className={`${el.platform}-icon`}
                        width={el.platform === 'alibaba' ? 24 : 16}
                        height={el.platform === 'alibaba' ? 24 : 16}
                        titleText={() => <>Search on <span
                          style={{ textTransform: 'capitalize' }}>{el.platform}</span></>}
                        tooltipProps={{
                          placement: 'bottom',
                          trigger: 'hover',
                          destroyTooltipOnHide: true,
                          overlayClassName: cls('details-tooltip', `${el.platform}-tooltip`),
                          getPopupContainer: (trigger) => trigger?.parentNode,
                        }}
                  />
                </a>
              );
            })}
            <div onClick={() => {
              handleOpenModalAutoDSConnect({
                'source': 'shopify',
                'product_id': `${data?.store?.id}_${data?.id}`,
              });
            }}>
              <Icon role="button"
                    type={'auto_ds'}
                    isTooltip={true}
                    width={21}
                    height={20}
                    titleText={() => <>{t('Import Product to AutoDS')}</>}
                    tooltipProps={{
                      placement: 'bottom',
                      trigger: 'hover',
                      destroyTooltipOnHide: true,
                      overlayClassName: cls('details-tooltip', `aliexpress-tooltip`),
                      getPopupContainer: (trigger) => trigger?.parentNode   ,
              }}
              />
            </div>
          </div>
        </div>
        <div className="database-product-card-btn-wrapper">
					<ButtonComponent 
							className={cls("database-product-card-btn wide tracked-btn", {
							"is-tracked": data.is_tracked,
							"is-not-tracked": !data.is_tracked,
							})} 
							onClick={() => toggleConnectProduct(data)
					}>
							<Icon role={'icon'} type={data?.is_tracked ? 'close' : 'gps'} width={20} height={20}/>
							{data?.is_tracked ? t('Stop tracking product') : t('Track product')}
					</ButtonComponent>
          <DropdownRender record={data}
                          handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
                          handleOpenModalAutoDSConnect={handleOpenModalAutoDSConnect}
                          t={t}
                          toggleConnectProduct={toggleConnectProduct}
                          toggleConnectStore={toggleConnectStore}
                          cls={cls}
                          isMobile={isMobile}
                          setCompetitor={setCompetitor}
                          view={view}
          />
        </div>
      </div>
    </div>
  )
}

const DatabaseProductsCards = (
  {
    loading,
    fetching,
    data,
    isTrial,
    isMobile,
    isChanged,
    enabled,
    setPageNumber,
    setCompetitor = null,
    view
  }) => {

  const productsRef = useRef();
  const dispatch = useDispatch();

  const totalProducts = useSelector(state => state?.competitors?.products?.total);
  const shopifyStores = useSelector(store => store['shopifyStore'].results) || [];
  const autoDS = useSelector(store => store.autoDS);
  const initialUrl = document?.location?.pathname + (document?.location?.search || '');

  const [modal, setModal] = useState(null);
  const [recordToCalc, setRecordToCalc] = useState(null);
  const [recordToDelete, setRecordToDelete] = useState(null);
  const [deleteType, setDeleteType] = useState(null);

  const handleSetRecordToCalc = useCallback((value) => setRecordToCalc(value), []);
  const handleSetModal = useCallback((value) => setModal(value), []);
  const connectStore = useCallback(data => dispatch(Creator.createTrackingStoreDatabaseRequest(data)), [dispatch]);
  const connectProduct = useCallback(data => dispatch(Creator.createTrackingProductDatabaseRequest(data)), [dispatch]);
  const disconnectStore = useCallback(id => dispatch(Creator.deleteTrackingStoreDatabaseByIDRequest(id)), [dispatch]);
  const disconnectProduct = useCallback(id => dispatch(Creator.deleteTrackingProductDatabaseByIDRequest(id)), [dispatch]);

  const toggleConnectProduct = useCallback((record) => {    
    if (record?.is_tracked) {
      if (isTrial) {
        handleSetModal('can_not_remove_tracking');
      } else {
        handleSetModal('remove_tracking');
        setDeleteType('product');
        setRecordToDelete(record)
      }
    } else {
      connectProduct({
        "custom_domain": record?.store?.custom_domain,
        "handle": record?.handle,
        "product_id": record?.id,
        "title": record?.title,
        "images": record?.images,
        "main_image": record?.main_image,
        "variants": record?.variants,
        "created_at": record?.created_at,
        "shopify_shop_id": record?.store?.shopify_shop_id,
        "internal_shop_id": record?.store?.internal_shop_id,
        "original_domain": record?.store?.original_domain,
        "original_price": record?.original_price,
        "original_price_min": record?.original_price_min,
        "original_price_max": record?.original_price_max,
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [deleteType, recordToDelete]);
  const toggleConnectStore = useCallback((record) => {
    if (record?.store?.is_tracked) {
      if (isTrial) {
        handleSetModal('can_not_remove_tracking');
      } else {
        handleSetModal('remove_tracking');
        setDeleteType('store');
        setRecordToDelete(record);
      }
    } else {
      connectStore({
        "custom_domain": record?.store?.custom_domain,
        "shopify_shop_id": record?.store?.shopify_shop_id,
        "internal_shop_id": record?.store?.internal_shop_id,
        "original_domain": record?.store?.original_domain
      });
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [deleteType, recordToDelete]);

  const handleOpenModalShopifyConnect = (importProductId) => {
    if (shopifyStores.length) {
      dispatch(Creators.changeVisibleModalShopify({ isVisibleModal: 'import', initialUrl, importProductId }));
    } else {
      dispatch(Creators.changeVisibleModalShopify({ isVisibleModal: 'connect', initialUrl, importProductId: null }));
    }
  };

  const handleOpenModalAutoDSConnect = (importProductId) => {
    if (autoDS?.email) {
      if (autoDS?.stores?.length) dispatch(AutoDSCreators.changeVisibleModalAuto({ isVisibleModal: 'import', importProductId }));
      else dispatch(AutoDSCreators.changeVisibleModalAuto({ isVisibleModal: 'import_unavailable', importProductId: null }));
    } else dispatch(AutoDSCreators.changeVisibleModalAuto({ isVisibleModal: 'connect', importProductId }));
  }

  function onScrollHandlerFunc(event) {
    const maxTopScroll = event.target.scrollHeight - event.target.clientHeight;
    const currentTopScroll = event.target.scrollTop;
    if (currentTopScroll >= maxTopScroll*0.95 && !loading && !fetching && isChanged && enabled && data?.length < totalProducts) {
      setPageNumber(prev => prev + 1);
      productsRef.current.removeEventListener('scroll', onScrollHandlerFunc);
    }
  }

  useEffect(() => {
    if (productsRef.current) {
      productsRef.current.addEventListener('scroll', onScrollHandlerFunc);
    }

    return () => {
      if (productsRef.current) {
        productsRef.current.removeEventListener('scroll', onScrollHandlerFunc);
      }
    };
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [data?.length, productsRef.current, totalProducts]);


  return (
    <div className={'products-overview'}>
    <Spin spinning={loading || fetching}>
      <div className={'database-product-cards'}
            id={'product-database-table-body'}
            ref={productsRef}
      >
        {
          data.map((product) =>
            <DatabaseProductsCard data={product}
                                  key={product?.id}
                                  isMobile={isMobile}
                                  handleOpenModalShopifyConnect={handleOpenModalShopifyConnect}
                                  handleOpenModalAutoDSConnect={handleOpenModalAutoDSConnect}
                                  toggleConnectProduct={toggleConnectProduct}
                                  toggleConnectStore={toggleConnectStore}
                                  handleSetRecordToCalc={handleSetRecordToCalc}
                                  setModal={setModal}
                                  setCompetitor={setCompetitor}
                                  view={view}
            />
          )}
      </div>
      </Spin>
      <NumbersBreakdownDatabaseBlock product={recordToCalc}
                                     loading={fetching}
                                     visible={modal === 'numbers_breakdown'}
                                     setModal={setModal}
      />
      <StopTrackingModal recordToDelete={recordToDelete}
                         type={deleteType}
                         loading={fetching}
                         visible={modal === 'remove_tracking'}
                         setModal={setModal}
                         disconnect={deleteType === 'store' ? disconnectStore : disconnectProduct}
                         isMobile={isMobile}
      />
      <CanNotRemoveTracking isMobile={isMobile}
                            setModal={setModal}
                            visible={modal === 'can_not_remove_tracking'}
      />
    </div>
  );
};

export default DatabaseProductsCards;
