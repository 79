import React, { useState } from 'react';
import cls from 'classname';
import { useTranslation } from 'react-i18next';
import Icon from '../../../Icon';
import { Dropdown } from 'antd';

const PresetsDropdown = (
  {
    isMobile,
    setModal,
    isChanged,
    setVisibleModal,
    disabled
  },
) => {

  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);

  const handleVisibleChange = (value) => setVisible(value);

  return (
    isMobile ?
      <span className={'presets'}
            onClick={() => setVisibleModal('presets_modal')}
      >
        <Icon type={'preset_icon'} role={'icon'} />
        {t('Presets')}
        <Icon type="arrow_select_down"
              role="icon"
        />
      </span>
      :
      <Dropdown destroyPopupOnHide={true}
                getPopupContainer={(trigger) => trigger.parentNode}
                onOpenChange={handleVisibleChange}
                disabled={disabled}
                placement="bottomRight"
                dropdownRender={() => (
                  <div className={'sales-tracker-top-page-header-dropdown'}>
                    <div className="sales-tracker-top-page-header-dropdown-option"
                         onClick={() => {
                           setVisible(false);
                           setModal('load_preset');
                         }}
                    >
                      <Icon type="load_preset" role="icon" />
                      <span>
                        {t('Load preset')}
                      </span>
                    </div>
                    <div className={cls('sales-tracker-top-page-header-dropdown-option', {
                      disabled: !isChanged || disabled,
                    })}
                         onClick={() => {
                           if (isChanged) {
                             setVisible(false);
                             setModal('save_preset');
                           }
                         }}
                    >
                      <Icon type={'save_preset'}
                            role={'icon'}
                      />
                      <span>
                        {t('Save this search as preset')}
                      </span>
                    </div>
                  </div>
                )}
                trigger={['click']}
                open={visible}
      >
        <span className={`presets ${disabled ? 'disabled' : ''}`}>
          <Icon type={'preset_icon'} role={'icon'} />
          {t('Presets')}
          <Icon type="arrow_select_down"
                role="icon"
          />
        </span>
      </Dropdown>
  );
};

export default PresetsDropdown;
