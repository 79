import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { notification, Modal, Divider } from 'antd';
import Creators from '../../Containers/User/reducer';
import * as DashboardCreators from '../../Containers/DashboardPage/reducer';
import SettingCreators from '../../Containers/SettingPage/reducer';
import OnboardingActions from '../../Redux/OnboardingRedux';
import ErrorsActions from '../../Redux/ErrorsRedux';
import ModalManageSubscriptions from '../../Containers/UpcomingDropPage/ModalManageSubscriptions';
import utils from '../../Utils/utils';
import dayjs from "dayjs";
import OnboardingCreators from "../../Redux/OnboardingRedux";
import CancelTrialModal from "./CancelTrialModal";
import Icon from "../../Icon";
import AddCardModal from "./AddCardModal";
import UnpauseModal from "./UnpauseModal";
import CancelSubModal from "./CancelSubModal";
import './styles.less';
import AvailablePlans from './AvailablePlans';
import ComparePlansComponent from './MockingData/ComparePlansComponent';
import CompareHeaders from './CompareHeaders';
import LimitChangeModal from '../BillingSubPage/LimitChangeModal';
import { openNotificationWithIcon } from '../Notification';
import { DefaultMsg } from '../Notification/notification-message';


const PlanSubPage = (
  {
    theme,
    isMobile,
    category,
    plan,
    onBoarding,
    user,
    getSubscriptionsList,
    setSwitchPlan,
    cancelSub,
    cancelTrial,
    reset,
    chargeLink,
    setYearlySubscription,
    dashboardInfo,
    ...props
  }) => {

  const {
    isYearlySubscription,
    cancelSubFetching,
    cancelTrialFetching,
    cancelSubResult,
    cancelTrialResult,
    undoPauseSubFetching,
    undoPauseSubResult,
    isRestoredCancel,
    chargebeeCardFetching,
  } = onBoarding;

  const { t } = useTranslation();
  const windowRef = useRef(null);
  /*select, switch, delete, undoCancel*/
  const [modalType, setModalType] = useState('switch');
  const [visible, setVisible] = useState(false);
  const [subModalType, setSubModalType] = useState('');
  const [visibleSub, setVisibleSub] = useState(false);

  function onChangeSwitch(state) {
    if (state === isYearlySubscription) return;
    setYearlySubscription(state);
  }

  const currentSubscription = user?.subscriptions?.[0];
  const priceType = isYearlySubscription ? 'yearly' : 'monthly';

  let discountCash = 0;

  let price = currentSubscription?.plan?.price * utils.multiplyPriceType?.[currentSubscription?.plan?.price_type?.id];

  if (onBoarding.discountResult) {
    discountCash = onBoarding.discountResult.type === 'value' ? onBoarding.discountResult.value : (price * 100 / 100) * onBoarding.discountResult.value;
  }

  const modalSubType = {
    cancelTrial: <CancelTrialModal isMobile={isMobile}
                                   setModal={setVisibleSub}
      setSubModal={setSubModalType}
      user={user}
      callBack={cancelTrial}
      fetching={cancelTrialFetching}
      currentSubscription={currentSubscription}
      priceType={onBoarding?.isYearlySubscription}
      isCurrentPlan={true}
      discount={onBoarding.discountResult}
      error={onBoarding.discountError}
      discountFetching={onBoarding.discountFetching}
      handleCheckDiscount={handleCheckDiscount}
      handleClearError={handleClearError}
      handleClearDiscount={handleClearDiscount}
    />,
    cancelSub: <CancelSubModal setVisible={setVisibleSub}
      theme={theme}
      callBack={() => cancelSub(currentSubscription?.id)}
      billingDate={dayjs(currentSubscription?.expire_date || currentSubscription?.next_payment_date).format('MMM DD, YYYY')}
      fetching={cancelSubFetching}
    />,
    unpause: <UnpauseModal callBack={() => props.undoPauseSub(currentSubscription?.id)}
      fetching={undoPauseSubFetching}
    />,
    addCard: <AddCardModal customClass={'add-card'}
      fetching={cancelTrialFetching || chargebeeCardFetching}
      paymentMethod={user?.card}
      billingInfo={{ country: props.defaultUserCountry }}
      onCancel={() => setSubModalType('cancelTrial')}
      onSubmit={cancelTrialWithCard}
      getCountries={props.getCountries}
      countries={onBoarding?.countries}
      isMobile={isMobile}
      theme={theme}
      setChargebeeCardFetching={props.setChargebeeCardFetching}
      errorSave={props.errorSave}
      intent={{
        result: onBoarding?.intentResult,
        error: onBoarding?.intentError,
        createIntent: props.createIntent,
        clearIntent: props.clearIntent,
        amount: onBoarding.discountResult ?
          (price * 100 - discountCash)
          :
          currentSubscription?.next_payment_amount,
      }}
    />,
  }

  function handleSelectPlan(plan) {
    const switchCategoryId = +Object.keys(onBoarding?.switchPlan)[0];
    if (user?.use_stripe && !Boolean(currentSubscription)) {
      setSwitchPlan({ [switchCategoryId]: plan });
      props.createStripeSession({ plan_pricing: plan?.prices?.[priceType]?.id })
    } else if (currentSubscription?.payment_status?.id === 2) {
      if (chargeLink) windowRef.current.focus();
      else props.getRecharge(currentSubscription?.id)
    } else {
      if (currentSubscription?.expire_date && currentSubscription?.next_payment_date === null && plan.name === currentSubscription?.plan.plan.name && currentSubscription?.plan?.price_type.id === priceType) {
        setModalType('undoCancel');
        setVisible(true);
      } else if (currentSubscription?.scheduled_plan?.plan?.name === plan.name && currentSubscription?.scheduled_plan?.price_type?.id === priceType ){
        setModalType('delete');
        setVisible(true);
      } else {
        setSwitchPlan({ [switchCategoryId]: plan })
        setModalType('switch');
      }
      setVisible(true);
    }
  }

  useEffect(() => {
    reset();
    if (windowRef.current) {
      windowRef.current.close();
      windowRef.current = null;
    }
    props.setRechargeLink(null);

    return () => {
      window.removeEventListener('beforeunload', closeModal);
      if (windowRef.current) {
        windowRef.current.close();
        windowRef.current = null;
      }

      props.setRechargeLink(null);
    }
  }, [])

  useEffect(() => {
    if (cancelSubResult) {
      notification.destroy();
      openNotificationWithIcon({
        key: `open${Date.now()}`,
        style: { width: '400px' },
        className: 'notification notification_delete',
        message: (
          <DefaultMsg
            text={'Your plan is now active.'}
            icon={'payment_added'}
            title={t(isRestoredCancel ? 'Schedule restored!' : 'Your plan change has been successfully scheduled')}
          />
        ),
      });
      setVisibleSub(false);
      reset();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [cancelSubResult])

  useEffect(() => {
    if (cancelTrialResult || undoPauseSubResult) {
      notification.destroy();
      openNotificationWithIcon({
        key: `open${Date.now()}`,
        style: { width: '400px' },
        className: 'notification notification_delete',
        message: (
          <DefaultMsg
            text={null}
            icon={'payment_added'}
            title={t('Your plan is now active.')}
          />
        ),
      });
      if (chargebeeCardFetching) props.setChargebeeCardFetching(false);
      setVisibleSub(false);
      reset();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [cancelTrialResult, undoPauseSubResult])


  useEffect(() => {
    if (chargeLink) {
      let popup = window.open(chargeLink, '_blank', `location=yes,height=570,left=${(window.innerWidth - 520) / 2},top=160,width=520,scrollbars=yes,status=yes`);
      windowRef.current = popup;
      window.addEventListener('beforeunload', closeModal);
      let timer = setInterval(function () {
        if (popup && popup?.closed) {
          windowRef.current = null;
          props.setRechargeLink(null);
          clearInterval(timer);
          window.removeEventListener('beforeunload', closeModal);
        }
      }, 1000);
    } else {
      props.getUserInfo();
    }
  }, [chargeLink]);


  function closeModal() {
    windowRef.current.close();
    windowRef.current = null;
  }

  function handleCheckDiscount(code) {
    props.checkDiscountCode(code);
  }

  function cancelTrialWithCard(data) {
    let fullData = {
      'discount_code': onBoarding?.discountResult?.id || null,
      payment: {
        intent_id: data.intent_id,
        type: 'card',
      }
    }
    cancelTrial(fullData);
  }

  function handleClearError() {
    if (onBoarding.discountError) props.checkDiscountCodeReset();
  }

  function handleClearDiscount() {
    if (onBoarding.discountResult) props.checkDiscountCodeReset();
  }

  return (
    <div className="plan-subpage">
      <AvailablePlans onChangeSwitch={onChangeSwitch}
                      handleSelectPlan={handleSelectPlan}
                      setModal={setModalType}
                      setCancelTrial={() => {
                        setVisibleSub(true);
                        setSubModalType('cancelTrial');
                      }}
                      setUnpauseSub={() => {
                        setVisibleSub(true);
                        setSubModalType('unpause');
                      }}
                      fullHeight/>
      <span className="plan-subpage-block-custom-title">
        {t('Compare plans')}
      </span>
      <Divider type={'horizontal'} style={{ margin: '16px 0' }} />
      {!isMobile && <CompareHeaders onChangeSwitch={onChangeSwitch}
                                    handleSelectPlan={handleSelectPlan}
                                    setCancelTrial={() => {
                                      setVisibleSub(true);
                                      setSubModalType('cancelTrial');
                                    }}
                                    setUnpauseSub={() => {
                                      setVisibleSub(true);
                                      setSubModalType('unpause');
                                    }}
                                    setModal={setModalType}/>
      }
      {!isMobile && <Divider type={'horizontal'} style={{ margin: '16px 0' }} />}
      <ComparePlansComponent isMobile={isMobile} />
      <LimitChangeModal
        setModal={setModalType}
        visible={modalType === 'editLimits'}
        isMobile={isMobile}
        data={{
          storeLimit: dashboardInfo?.limits?.stores?.allowed < 50 ? 50 : dashboardInfo?.limits?.stores?.allowed,
          productLimit: dashboardInfo?.limits?.products?.allowed < 100 ? 100 : dashboardInfo?.limits?.products?.allowed,
          subscription: user?.subscriptions?.[0]
        }} />


  <Modal
    className="collection-list-modal collection-modal account-modal edit-card-modal edit-limits-modal"
    getContainer={() => document.getElementById('global-wrap')}
    {...(isMobile ? { transitionName: '' } : null)}
    open={visibleSub}
    centered={!isMobile}
    width={450}
    footer={null}
    closable="true"
    closeIcon={
      <Icon role="icon" type="close_modal" color="#707BA0" opacity={1} />
    }
    onCancel={() => setVisibleSub(false)}
    destroyOnClose
  >
   {modalSubType[subModalType]}
  </Modal>

  <ModalManageSubscriptions
    visible={visible}
    setVisible={setVisible}
    modalType={modalType}
    setModalType={setModalType}
    getSubscriptionsList={getSubscriptionsList}
    cancelSubscriptionFetching={props.saveFetching}
    withoutSelectPlan={true}
  />

      </div >
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.userInfo,
  onBoarding: state.onBoarding,
  category: state.category,
  plan: state.plan,
  subscriptions: state.setting.subscriptions,
  subscribe: state.setting.subscribe,
  fetching: state.setting.fetching,
  userLoading: state.users.fetching,
  chargeLoading: state.setting.chargeLoading,
  chargeLink: state.setting.chargeLink,
  saveResult: state.setting.saveResult,
  saveFetching: state.setting.saveFetching,
  errors: state.setting.errors,
  theme: state.nav.theme,
  isMobile: state.nav.isMobile,
  maxProducts: state.plan.maxProducts,
  defaultUserCountry: state.setting.userCountry,
  dashboardInfo: state.dashboard.result,
});

const mapDispatchToProps = (dispatch) => ({
  cancelSub: (id) => dispatch(OnboardingActions.cancelSubRequest(id)),
  cancelTrial: (data) => dispatch(OnboardingActions.cancelTrialRequest(data)),
  reset: () => dispatch(OnboardingCreators.changeSubscriptionReset()),
  getCountries: () => dispatch(OnboardingActions.countriesRequest()),
  getSubscriptionsList: (filter) => dispatch(SettingCreators.getSubscriptionsListRequest(filter)),
  getRecharge: (data) => dispatch(SettingCreators.getRechargeRequest(data)),
  setRechargeLink: (data) => dispatch(SettingCreators.setRechargeLink(data)),
  getUserInfo: () => dispatch(Creators.userRequest()),
  createIntent: data => dispatch(OnboardingActions.createIntentRequest(data)),
  clearIntent: () => dispatch(OnboardingActions.createIntentReset()),
  setSwitchPlan: (plan) => dispatch(OnboardingActions.setSwitchPlan(plan)),
  undoPauseSub: (id) => dispatch(OnboardingActions.undoPauseSubRequest(id)),
  setChargebeeCardFetching: (value) => dispatch(OnboardingActions.setChargebeeCardFetching(value)),
  createStripeSession: (value) => dispatch(OnboardingActions.createStripeSessionRequest(value)),
  errorSave: (error) => dispatch(ErrorsActions.errorSave(error)),
  checkDiscountCode: code => dispatch(OnboardingActions.checkDiscountCodeRequest(code)),
  checkDiscountCodeReset: () => dispatch(OnboardingActions.checkDiscountCodeReset()),
  setYearlySubscription: (data) => dispatch(OnboardingCreators.setYearlySubscription(data)),
  getDashboardInfo: (params) =>
    dispatch(Creators.DashboardCreators(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanSubPage);
